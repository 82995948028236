<template>
  <div class="editor_box report_editor">
    <el-dialog
      :title="$locale.report_editor.tooltip.add_report"
      :visible.sync="isCreateDialogVisible"
      width="30%">
      <span>
        <template v-if="createModel !== null">
          <el-form :model="createModel" size="mini" label-width="80px" labelPosition="right">
            <el-form-item :label="$locale.report_editor.form.name">
              <el-input v-model="createModel.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$locale.report_editor.form.type">
              <el-select style="width: 100%;" v-model="createModel.type">
                <el-option
                  v-for="item in reportTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small" @click="isCreateDialogVisible = false">{{$locale.main.button.cancel}}</el-button>
        <el-button icon="el-icon-success" size="small" type="primary" @click="addMenu">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>

    <el-container>
      <el-header height="">
        {{$locale.report_editor.header.title}}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.report_editor.tooltip.add_report"
                placement="bottom">
                <el-button style="display: block;" size="small" icon="el-icon-plus" @click="onCreate(null)">
                  {{ $locale.main.button.add }}
                </el-button>
              </el-tooltip>
            </div>
            <el-scrollbar class="tree-scroll">
              <el-tree
                ref="tree"
                lazy
                draggable
                :props="props"
                :load="loadNode"
                node-key="id"
                :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span
                    :class="{ 'selected-node': editorModel !== null && editorModel.id === data.id }"
                    style="width: 100%;"
                    @click="openEditor(data, node)">
                    <span style="font-size: 16px;" :class="getTreeIcon(data, node.expanded)"></span> {{ node.label }}
                  </span>
                  <span v-if="editorModel !== null && editorModel.id === data.id">
                    <el-tooltip
                      v-if="data.report_type === 'report_group'"
                      slot="reference"
                      class="item"
                      effect="dark"
                      :content="$locale.report_editor.tooltip.add_report"
                      placement="bottom">
                      <el-button class="btn_visible" size="mini" icon="el-icon-plus" circle @click="onCreate(data.id)"></el-button>
                    </el-tooltip>
                    <!--<el-tooltip :content="$locale.report_editor.tooltip.download" placement="bottom">
                      <el-button
                        v-show="['document', 'xml'].indexOf(data.report_type) !== -1"
                        class="btn_visible"
                        size="mini"
                        icon="el-icon-download"
                        circle
                        @click="downloadTemplate(data)">
                      </el-button>
                    </el-tooltip>-->
                    <el-tooltip :content="$locale.report_editor.tooltip.upload" placement="bottom">
                      <el-button
                        v-show="['report', 'document', 'xml'].indexOf(data.report_type) !== -1"
                        class="btn_visible"
                        size="mini"
                        icon="el-icon-upload2"
                        circle
                        @click="openUpload(data.report_type)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip :content="$locale.report_editor.tooltip.edit" placement="bottom">
                      <el-button
                        v-show="['report', 'document', 'xml'].indexOf(data.report_type) !== -1"
                        class="btn_visible"
                        size="mini"
                        icon="el-icon-s-tools"
                        circle
                        @click="editReport(data)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip :content="$locale.main.button.delete" placement="bottom">
                    <el-button
                      class="btn_visible"
                      size="mini"
                      type="danger"
                      icon="el-icon-minus"
                      circle
                      @click="remove(node, data)">
                    </el-button>
                    </el-tooltip>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <div v-if="editorModel !== null" class="tool_box">
              <div class="tool_header">
                {{ titleForm }} <b>({{ $locale.report_editor.types[editorModel.report_type] }})</b>
              </div>
            </div>
            <div class="form-container">
              <component
                v-if="editorModel !== null"
                @cancelCard="typeComponent = null"
                v-model="editorModel"
                :key="generateGuid()"
                :is="typeComponent"
                :activeNode="activeNode">
              </component>
            </div>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>

    <el-dialog
      :title="$locale.report_editor.form.select_file"
      :visible.sync="isUploadDialogVisible"
      width="20%">
      <template v-if="editorModel !== null">
        <el-upload
          :headers="getHeaders()"
          class="upload-demo"
          ref="upload"
          :action="`${$config.api}/reporteditor/reports/${editorModel.id}/templates`"
          :on-change="numberFiles"
          :file-list="fileList"
          :auto-upload="false"
          :accept="acceptFile">
          <el-button size="small" type="primary" icon="el-icon-upload2">{{ $locale.report_editor.form.download }}</el-button>
        </el-upload>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small" @click="isUploadDialogVisible = false">{{ $locale.main.button.cancel }}</el-button>
        <el-button icon="el-icon-success" size="small" type="primary" @click="saveFile">{{ $locale.main.button.save }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GroupForm from './components/GroupForm'
import ReportForm from './components/ReportForm'
import Report from './Models/Reports'

import Node from 'element-ui/packages/tree/src/model/node'
export default {
  components: {
    GroupForm,
    ReportForm
  },

  inject: ['addMainTab'],

  data () {
    return {
      fileList: [],
      reportTypes: [
        {
          value: 'report_group',
          label: this.$locale.report_editor.form.report_group
        },
        {
          value: 'report',
          label: this.$locale.report_editor.form.report
        },
        {
          value: 'document',
          label: this.$locale.report_editor.form.document
        },
        {
          value: 'xml',
          label: this.$locale.report_editor.form.xml
        }
      ],
      activeNode: null,
      typeComponent: null,
      isCreateDialogVisible: false,
      isUploadDialogVisible: false,
      acceptFile: '.docx',
      props: {
        isLeaf: 'is_leaf',
        label: 'name',
        children: 'child'
      },
      selectParentId: null,
      selectNode: null,
      editorModel: null,
      createModel: null,
      defaultModel: {
        name: null,
        parent_id: this.selectParentId,
        type: null
      },
      titleForm: null
    }
  },

  watch: {
    isCreateDialogVisible: function (val) {
      if (val === false) {
        this.selectParentId = null
        this.createModel = null
      }
    }
  },

  methods: {
    getTreeIcon (data, expanded) {
      if (data.report_type === 'report_group' && !expanded) {
        return 'el-icon-folder'
      } else if (data.report_type === 'report_group' && expanded) {
        return 'el-icon-folder-opened'
      }

      let accessIcon = {
        report: 'el-icon-document-copy',
        document: 'el-icon-document',
        xml: 'el-icon-tickets'
      }

      return accessIcon[data.report_type]
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    // Tree
    async loadNode (node, resolve) {
      resolve(await this.loadEntities(node.level === 0 ? null : node.data.id))
    },
    async loadEntities (entityId = null) {
      let params =
        !entityId ? {
          spec: 'is_null',
          parent_id: entityId,
          order: 'report_type:asc,row_order:asc,name:desc'
        } : {
          parent_id: entityId,
          order: 'report_type:asc,row_order:asc,name:desc'
        }

      let data = await new Report().params(params).$get()

      data.forEach(d => {
        d.is_leaf = !d.has_children
      })

      return data
    },
    // Open edit entity
    async openEditor (data, node) {
      let report = await Report.find(data.id)
      if (report) {
        this.activeNode = node
        this.editorModel = report
        this.titleForm = this.editorModel.name
        data.report_type === 'report_group'
          ? (this.typeComponent = GroupForm)
          : (this.typeComponent = ReportForm)
      }
    },
    // Open create dialog
    async onCreate (reportId = null) {
      this.selectParentId = reportId
      this.isCreateDialogVisible = true
      this.defaultModel.parent_id = reportId
      this.createModel = new Report(this.defaultModel)

      this.editorModel = null
      this.activeNode = null
      this.typeComponent = null
    },
    // добавить сущность
    async addMenu () {
      let response = await this.createModel.save()

      if (this.selectParentId !== null) {
        if (response.report_type !== 'report_group') {
          this.$set(response, 'is_leaf', true)
        }
        this.$refs.tree.append(response, this.selectParentId)

        if (response.report_type === 'report_group') {
          let node = this.$refs.tree.getNode(this.selectParentId)
          node.isLeaf = false
        }
      } else {
        let node = new Node({
          parent: this.$refs.root,
          store: this.$refs.tree.store,
          data: response
        })
        node.level = 1
        this.$refs.tree.root.childNodes.push(node)
      }

      this.isCreateDialogVisible = false
    },
    // удалить сущность
    remove (node, data) {
      if (!data.id) {
        return false
      }
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        await this.editorModel.delete()
        this.$refs.tree.remove(data.id)
        this.activeNode = null
        this.typeComponent = null
        this.editorModel = null
      }).catch(error => { console.log(error) })
    },
    /* downloadTemplate (report) {
      let ext = {
        document: 'docx',
        xml: 'xml'
      }
      let fileType = {
        document: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xml: 'application/xml'
      }
      this.$http
        .get(`${this.$config.api}/reporteditor/reports/${report.id}/document/${ext[report.report_type]}`, {
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType[report.report_type] }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${report.name}.${ext[report.report_type]}`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => console.log('error occured'))
    }, */
    openStimulsoft (report) {
      this.addMainTab({ name: report.name,
        componentType: 'StimulsoftReport',
        payload: {
          filename: `${report.guid}.mrt`,
          reportId: report.id
        } })
    },
    openDocumentEditor (report) {
      this.addMainTab({ name: report.name,
        componentType: 'DocumentEditor',
        payload: {
          guid: this.generateGuid(),
          filename: `${report.guid}.docx`,
          reportId: report.id
        } })
    },
    openXmlEditor (report) {
      let me = this
      this.addMainTab({ name: report.name,
        componentType: 'XmlEditor',
        payload: {
          guid: this.generateGuid(),
          reportId: report.id
        },
        beforeClose: (component) => new Promise((resolve, reject) => {
          if (component.isEditXml()) {
            me.$confirm(me.$locale.main.message.close_xml_editor, me.$locale.main.message_title.warning, {
              confirmButtonText: me.$locale.main.button.yes,
              cancelButtonText: me.$locale.main.button.no,
              type: 'warning'
            }).then(() => {
              resolve()
            }).catch(() => {
              reject(new Error('canceled'))
            })
          } else {
            resolve()
          }
        }) })
    },
    editReport (data) {
      console.log(data)
      if (data.report_type === 'report') {
        this.openStimulsoft(data)
      } else if (data.report_type === 'document') {
        this.openDocumentEditor(data)
      } else if (data.report_type === 'xml') {
        this.openXmlEditor(data)
      }
    },
    openUpload (type) {
      let types = {
        report: '.mrt',
        document: '.docx',
        xml: '.xml'
      }
      this.acceptFile = types[type]
      this.fileList = []
      this.isUploadDialogVisible = true
    },
    saveFile () {
      this.$refs.upload.submit()
      this.isUploadDialogVisible = false
      this.$notify({
        title: this.$locale.main.message.success,
        message: this.$locale.main.message.saved,
        type: 'success'
      })
    },
    numberFiles (file, fileList) {
      if (this.acceptFile === '.docx' && file.raw.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.fileList = []
        this.$message.error(this.$locale.report_editor.form.error_format_mrt)
        return false
      }

      if (this.acceptFile === '.xml' && file.raw.type !== 'application/xml') {
        this.fileList = []
        this.$message.error(this.$locale.report_editor.form.error_format_xml)
        return false
      }

      if (this.acceptFile === '.mrt' && file.raw.type !== '') {
        this.fileList = []
        this.$message.error(this.$locale.report_editor.form.error_format_docx)
        return false
      }

      this.fileList = fileList.slice(-1)
    }
  }
}
</script>
<style src="./ReportEditor.css"></style>
