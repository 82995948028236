<template style="height: 100%">
  <div class="editor_box">
    <el-container>
      <el-header height="">
        {{ $locale.dashboard_editor.main.title }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.main.button.add"
                placement="top">
                <el-dropdown @command="addEntity($event)" trigger="click">
                  <el-button
                    icon="el-icon-plus"
                    size="small" plain>
                    {{ $locale.main.button.add }}
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="dashboard">{{ $locale.dashboard_editor.types.dashboard }}</el-dropdown-item>
                    <el-dropdown-item command="group">{{ $locale.dashboard_editor.types.group }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
            </div>
            <el-scrollbar :style="{height:'calc(100vh - 153px)'}" >
              <el-tree
                ref="tree"
                lazy
                :props="props"
                v-loading="loading"
                :load="loadNode"
                node-key="guid"
                :expand-on-click-node="false">
                <span class="custom-tree-node" style="width: calc(100% - 24px);" slot-scope="{ node, data }">
                  <span  :class="{ 'selected-node': (editorModel && (editorModel.guid === data.guid)) }" style="width: calc(100% - 140px);" @click="select(data)">
                    <i style="font-size: 16px;" :class="{'el-icon-folder': data.type === 'group' && !node.expanded, 'el-icon-folder-opened': data.type === 'group' && node.expanded, 'el-icon-data-line': data.type === 'dashboard'}"></i><span style="padding-left: 5px; display: inline-block; vertical-align: top; white-space: nowrap;overflow: hidden;text-overflow: ellipsis; width: calc(100% - 40px)">{{ node.label }} <b>(id: {{data.id}})</b></span>
                  </span>
                  <span v-if="editorModel && (editorModel.guid === data.guid)">
                    <el-dropdown @command="addEntity($event, data)" v-show="data.type === 'group'" trigger="click">
                      <el-button type="text" size="small"><i class="el-icon-plus el-icon-left"></i> {{ $locale.main.button.add }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="dashboard">{{ $locale.dashboard_editor.types.dashboard }}</el-dropdown-item>
                          <el-dropdown-item command="group">{{ $locale.dashboard_editor.types.group }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button v-show="data.type === 'dashboard'" type="text" size="small" @click="openEditor(data.id)"><i class="el-icon-check el-icon-left"></i> {{ $locale.main.button.preview }}</el-button>
                    <el-popover
                      placement="top"
                      width="160">
                        <p>{{ $locale.main.message.confirm }}</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="small" type="text">{{ $locale.main.button.cancel }}</el-button>
                          <el-button type="primary" size="small" @click="deleteEntity(data)">{{ $locale.main.button.delete }}</el-button>
                        </div>
                        <el-button type="text" size="small" style="color:red" slot="reference"><i class="el-icon-delete el-icon-left"></i> {{ $locale.main.button.delete }}</el-button>
                    </el-popover>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <div v-show="editorModel" class="tool_box">
              <div class="tool_header">
                {{ formTitle }}
              </div>
            </div>
            <el-main v-if="editorModel">
              <el-form :model="editorModel" style="height: calc(100% - 40px)" size="mini">
                <el-form-item prop="name" :label="$locale.main.fields.name">
                  <el-input v-model="editorModel.name"></el-input>
                </el-form-item>
              </el-form>
              <div style="text-align: right">
                <el-button type="primary" @click="save" size="small" icon="el-icon-success">
                  {{ $locale.main.button.save }}
                </el-button>
              </div>
            </el-main>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Dashboard from './Models/Dashboard'
import DashboardTree from './Models/DashboardTree'
import DashboardGroup from './Models/DashboardGroup'
import Node from 'element-ui/packages/tree/src/model/node'

export default {
  name: 'DashboardEditor',
  inject: ['addMainTab'],
  data () {
    return {
      props: {
        isLeaf: 'is_leaf',
        label: 'name'
      },
      dashboards: [],
      formTitle: null,
      editorModel: null,
      loading: true,
      types: {
        dashboard: {
          Model: Dashboard,
          parent_field: 'group_id'
        },
        group: {
          Model: DashboardGroup,
          parent_field: 'parent_id'
        }
      }
    }
  },
  methods: {
    async loadNode (node, resolve) {
      this.loading = true
      if (node.level === 0) {
        resolve(await this.loadTree())
      } else {
        resolve(await this.loadTree(node.data.id))
      }
      this.loading = false
    },
    async loadTree (parentId = null) {
      return new DashboardTree().params({ spec: parentId ? 'equals' : 'is_null', parent_id: parentId, order: 'row_order:asc,id:desc' }).get()
    },
    async save () {
      if (!this.editorModel) {
        return false
      }
      await this.editorModel.save()
      this.$set(this.$refs.tree.getCurrentNode(), 'name', this.editorModel.name)
    },
    async addEntity (type, parent = null) {
      let answer = await this.$prompt(this.$locale.main.fields.name, `${this.$locale.object_editor.add_entity.title} (${this.$locale.dashboard_editor.types[type]})`, {
        confirmButtonText: this.$locale.main.button.add,
        cancelButtonText: this.$locale.main.button.cancel,
        inputValidator (value) {
          return !!(value || '').trim()
        }
      })
      if (answer.action !== 'confirm') {
        return false
      }
      let entity = new this.types[type].Model({})
      entity.name = answer.value
      if (parent) {
        entity[this.types[type].parent_field] = parent.id
      }
      let response = await entity.save()
      if (parent) {
        this.$refs.tree.append(Object.assign({ type: type, leaf: type === 'dashboard' }, response), parent.guid)
      } else {
        let node = new Node({ parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: Object.assign({ type: type, leaf: type === 'dashboard' }, response) })
        node.level = 1
        this.$refs.tree.root.childNodes.push(node)
      }
    },
    async select (node) {
      if (!node) {
        return false
      }
      this.formTitle = this.$locale.dashboard_editor.editor[node.type]
      this.editorModel = await new this.types[node.type].Model().params({ fields: 'id,name,guid' }).find(node.id)
    },
    async deleteEntity (node) {
      let entity = new this.types[node.type].Model({ id: node.id })
      await entity.delete()
      this.$refs.tree.remove(node.guid)
      this.editorModel = null
    },
    async openEditor (id) {
      this.addMainTab({ name: this.editorModel.name,
        componentType: 'DashboardEditorViewer',
        payload: {
          dashboardId: id
        } })
    }
  }
}
</script>

<style scoped>

</style>
