import { ActionTree } from 'vuex'
import { CoordinateSystemState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, CoordinateSystemLoadingEvent, CoordinateSystemLoadedEvent, CoordinateSystemCreatedEvent, CoordinateSystemUpdatedEvent, CoordinateSystemDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { CoordinateSystemAPI } from '@/services/MapEditor/infrastructure/api/CoordinateSystemAPI'

export const actions: ActionTree<CoordinateSystemState, any> = {
    [ActionTypes.ADD_COORDINATE_SYSTEM]: async ({ commit, dispatch }, data) => {
        try {
            commit(new CoordinateSystemLoadingEvent());
            let location = await APIClient.shared.request(new CoordinateSystemAPI.AddCoordinateSystem(data.payload));
            commit(new CoordinateSystemCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_COORDINATE_SYSTEM]: async ({ commit, dispatch }, data) => {
        try {
            commit(new CoordinateSystemLoadingEvent());
            let location = await APIClient.shared.request(new CoordinateSystemAPI.UpdateCoordinateSystem(data.payload));
            commit(new CoordinateSystemUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_COORDINATE_SYSTEM]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new CoordinateSystemAPI.DeleteCoordinateSystem(data.srid));
            commit(new CoordinateSystemDeletedEvent(data.srid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_COORDINATE_SYSTEMS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new CoordinateSystemLoadingEvent());
            let res = await APIClient.shared.request(new CoordinateSystemAPI.GetCoordinateSystems(data.payload));
            commit(new CoordinateSystemLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_COORDINATE_SYSTEMS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new CoordinateSystemLoadingEvent());
            let res = await APIClient.shared.request(new CoordinateSystemAPI.CountCoordinateSystems(data.payload));
            commit(new CoordinateSystemLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_COORDINATE_SYSTEM_BY_SRID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new CoordinateSystemAPI.GetCoordinateSystemBySrid(data.srid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
