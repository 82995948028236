<template>
    <div>
        <component
                v-for="(component, index) in components"
                :class="{'component': true, 'active-component': activeComponentGuid === component.guid}"
                :key="component.guid"
                v-bind="component.properties"
                :ref="'component_' + component.guid"
                :guid="component.guid"
                :is="component.type"
                v-model="model[component.properties.name]"
        >
            <div class="component-placeholder" v-if="activeComponentGuid !== component.guid" @click="setActiveComponent(component.guid)"></div>
        </component>
    </div>
</template>

<script>
export default {
  name: 'default-container',
  inject: {
    setActiveComponent: {
      default: () => {}
    }
  },
  props: {
    activeComponentGuid: {
      type: String,
      default: null
    },
    components: {
      type: Array,
      default () { return [] }
    },
    model: {
      type: Object,
      default () { return {} }
    }
  },
  methods: {
    /* получает свойства компонента */
    getComponentProperties (guid) {
      return this.components.find((item) => {
        return item.guid === guid
      }).properties
    }
  }
}
</script>

<style scoped>
    .component {
        position: relative;
    }
    .component-placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 102;
    }
    .component-placeholder:hover {
        background: #409eff;
        opacity: 0.2;
        cursor: pointer;
    }
    .active-component {
        outline: 1px dashed #409eff;
        position: relative;
        z-index: 101;
    }
    .active-component .component-placeholder {
        background: #409eff;
        opacity: 0.2;
    }
</style>
