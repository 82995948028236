import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'

export namespace XmlRouteAPI {

    export class AddXmlRoute implements APIRequest<string> {
        response: string;
        path = '/databusservice/xmlroutes';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: XmlRouteDTO) {}
    }

    export class UpdateXmlRoute implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: XmlRouteDTO) {
            this.path = `/databusservice/xmlroutes/${params.guid}`;
        }
    }

    export class DeleteXmlRoute implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/xmlroutes/${guid}`;
        }
    }

    export class CountXmlRoutes implements APIRequest<XmlRouteDTO> {
        response: XmlRouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/xmlroutes?*[func]=count'
        }
    }

    export class GetXmlRoutes implements APIRequest<XmlRouteDTO> {
        response: XmlRouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/xmlroutes?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetXmlRouteByGuid implements APIRequest<XmlRouteDTO> {
        response: XmlRouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/xmlroutes/${guid}`;
        }
    }
}