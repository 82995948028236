<template>
  <div class="vue-query-builder" :class="{ 'vue-query-builder-styled': styled }">
    <query-builder-group
      :index="0"
      :query.sync="query"
      :labels="labels"
      :maxDepth="maxDepth"
      :depth="depth"
      :styled="styled"
      :rules="buildRules"
      :size-control="sizeControl"
      :type="type">
    </query-builder-group>
  </div>
</template>

<script>
import QueryBuilderGroup from './QueryBuilderGroup.vue'
import deepClone from './utilities.js'

export default {
  name: 'QueryBuilder',

  props: {
    fields: {
      type: Array,
      default () {
        return []
      }
    },

    labels: {
      type: Object,
      default () {
        return {
          matchType: this.$locale.main.query_builder.labels.match_type,
          matchTypes: [
            {
              id: 'and',
              label: this.$locale.main.query_builder.labels.and
            },
            {
              id: 'or',
              label: this.$locale.main.query_builder.labels.or
            }
          ],
          groupLabel: this.$locale.main.query_builder.labels.group,
          addRule: this.$locale.main.query_builder.labels.add_rule,
          removeRule: '<i class="el-icon-delete"></i>',
          addGroup: '<i class="el-icon-folder-add"></i>',
          removeGroup: '<i class="el-icon-delete"></i>',
          editGroup: '<i class="el-icon-edit"></i>',
          rulePlaceholder: this.$locale.main.query_builder.labels.rule_placeholder,
          filterTypePlaceholder: 'Filter type'
        }
      }
    },

    type: {
      type: String,
      default: 'state',
      validator: function (value) {
        return [
          'state',
          'extended_object'
        ].indexOf(value) !== -1
      }
    },

    styled: {
      type: Boolean,
      default: true
    },

    maxDepth: {
      type: Number,
      default: 5,
      validator: function (value) {
        return value >= 1
      }
    },

    value: Object,

    sizeControl: {
      type: String,
      default: 'mini'
    },

    operationTypes: {
      type: Array,
      required: true
    },

    fieldTypeToOperationType: {
      type: Object,
      required: true
    }
  },

  components: {
    QueryBuilderGroup
  },

  computed: {
    buildRules () {
      return this.buildRuleTree(this.fields)
    }
  },

  data () {
    return {
      depth: 1,
      query: {
        logical_operator: this.labels.matchTypes[0].id,
        children: []
      },
      fieldTypeToPlaceholder: {
        string_field: this.$locale.main.query_builder.field_placeholder.string_field,
        text_field: this.$locale.main.query_builder.field_placeholder.text_field,
        integer_field: this.$locale.main.query_builder.field_placeholder.integer_field,
        float_field: this.$locale.main.query_builder.field_placeholder.float_field,
        date_field: this.$locale.main.query_builder.field_placeholder.date_field,
        time_field: this.$locale.main.query_builder.field_placeholder.time_field,
        datetime_field: this.$locale.main.query_builder.field_placeholder.datetime_field,
        boolean_field: null,
        xref_field: this.$locale.main.query_builder.field_placeholder.xref_field,
        xref_multi_field: this.$locale.main.query_builder.field_placeholder.xref_multi_field
      },
      fieldTypeToInputType: {
        string_field: 'StringInput',
        text_field: 'TextInput',
        integer_field: 'NumberInput',
        float_field: 'FloatInput',
        date_field: 'DateInput',
        time_field: 'TimeInput',
        datetime_field: 'DatetimeInput',
        boolean_field: 'FlagInput',
        xref_field: 'XrefInput',
        xref_multi_field: 'XrefMultiInput'
      }
    }
  },

  mounted () {
    this.$watch(
      'query',
      newQuery => {
        if (JSON.stringify(newQuery) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(newQuery))
        }
      }, {
        deep: true
      })

    this.$watch(
      'value',
      newValue => {
        if (JSON.stringify(newValue) !== JSON.stringify(this.query)) {
          this.query = deepClone(newValue)
        }
      }, {
        deep: true
      })

    if (typeof this.$options.propsData.value !== 'undefined') {
      this.query = Object.assign(this.query, this.$options.propsData.value)
    }
  },

  methods: {
    buildRuleTree (fields) {
      let rules = []

      fields.forEach(field => {
        if (field.fieldTypeId !== 'field_group') {
          rules.push({
            id: field.id,
            fieldId: field.fieldId,
            fieldTypeId: field.fieldTypeId,
            label: field.name,
            inputType: this.fieldTypeToInputType[field.fieldTypeId],
            operators: this.buildOperators(field.fieldTypeId),
            placeholder: this.fieldTypeToPlaceholder[field.fieldTypeId],
            children: [],
            leaf: true
          })
        } else {
          rules.push({
            id: field.id,
            label: field.name,
            children: this.buildRuleTree(field.children),
            leaf: false
          })
        }
      })

      return rules
    },

    buildOperators (fieldTypeId) {
      let operators = []

      this.operationTypes.forEach(operatorType => {
        if (this.fieldTypeToOperationType[fieldTypeId].indexOf(operatorType.id) !== -1) {
          operators.push({
            id: operatorType.id,
            label: this.$locale.bi_editor.operation_types[operatorType.name]
          })
        }
      })

      return operators
    }
  }
}
</script>

<style>
  .vue-query-builder-styled div:not(.input-group) .el-input-number {
    width: 100%;
  }

  .vue-query-builder-styled .vqb-group {
    padding: 15px;
    background-color: #f5f5f5;
    border-left: 2px solid #909090;
  }

  .vue-query-builder-styled .vqb-rule {
    background-color: #ececec;
    border-left: 2px solid #ddd;
    padding: 15px;
  }

  .vue-query-builder-styled .vqb-rule .el-form-item {
    margin-bottom: 0;
  }

  .vue-query-builder-styled .vqb-group label {
    color: #e91313;
  }

  .vue-query-builder-styled .vqb-rule label {
    color: #0066aa;
  }

  .vue-query-builder-styled .vqb-group.depth-1 {
    border-left: 2px solid #d2d45e;
  }

  .vue-query-builder-styled .vqb-group.depth-1 .vqb-rule,
  .vue-query-builder-styled .vqb-group.depth-2 {
    border-left: 2px solid #8bc34a;
  }

  .vue-query-builder-styled .vqb-group.depth-2 .vqb-rule,
  .vue-query-builder-styled .vqb-group.depth-3 {
    border-left: 2px solid #00bcd4;
  }

  .vue-query-builder-styled .vqb-group.depth-3 .vqb-rule,
  .vue-query-builder-styled .vqb-group.depth-4 {
    border-left: 2px solid #ff5722;
  }

  .vue-query-builder-styled .close {
    opacity: 1;
    color: rgb(150,150,150);
  }

  .vue-query-builder-styled .vqb-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    .vue-query-builder-styled .vqb-rule.form-inline .form-group {
      display: block;
    }
  }
</style>
