<script type="ts">
import Treeselect from '@bingosoftnn/vue-treeselect'
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import ElCollapse from 'element-ui/packages/collapse/src/collapse.vue'
import ElCollapseItem from 'element-ui/packages/collapse/src/collapse-item.vue'
import EntitiesQuery from '@/services/MapEditor/application/query/EntitiesQuery'
import EntityByIdQuery from '@/services/MapEditor/application/query/EntityByIdQuery'

export default {
  name: "PropertySet",
  inject: ['getEventBus', 'getQueryBus'],
  props: {
    items: {
      type: Array,
      default: () => []
    }, 
    dto: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectOptions: {},
      selectValues: {}
    }
  },
  watch: {
    isEntityLoading: (state) => state
  },
  computed: {
    isEntityLoading() {
      return this.$store.getters['Entity/isLoading'];
    }
  },
  methods: {
    createProperties() {
      return this.createFields();
    },
    createFields() {
      let fields = [];  
      this.items.forEach((field) => {
        switch(field.type) {
          case 'geometry_object':
            fields.push(
              this.createGeometryObject(field)
            );
            break;
          case 'geometry_field':
            fields.push(
              this.createGeometryField(field)
            );
            break;
          default:
            break;
        }
      });
      return fields;
    },
    createOptions(name) {
      if (typeof this.selectOptions[name] == "undefined") {
        this.$set(this.selectOptions, name, []);
        this.$set(this.selectValues, name, null);
      }
    },
    createGeometryObject(field) { 
      return this.createSelectField(field, async (component) => {
        await this.getObjectList(field.id);
      });     
    },
    createGeometryField(field) { 
      return this.createSelectField(field, async (component) => {
        await this.getGeometryFieldList(field.id);
      });     
    },
    createSelectField(field, optionsCallback) {
      this.createOptions(field.id);
      let component = this.$createElement(Treeselect, {
        props: {
          placeholder: this.$locale.main.fields.select,
          noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
          loadingText: this.$locale.main.fields.loading_text,
          noResultsText: this.$locale.main.fields.no_results_text,
          normalizer: this.normalizeEntityList,
          searchable: true,
          cacheOptions: true,
          appendToBody: true,
          options: this.selectOptions[field.id],
          value: this.selectValues[field.id],
          disabled: this.readonly || this.disableSelect(field.id)
        },
        on: {
          open: () => {
            optionsCallback(component)
          },
          select: (record) => {
            this.selectChange(field.id, record);            
          },
          input: (value) => {
            this.inputChange(field.id, value);
          }
        }
      });
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.main.fields[field.name],
          rules: {
            required: !this.readonly && true,
            trigger: 'blur',
            validator: (rules, value, callback) => {
              if (component.data.props.value) {
                callback()
              } else {
                callback(new Error(this.$locale.main.message.required_field));
              }
            }
          }
        }
      }, [component]);
    },
    async getObjectList(optionsId) {
      let entities = await this.getQueryBus().execute(
        new EntitiesQuery({
          type: "registry"
        })
      );       
      this.selectOptions[optionsId] = this.normalizeEntities(entities);
    },
    async getGeometryFieldList(optionsId) {
      let objectId = this.getProperty('geometry_object_id');
      if (objectId) {
        let entities = await this.getQueryBus().execute(
          new EntitiesQuery({
            object_id: objectId,
            type: 'geometry_field'
          })
        );
        this.selectOptions[optionsId] = this.normalizeEntities(entities);
      }
    },
    setValues(values) {
      if (values.length) {
        for (let i = 0; i < values.length; i += 1) {
          if (typeof values[i].value !== 'undefined') {
            this.setValue(values[i].type, values[i].id, values[i].value);
          }
        }
      }
    },
    setValue(type, name, value) {
      switch(type) {
        case 'geometry_object':
        case 'geometry_field':
          this.setEntityValue(name, value);
          break;
        default:
          break;
      }
    },
    async setEntityValue(name, id) {
      if (id !== null) {
        let entities = await this.getQueryBus().execute(
          new EntityByIdQuery(id)
        );
        this.selectOptions[name] = this.normalizeEntities(entities);
        this.selectValues[name] = id;
      } else {
        this.selectOptions[name] = [];
        this.selectValues[name] = null;
      }      
    },
    normalizeEntities(entities) {
      if (entities.data && entities.data instanceof Array) {
        return entities.data;
      }
      if (entities.data && typeof entities.data == 'object') {
        return [entities.data];
      }
      if (typeof entities.data == 'undefined') {
        return entities;
      }
      return [];
    },
    inputChange(name, value) {
      this.setProperty(name, value);              
      this.clearRelatedOnInputChange(name, value);
      this.selectValues[name] = value;  
    },
    selectChange(name, value) {
      this.setProperty(name, value['id']);
      this.clearRelatedOnSelectChange(name, value['id']);
    },
    setProperty(name, value) {
      let propExists = false;
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          this.dto.properties[i].value = value;
          propExists = true;
          break;
        }
      }
      if (!propExists) {
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id == name) {
            this.dto.properties.push({
              id: this.items[i].id,
              type: this.items[i].type,
              value: value
            });
            break;
          }
        }
      }
    },
    getProperty(name) {
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          return this.dto.properties[i].value;
        }
      }
      return null;
    },
    clearRelatedOnInputChange(name, value) {
      switch(name) {
        case 'geometry_object_id':
          this.clearGeometryField('geometry_field_id', value);
        default:
          break;
      }
    },
    clearRelatedOnSelectChange(name, value) {
      switch(name) {
        case 'geometry_object_id':
          this.resetGeometryField('geometry_field_id', value);
        default:
          break;
      }
    },
    clearGeometryField(name, value) {
      if (typeof value == 'undefined') {
        this.inputChange(name);
      }
    },
    async resetGeometryField(name) {
      await this.getGeometryFieldList(name);
      if (this.selectOptions[name].length == 1) {
        this.setProperty(name, this.selectOptions[name][0].id);
        this.selectValues[name] = this.selectOptions[name][0].id;        
      } else {
        this.selectValues[name] = undefined;
        this.setProperty(name);
      }
    },
    disableSelect(name) {
      switch(name) {
        case 'geometry_field_id':
          for (let i = 0; i < this.dto.properties.length; i += 1) {
            if (this.dto.properties[i].id == 'geometry_object_id') {
              return typeof this.dto.properties[i].value == 'undefined';
            }
          }
          return this.dto.properties.length == 0;
        default:
          return false;
      }
    },
    normalizeEntityList(node) {
      return {
        id: node.id,
        label: node.name
      };
    }
  },
  mounted () { 
    this.getEventBus().$emit('propertiesMounted', this);
  },
  render (createElement) {
    return createElement("div", {
      class: {
        "property-set": true
      }
    }, this.createProperties());
  }
}

</script>