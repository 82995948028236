<template>
  <el-select
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    type="textarea"
    :rows="1"
    :placeholder="placeholder">
    <el-option
      v-for="item in xrefList"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'XrefInput',

  props: ['value', 'placeholder', 'xrefList']
}
</script>

<style type="text/css"></style>
