<template>
  <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="value" size="mini">
    <el-form-item :label="$locale.bi_editor.form.label.name" prop="name">
      <el-input v-model="value.name"></el-input>
    </el-form-item>

    <el-form-item class="code-input" :label="$locale.bi_editor.form.label.sql_statement" prop="sql_statement">
      <el-input :autosize="{ minRows: 6, maxRows: 20}" type="textarea" v-model="value.sql_statement"></el-input>
    </el-form-item>

    <el-form-item>
      <el-checkbox :label="$locale.bi_editor.form.label.is_materialized" v-model="value.is_materialized"></el-checkbox>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'UpdateQuery',
  props: {
    value: Object,
    rules: Object
  },
  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
}
</script>

<style type="text/css">
.code-input .el-textarea textarea {
  color: #409EFF;
  font-family: 'Courier New';
  font-size: 14px;
}
</style>
