import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import UserCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/UserCommandRepositoryInterface'
import UsersUploadFromFileCommand from '@/services/AccessEditor/application/command/UsersUploadFromFileCommand'
import { UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'

export default class UserCreateHandler implements CommandHandlerInterface
{
    private repo: UserCommandRepositoryInterface;

    constructor(repo: UserCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: UsersUploadFromFileCommand): Promise<void>
    {
        let item: UserUploadDTO = {
            role_id: command.getRoleId(),
            is_login_dynamic: command.getIsLoginDynamic(),
            upload_file: command.getUploadFile()
        };
        return this.repo.upload(item);
    }
}