<template>
  <div class="source-form">
    <el-form ref="form" :model="value" :rules="formRules" size="mini" label-position="top">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item :label="$locale.report_editor.form.select_source_type" prop="report_source_type_id">
            <el-select
              style="width: 100%;"
              v-model="value.report_source_type_id"
              :placeholder="$locale.report_editor.form.select_source_type"
              @change="sourceTypeChange($event)">
              <el-option
                v-for="item in reportSourceTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$locale.report_editor.form.source" prop="properties.id">
            <template v-if="value.report_source_type_id === 'extended_object'">
              <el-select
                style="width: 100%;"
                v-model="value.properties.id"
                :placeholder="$locale.report_editor.object_types.extended_object"
                @change="selectedExtendedObject($event)">
                <el-option
                  v-for="item in extendedObjectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>

            <template v-if="value.report_source_type_id === 'query'">
              <el-select
                style="width: 100%;"
                v-model="value.properties.id"
                :placeholder="$locale.report_editor.object_types.query"
                @change="selectedQuery($event)">
                <el-option
                  v-for="item in queryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>

            <template v-if="value.report_source_type_id === 'registry'">
              <registry-select-tree
                style="width: 100%;"
                type="registry"
                v-model="value.properties"
                :placeholder="$locale.report_editor.object_types.registry" />
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$locale.report_editor.form.alias" prop="properties.alias">
            <el-input
              :placeholder="$locale.report_editor.form.alias"
              v-model="value.properties.alias"
              @input="aliasChange">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-tooltip class="item" effect="dark" :content="$locale.report_editor.relation.add" placement="top">
        <el-button
          v-if="value.parent_id !== null"
          type="success"
          icon="el-icon-plus"
          circle
          style="float: right;"
          @click="value.properties.relation.push({ from: null, to: null })">
        </el-button>
      </el-tooltip>
      <el-form-item v-if="value.parent_id !== null" :label="$locale.report_editor.form.relations" prop="properties.relation">
        <el-scrollbar class="relation-container">
          <relation-box
            v-for="(item, index) in value.properties.relation"
            v-model="value.properties.relation[index]"
            :key="index"
            :from-field-list="fields.from"
            :to-field-list="fields.to">
            <div style="text-align: right">
              <el-tooltip class="item" effect="dark" :content="$locale.main.button.delete" placement="top">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="value.properties.relation.splice(index, 1)">
                </el-button>
              </el-tooltip>
            </div>
          </relation-box>
        </el-scrollbar>
      </el-form-item>
      <el-tooltip class="item" effect="dark" :content="$locale.report_editor.filter.add" placement="top">
        <el-button style="float: right;" circle size="mini" icon="el-icon-plus" type="success" @click="addFilter()"></el-button>
      </el-tooltip>
      <el-form-item :label="$locale.report_editor.form.filters" style="margin-bottom: 0;">
        <el-scrollbar class="scroll-container">
          <el-form-item class="multi-form" v-for="(filter, index) in value.report_filters" :key="filter.id">
            <filter-form
              ref="filterForm"
              v-model="value.report_filters[index]"
              :index="index"
              :field-list="fieldList"
              :filter-type-list="filterTypeList"
              :remove-filter="removeFilter" />
          </el-form-item>
        </el-scrollbar>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import FilterForm from './FilterForm'
import RelationBox from './RelationBox'
import ExtendedObjectField from '../../BIEditor/Models/ExtendedObjectField'

export default {
  name: 'SourceForm',

  props: ['value', 'sources', 'reportSourceTypes', 'extendedObjectList', 'queryList', 'filterTypeList'],

  components: {
    RegistrySelectTree,
    FilterForm,
    RelationBox
  },

  watch: {
    value () {
      this.localValue = this.value
    },

    'value.properties': (value, oldValue) => {
      if (value.id !== oldValue.id) {
        value.alias = !value.name ? null : value.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
        value.relation = []
      }
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        report_source_type_id: [{ required: true, message: 'Выберите тип источника', trigger: 'change' }],
        'properties.id': [{ required: true, message: 'Выберите источник', trigger: 'change' }],
        'properties.alias': [{ required: true, message: 'Введите псевдоним источника', trigger: 'blur' }]
        // 'properties.relation': [{ required: true, message: 'Укажите поля для связи источников', trigger: 'blur' }]
      },
      validForm: true,
      fields: {
        from: [],
        to: []
      },
      fieldList: []
    }
  },

  async mounted () {
    if (this.value.report_source_type_id === 'extended_object') {
      let response = await new ExtendedObjectField().params({ extended_object_id: this.value.properties.id }).$get()

      response.forEach(item => {
        this.fieldList.push({
          id: item.name,
          name: item.name,
          entity_type_id: item.entity_type_id
        })
      })
    } else if (this.value.report_source_type_id === 'query') {
      await this.$http.get(`${this.$config.api}/bieditor/queries/fields/${this.value.properties.id}`).then((response) => {
        response.data.forEach(item => {
          this.fieldList.push({
            id: item.name,
            name: item.name,
            entity_type_id: null
          })
        })
      })
    } else {
      await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.value.properties.id}&is_field=true`).then((response) => {
        this.fieldList.push({
          id: 'id',
          name: 'id',
          entity_type_id: null
        })
        response.data.data.forEach(item => {
          let attr = `attr_${item.id}_`
          this.fieldList.push({
            id: attr,
            name: `${item.name} (${attr})`,
            entity_type_id: item.entity_type_id
          })
        })
      })
    }

    if (this.value.parent_id !== null) {
      await this.loadFields(this.value.parent_id, 'from')
      await this.loadFields(this.value.id, 'to')
    }
  },

  methods: {
    isValid () {
      this.$refs.form.validate((valid) => {
        this.validForm = valid
      })

      return this.validForm
    },

    selectedExtendedObject (value) {
      let extendedObject = this.extendedObjectList.find(item => value === item.id)

      this.localValue.properties.name = extendedObject.name
      this.localValue.properties.alias = extendedObject.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    selectedQuery (value) {
      let query = this.queryList.find(item => value === item.id)

      this.localValue.properties.name = query.name
      this.localValue.properties.alias = query.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    sourceTypeChange (value) {
      let relation = this.localValue.properties.relation

      this.localValue.properties = {
        id: null,
        name: null,
        alias: null
      }

      if (this.localValue.parent_id && relation) {
        this.localValue.properties.relation = relation
      }
    },

    addFilter () {
      this.localValue.report_filters.push({
        report_source_id: this.localValue.id,
        report_filter_type_id: null,
        operation_type_id: null,
        properties: {
          field: null,
          type: null,
          value: null
        }
      })

      this.$emit('input', this.localValue)
    },

    removeFilter (index) {
      this.localValue.report_filters.splice(index, 1)

      this.$emit('input', this.localValue)
    },

    async loadFields (value, listId = 'from') {
      let source = this.sources.find(item => item.id === value)
      let response = []
      if (source.report_source_type_id === 'extended_object') {
        response = await new ExtendedObjectField().params({ extended_object_id: source.properties.id }).$get()
      } else if (source.report_source_type_id === 'query') {
        await this.$http.get(`${this.$config.api}/bieditor/queries/fields/${source.properties.id}`).then((res) => {
          response.push(...res.data)
        })
      }
      response.forEach((item) => {
        this.fields[listId].push({
          id: item.name,
          label: item.name,
          disabled: false
        })
      })
    },

    aliasChange (value) {
      value = value.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.localValue.properties.alias = value
    }
  }
}
</script>

<style type="text/css"></style>
