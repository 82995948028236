import { Nullable } from '@/core/domain/type/types'

export default class UserUpdateCommand
{
    private guid: string;
    private roleId: number;
    private name: string;
    private midname: Nullable<string>;
    private surname: Nullable<string>;
    private avatar: Nullable<File>;
    private isAvatarDeleted: Nullable<boolean>;
    private login: Nullable<string>;
    private password: Nullable<string>;
    private isAdmin: Nullable<boolean>;
    private isBlocked: Nullable<boolean>;
    private isSystem: Nullable<boolean>;
    private apiKey: Nullable<string>;
    private systemIps: Array<string>;

    constructor(
        guid: string,
        roleId: number,
        name: string,
        midname: Nullable<string>,
        surname: Nullable<string>,
        avatar: Nullable<File>,
        isAvatarDeleted: Nullable<boolean>,
        login: Nullable<string>,    
        password: Nullable<string>,      
        isAdmin: Nullable<boolean>,
        isBlocked: Nullable<boolean>,
        isSystem: Nullable<boolean>,
        apiKey: Nullable<string>,
        systemIps: Array<string>
    ) {
        this.guid = guid;
        this.roleId = roleId;
        this.name = name;
        this.midname = midname;
        this.surname = surname;
        this.avatar = avatar;
        this.isAvatarDeleted = isAvatarDeleted ?? false;
        this.login = login;
        this.password = password;
        this.isAdmin = isAdmin ?? false;
        this.isBlocked = isBlocked ?? false;
        this.isSystem = isSystem ?? false;
        this.apiKey = apiKey;
        this.systemIps = systemIps;
    }

    getClassName(): string
    {
        return 'UserUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getRoleId(): number
    {
        return this.roleId;
    }

    getName(): string
    {
        return this.name;
    }

    getMidname(): Nullable<string>
    {
        return this.midname;
    }

    getSurname(): string
    {
        return this.surname;
    }

    getAvatar(): Nullable<File>
    {
        return this.avatar;
    }

    getIsAvatarDeleted(): boolean
    {
        return this.isAvatarDeleted;
    }

    getLogin(): string
    {
        return this.login;
    }

    getIsAdmin(): boolean
    {
        return this.isAdmin;
    }

    getIsBlocked(): boolean
    {
        return this.isBlocked;
    }

    getIsSystem(): boolean
    {
        return this.isSystem;
    }

    getPassword(): string
    {
        return this.password;
    }

    getApiKey(): string
    {
        return this.apiKey;
    }

    getSystemIps(): Array<string>
    {
        return this.systemIps;
    }
}