<template>
    <el-row>
        <el-row style="padding: 15px">
            <el-col :span="12">
                <el-dropdown @command="addBlock" trigger="click">
                  <span class="el-dropdown-link">
                    {{ $locale.main.button.add }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(component, index) in components" :key="index" :command="component.type">{{ component.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div :style="style" class="work-area">
                    <draggable-block
                            v-for="block in blocks"
                            :key="block.guid"
                            :ref="block.guid"
                            :component="block.component"
                            :properties="block.properties"
                            :width="block.width"
                            :height="block.height"
                            :x="block.x"
                            :y="block.y"
                            @change-size="onChangeSize(block.guid, $event)"
                            @change-active="block.isActive = $event"
                    ></draggable-block>
                </div>
            </el-col>
            <el-col :span="12" >
                <el-select v-model="activeBlockGuid" clearable size="small"
                           style="width: calc(100% - 43px);padding-left: 4px;">
                    <el-option
                            v-for="item in blocks"
                            :key="item.guid"
                            :label="item.guid"
                            :value="item.guid">
                        <span style="float: left">{{ item.guid }}</span>
                    </el-option>
                </el-select>
                <el-button @click="deleteBlock(activeBlockGuid);activeBlockGuid=null" v-if="activeBlockGuid">remove</el-button>
                <el-form v-if="activeBlockGuid" size="mini" @submit.native.prevent>
                    <div class="entity_title">{{ $locale.main.button.settings }}</div>
                    <div v-for="(property, index) in getBlockPropertiesFields(activeBlockGuid)" :key="`${index}_${activeBlockGuid}`">
                        <span class="registry_fields_label" style="display: inline-block">
                            {{ property.label }}
                        </span>
                        <el-form-item style="margin-bottom: 5px">
                            <component
                                v-bind="property.bind"
                                :is="property.type"
                                @change="updateBlockProperty($event, property.name, activeBlockGuid)"
                            ></component>
                        </el-form-item>
                    </div>
                </el-form>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
import DraggableBlock from './DraggableBlock'

import StringEditor from './Editors/String'
import BooleanEditor from './Editors/Boolean'
import ImageSourceEditor from './Editors/ImageSource'
import StyleConditionsEditor from './Editors/StyleConditions'
import InteractiveEditor from './Editors/Interactive'
import IndicatorFieldsEditor from './Editors/IndicatorFields'
const Editors = {
  'String': 'StringEditor',
  'Boolean': 'BooleanEditor'
}

export default {
  name: 'HTML-interface-designer',
  components: {
    DraggableBlock,
    StringEditor,
    BooleanEditor,
    ImageSourceEditor,
    StyleConditionsEditor,
    InteractiveEditor,
      IndicatorFieldsEditor
  },
  provide () {
    return {
      isDesigner () {
        return true
      }
    }
  },
  props: {
    initialBlocks: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      activeBlockGuid: null,
      components: [
        {
          type: 'TextLabel',
          name: 'Текст'
        },
        {
          type: 'ImageBlock',
          name: 'Изображение'
        },
        {
          type: 'IndicatorsBlock',
          name: 'Блок индикаторов'
        }
      ],
      blocks: this.initialBlocks.map((block) => {
        if (Array.isArray(block.properties)) {
          block.properties = {}
        }
        return block
      })
    }
  },
  watch: {
    blocks: {
      immediate: false,
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.$emit('save', this.getBlocks())
        })
      }
    }
  },
  methods: {
    deleteBlock (guid) {
      this.blocks = this.blocks.filter(block => block.guid !== guid)
    },
    onChangeSize (guid, size) {
      let block = this.blocks.find((item) => item.guid === guid)
      if (!block) {
        console.warn(`block (guid=${guid}) - not found`)
        return false
      }
      this.$set(block, 'width', size.width)
      this.$set(block, 'height', size.height)
      this.$set(block, 'x', size.x)
      this.$set(block, 'y', size.y)
    },
    getBlocks () {
      return this.blocks
    },
    addBlock (component) {
      this.blocks.push({
        guid: this.generateGuid(),
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        component: component,
        properties: {},
        isActive: false
      })
    },
    getBlockPropertiesFields (guid) {
      let answer = []
      let component = this.$refs[guid][0].$refs.component
      let properties = component.getProperties()
      for (let property in properties) {
        let type = properties[property].editor ? properties[property].editor : Editors[properties[property].type.name]
        answer.push({
          label: properties[property].description,
          name: property,
          type: type,
          bind: {
            value: component.$props[property]
          }
        })
      }
      return answer
    },
    updateBlockProperty (value, property, guid) {
      let block = this.blocks.find((item) => item.guid === guid)
      this.$set(block.properties, property, value)
    }
  },
  computed: {
    /* activeBlock () {
      return this.blocks.find((block) => block.isActive)
    }, */
    style () {
      return `width: ${this.width}px; height: ${this.height}px;`
    }
  }
}
</script>

<style scoped>
.work-area {
    border: 1px solid black;
}
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    margin-bottom: 10px;
}
.el-icon-arrow-down {
    font-size: 12px;
}
</style>
