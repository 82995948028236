import XmlRouteCommandRepositoryInterface from '@/services/Databus/domain/repository/XmlRouteCommandRepositoryInterface'
import { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'
import store from '@/store'
import { AddXmlRoute, UpdateXmlRoute, DeleteXmlRoute } from '@/services/Databus/infrastructure/store/modules/XmlRoute/action-types'

export default class XmlRouteCommandRepository implements XmlRouteCommandRepositoryInterface
{
    insert(item: XmlRouteDTO): Promise<void>
    {
        return store.dispatch(new AddXmlRoute(item));
    }

    update(item: XmlRouteDTO): Promise<void>
    {
        return store.dispatch(new UpdateXmlRoute(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteXmlRoute(guid));
    }
}
