import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'

export enum ActionTypes {
    ADD_SMEV_SETTING = 'add_smev_setting',
    UPDATE_SMEV_SETTING = 'update_smev_setting',
    DELETE_SMEV_SETTING = 'delete_smev_setting',
    GET_SMEV_SETTINGS = 'get_smev_settings',
    GET_SMEV_SETTING_BY_GUID = 'get_smev_setting_by_guid'
}

export class AddSmevSetting implements FluxStandardAction {
    type = 'SmevSetting/' + ActionTypes.ADD_SMEV_SETTING
    constructor(public payload: SmevSettingDTO){}
}

export class UpdateSmevSetting implements FluxStandardAction {
    type = 'SmevSetting/' + ActionTypes.UPDATE_SMEV_SETTING
    constructor(public payload: SmevSettingDTO){}
}

export class DeleteSmevSetting implements FluxStandardAction {
    type = 'SmevSetting/' + ActionTypes.DELETE_SMEV_SETTING
    constructor(public guid: string){}
}

export class GetSmevSettings implements FluxStandardAction {
    type = 'SmevSetting/' + ActionTypes.GET_SMEV_SETTINGS
    constructor(public payload: object){}
}

export class GetSmevSettingByGuid implements FluxStandardAction {
    type = 'SmevSetting/' + ActionTypes.GET_SMEV_SETTING_BY_GUID
    constructor(public guid: string){}
}