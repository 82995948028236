import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
    changePassword: false,
    userId: '',
    roleId: ''
  },
  actions: {
    OAuthLogin: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/oauthservice/login/${data.providerId}`,
          data: {
            code: data.code
          },
          method: 'POST',
          hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            localStorage.clear()
            reject(err)
          })
      })
    },
    EsiaLogin: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        axios({ url: `${config.api}/esiaauthentication/auth/geturl`, method: 'GET' })
          .then(response => {
            if (response.data.url && response.data.state) {
              // window.location = response.data.url.replace('public_hearing.bingosoft-office.ru', 'localhost:8080')
              window.location = response.data.url
              // const esiaState = response.data.state
              // localStorage.setItem('esiaState', esiaState)
            }
          })
          .catch(err => {
            commit('error', err)
            localStorage.clear()
            reject(err)
          })
      })
    },
    LDAPLogin: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/ldapauthentication/auth/login`, data: user, method: 'POST', hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            localStorage.clear()
            reject(err)
          })
      })
    },
    defaultLogin: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/defaultauthentication/auth/login`, data: user, method: 'POST', hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            localStorage.clear()
            reject(err)
          })
      })
    },
    login: ({ commit, dispatch }, uuid) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/accesseditor/login/authorize`, data: { uuid: uuid }, method: 'POST', hideNotification: true })
          .then(response => {
            const token = response.data['token']
            const nameUser = response.data['name']
            const avatar = response.data['avatar_id']
            const changePassword = response.data['need_change_password']
            localStorage.setItem('user-token', token) // store the token in localstorage
            localStorage.setItem('nameUser', nameUser) // store the token in localstorage
            localStorage.setItem('avatar', avatar) // store the token in localstorage
            localStorage.setItem('changePassword', changePassword) // store the token in localstorage
            commit('success', token)
            // you have your token, now log in your user :)
            resolve(response)
          })
          .catch(err => {
            commit('error', err)
            localStorage.clear()
            reject(err)
          })
      })
    },
    logout: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.clear()
        resolve()
      })
    }
  },
  mutations: {
    success: (state, token) => {
      state.status = 'success'
      state.token = token
      state.changePassword = true
      try {
        state.userId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
        state.roleId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.role_id
      } catch (e) {
        state.userId = null
        state.roleId = null
      }
    },
    error: (state) => {
      state.status = 'error'
    },
    logout: (state) => {
      state.token = ''
      state.userId = ''
      state.roleId = ''
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    changePassword: state => state.changePassword,
    userId: state => {
      if (state.userId) {
        return state.userId
      } else {
        try {
          return JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
        } catch (e) {
          return null
        }
      }
    },
    roleId: state => {
      if (state.roleId) {
        return state.roleId
      } else {
        try {
          return JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.role_id
        } catch (e) {
          return null
        }
      }
    }
  }
}
