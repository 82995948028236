<template>
  <div class="editor-contol-values">
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_control_values.edit"
      :modal="false"
      :visible.sync="dialogSettingsVisible"
    >
      <el-button size="small" class="add_condition" @click="addCondition">{{$locale.interface_editor.component_editor.editor_control_values.add_condition}}</el-button>
      <el-radio-group v-show="onShowOperator" v-model="value.operator">
        <el-radio-button label="and">{{$locale.interface_editor.component_editor.conditions.and}}</el-radio-button>
        <el-radio-button label="or">{{$locale.interface_editor.component_editor.conditions.or}}</el-radio-button>
      </el-radio-group>
      <div class="condition" v-for="(condition, index) in value.conditions" :key="index">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_control_values.condition_types">
          <el-select v-model="condition.type">
            <el-option
              v-for="(item, index) in conditionTypes"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-form-item>
            <el-col :span="7">
              <el-select v-model="condition.mathOperations">
                <el-option
                  v-for="(item, index) in mathOperations"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <!--  -->
              {{condition.type === 'const' ? condition.attribute = null : condition.value = null}}
              <!--  -->
              <el-input v-if="condition.type === 'const' && options.type === 'integer'" v-model="condition.value"></el-input>
              <el-date-picker
                v-model="condition.value"
                v-if="condition.type === 'const' && options.type === 'date'"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="{ firstDayOfWeek: 1 }"
                format="dd.MM.yyyy">
              </el-date-picker>
              <el-select v-show="condition.type === 'not_const'" v-model="condition.attribute">
                <el-option
                  v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                  :key="index"
                  :label="`${item.name}`"
                  :value="item.properties.name"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$locale.interface_editor.component_editor.editor_control_values.text_user">
            <el-col :span="16">
              <el-input v-model="condition.textUser"></el-input>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-button @click="deleteCondition(index)" size="small" type="danger">{{$locale.main.button.delete}}</el-button>
            </el-col>
          </el-form-item>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'editor-control-values',
  props: {
    value: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  inject: ['getComponents'],
  data () {
    return {
      dialogSettingsVisible: false,
      conditionTypes: [
        {
          name: 'Константа',
          value: 'const'
        },
        {
          name: 'Зависит от другого поля',
          value: 'not_const'
        }
      ],
      mathOperations: [
        {
          name: 'Равно',
          value: 'equally'
        },
        {
          name: 'Не равно',
          value: 'not_equally'
        },
        {
          name: 'Меньше',
          value: 'less'
        },
        {
          name: 'Больше',
          value: 'more'
        },
        {
          name: 'Меньше или равно',
          value: 'less_equal'
        },
        {
          name: 'Больше или равно',
          value: 'more_equal'
        }
      ],
      components: this.getComponents()
    }
  },
  watch: {
    value: {
      handler (value) {
        if (this.value.conditions.length === 1) {
          this.value.operator = null
        }
        if (this.value.conditions.length > 1 && this.value.operator === null) {
          this.$message.success('Выберите связь И / ИЛИ')
        }
        this.$emit('change', value)
      },
      deep: true
    }
  },
  computed: {
    onShowOperator () {
      if (this.value.conditions.length > 1) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    addCondition () {
      this.value.conditions.push({
        type: null,
        value: null,
        mathOperations: null,
        attribute: null,
        textUser: null
      })
    },
    deleteCondition (index) {
      this.value.conditions.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.editor-contol-values .add_condition {
  display: block;
  margin-bottom: 10px;
}
.editor-contol-values .condition {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  border-color: #ddd;
  border-left: 2px solid #8bc34a;
  padding: 15px;
}
</style>
