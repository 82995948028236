<template>
  <div>
    <el-select
      value-key="id"
      v-model="value.email_type"
      clearable
      size="mini">
      <el-option
        v-for="item in listEmailType"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>

    <author-tree v-if="value.email_type === 'field'" :object_id='value.object_id' v-model="value.author_field_id"></author-tree>

    <el-input
      v-if="value.email_type === 'constant'"
      class="textFieldMini"
      v-model="value.email_value"
      size="mini"
      :placeholder="$locale.notify_editor.label.written_to_email"
    ></el-input>
  </div>
</template>

<script>
import authorTree from '@/components/NotificationEditor/components/notify/authorTree'
export default {
  name: 'author',
  props: ['listStatesAroundUser', 'listUser', 'listField', 'value'],
  components: {
    authorTree
  },
  data () {
    return {
      listEmailType: [
        {
          id: 'field',
          name: 'Поле уведомлений'
        },
        {
          id: 'user',
          name: 'Электронная почта сотрудника'
        },
        {
          id: 'constant',
          name: 'Константа'
        }
      ]
    }
  },
  watch: {
    'value.email_type': function (newVal, oldval) {
      if (newVal === '') this.value.email_type = null
      if (oldval !== null) {
        this.$nextTick(function () {
          this.value.author_field_id = null
          this.value.email_value = null
        })
      }
    }
  }
}
</script>

<style scoped>
.extraAccordion__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0px;
  margin-top: 0px;
}
</style>
