import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RoleQueryRepositoryInterface from '../../../domain/repository/RoleQueryRepositoryInterface'
import RolesCountQuery from '../../query/RolesCountQuery'

export default class RolesCountHandler implements QueryHandlerInterface
{
    private repo: RoleQueryRepositoryInterface;

    constructor(repo: RoleQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: RolesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}