import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SMEV_SETTING_LOADING_EVENT = 'smev_settingloading_event',
    SMEV_SETTING_LOADED_EVENT = 'smev_settingloaded_event',
    SMEV_SETTING_CREATED_EVENT = 'smev_settingcreated_event',
    SMEV_SETTING_UPDATED_EVENT = 'smev_settingupdated_event',
    SMEV_SETTING_DELETED_EVENT = 'smev_settingdeleted_event' 
}

export class SmevSettingLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SMEV_SETTING_LOADING_EVENT
}

export class SmevSettingLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SMEV_SETTING_LOADED_EVENT
}

export class SmevSettingCreatedEvent implements FluxStandardAction {
    type = MutationTypes.SMEV_SETTING_CREATED_EVENT
    constructor(public location: string){}
}

export class SmevSettingUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.SMEV_SETTING_UPDATED_EVENT
}

export class SmevSettingDeletedEvent implements FluxStandardAction {
    type = MutationTypes.SMEV_SETTING_DELETED_EVENT
    constructor(public guid: string){}
}