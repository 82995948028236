<template>
  <div class="el-tree-select" :class="selectClass">
    <el-select
      :style="styles"
      class="el-tree-select-input"
      v-bind:value="getValue"
      :disabled="localDisabled"
      popper-class="select-option"
      ref="select"
      :popper-append-to-body="false"
      :filterable="false"
      v-popover:popover
      :multiple="false"
      :placeholder="placeholder"
      @focus="_popoverShowFun">
      <el-option
        v-for="item in dataList"
        :value="item.id"
        :label="item.name"
        :key="item.id">
      </el-option>
    </el-select>
    <el-popover ref="popover" :placement="placement" :popper-class="popperClass" :width="width" v-model="visible" trigger="click">
      <el-scrollbar tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list" class="is-empty">
        <el-tree
          ref="tree"
          lazy
          :props="props"
          :accordion="false"
          :load="loadNode"
          show-checkbox
          @check-change="checkNode"
          :default-checked-keys="[getValue]"
          :default-expanded-keys="expanded"
          check-on-click-node
          check-strictly
          :node-key="nodeKey"
          :size="size"
          :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
          </span>
        </el-tree>
        <!--<div v-if="data.length===0" class="no-data">Нет данных!</div>-->
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script>
import Entity from '@/components/ObjectEditor/Models/Entity'

export default {
  name: 'RegistrySelectTree',

  props: {
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    value: {
      type: [Object, Number, String],
      default: function () {
        return {
          id: null,
          name: null
        }
      }
    },
    type: {
      type: String,
      default: 'any',
      validate: function (value) {
        return [
          'registry',
          'field',
          'datetime',
          'xref',
          'text',
          'number',
          'any'
        ].indexOf(value) !== -1
      }
    },
    size: String,
    disabled: {
      type: Boolean,
      default: false
    },
    valueAs: {
      type: String,
      default: 'object',
      validate: function (value) {
        return [
          'object',
          'number',
          'string'
        ].indexOf(value) !== -1
      }
    },
    parentId: {
      type: Number,
      default: null
    },

    popoverStyles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    styles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    selectClass: {
      type: String,
      default () {
        return ''
      }
    },
    popoverClass: {
      type: String,
      default () {
        return ''
      }
    },
    placement: {
      type: String,
      //  bottom
      default () {
        return 'bottom'
      }
    },
    nodeKey: {
      type: String,
      default: 'id'
    }
  },

  computed: {
    popperClass () {
      let _c = 'el-tree-select-popper ' + this.popoverClass
      return this.disabled ? _c + ' disabled ' : _c
    },

    getValue () {
      if (this.valueAs === 'object') {
        return this.localValue[this.nodeKey] || null
      } else if (this.valueAs === 'string') {
        return this.localValue
      } else {
        return this.localValue ? parseInt(this.localValue) : null
      }
    }
  },

  data () {
    return {
      expanded: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: 'disabled'
      },
      width: 150,
      localDisabled: this.disabled,
      visible: false,
      dataList: [],
      localValue: this.value
    }
  },

  async mounted () {
    if (this.getValue !== null) {
      let entities = await new Entity({ id: this.getValue }).expanded().$first()
      this.expanded = this.getExpanded(entities)
    }

    this._updateH()
  },

  watch: {
    disabled () {
      this.localDisabled = this.disabled
    },
    value () {
      this.localValue = this.value
    }
  },

  methods: {
    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width
      })
    },
    _popoverShowFun (val) {
      this._updateH()
    },
    checkNode (node, checked) {
      if (checked) {
        this.setValue(node)
        this.$refs.tree.setCheckedKeys([this.getValue])
      } else {
        if (node.id === this.getValue) {
          this.setValue(null)
        }
      }
    },
    getExpanded (object) {
      let array = []
      if (object && object.parent) {
        array.push(object.parent.id)
        array.push(...this.getExpanded(object.parent))
      }
      return array
    },
    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities(this.parentId))
      } else {
        if (this.type === 'registry') {
          if (node.data.entity_type_id === 'registry_group') {
            resolve(await this.loadEntities(node.data.id))
          } else {
            resolve([])
          }
        } else {
          resolve(await this.loadEntities(node.data.id))
        }
      }
    },
    async loadEntities (entityId = null) {
      let entities = []
      if (!entityId) {
        entities = await new Entity().params({ root: true }).$get()
      } else {
        entities = await new Entity({ id: entityId }).children().$get()
      }

      if (this.type === 'registry') {
        entities.forEach((item, index) => {
          if (item.entity_type_id !== 'registry') {
            entities[index].leaf = false
            entities[index].disabled = true
          } else {
            entities[index].leaf = true
            entities[index].disabled = false
          }
        })
      } else if (this.type === 'field') {
        entities.forEach((item, index) => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = false
            entities[index].disabled = true
          } else {
            entities[index].leaf = true
            entities[index].disabled = false
          }
        })
      } else if (this.type === 'datetime') {
        entities.forEach((item, index) => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = false
            entities[index].disabled = true
          } else if (['date_field', 'datetime_field', 'time_field'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = true
            entities[index].disabled = false
          } else {
            entities[index].leaf = true
            entities[index].disabled = true
          }
        })
      } else if (this.type === 'xref') {
        entities.forEach((item, index) => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = false
            entities[index].disabled = true
          } else if (['xref_field', 'xref_multi_field', 'xref_outer_field'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = true
            entities[index].disabled = false
          } else {
            entities[index].leaf = true
            entities[index].disabled = true
          }
        })
      } else if (this.type === 'text') {
        entities.forEach((item, index) => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = false
            entities[index].disabled = true
          } else if (['text_field', 'string_field'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = true
            entities[index].disabled = false
          } else {
            entities[index].leaf = true
            entities[index].disabled = true
          }
        })
      } else if (this.type === 'number') {
        entities.forEach((item, index) => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = false
            entities[index].disabled = true
          } else if (['integer_field', 'float_field'].indexOf(item.entity_type_id) !== -1) {
            entities[index].leaf = true
            entities[index].disabled = false
          } else {
            entities[index].leaf = true
            entities[index].disabled = true
          }
        })
      } else {
        if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
          entities[index].leaf = false
          entities[index].disabled = false
        } else {
          entities[index].leaf = true
          entities[index].disabled = false
        }
      }

      entities.forEach((item) => {
        this.dataList.push({
          id: item[this.nodeKey],
          name: item.name
        })
      })

      return entities
    },
    setValue (value) {
      if (this.valueAs === 'object') {
        if (value !== null) {
          this.localValue[this.nodeKey] = value[this.nodeKey]
          this.localValue.name = value.name
        } else {
          this.localValue[this.nodeKey] = null
          this.localValue.name = null
        }
      } else {
        this.localValue = value ? value[this.nodeKey] : null
      }

      this.$emit('change', this.localValue)
      this.$emit('input', this.localValue)
    }
  }
}
</script>

<style>
  /*.registry-select-tree {
    width: 100%;
  }
  .el-tree-select .el-select {
    display: block;
  }*/
  .el-tree-select .select-option {
    display: none !important;
  }
  [aria-disabled='true'] > .el-tree-node__content {
    color: inherit !important;
    background: transparent !important;
    cursor: no-drop !important;
  }
  .el-tree-select-popper {
    max-height: 400px;
    overflow: auto;
  }
  .el-tree-select-popper.disabled {
    display: none !important;
  }
  .el-tree-select-popper .el-button--small {
    width: 25px !important;
    min-width: 25px !important;
  }
  .el-tree-select-popper[x-placement^='bottom'] {
    margin-top: 5px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .no-data {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #cccccc;
    text-align: center;
  }
</style>
