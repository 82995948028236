<template>
  <el-row>
    <el-col :span="24"> <!-- :xs="24" :sm="24" :md="24" :lg="24" :xl="22" -->
      <el-form size="mini" :model="value">
        <el-form-item :label="$locale.report_editor.form.name" >
          <el-input v-model="value.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0;">
          <el-button size="small" @click="cancel">
            <span class="el-icon-close"></span> {{ $locale.main.button.cancel }}
          </el-button>
          <el-button size="small" type="primary" @click="save" style="float: right;">
            <span class="el-icon-success"></span> {{ $locale.main.button.save }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'GroupForm',

  props: ['value', 'activeNode'],

  data () {
    return {
      localValue: this.value,
      localNode: this.activeNode
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    },
    activeNode () {
      this.localNode = this.activeNode
    }
  },

  methods: {
    async save () {
      let response = await this.localValue.save()

      this.localNode.data = Object.assign({}, this.activeNode.data, { ...response })
    },
    cancel () {
      this.$emit('cancelCard', null)
    }
  }
}
</script>

<style>
</style>
