<template>
    <div>
        <component :is="component"/>
    </div>
</template>
<script>

export default {
  name: 'WelcomePage',
  data () {
    return {
      component: null
    }
  },
  async mounted () {
    try {
      await import(`@/plugins/${this.$config.project}/infrastructure/components/WelcomePage`)
      this.component = () => import(`@/plugins/${this.$config.project}/infrastructure/components/WelcomePage`)
    } catch (e) {
      try {
        await import(`@/components/WelcomePage/${this.$config.project}`)
        this.component = () => import(`@/components/WelcomePage/${this.$config.project}`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
