






import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import ActivitiesHandler from '@/services/ActivityService/application/handler/query/ActivitiesHandler'
import ActivitiesCountHandler from '@/services/ActivityService/application/handler/query/ActivitiesCountHandler'
import ActivityRepository from '@/services/ActivityService/infrastructure/domain/repository/ActivityRepository'
import ActivityPanel from '@/services/ActivityService/infrastructure/components/ActivityPanel/index.vue'

export default {
  name: 'ActivityService',
  components: {
    ActivityPanel
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
            'ActivitiesQuery': new ActivitiesHandler(
              new ActivityRepository()
            ),
            'ActivitiesCountQuery': new ActivitiesCountHandler(
              new ActivityRepository()
            )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    }
  },
  mounted() {
    
  }
}
