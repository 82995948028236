<template>
  <div class="topic-right-panel">
    <el-dialog
      :visible.sync="isCreateRightWindowVisible"
      width="30%"
      ref="createRightWindow"
      class="create-topic-right-window"
      @close="closeCreateRightWindow"
      :close-on-click-modal="false">
      <span>
        <template v-if="topicRightDto">
          <el-form :model="topicRightDto" :disabled="isRightLoading" size="mini" label-width="140px" label-position="right">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="topic_code" :label="$locale.databus.route_panel.topic_code">
                  <el-input v-model="topicRightDto.topic_code" :readonly="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="topic_name" :label="$locale.databus.route_panel.topic_name">
                    <el-input v-model="topicRightDto.topic_name" :readonly="true" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="checkbox-group">
              <el-col :span="12">
                <el-form-item prop="is_topic_producer">
                  <el-checkbox v-model="topicRightDto.is_topic_producer" :label="$locale.access_editor.topic_rights_table.is_topic_producer" name="is_topic_producer"></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="is_topic_consumer">
                  <el-checkbox v-model="topicRightDto.is_topic_consumer" :label="$locale.access_editor.topic_rights_table.is_topic_consumer" name="is_topic_consumer"></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <span v-loading="true" v-if="isRightLoading"></span>
          <el-button icon="el-icon-close" :disabled="isRightLoading" size="small" @click="closeCreateRightWindow">{{$locale.main.button.cancel}}</el-button>
          <el-button icon="el-icon-success" :disabled="topicRightDto == null || isRightLoading" size="small" @click="saveRight" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
    <el-container class="tools">
      <el-button icon="edit-button" size="mini" circle  @click="updateRight"></el-button>
    </el-container>
    <el-row class="main-table topic-right-table">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="registry custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="topicRights"
          stripe
          border
          ref="topic_rights_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeRight"
        >
        <el-table-column
          prop="topic_code"
          :label="$locale.databus.route_panel.topic_code"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="topic_name"
          :label="$locale.databus.route_panel.topic_name"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="is_topic_producer"
          :label="$locale.access_editor.topic_rights_table.is_topic_producer"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_topic_producer ? 'success' : 'primary'"
            >{{scope.row.is_topic_producer ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_topic_consumer"
          :label="$locale.access_editor.topic_rights_table.is_topic_consumer"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_topic_consumer ? 'success' : 'primary'"
            >{{scope.row.is_topic_consumer ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="topic-right-pagination"
            :page-size="topicRightsPageSize"
            :layout="'total, prev, pager, next'"
            :total="topicRightsCount"
            @current-change="handleRightsPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TopicRightUpdateCommand from '@/services/AccessEditor/application/command/TopicRightUpdateCommand'
import TopicRightsQuery from '@/services/AccessEditor/application/query/TopicRightsQuery'
import TopicRightsCountQuery from '@/services/AccessEditor/application/query/TopicRightsCountQuery'
import TopicRight, { TopicRightDTO } from '@/services/AccessEditor/domain/model/Right/TopicRight'

export default {
  name: 'TopicRightPanel',
  components: {
  },
  data () {
    return {
      loading: false,
      topicRightsPageSize: 0,
      defaultRightsPageSize: 100,
      topicRightsCurrentPage: 0,
      topicRightsPageLimit: 100,
      topicRightsCount: 0,
      allRightsCount: 0,
      topicRights: [],
      topicRight: null,
      topicRightDtoPrev: null,
      topicRightDto: null,
      informationPanelHeight: 0,
      isCreateRightWindowVisible: false,
      createRightRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getSelectedRole'],
  watch: {
    isRightLoading: (state) => state,
    topicRightDto: {
      handler: async function(curDto) {
        
      },
      deep: true
    }
  },
  computed: {
    isRightLoading() {
      return this.$store.getters['TopicRight/isLoading'];     
    },
    topicRightCreated() {
      return this.$store.getters['TopicRight/getLocation'];
    },
    getSaveRightError() {
      return this.$store.getters['TopicRight/getError']; 
    }
  },
  methods: {
    loadRights(callback) {
      if (this.allRightsCount == 0) {
        this.countAndLoadRights(callback);
      } else {
        this.loadAllRights(callback);
      }
    },
    async countAndLoadRights(callback) {
      await this.getQueryBus().execute(
        new TopicRightsCountQuery({role_guid: this.getSelectedRole()})
      ).then(data => {
        this.topicRightsCount = data[0].count;      
        this.loadAllRights(callback);
      });
    },
    async loadAllRights(callback) {
      await this.getQueryBus().execute(
        new TopicRightsQuery({
          role_guid: this.getSelectedRole(),
          limit: this.topicRightsPageLimit,
          offset: this.topicRightsCurrentPage
        })
      ).then(data => {
        this.topicRightsPageSize = this.topicRightsPageLimit;
        this.topicRights = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createRight() {
      this.topicRightDtoPrev = this.topicRightDto;
      this.topicRightDto = new TopicRightDTO({role_guid: this.getSelectedRole()});    
      this.isCreateRightWindowVisible = true;
    },
    async updateRight() {
      if (this.topicRight !== null) {
        this.isCreateRightWindowVisible = true;
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveRight() {
      let topicRight = TopicRight.create(this.topicRightDto);
      this.getCommandBus().execute(
        new TopicRightUpdateCommand(
          this.getSelectedRole(),
          topicRight.getTopicId(),
          topicRight.getIsTopicProducer(),
          topicRight.getIsTopicConsumer()
        )
      ).then(async () => {
        this.closeCreateRightWindow();
      });
    },
    closeCreateRightWindow() {
      this.isCreateRightWindowVisible = false;
      if (this.topicRightDtoPrev !== null) {
        this.topicRightDto = this.topicRightDtoPrev;
        this.topicRight = TopicRight.create(this.topicRightDto);
      }
    },
    changeRight(topicRightDto) {
      if (topicRightDto !== null) {
        this.topicRight = TopicRight.create(topicRightDto);
        this.topicRightDto = topicRightDto;
      }
    },
    handleRightsPageChange(val) {
      val--;
      this.topicRightsCurrentPage = (val * this.topicRightsPageLimit);
      this.loadRights();
    },
    roleSelected(roleGuid) {
      this.loadRights();
    }
  },
  mounted () {
    this.getEventBus().$on('selectRole', this.roleSelected);
    if (this.getSelectedRole() !== null) {
      this.loadRights();
    } else {
      this.$message({
        message: this.$locale.access_editor.right_object.role_not_found,
        type: 'warning'
      });
    }
  }
}
</script>