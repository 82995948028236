<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
      <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name"></el-input>
    </el-form-item>

    <el-form-item v-if="showType" :label="$locale.etl_editor.form.fields.type" prop="loader_type_id">
      <el-select v-model="value.loader_type_id" :placeholder="$locale.etl_editor.form.fields.type">
        <el-option
          v-for="item in loaderTypes"
          :key="item.id"
          :label="$locale.etl_editor.menu[item.name]"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <slot></slot>
  </el-form>
</template>

<script>
import LoaderType from '../../Models/LoaderType'
export default {
  name: 'LoaderCreate',

  props: {
    value: {
      type: Object,
      default: null
    },
    title: String,
    showType: {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        loader_type_id: [
          { required: true, message: 'Please select loader type', trigger: 'change' }
        ]
      },
      loaderTypes: []
    }
  },

  async mounted () {
    if (this.showType) {
      this.loaderTypes = await new LoaderType().get()
    }
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(true)
        }
      })
    }
  }
}
</script>

<style type="text/css">

</style>
