
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ActivityRepositoryInterface from '../../../domain/repository/ActivityRepositoryInterface'
import ActivitiesCountQuery from '../../query/ActivitiesCountQuery'

export default class ActivitiesCountHandler implements QueryHandlerInterface
{
    private repo: ActivityRepositoryInterface;

    constructor(repo: ActivityRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: ActivitiesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}