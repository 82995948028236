<template>
  <div class="task_form">
    <dialog-form v-if="editor.show" :form="editor" :save="dialogSave" :cancel="dialogCancel"></dialog-form>

    <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
      <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
        <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor.form.fields.extractor" prop="extractor_id">
        <div class="input-group">
          <el-select v-model="value.extractor_id" :placeholder="$locale.etl_editor.form.fields.extractor" filterable @change="selectExtractor" size="mini">
            <el-option v-for="item in list.extractor" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="input-group-append">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="added('extractor')"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit('extractor', value.extractor_id)" :disabled="value.extractor_id === null"></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor.form.fields.loader" prop="loader_id">
        <div class="input-group">
          <el-select v-model="value.loader_id" :placeholder="$locale.etl_editor.form.fields.loader" filterable size="mini">
            <el-option v-for="item in list.loader" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="input-group-append">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="added('loader')"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit('loader', value.loader_id)" :disabled="value.loader_id === null"></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor.form.fields.code" prop="code">
        <el-input v-model="value.code" :placeholder="$locale.etl_editor.form.fields.code" @change="codeChange"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="value.is_visible_in_registry">
          {{ $locale.etl_editor.form.fields.is_visible_in_registry }}
        </el-checkbox>
      </el-form-item>

      <template v-if="mappingComponent">
        <component
          ref="mapping"
          :is="mappingComponent"
          :task-id="value.id"
          :loaders="list.loader"
          :transformers="list.transformer"
          :extractor-type="extractorType"
          :key="generateGuid()">
        </component>
      </template>

      <slot></slot>
    </el-form>
  </div>
</template>

<script>
import DialogForm from '../../render/DialogForm'
import TableMapping from '../../render/mapping/TableMapping'
import TreeMapping from '../../render/mapping/TreeMapping'

import Loader from '../../Models/Loader'
import Extractor from '../../Models/Extractor'

export default {
  name: 'TaskUpdate',

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  components: {
    DialogForm,
    TableMapping,
    TreeMapping
  },

  computed: {
    extractorType () {
      let extractor = this.extractorById(this.value.extractor_id)

      return extractor ? extractor.extractor_type_id : null
    }
  },

  data () {
    return {
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        loader_id: [
          { required: true, message: 'Please select Loader Type', trigger: 'change' }
        ],
        extractor_id: [
          { required: true, message: 'Please select Extractor Type', trigger: 'change' }
        ]
      },

      list: {
        loader: [],
        extractor: [],
        transformer: []
      },
      editor: {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false
      },
      defaultModel: {
        loader: {
          name: null,
          loader_type_id: null
        },
        extractor: {
          name: null,
          extractor_type_id: null
        }
      },
      models: {
        loader: Loader,
        extractor: Extractor
      },
      forms: {
        create: {
          loader: 'LoaderCreate',
          extractor: 'ExtractorCreate'
        },
        update: {
          loader: 'LoaderUpdate',
          extractor: 'ExtractorUpdate'
        }
      },
      mappingComponent: null
    }
  },

  watch: {
    'value.code': function (val, old) {
      if (val === '') {
        this.value.code = null
      }
    }
  },

  async mounted () {
    await this.$http
      .get(`${this.$config.api}/etleditor/extractors?fields=id,name,extractor_type_id&order=name:asc`)
      .then((response) => {
        this.list.extractor = response.data

        this.setMappingComponent(this.value.extractor_id)
      })

    await this.$http
      .get(`${this.$config.api}/etleditor/loaders?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.loader = response.data
      })

    await this.$http
      .get(`${this.$config.api}/etleditor/transformers?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.transformer = response.data
      })
  },

  methods: {
    added (type) {
      this.editor.title = this.$locale.etl_editor.form.title[`create_${type}`]
      this.editor.model = new this.models[type](this.defaultModel[type])
      this.editor.component = this.forms.create[type]

      this.editor.isNew = true
      this.editor.show = true
      this.editor.type = type
    },

    async edit (type, id) {
      this.editor.title = this.$locale.etl_editor.form.title[`edit_${type}`]
      this.editor.model = await this.models[type].find(id)
      this.editor.component = this.forms.update[type]

      this.editor.isNew = false
      this.editor.show = true
      this.editor.type = type
    },

    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(false)

          this.$refs.mapping.saveAllEditMapping()
        }
      })
    },

    dialogCancel () {
      this.editor = {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false,
        type: null
      }
    },

    async dialogSave (isNew) {
      let response = await this.editor.model.save()

      if (isNew) {
        this.editor.isNew = false
        this.editor.model = await this.models[this.editor.type].find(response.id)
        this.editor.title = this.$locale.etl_editor.form.title[`edit_${this.editor.type}`]
        this.editor.component = this.forms.update[this.editor.type]
      }

      let listIndex = this.list[this.editor.type].findIndex(item => item.id === response.id)

      if (listIndex !== -1) {
        this.list[this.editor.type][listIndex].name = response.name
      } else {
        this.list[this.editor.type].push({
          id: response.id,
          name: response.name
        })
      }

      let localValue = this.value
      let props = {
        loader: 'loader_id',
        extractor: 'extractor_id'
      }

      localValue[props[this.editor.type]] = response.id

      this.$emit('input', localValue)
    },

    extractorById (value) {
      let extractor = this.list.extractor.find(e => e.id === value)

      return extractor || null
    },

    codeChange (value) {
      if (value === '') {
        this.value.code = null
      }
    },

    selectExtractor (value) {
      this.setMappingComponent(value)
    },

    setMappingComponent (value) {
      console.log(value)

      let extractor = this.extractorById(value)

      if (extractor) {
        this.mappingComponent = ['xml', 'json_tree'].indexOf(extractor.extractor_type_id) !== -1 ? TreeMapping : TableMapping
      } else {
        this.mappingComponent = null
      }
    }
  }
}
</script>

<style type="text/css"></style>
