<template>
  <el-select
    v-model="localModel"
    :multiple="options.multiple"
    filterable
    @clear="localModel = multiple ? [] : null;$emit('change', localModel)"
    @change="$emit('change', localModel)"
  >
    <el-option
      v-for="(item, index) in options.options"
      :key="index"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'editor-select',
  props: ['value', 'options'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>

<style scoped>

</style>
