<template>
    <el-checkbox v-model="local_value" @change="$emit('change', local_value)"></el-checkbox>
</template>

<script>
export default {
  data () {
    return {
      local_value: this.value
    }
  },
  props: {
    value: Boolean
  },
  name: 'editor-checkbox'
}
</script>

<style scoped>

</style>
