<template>
  <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="value" size="mini" class="notifyForm">

    <el-form-item :label="$locale.notify_editor.label.name" prop="name">
      <el-input v-model="value.name"></el-input>
    </el-form-item>

    <el-form-item :label="$locale.notify_editor.label.relates_registry" prop="object_id">
      <el-select
      value-key="id"
      v-model="value.object_id"
      clearable
      filterable
      size="mini">
        <el-option
          v-for="item in listRelatesRegistry"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item :label="$locale.notify_editor.label.creation_condition" prop="condition_type">
      <el-select
      value-key="id"
      v-model="value.condition_type"
      size="mini">
        <el-option
          v-for="item in listCondition"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import Registry from '@/components/LogicEditor/Models/Registry'
export default {
  name: 'notifyForm',
  props: {
    value: Object,
    rules: Object
  },
  data () {
    return {
      listRelatesRegistry: [],
      listCondition: [{
        id: 'state',
        name: 'По состояниям'
      },
      {
        id: 'command',
        name: 'Внешней командой'
      }]
    }
  },
  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  },
  async mounted () {
    this.listRelatesRegistry = await Registry.params({ is_object: true }).$get()
  }
}
</script>

<style scoped>
.notifyForm .el-select {
  width: 100%;
}
</style>
