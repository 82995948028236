import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SmevSettingCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/SmevSettingCommandRepositoryInterface'
import SmevSettingUpdateCommand from '@/services/AccessEditor/application/command/SmevSettingUpdateCommand'
import { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'

export default class SmevSettingUpdateHandler implements CommandHandlerInterface
{
    private repo: SmevSettingCommandRepositoryInterface;

    constructor(repo: SmevSettingCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SmevSettingUpdateCommand): Promise<void>
    {
        let item: SmevSettingDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            value: command.getValue()
        };
        return this.repo.update(item);
    }
}
