import AbstractEntitiesQuery from './AbstractEntitiesQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'

export default abstract class AbstractEntitiesQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractEntitiesQuery): Promise<any>
    {
        return this.repo.get(query.getPayload());
    }
}