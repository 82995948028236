<template>
  <div class="multi-input-box">
    <el-row :gutter="10">
      <el-col :span="22">
        <div v-for="(element, index) in localValue" :key="index">
          <div class="input-group">
            <component class="custom-input" v-model="localValue[index]" :is="component" :placeholder="placeholder" :xref-list="xrefList"></component>
            <div class="input-group-append">
              <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.delete_value" placement="top">
                <el-button size="mini" type="default" icon="el-icon-delete" @click="removeInput(index)"></el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.add_value" placement="top">
          <el-button class="mi-plus-button" circle size="mini" type="success" icon="el-icon-plus" @click="addInput"></el-button>
        </el-tooltip>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DateInput from './DateInput'
import DatetimeInput from './DatetimeInput'
import FlagInput from './FlagInput'
import FloatInput from './FloatInput'
import MultiInput from './MultiInput'
import NumberInput from './NumberInput'
import StringInput from './StringInput'
import TextInput from './TextInput'
import TimeInput from './TimeInput'
import XrefInput from './XrefInput'
import XrefMultiInput from './XrefMultiInput'

export default {
  name: 'MultiInput',

  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },

    component: {
      type: String,
      default: 'NumberInput'
    },

    xrefList: {
      type: Array,
      default () {
        return []
      }
    },

    placeholder: String
  },

  components: {
    DateInput,
    DatetimeInput,
    FlagInput,
    FloatInput,
    MultiInput,
    NumberInput,
    StringInput,
    TextInput,
    TimeInput,
    XrefInput,
    XrefMultiInput
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value
    }
  },

  methods: {
    addInput () {
      this.localValue.push('')
      this.$emit('input', this.localValue)
    },

    removeInput (index) {
      if (this.localValue.length > 1) {
        this.localValue.splice(index, 1)
        this.$emit('input', this.localValue)
      }
    }
  }
}
</script>

<style type="text/css">
.multi-input-box div > .input-group {
  margin-bottom: 10px;
}
.multi-input-box div:last-child > .input-group {
  margin-bottom: 0;
}
</style>
