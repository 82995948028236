export default class XmlRouteCreateCommand
{
    private name: string;
    private topic: string;
    private taskId: number;
    private dagId: string;
    private actionType: string;

    constructor(name: string, topic: string, taskId: number, dagId: string, actionType: string)
    {
        this.name = name;
        this.topic = topic;
        this.taskId = taskId;
        this.dagId = dagId;     
        this.actionType = actionType;
    }

    getClassName(): string
    {
        return 'XmlRouteCreateCommand';
    }

    getTopic(): string
    {
        return this.topic;
    }

    getTaskId(): number
    {
        return this.taskId;
    }

    getDagId(): string
    {
        return this.dagId;
    }

    getName(): string
    {
        return this.name;
    }

    getActionType(): string
    {
        return this.actionType;
    }
}