<template>
  <div class="relation_box">
    <el-row :gutter="10">
      <el-col :span="11">
        <el-select v-model="value.from" :placeholder="$locale.report_editor.relation.source_from">
          <el-option
            v-for="item in fromFieldList"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="11">
        <el-select v-model="value.to" :placeholder="$locale.report_editor.relation.source_to">
          <el-option
            v-for="item in toFieldList"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <slot></slot>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'RelationBox',

  props: {
    value: {
      type: Object,
      default () {
        return {
          from: null,
          to: null
        }
      }
    },

    fromFieldList: {
      type: Array,
      default () {
        return []
      }
    },

    toFieldList: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style type="text/css">
  .relation_box {
    margin-bottom: 10px;
  }
</style>
