<template>
  <el-select v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="placeholder">
    <el-option
      v-for="item in conditionList"
      :key="item.id"
      :label="$locale.report_editor.filter.conditions[item.name]"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'Condition',

  props: ['value', 'placeholder'],

  data () {
    return {
      conditionList: [
        {
          id: 'is_null',
          name: 'is_null'
        }, {
          id: 'is_not_null',
          name: 'is_not_null'
        }
      ]
    }
  }
}
</script>

<style type="text/css">

</style>
