<template>
  <div :id="`stimulsoft_designer_${componentId}`" :key="componentId"></div>
</template>

<script>
import StiHelper from './StiHelper'

export default {
  name: 'StimulsoftReport',
  props: {
    filename: {
      type: String,
      default () {
        return 'SimpleList.mrt'
      }
    },
    reportId: Number
  },
  data () {
    return {
      componentId: this.generateGuid()
    }
  },
  async mounted () {
    if (typeof window.Stimulsoft === 'undefined') {
      this.loadStyles()
      await this.loadScripts()
    }
    window.Stimulsoft.Base.StiLicense.loadFromFile(`${this.$config.api}/stimulsoftreports/stimulsoft/license.php`)
    window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
      `${this.$config.api}/stimulsoftreports/localization/ru.xml`, false, 'Russian'
    )
    window.Stimulsoft.Base.Localization.StiLocalization.cultureName = 'Russian'

    let options = new window.Stimulsoft.Designer.StiDesignerOptions()
    options.appearance.fullScreenMode = true
    options.toolbar.showSendEmailButton = true
    options.appearance.allowChangeWindowTitle = false

    let designer = new window.Stimulsoft.Designer.StiDesigner(options, 'StiDesigner_' + this.componentId, false)

    let jsHelper = new StiHelper(`${this.$config.api}/stimulsoftreports/handler.php`, 300)
    designer.onBeginProcessData = function (event, callback) {
      jsHelper.process(arguments[0], arguments[1])
    }

    designer.onSaveReport = function (event) {
      jsHelper.process(arguments[0], arguments[1])
    }

    let report = new window.Stimulsoft.Report.StiReport()
    report.loadFile(`${this.$config.api}/reports/${this.filename}`)

    let databases = report.getDictionary().databases
    if (!databases.getByName('Акцент')) {
      let database = new window.Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase('Акцент', 'Акцент')
      report.getDictionary().databases.add(database)
    }

    let response = await this.$http.get(`${this.$config.api}/reporteditor/reports/sources/${this.reportId}`)
    let sourceCollection = report.dataSources
    if (Array.isArray(response.data) && response.data.length > 0) {
      response.data.forEach((item) => {
        if (!sourceCollection.getByName(item.name)) {
          let source = new window.Stimulsoft.Report.Dictionary.StiPostgreSQLSource('Акцент', item.name, item.name, item.query, true, false)
          source.dictionary = report.getDictionary()
          sourceCollection.add(source)
        }
      })
    }

    setTimeout(() => {
      designer.report = report
    }, 1000)

    designer.renderHtml(`stimulsoft_designer_${this.componentId}`)
  },
  methods: {
    async loadScripts () {
      await this.loadScript('/stimulsoft/stimulsoft.reports.js')
      await this.loadScript('/stimulsoft/stimulsoft.reports.maps.js')
      await this.loadScript('/stimulsoft/stimulsoft.dashboards.js')
      await this.loadScript('/stimulsoft/stimulsoft.viewer.js')
      await this.loadScript('/stimulsoft/stimulsoft.designer.js')
    },
    loadScript (src) {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.onload = () => {
          resolve()
        }
        script.src = src
        document.head.appendChild(script)
      })
    },
    loadStyles () {
      [
        '/stimulsoft/stimulsoft.designer.office2013.whiteblue.css',
        '/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css'
      ].forEach((src) => {
        let style = document.createElement('link')
        style.rel = 'stylesheet'
        style.href = src
        document.head.appendChild(style)
      })
    }
  }
}
</script>

<style type="text/css">
</style>
