import { ActionTree } from 'vuex'
import { SnapshotState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SnapshotLoadingEvent, SnapshotLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SnapshotAPI } from '@/services/SyncService/infrastructure/api/SnapshotAPI'

export const actions: ActionTree<SnapshotState, any> = {
    [ActionTypes.GET_SNAPSHOTS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SnapshotLoadingEvent());
            let res = await APIClient.shared.request(new SnapshotAPI.GetSnapshots(data.payload));
            commit(new SnapshotLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_SNAPSHOTS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SnapshotLoadingEvent());
            let res = await APIClient.shared.request(new SnapshotAPI.CountSnapshots(data.payload));
            commit(new SnapshotLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
