<template>
  <el-select
    value-key="id"
    class="extraLine_select"
    :disabled="isEdit"
    v-model="condition_field_type_id"
    v-on:input="$emit('input', $event)"
    :placeholder="this.$locale.logic_editor.state.state_fields.condition">
    <el-option
      v-for="item in conditions"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'condition',
  props: {
    state: {
      type: Object
    },
    listField: {
      type: Array
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      condition_field_type_id: null,
      conditions: []
    }
  },
  methods: {
    getValue () {
      return this.condition_field_type_id + ''
    }
  },
  mounted () {
    this.$http
      .get(`${this.$config.api}/logiceditor/conditions/field/types`)
      .then(response => {
        let stateFields = this.$locale.logic_editor.state.condition
        let obj = response.data.map(el => ({ id: el.id, name: stateFields[el.id] }))
        this.conditions = obj
      })
      .catch(error => console.log(error))
    if (this.state) {
      this.condition_field_type_id =
        this.state.condition_field_type_id
          ? this.state.condition_field_type_id
          : null
    }
  }
}
</script>

<style scoped>
</style>
