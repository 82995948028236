<template>
    <el-input type="number" size="small" v-model="local_value" v-on:input="onChange">
    </el-input>
</template>

<script>
export default {
  data () {
    return {
      local_value: this.value
    }
  },
  props: {
    value: Number
  },
  name: 'editor-number',
  methods: {
    onChange (value) {
      if (!value) {
        this.$emit('change', null)
      } else {
        this.$emit('change', parseInt(value))
      }
    }
  }
}
</script>

<style scoped>

</style>
