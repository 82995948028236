import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export enum ActionTypes {
    ADD_LAYER = 'add_layer'
}

export class AddLayer implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.ADD_LAYER
    constructor(public payload: LayerDTO){}
}