<template>
  <div class="coordinate-system-form custom_scrollbar">
    <el-form :rules="formRules" :ref="formId" v-if="dto" class="coordinate-system-form-inner-body" :model="dto">
      <el-form-item readonly v-if="typeof this.coordinateSystem !== 'undefined'" :label="$locale.map_editor.coordinate_system_panel.epsg">
        <el-input v-model="dto.srid"></el-input>        
      </el-form-item>
      <el-form-item :label="$locale.main.fields.name">
        <el-input v-model="dto.auth_name"></el-input>        
      </el-form-item>
      <el-form-item :label="$locale.main.fields.description">
        <el-input v-model="dto.description" type="textarea"></el-input>        
      </el-form-item>
      <el-form-item :label="$locale.map_editor.coordinate_system_panel.proj4">
        <el-input v-model="dto.proj4text" type="textarea"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.map_editor.coordinate_system_panel.epsg_code">
        <el-radio-group class="epsg-type" v-model="dto.epsg_type">
          <el-row>
            <el-radio :label="1">{{$locale.map_editor.coordinate_system_panel.last_available}}</el-radio>
          </el-row>
          <el-row class="epsg-input">
            <el-radio :label="2"><el-input-number v-model="dto.srid" :step="1" :min="0" placeholder="> 900913" controls-position="right"></el-input-number></el-radio>
          </el-row>
        </el-radio-group> 
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="ts">
import CoordinateSystem, { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'
import CoordinateSystemCreateCommand from '@/services/MapEditor/application/command/CoordinateSystemCreateCommand'
import CoordinateSystemUpdateCommand from '@/services/MapEditor/application/command/CoordinateSystemUpdateCommand'
import CoordinateSystemBySridQuery from '@/services/MapEditor/application/query/CoordinateSystemBySridQuery'

export default {
  name: 'CoordinateSystemForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'coordinateSystem', 'submitCallback', 'CoordinateSystemCreatedCallback', 'CoordinateSystemUpdatedCallback', 'field'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  watch: {
    coordinateSystemCreated: function (location) {
      this.getQueryBus().execute(
        new CoordinateSystemBySridQuery(
          location.replace('/coordinate_systems/', '')
        )
      ).then((coordinateSystemDto) => {
        let coordinateSystem = CoordinateSystem.create(coordinateSystemDto);
        if (typeof this.coordinateSystemCreatedCallback == 'function') {
          this.coordinateSystemCreatedCallback(coordinateSystem);
        }
        this.getEventBus().$emit('coordinateSystemCreated', coordinateSystem, this.parentFormId, this.field);        
      }); 
    } 
  },
  data() {
    return {
      dto: null,      
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        proj4text: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }     
    }
  },
  computed: {
    coordinateSystemCreated() {
      return this.$store.getters['CoordinateSystem/getLocation'];
    }
  },
  methods: {
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          if (typeof this.coordinateSystem !== 'undefined') {
            this.coordinateSystem.setSrid(this.dto.srid);
            this.coordinateSystem.setName(this.dto.auth_name);
            this.coordinateSystem.setDescription(this.dto.description);
            this.coordinateSystem.setProj4text(this.dto.proj4text);

            this.getCommandBus().execute(
              new CoordinateSystemUpdateCommand(
                this.coordinateSystem.getSrid(),
                this.coordinateSystem.getName(),
                this.coordinateSystem.getDescription(),
                this.coordinateSystem.getProj4text()
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.coordinateSystemUpdatedCallback == 'function') {
                this.coordinateSystemUpdatedCallback(this.coordinateSystem);
              }
              this.getEventBus().$emit('CoordinateSystemUpdated', this.coordinateSystem);
            });
          } else {
            let coordinateSystem = CoordinateSystem.create(this.dto);
            this.getCommandBus().execute(
              new CoordinateSystemCreateCommand(                
                coordinateSystem.getSrid(),
                coordinateSystem.getName(),
                coordinateSystem.getDescription(),
                coordinateSystem.getProj4text(),
                coordinateSystem.getEpsgType(),
                this.projectId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }              
            });
          }
        }
      });
    }
  },
  mounted() {
    if (typeof this.coordinateSystem !== 'undefined') {
      this.dto = new CoordinateSystemDTO({
        srid: this.coordinateSystem.getSrid(),
        name: this.coordinateSystem.getName(),
        description: this.coordinateSystem.getDescription(),
        proj4text: this.coordinateSystem.getProj4text()
      });
    } else {      
      this.dto = new CoordinateSystemDTO({});
    }
  }
}
</script>