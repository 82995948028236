<template>
  <div class="document_editor">
    <iframe :src="url" width="100%" height="99%" style="border: none" title=""></iframe>

    <el-button
      class="cd-button"
      type="primary"
      :style="{ right: !collapsed ? '0px' : '300px' }"
      :icon="!collapsed ? 'el-icon-caret-left' : 'el-icon-caret-right'"
      @click="collapsePanel">
    </el-button>

    <div class="cd-panel from-right" v-bind:class="{ 'is-visible': collapsed }">
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <el-scrollbar class="tree_scroll">
            <el-tree
              :data="fields"
              :props="{ children: 'children', label: 'name' }">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                  <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.button.copy_clipboard" placement="top">
                    <el-button
                      type="text"
                      size="small"
                      v-clipboard:copy="data.name">
                      {{ node.label }}
                    </el-button>
                  </el-tooltip>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentEditor',

  props: {
    reportId: Number,
    filename: String
  },

  computed: {
    url () {
      let filePath = `file:///data/reports/${this.filename}`

      return `${this.$config.api}/documenteditor?file_path=${filePath}`
    }
  },

  data () {
    return {
      fields: [],
      collapsed: false
    }
  },

  async mounted () {
    await this.$http.get(`${this.$config.api}/reporteditor/report_source_columns/${this.reportId}`).then(response => {
      this.fields.push(...response.data)
    })
  },

  methods: {
    collapsePanel () {
      this.collapsed = !this.collapsed

      console.log('collapsed: ' + this.collapsed)
    }
  }
}
</script>

<style type="text/css">
  .document_editor {
    height: 99%;
    width: 100%;
  }

  .cd-button {
    position: absolute;
    top: calc(50% - 35px);
    border-radius: 5px 0 0 5px;
    height: 70px;
    width: 10px;
    -webkit-transition: top 1s ease-out 0.5s;
    transition: top 1s ease-out 0.5s;
    padding: 10px;
    text-align: center;
  }

  .cd-panel {
    visibility: hidden;
    transition: visibility 0s 0.6s;
  }

  .cd-panel.is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
  }

  .cd-panel-container {
    position: absolute;
    width: 300px;
    height: 100%;
    top: 0;
    right: 0;

    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0.3s;

    transform: translate3d(100%, 0, 0);

    background-color: #fff;
  }

  .is-visible .cd-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }

  .document_editor .tree_scroll {
    height: calc(100vh - 41px);
    width: 100%;
  }

  .document_editor .tree_scroll .el-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
