


































import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import RolePanel from '@/services/AccessEditor/infrastructure/components/RolePanel/index.vue'
import UserPanel from '@/services/AccessEditor/infrastructure/components/UserPanel/index.vue'
import RightPanel from '@/services/AccessEditor/infrastructure/components/RightPanel/index.vue'
import SmevPanel from '@/services/AccessEditor/infrastructure/components/SmevPanel/index.vue'
import DashboardsHandler from '@/services/AccessEditor/application/handler/query/DashboardsHandler'
import MenuHandler from '@/services/AccessEditor/application/handler/query/MenuHandler'
import RolesHandler from '@/services/AccessEditor/application/handler/query/RolesHandler'
import RolesCountHandler from '@/services/AccessEditor/application/handler/query/RolesCountHandler'
import RoleByGuidHandler from '@/services/AccessEditor/application/handler/query/RoleByGuidHandler'
import RoleCreateHandler from '@/services/AccessEditor/application/handler/command/RoleCreateHandler'
import RoleUpdateHandler from '@/services/AccessEditor/application/handler/command/RoleUpdateHandler'
import RoleDeleteHandler from '@/services/AccessEditor/application/handler/command/RoleDeleteHandler'
import SmevSettingCreateHandler from '@/services/AccessEditor/application/handler/command/SmevSettingCreateHandler'
import SmevSettingUpdateHandler from '@/services/AccessEditor/application/handler/command/SmevSettingUpdateHandler'
import SmevSettingDeleteHandler from '@/services/AccessEditor/application/handler/command/SmevSettingDeleteHandler'
import TopicRightsHandler from '@/services/AccessEditor/application/handler/query/TopicRightsHandler'
import TopicRightsCountHandler from '@/services/AccessEditor/application/handler/query/TopicRightsCountHandler'
import UsersHandler from '@/services/AccessEditor/application/handler/query/UsersHandler'
import UsersCountHandler from '@/services/AccessEditor/application/handler/query/UsersCountHandler'
import UserByGuidHandler from '@/services/AccessEditor/application/handler/query/UserByGuidHandler'
import UserApiKeyHandler from '@/services/AccessEditor/application/handler/query/UserApiKeyHandler'
import UserAvatarHandler from '@/services/AccessEditor/application/handler/query/UserAvatarHandler'
import UserPasswordHandler from '@/services/AccessEditor/application/handler/query/UserPasswordHandler'
import SmevSettingsHandler from '@/services/AccessEditor/application/handler/query/SmevSettingsHandler'
import SmevSettingByGuidHandler from '@/services/AccessEditor/application/handler/query/SmevSettingByGuidHandler'
import TopicRightUpdateHandler from '@/services/AccessEditor/application/handler/command/TopicRightUpdateHandler'
import UserCreateHandler from '@/services/AccessEditor/application/handler/command/UserCreateHandler'
import UserUpdateHandler from '@/services/AccessEditor/application/handler/command/UserUpdateHandler'
import UserDeleteHandler from '@/services/AccessEditor/application/handler/command/UserDeleteHandler'
import UsersUploadFromFileHandler from '@/services/AccessEditor/application/handler/command/UsersUploadFromFileHandler'
import RoleQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleQueryRepository'
import TopicRightQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/TopicRightQueryRepository'
import DashboardQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/DashboardQueryRepository'
import MenuQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/MenuQueryRepository'
import UserQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserQueryRepository'
import SmevSettingQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/SmevSettingQueryRepository'
import RoleCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleCommandRepository'
import TopicRightCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/TopicRightCommandRepository'
import UserCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserCommandRepository'
import SmevSettingCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/SmevSettingCommandRepository'

export default {
  name: 'AccessEditor',
  components: {
    RolePanel, UserPanel, RightPanel, SmevPanel
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'RoleCreateCommand': new RoleCreateHandler(
            new RoleCommandRepository()
          ),
          'RoleUpdateCommand': new RoleUpdateHandler(
            new RoleCommandRepository()
          ),
          'RoleDeleteCommand': new RoleDeleteHandler(
            new RoleCommandRepository()
          ),
          'TopicRightUpdateCommand': new TopicRightUpdateHandler(
            new TopicRightCommandRepository()
          ),
          'UserCreateCommand': new UserCreateHandler(
            new UserCommandRepository()
          ),
          'UserUpdateCommand': new UserUpdateHandler(
            new UserCommandRepository()
          ),
          'UserDeleteCommand': new UserDeleteHandler(
            new UserCommandRepository()
          ),
          'UsersUploadFromFileCommand': new UsersUploadFromFileHandler(
            new UserCommandRepository()
          ),
          'SmevSettingCreateCommand': new SmevSettingCreateHandler(
            new SmevSettingCommandRepository()
          ),
          'SmevSettingUpdateCommand': new SmevSettingUpdateHandler(
            new SmevSettingCommandRepository()
          ),
          'SmevSettingDeleteCommand': new SmevSettingDeleteHandler(
            new SmevSettingCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'DashboardsQuery': new DashboardsHandler(
            new DashboardQueryRepository()
          ),
          'MenuQuery': new MenuHandler(
            new MenuQueryRepository()
          ),
          'RolesQuery': new RolesHandler(
            new RoleQueryRepository()
          ),
          'RolesCountQuery': new RolesCountHandler(
            new RoleQueryRepository()
          ),
          'RoleByGuidQuery': new RoleByGuidHandler(
            new RoleQueryRepository()
          ),
          'TopicRightsQuery': new TopicRightsHandler(
            new TopicRightQueryRepository()
          ),
          'TopicRightsCountQuery': new TopicRightsCountHandler(
            new TopicRightQueryRepository()
          ),
          'UsersQuery': new UsersHandler(
            new UserQueryRepository()
          ),
          'UsersCountQuery': new UsersCountHandler(
            new UserQueryRepository()
          ),
          'UserByGuidQuery': new UserByGuidHandler(
            new UserQueryRepository()
          ),
          'UserPasswordQuery': new UserPasswordHandler(
            new UserQueryRepository()
          ),
          'UserApiKeyQuery': new UserApiKeyHandler(
            new UserQueryRepository()
          ),
          'UserAvatarQuery': new UserAvatarHandler(
            new UserQueryRepository()
          ),
          'SmevSettingsQuery': new SmevSettingsHandler(
            new SmevSettingQueryRepository()
          ),
          'SmevSettingByGuidQuery': new SmevSettingByGuidHandler(
            new SmevSettingQueryRepository()
          )
        })
      ),
      activeEntity: 'roles',
      selectedRole: null,
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus,
      getSelectedRole: this.getSelectedRole
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    },
    roleSelected(roleGuid) {
      this.selectedRole = roleGuid;
    },
    getSelectedRole() {
      return this.selectedRole;
    }
  },
  mounted() {
    this.getEventBus().$on('selectRole', this.roleSelected);
  }
}
