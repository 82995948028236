import QueryRepositoryInterface from './QueryRepositoryInterface'
import MethodNotImplemented from '../exception/MethodNotImplemented'
import { Nullable } from '@/core/domain/type/types'

export default abstract class AbstractQueryRepository implements QueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any> 
    {
        throw new MethodNotImplemented();
    }

    getById(id: number): Promise<any>
    {
        throw new MethodNotImplemented();
    }

    getByGuid(guid: string): Promise<any>
    {
        throw new MethodNotImplemented();
    }

    getCount(payload: string[][] | object): Promise<any> 
    {
        throw new MethodNotImplemented();
    }

    getByJoinTable(parentGuid: string): Promise<any>
    {
        throw new MethodNotImplemented();
    }

    getParentRecords(guid: string): Promise<any>
    {
        throw new MethodNotImplemented();
    }

    getChildNodes(parentId: Nullable<number>, projectId?: Nullable<number>): Promise<any>
    {
        throw new MethodNotImplemented();
    }
}