export default {
  computed: {
    clickTable () {
      if (this.$attrs['open-card'] && Object.keys(this.$attrs['open-card']).length === 0) {
        return false
      }
      return true
    }
  }
}
