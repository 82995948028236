<template>
  <div>
    <selectTree
    class="extraLine_select"
    :disabled="isEdit"
    :clearable="false"
    :standartTree="true"
    :dataTable="listField"
    :selectAlias="selectAlias"
    :value="currentTableField"
    @input="currentTableField = $event"/>
  </div>
</template>

<script>
import selectTree from '@/components/LogicEditor/components/treeSelect'
export default {
  name: 'currentTableField',
  props: {
    state: {
      type: Object
    },
    listField: {
      type: Array
    },
    isEdit: {
      type: Boolean
    }
  },
  components: {
    selectTree
  },
  data () {
    return {
      currentTableField: null,
      chooseFiled: null
    }
  },
  methods: {
    getValue () {
      return this.chooseFiled.id
    },
    selectAlias (data) {
      this.chooseFiled = data
    }
  },
  mounted () {
    if (this.state) {
      this.currentTableField = this.state.current_table_field_id ? this.state.current_table_field_id : null
    }
  }

}
</script>

<style scoped>

</style>
