import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RoleCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleCommandRepositoryInterface'
import RoleCreateCommand from '@/services/AccessEditor/application/command/RoleCreateCommand'
import { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'

export default class RoleCreateHandler implements CommandHandlerInterface
{
    private repo: RoleCommandRepositoryInterface;

    constructor(repo: RoleCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RoleCreateCommand): Promise<void>
    {
        let item: RoleDTO = {
            name: command.getName(),
            is_readable_default: command.getIsReadable(),
            is_addable_default: command.getIsAddable(),
            is_editable_default: command.getIsEditable(),
            is_deletable_default: command.getIsDeletable(),
            is_menu_visible_default: command.getIsMenuVisible(),
            root_menu_id: command.getRootMenuId(),
            dashboards: command.getDashboards()
        };
        return this.repo.insert(item);
    }
}
