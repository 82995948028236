<template>
  <el-input
    class="textFieldMini"
    v-model="value"
    size="medium"
    :disabled="isEdit"
    v-on:input="$emit('input', $event)"
    type="textarea"
    :placeholder="$locale.object_editor.entity_types.text_field"
  ></el-input>
</template>

<script>
export default {
  name: 'textField',
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      value: null
    }
  },
  mounted () {
    if (this.state) {
      this.value =
        this.state.properties && this.state.properties.value
          ? this.state.properties.value
          : null
    }
  }
}
</script>

<style scoped>
.textFieldMini .el-textarea__inner {
  min-height: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 30px;
}
</style>
