<template>
  <div style="height: 100%">
    <cards
      ref="cards"
      v-show="openedCards.length > 0"
      :opened-cards="openedCards"
      :show-card="showCard"
      @cancelChanges="cancelChanges"
      @back="openedCards = openedCards.slice(0, $event + 1)"
      @back-registry="showCard=false; openedCards = []"
      style="height: calc(100% - 15px);padding-top: 15px"
    ></cards>
    <el-form :model="interfaceModel" v-show="!showCard" ref="validateForm" v-loading="loading" style="height: 100%" @submit.native.prevent>
      <InterfaceViewer
        ref="interface_viewer"
        :model="interfaceModel"
      ></InterfaceViewer>
    </el-form>
  </div>
</template>

<script>
import InterfaceViewer from '@/components/InterfaceViewer'
import Dashboard from '@/components/DashboardEditor/Models/Dashboard'
import Cards from '../../components/Registry/Cards.vue'

export default {
  components: {
    InterfaceViewer,
    Cards
  },
  props: ['id', 'model', 'main', 'parentContext'],
  name: 'Dashboard',
  data () {
    return {
      loading: true,
      openedCards: [],
      showCard: false,
      interfaceModel: this.model || {}
    }
  },
  provide () {
    return {
      openRegistryCard: this.openRegistryCard,
      openDashboardCard: this.openDashboardCard,
      getParentContext: this.getParentContext
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return false
      }
      this.loading = true
      let dashboard = await new Dashboard().find(this.id)
      this.$refs.interface_viewer.loadState(JSON.parse(dashboard.structure))
      this.loading = false
    },
    getParentContext () {
      return this.parentContext
    },
    cancelChanges () {
      if (this.openedCards.length === 1) {
        this.showCard = false
        this.openedCards = []
      } else {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
      }
    },
    openDashboardCard (dashboardId, name, recordId = null, initialData = {}) {
      if (!dashboardId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: dashboardId,
        recordId: recordId,
        name: name,
        initialData: initialData,
        isDashboard: true
      })
    },
    openRegistryCard ({
      registryId,
      cardId,
      cardName,
      recordId = null,
      initialData = {},
      registry = null
    }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false
      })
    }
  }
}
</script>

<style scoped>

</style>
