import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import ActionType from './ActionType'

export class XmlRouteDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    topic?: Nullable<string>;
    task_id?: Nullable<number>;
    dag_id?: Nullable<string>;
    action_type?: Nullable<string>;

    constructor({ id, guid, name, topic, task_id, dag_id, action_type}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, topic?: Nullable<string>, task_id?: Nullable<number>, dag_id?: Nullable<string>, action_type?: Nullable<string>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.topic = topic;
        this.task_id = task_id;
        this.dag_id = dag_id;
        this.action_type = action_type;     
    }
}

export default class XmlRoute extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private topic: string;
    private taskId: number;
    private dagId: string;
    private actionType: ActionType;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        topic: string,
        taskId: number,
        dagId: string,
        actionType: ActionType
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.topic = topic;
        this.taskId = taskId;
        this.dagId = dagId;      
        this.actionType = actionType;
    }

    static create(dto: XmlRouteDTO): XmlRoute
    {
        return new XmlRoute(
            dto.id,
            dto.guid,
            dto.name,
            dto.topic,
            dto.task_id,
            dto.dag_id,
            ActionType[dto.action_type.toUpperCase()]
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setTopic(topic: string): void
    {
        this.topic = topic;
    }

    getTopic(): string
    {
        return this.topic;
    }

    setTaskId(taskId: number): void
    {
        this.taskId = taskId;
    }

    getTaskId(): number
    {
        return this.taskId;
    }

    setDagId(dagId: string): void
    {
        this.dagId = dagId;
    }

    getDagId(): string
    {
        return this.dagId;
    }

    setActionType(actionType: ActionType): void
    {
        this.actionType = actionType;
    }

    getActionType(): ActionType
    {
        return this.actionType;
    }
}
