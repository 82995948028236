import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export namespace LayerAPI {

    export class AddLayer implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layers';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerDTO) {}
    }

}