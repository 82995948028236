<script type="ts">
import ElButton from 'element-ui/packages/button/src/button.vue'
import ElDropdown from 'element-ui/packages/dropdown/src/dropdown.vue'
import ElDropdownMenu from 'element-ui/packages/dropdown/src/dropdown-menu.vue'
import ElDropdownItem from 'element-ui/packages/dropdown/src/dropdown-item.vue'

export default {
    name: 'SelectOptions',
    props: {
        addRecord: {
            type: Function,
            default: () => {}
        },
        selectRecord: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        createOptions() {
            return this.$createElement(ElDropdown, {
                props: {
                    placement: 'bottom-start',
                    trigger: 'click'
                },
                on: {
                    command: (val) => {
                        if (val == 'select_record') {
                            this.selectRecord()
                        } else if (val == 'add_record') {
                            this.addRecord()
                        }
                    }
                }
                },[
                this.$createElement(ElButton, {
                    props: {
                    icon: "el-icon-more",
                    size: "mini",
                    circle: true
                    },
                    style: {
                    "margin-top": "42px",
                    "margin-left": "8px"
                    }
                }),
                this.$createElement(ElDropdownMenu, {
                    props: {
                    slot: "dropdown"
                    }
                },[
                    this.$createElement(ElDropdownItem, {
                    props: {
                        command: "add_record"
                    }
                    },[this.$locale.main.button.add_record]),
                    this.$createElement(ElDropdownItem, {
                    props: {
                        command: "select_record"
                    }
                    },[this.$locale.main.button.select_record])               
                ])
            ]);
        }
    },
    render (createElement) {
        return createElement("div", {
            class: {
                "select-options": true
            }
        }, [this.createOptions()]);
    }
};
</script>