import { render, staticRenderFns } from "./xrefField.vue?vue&type=template&id=79e79efe&scoped=true&"
import script from "./xrefField.vue?vue&type=script&lang=js&"
export * from "./xrefField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e79efe",
  null
  
)

export default component.exports