<template>
  <div>
    <el-tree
      class="tree-source"
      ref="tree"
      node-key="id"
      lazy
      :load="loadNode"
      :props="tree.props">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span :title="node.label" :class="{ 'selected-node': isSelected(data) }" style="width: 100%;" @click="selectEntity(node, data)">
          <span
            v-if="typeof data.icon !== 'undefined'"
            style="font-size: 16px;"
            :class="getTreeIcon(node)">
          </span> {{ node.label.length > 40 ? node.label.substring(0, 40) + '...' : node.label }}
        </span>
        <span v-if="showAddButton(data)">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.add" placement="top">
            <el-button
              circle
              class="added-button"
              type="default"
              size="mini"
              icon="el-icon-plus"
              @click.stop="addEntity(node, data)">
            </el-button>
          </el-tooltip>
        </span>
        <span v-if="isSelected(data) && data.model === 'task'">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.view_log" placement="top">
            <el-button
              type="text"
              size="small"
              icon="el-icon-files"
              @click="viewLog(data)">
              {{ $locale.etl_editor.button.import_log }}
            </el-button>
          </el-tooltip>
        </span>
        <span v-if="isSelected(data)">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.delete" placement="top">
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              class="text_danger"
              @click="deleteEntity(node, data)">
              {{ $locale.main.button.delete }}
            </el-button>
          </el-tooltip>
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import Task from '../Models/Task'
import Extractor from '../Models/Extractor'
import Loader from '../Models/Loader'
import Template from '../Models/Template'
import Transformer from '../Models/Transformer'

export default {
  name: 'TreeSource',

  props: {
    addEntity: {
      type: Function,
      default: function (node, data) {
      }
    },
    editEntity: {
      type: Function,
      default: async (node, data) => {
      }
    },
    deleteEntity: {
      type: Function,
      default: function (node, data) {
      }
    },
    viewLog: {
      type: Function,
      default: function (data) {
      }
    }
  },

  watch: {
    selectedNode () {
      this.tree.selectedNode = this.selectedNode
    }
  },

  data () {
    return {
      available: ['csv', 'object_table', 'excel', 'json', 'json_tree', 'xml', 'txt', 'system_table', 'task', 'template', 'databus', 'geometry_reprojection'],
      tree: {
        data: [
          {
            id: 1000000,
            name: this.$locale.etl_editor.menu.extractors,
            model: 'extractor',
            type: null,
            icon: 'el-icon-folder',
            isLeaf: false,
            children: [
              {
                id: 1000001,
                name: this.$locale.etl_editor.menu.csv,
                model: 'extractor',
                type: 'csv',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000002,
                name: this.$locale.etl_editor.menu.excel,
                model: 'extractor',
                type: 'excel',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000003,
                name: this.$locale.etl_editor.menu.json,
                model: 'extractor',
                type: 'json',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000004,
                name: this.$locale.etl_editor.menu.json_tree,
                model: 'extractor',
                type: 'json_tree',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000005,
                name: this.$locale.etl_editor.menu.xml,
                model: 'extractor',
                type: 'xml',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000006,
                name: this.$locale.etl_editor.menu.object_table,
                model: 'extractor',
                type: 'object_table',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000007,
                name: this.$locale.etl_editor.menu.system_table,
                model: 'extractor',
                type: 'system_table',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 1000008,
                name: this.$locale.etl_editor.menu.txt,
                model: 'extractor',
                type: 'txt',
                icon: 'el-icon-folder',
                isLeaf: false
              }
            ]
          },
          {
            id: 2000000,
            name: this.$locale.etl_editor.menu.loaders,
            model: 'loader',
            type: null,
            icon: 'el-icon-folder',
            isLeaf: false,
            children: [
              {
                id: 2000001,
                name: this.$locale.etl_editor.menu.csv,
                model: 'loader',
                type: 'csv',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 2000002,
                name: this.$locale.etl_editor.menu.excel,
                model: 'loader',
                type: 'excel',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 2000003,
                name: this.$locale.etl_editor.menu.object_table,
                model: 'loader',
                type: 'object_table',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 2000004,
                name: this.$locale.etl_editor.menu.txt,
                model: 'loader',
                type: 'txt',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 2000005,
                name: this.$locale.etl_editor.menu.json,
                model: 'loader',
                type: 'json',
                icon: 'el-icon-folder',
                isLeaf: false
              },
              {
                id: 2000006,
                name: this.$locale.etl_editor.menu.databus,
                model: 'loader',
                type: 'databus',
                icon: 'el-icon-folder',
                isLeaf: false
              }
            ]
          },
          {
            id: 3000000,
            name: this.$locale.etl_editor.menu.tasks,
            model: 'task',
            type: 'task',
            icon: 'el-icon-folder',
            isLeaf: false
          },
          {
            id: 4000000,
            name: this.$locale.etl_editor.menu.templates,
            model: 'template',
            type: 'template',
            icon: 'el-icon-folder',
            isLeaf: false
          },
          {
            id: 5000000,
            name: this.$locale.etl_editor.menu.transformers,
            model: 'transformer',
            type: null,
            icon: 'el-icon-folder',
            isLeaf: false,
            children: [
              {
                id: 5000001,
                name: this.$locale.etl_editor.menu.geometry_reprojection,
                model: 'transformer',
                type: 'geometry_reprojection',
                icon: 'el-icon-folder',
                isLeaf: false
              }
            ]
          }
        ],
        props: {
          children: 'children',
          label: 'name',
          isLeaf: 'isLeaf'
        },
        models: {
          extractor: Extractor,
          loader: Loader,
          task: Task,
          template: Template,
          transformer: Transformer
        },
        selectedNode: this.selectedNode
      },
      getIcon: {
        extractor: 'el-icon-d-arrow-right',
        loader: 'el-icon-d-arrow-right',
        task: 'el-icon-tickets',
        template: 'el-icon-document-checked',
        transformer: 'el-icon-d-arrow-right'
      },
      typeProperty: {
        extractor: 'extractor_type_id',
        loader: 'loader_type_id',
        transformer: 'transformer_type_id'
      }
    }
  },

  methods: {
    getTreeIcon (node) {
      if (!node.isLeaf && !node.expanded) {
        return node.data.icon
      } else if (!node.isLeaf && node.expanded) {
        return 'el-icon-folder-opened'
      }

      return node.data.icon
    },
    getSelectedNode () {
      return this.tree.selectedNode
    },
    selectEntity (node, data) {
      this.tree.selectedNode = node

      if (typeof data.guid !== 'undefined') {
        this.editEntity(node, data)
      }
    },
    isSelected (data) {
      return this.tree.selectedNode && typeof this.tree.selectedNode.data.guid !== 'undefined' && this.tree.selectedNode.data.guid === data.guid
    },
    showAddButton (data) {
      return typeof data.guid === 'undefined' && this.available.indexOf(data.type) !== -1
    },
    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(this.tree.data)
      } else if (node.level === 1) {
        if (['task', 'template'].indexOf(node.data.model) !== -1) {
          resolve(await this.load(node.data.model, null))
        } else {
          resolve(node.data.children)
        }
      } else if (node.level === 2) {
        resolve(await this.load(node.data.model, node.data.type))
      }
    },
    async load (model, type) {
      let result = []

      let getParams = {
        extractor: {
          extractor_type_id: type
        },
        loader: {
          loader_type_id: type
        },
        task: {},
        template: {},
        transformer: {
          transformer_type_id: type
        }
      }

      let response = await new this.tree.models[model]().params(Object.assign({ order: 'name:asc' }, getParams[model])).get()
      response.forEach((item) => {
        result.push({
          id: item.id,
          name: item.name,
          guid: item.guid,
          icon: this.getIcon[model],
          model: model,
          type: ['task', 'template'].indexOf(model) === -1 ? item[this.typeProperty[model]] : null,
          isLeaf: true
        })
      })

      return result
    },
    addNode (parentNode, data) {
      this.$refs.tree.append({
        id: data.id,
        name: data.name,
        guid: data.guid,
        icon: this.getIcon[parentNode.data.model],
        model: parentNode.data.model,
        type: ['task', 'template'].indexOf(parentNode.data.model) === -1 ? data[this.typeProperty[parentNode.data.model]] : null,
        isLeaf: true
      }, parentNode.data.id)
    },
    deleteNode (node) {
      this.$refs.tree.remove(node.data.id)
    },
    getTree () {
      return this.$refs.tree
    }
  }
}
</script>

<style type="text/css">
.tree-source .added-button {
  display: none;
}
.tree-source .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 90%;
}
.tree-source .custom-tree-node:hover .added-button,
.tree-source .custom-tree-node:focus .added-button {
  display: block;
}
</style>
