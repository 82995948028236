














export default {
  name: 'MainToolbar',
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
