import { ActionTree } from 'vuex'
import { LayerState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LayerCreatedEvent, LayerLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LayerAPI } from '@/services/MapEditor/infrastructure/api/LayerAPI'

export const actions: ActionTree<LayerState, any> = {
    [ActionTypes.ADD_LAYER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerLoadingEvent());
            let location = await APIClient.shared.request(new LayerAPI.AddLayer(data.payload));
            commit(new LayerCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
