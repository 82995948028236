<template>
  <div class="extended_object">
    <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="value" size="mini" class="extended-form">
      <el-row>
        <el-tabs value="1" class="extended-tabs" type="border-card">
          <el-tab-pane :label="$locale.bi_editor.form.tabs.objects" name="1">
            <el-form-item :label="$locale.bi_editor.form.label.name" prop="name">
              <el-input v-model="value.name"></el-input>
            </el-form-item>

            <el-form-item>
              <b>{{ entity.name }} (id: {{ entity.id }})</b>
            </el-form-item>

            <el-form-item>
              <el-checkbox :label="$locale.bi_editor.form.label.is_materialized" v-model="value.is_materialized"></el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-checkbox :label="$locale.bi_editor.form.label.is_group_by_record" v-model="value.is_group_by_record"></el-checkbox>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="$locale.bi_editor.form.tabs.fields" name="2">
            <el-row :gutter="10">
              <el-col :span="12">
                <!--<registry-tree :registry-id="value.object_id" :multiple-checked="true" type="all-objects" />-->
                <el-form-item label="Выбор полей">
                  <el-scrollbar class="scroll-tree-field">
                    <el-tree
                      class="tree-field tree-fix-button"
                      lazy
                      :load="loadNode"
                      :props="treeProps">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>
                        {{ node.label.length > 32 ? node.label.substring(0, 32) + '...' : node.label }} (field id: {{ data.id }}, object id: {{ data.object_id }}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)
                      </span>
                      <span>
                        <el-button
                          v-if="availableAddFieldType.indexOf(data.entity_type_id) === -1"
                          type="text"
                          size="small"
                          @click="addField(node, data)"
                        >
                          {{ $locale.main.button.add }}
                        </el-button>
                      </span>
                    </span>
                    </el-tree>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-tooltip class="item" effect="dark" content="Add formula" placement="top">
                  <el-button style="float: right;" type="success" icon="el-icon-plus" circle @click="addFormula" size="mini"></el-button>
                </el-tooltip>
                <el-form-item :label="$locale.bi_editor.form.label.select_field">
                  <el-scrollbar class="scroll-tree-field">
                    <el-tree
                      class="tree-field"
                      :data="value.extended_object_fields"
                      :props="treeProps">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span v-if="data.field_id" :style="data.is_deleted ? { color: 'gray' } : {}">
                      {{ data.name }} (field id: {{ data.field_id }})
                    </span>
                    <span v-if="!data.field_id" :style="data.is_deleted ? { color: 'gray' } : {}">
                      {{ data.name }}
                      <span v-if="data.formula_id">(formula id: {{ data.formula_id }})</span>
                    </span>
                    <span>
                      <span>
                        <el-button
                          v-if="!data.is_deleted"
                          type="text"
                          size="small"
                          class="text_danger"
                          @click="deleteField(node, data)">
                        {{ $locale.main.button.delete }}
                        </el-button>
                        <el-button
                          v-else
                          type="text"
                          size="small"
                          style="color: gray;"
                          @click="recoverField(node, data)">
                        {{ $locale.main.button.recover }}
                        </el-button>
                      </span>
                      |
                      <span>
                        <el-popover
                          placement="right"
                          width="400"
                          trigger="click">
                          <el-form-item label-width="200px" :label="$locale.bi_editor.form.label.alias">
                            <el-input v-model="data.name" @input="aliasChange(data)" @blur="aliasBlur(data)" @focus="aliasFocus(data)"></el-input>
                          </el-form-item>

                          <el-form-item label-width="200px" :label="$locale.bi_editor.form.label.sort_order">
                            <el-input-number v-model="data.sort_order" :min="0" :max="999"></el-input-number>
                          </el-form-item>

                          <el-form-item label-width="200px" :label="$locale.bi_editor.form.label.function_type">
                            <el-select v-model="data.function_type_id" clearable @clear="data.function_type_id = null">
                              <el-option v-for="itemFun in functionTypes" :key="itemFun.id" :label="$locale.bi_editor.function_types[itemFun.name]" :value="itemFun.id"></el-option>
                            </el-select>
                          </el-form-item>

                          <template v-if="data.function_type_id">
                            <el-form-item label-width="200px">
                              <el-checkbox v-model="data.is_distinct">{{ $locale.bi_editor.form.label.is_distinct }}</el-checkbox>
                            </el-form-item>
                          </template>

                          <el-form-item label-width="200px">
                            <el-checkbox v-model="data.group_by">{{ $locale.bi_editor.form.label.group_by }}</el-checkbox>
                          </el-form-item>

                          <el-form-item label-width="200px" :label="$locale.bi_editor.form.label.order_by">
                            <el-select clearable @clear="data.order_by = null" v-model="data.order_by" :placeholder="$locale.bi_editor.form.label.order_by">
                              <el-option
                                v-for="(itemOrder, index) in orderTypes"
                                :key="index"
                                :label="$locale.bi_editor.order_types[itemOrder.name]"
                                :value="itemOrder.id">
                              </el-option>
                            </el-select>
                          </el-form-item>

                          <el-form-item label-width="200px">
                            <el-checkbox v-model="data.is_group_filter">{{ $locale.bi_editor.form.label.is_group_filter }}</el-checkbox>
                          </el-form-item>

                          <template v-if="!data.field_id">
                            <div class="formula-box">
                              <h4>Формула</h4>

                              <div contenteditable="false" readonly :ref="`formula_${data.id}`" class="row_formulas__edit"></div>

                              <el-button type="primary" @click="editFormula(data)" plain size="small">{{ $locale.notify_editor.label.calc }}</el-button>
                            </div>
                          </template>

                          <el-button type="text" slot="reference" size="small" @click="editField(data)">
                            {{ $locale.main.button.edit }}
                          </el-button>
                        </el-popover>
                      </span>
                    </span>
                  </span>
                    </el-tree>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$locale.bi_editor.form.tabs.filters" name="3">
            <el-scrollbar class="scroll-filter">
              <el-query-builder v-if="ruleLoaded" v-model="filter" name="1" :rules="qbRules" :labels="qbLabels"></el-query-builder>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-form>

    <el-dialog
      :visible.sync="dialogNotify"
      width="45%"
      :modal="true"
      :top='"15"'
      :close-on-click-modal="false">
      <span>
        <column-formula
          ref="formulaEditor"
          :propslocal="propslocal"
          :dialogNotify.sync="dialogNotify"
          :objectFormula="objectFormula" />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Entity from '../../ObjectEditor/Models/Entity'
import FunctionType from '../Models/FunctionType'
import OperationType from '../Models/OperationType'
import FilterType from '../Models/FilterType'
import Join from '../Models/ExtendedObjectJoin'
import Field from '../Models/ExtendedObjectField'
import ElQueryBuilder from '../Render/QueryBuilder/ElementQueryBuilder'
import columnFormula from '@/components/LogicEditor/components/column_formula'
import Logic from '@/components/LogicEditor/Models/Logic'
import FormulasHttp from '@/components/LogicEditor/Models/formulas'

export default {
  name: 'UpdateExtendedObject',
  props: {
    value: Object,
    rules: Object,
    dataWarehouse: Object
  },
  watch: {
    value () {
      this.localValue = this.value
    }
  },
  components: {
    ElQueryBuilder,
    columnFormula
  },
  data () {
    return {
      ruleLoaded: false,
      filter: {},
      localValue: this.value,
      availableEntityType: [
        'xref_field',
        'xref_outer_field',
        'xref_multi_field'
      ],
      availableAddFieldType: ['xref_field', 'xref_multi_field', 'xref_outer_field', 'file_field', 'button_field', 'field_group', 'row_formula_field'],
      entity: {},
      qbRules: [],
      qbDelRules: [],
      orderTypes: [
        {
          id: 'asc',
          name: 'asc'
        }, {
          id: 'desc',
          name: 'desc'
        }
      ],
      qbLabels: {
        matchType: 'Логическая связка',
        matchTypes: [
          {
            'id': 'and',
            'label': 'И'
          },
          {
            'id': 'or',
            'label': 'ИЛИ'
          }
        ],
        addRule: 'Добавить условие',
        addGroup: 'Добавить группу',
        textInputPlaceholder: 'Значение',
        rulePlaceholder: 'Выберите поле'
      },
      functionTypes: [],
      operationTypes: [],
      filterTypes: [],
      treeProps: {
        label: 'name',
        children: 'children',
        isLeaf: 'leaf'
      },
      toQueryBuilderType: {
        string_field: 'text',
        text_field: 'textarea',
        integer_field: 'integer',
        float_field: 'float',
        date_field: 'date',
        time_field: 'time',
        datetime_field: 'datetime',
        boolean_field: 'checkbox'
      },
      typeToPlaceholder: {
        string_field: 'Значение: "Текст..."',
        text_field: 'Значение: "Текст..."',
        integer_field: 'Число: 777',
        float_field: 'Дробное число: 99.9',
        date_field: 'Дата: 01.01.2020',
        time_field: 'Время: 08:00:00',
        datetime_field: 'Дата и время: 01.01.2020 08:00:00',
        boolean_field: null
      },
      opers: {
        string_field: [
          'eq', 'neq', 'in', 'not_in', 'is_null', 'is_not_null', 'like', 'not_like'
        ],
        text_field: [
          'eq', 'neq', 'is_null', 'is_not_null', 'like', 'not_like'
        ],
        integer_field: [
          'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        float_field: [
          'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        date_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        time_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        datetime_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        boolean_field: [
          'eq', 'neq', 'is_null', 'is_not_null'
        ]
      },
      fieldEdit: null,
      propslocal: {
        // новое действие
        isNewAction: true,
        // выбранная бизнес логика
        businesslogic: {},
        // список для построения subAction
        listOpenAction: null,
        // выбранное subAction
        selectSubAction: '',
        // значение полей от сервера
        getAnswer: '',
        getNotifyContent: null
      },
      objectFormula: {
        eof: null,
        isShow: true
      },
      dialogNotify: false
    }
  },
  async mounted () {
    this.entity = await Entity.$find(this.value.object_id)
    this.functionTypes = await new FunctionType().get()
    this.operationTypes = await new OperationType().get()
    this.filterTypes = await new FilterType().get()
    this.loadFields()
    this.filter = this.parseFilter(this.value.filter)
    let logic = await new Logic().custom('/logics').params({ registry_id: this.value.object_id }).get()
    if (logic[0]) {
      this.propslocal.businesslogic = logic[0]
    }
  },
  methods: {
    async editFormula (data) {
      this.objectFormula.eof = data
      this.dialogNotify = true

      if (data.formula) {
        this.propslocal.getAnswer = await FormulasHttp.find(data.formula.id)
      }

      this.$nextTick(async () => {
        await this.$refs.formulaEditor.initialize()
      })
    },
    editField (data) {
      if (data.formula) {
        let formulaBox = `formula_${data.id}`

        if (data.formula.is_dsl) {
          this.$refs[formulaBox].innerHTML = data.formula.dsl_statement
        } else {
          this.$refs[formulaBox].textContent = data.formula.sql_statements.select
        }
      }
    },
    addFormula () {
      this.$prompt('Задайте псевдоним новому полю:', 'Добавление формульного поля', {
        confirmButtonText: 'Добавить',
        cancelButtonText: 'Отмена',
        inputValue: null,
        inputPattern: /^[_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]+$/,
        inputErrorMessage: 'Некорректный псивдоним!'
      }).then(async ({ value }) => {
        let valid = true
        this.value.extended_object_fields.forEach(field => {
          if (value === field.name) {
            valid = false
            this.$alert('Введённый псевдоним совпадает с псеводнимом ранее добавленного поля!', 'Ошибка', {
              confirmButtonText: 'ОК'
            })
          }
        })

        if (valid) {
          let newData = {
            name: value,
            extended_object_id: this.value.id,
            field_id: null,
            formula_id: null,
            function_type_id: null,
            group_by: false,
            order_by: null,
            extended_object_join_id: null,
            row_order: 0,
            sort_order: 0,
            is_group_filter: false,
            is_distinct: false
          }

          let response = await (new Field(newData)).save()

          this.localValue.extended_object_fields.push(response)
        }
      })
    },
    aliasFocus (field) {
      this.fieldEdit = field.name
    },
    aliasBlur (field) {
      if (field.name === null || field.name === '') {
        field.name = this.fieldEdit
      }
    },
    aliasChange (field) {
      field.name = field.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.qbRules.forEach(rule => {
        if (rule.id === field.id) {
          rule.label = field.name
          this.filterChangeName(this.filter, field)
          this.localValue.filter = this.getFilter(this.filter)
        }
      })
    },
    filterChangeName (filter, field) {
      if (typeof filter.children !== 'undefined') {
        filter.children.forEach(item => {
          if (item.type === 'query-builder-rule') {
            if (item.query.rule === field.id) {
              item.query.selectedOperand = field.name
            }
          }

          if (item.type === 'query-builder-group') {
            this.filterChangeName(item.query, field)
          }
        })
      }
    },
    parseFilter (filters, root = true) {
      if (filters === null) {
        return {
          logicalOperator: 'and',
          children: []
        }
      }

      let data = {}

      for (const [key, value] of Object.entries(filters)) {
        if (['and', 'or'].indexOf(key) !== -1) {
          // Group
          if (root) {
            data = {
              logicalOperator: key,
              children: []
            }

            if (typeof value === 'object') {
              value.forEach(filter => {
                data.children.push(this.parseFilter(filter, false))
              })
            }
          } else {
            data = {
              type: 'query-builder-group',
              query: {
                logicalOperator: key,
                children: []
              }
            }

            if (typeof value === 'object') {
              value.forEach(filter => {
                data.query.children.push(this.parseFilter(filter, false))
              })
            }
          }
        }

        let contains = { contains_string: 'like', not_contains_string: 'not_like' }
        if (['eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte'].indexOf(key) !== -1) {
          // Rule
          let operand = Object.keys(value)[0]
          let field = this.value.extended_object_fields.find(f => f.name === operand)
          let qbRule = this.qbRules.find(rule => rule.id === field.id)
          data = {
            type: 'query-builder-rule',
            query: {
              rule: field.id,
              selectedOperator: qbRule.operators.find(operator => operator.id === key),
              selectedOperand: operand, // Из value Получить имя свойства
              value: value[operand] // Из value[имя свойства] Получить значение
            }
          }
        } else if (['contains_string', 'not_contains_string'].indexOf(key) !== -1) {
          let operand = Object.keys(value)[0]
          let field = this.value.extended_object_fields.find(f => f.name === operand)
          let qbRule = this.qbRules.find(rule => rule.id === field.id)
          data = {
            type: 'query-builder-rule',
            query: {
              rule: field.id,
              selectedOperator: qbRule.operators.find(operator => operator.id === contains[key]),
              selectedOperand: operand, // Из value Получить имя свойства
              value: value[operand] // Из value[имя свойства] Получить значение
            }
          }
        } else if (['is_null', 'is_not_null'].indexOf(key) !== -1) {
          // Rule specific
          let field = this.value.extended_object_fields.find(f => f.name === value)
          let qbRule = this.qbRules.find(rule => rule.id === field.id)
          data = {
            type: 'query-builder-rule',
            query: {
              rule: field.id,
              selectedOperator: qbRule.operators.find(operator => operator.id === key),
              selectedOperand: value,
              value: null
            }
          }
        }
      }

      return data
    },
    checkFilter (filter, field) {
      let result = false
      if (typeof filter.children !== 'undefined') {
        filter.children.forEach(item => {
          if (item.type === 'query-builder-rule') {
            // rule: 115
            // selectedOperand: "Период действия"
            // selectedOperator: "eq"
            // value: "123"
            // item.query.rule
            // {"and":[{"eq":{"Ставка дисконтирования":"123"}}]}
            if (field === item.query.rule) {
              result = true
            }
          }

          if (item.type === 'query-builder-group') {
            result = this.checkFilter(item.query, field)
          }
        })
      }

      return result
    },
    getFilter (filter) {
      let value = {}
      let logicalOperator = filter.logicalOperator

      if (typeof value[logicalOperator] === 'undefined') {
        this.$set(value, logicalOperator, [])
      }

      if (typeof filter.children !== 'undefined') {
        filter.children.forEach(item => {
          if (item.type === 'query-builder-rule') {
            let contains = { like: 'contains_string', not_like: 'not_contains_string' }
            let parent = {}
            let child = {}
            if (typeof item.query.selectedOperator !== 'undefined') {
              let operator = item.query.selectedOperator.id
              if (['is_null', 'is_not_null'].indexOf(operator) !== -1) {
                this.$set(parent, operator, item.query.selectedOperand)
              } else if (['like', 'not_like'].indexOf(operator) !== -1) {
                this.$set(child, item.query.selectedOperand, item.query.value)
                this.$set(parent, contains[operator], child)
              } else {
                this.$set(child, item.query.selectedOperand, item.query.value)
                this.$set(parent, operator, child)
              }
            }

            value[logicalOperator].push(parent)
          }

          if (item.type === 'query-builder-group') {
            let ret = this.getFilter(item.query)
            value[logicalOperator].push(ret)
          }
        })
      }

      return value
    },
    loadFields () {
      this.qbRules = []
      this.value.extended_object_fields.forEach(field => {
        if (field.field_id) {
          let operators = []
          this.operationTypes.forEach(item => {
            if (this.opers[field.entity_type_id].indexOf(item.id) !== -1) {
              operators.push({
                id: item.id,
                label: this.$locale.bi_editor.operation_types[item.name]
              })
            }
          })

          this.qbRules.push({
            id: field.id,
            label: field.name,
            inputType: this.toQueryBuilderType[field.entity_type_id],
            operators: operators,
            operands: undefined,
            placeholder: this.typeToPlaceholder[field.entity_type_id]
          })
        }
      })
      this.ruleLoaded = true
    },
    async addField (node, data) {
      this.$prompt('Задайте псевдоним выбранному полю:', 'Добавление поля', {
        confirmButtonText: 'Добавить',
        cancelButtonText: 'Отмена',
        inputValue: node.data.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, ''),
        inputPattern: /^[_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]+$/,
        inputErrorMessage: 'Некорректный псивдоним!'
      }).then(async ({ value }) => {
        let valid = true
        this.value.extended_object_fields.forEach(field => {
          if (value === field.name) {
            valid = false
            this.$alert('Введённый псевдоним совпадает с псеводнимом ранее добавленного поля!', 'Ошибка', {
              confirmButtonText: 'ОК'
            })
          }
        })

        if (valid) {
          let xrefNode = this.getXrefNode(node)
          let joinId = null
          let response

          if (xrefNode !== null) {
            let joinWithParent = await this.buildJoins(xrefNode)
            let join = this.findJoinByXref(joinWithParent.xref_path)

            if (join !== null) {
              joinId = join.id
            } else {
              response = await (new Join(joinWithParent)).save()
              this.localValue.extended_object_joins = await Join.params({ extended_object_id: this.value.id }).get()
              joinId = response.id
            }
          }

          let newData = {
            name: value,
            extended_object_id: this.value.id,
            field_id: node.data.id,
            function_type_id: null,
            group_by: false,
            order_by: null,
            extended_object_join_id: joinId,
            row_order: 0,
            sort_order: 0,
            is_group_filter: false,
            is_distinct: false
          }

          response = await (new Field(newData)).save()

          this.localValue.extended_object_fields.push(response)

          let operators = []
          this.operationTypes.forEach(item => {
            if (this.opers[response.entity_type_id].indexOf(item.id) !== -1) {
              operators.push({
                id: item.id,
                label: this.$locale.bi_editor.operation_types[item.name]
              })
            }
          })

          this.qbRules.push({
            id: response.id,
            label: response.name,
            inputType: this.toQueryBuilderType[response.entity_type_id],
            operators: operators,
            placeholder: this.typeToPlaceholder[response.entity_type_id]
          })
        }
      })
    },
    deleteField (node, data) {
      let me = this
      if (!data.id) {
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex(d => d.id === data.id)
        children.splice(index, 1)
      } else {
        if (this.checkFilter(this.filter, data.id)) {
          this.$alert('Удалите все фильтры связанные с этим полем!', 'Ошибка', {
            confirmButtonText: 'OK'
          })
        } else {
          this.qbRules.forEach(function (rule, index) {
            if (rule.id === data.id) {
              me.qbDelRules.push(rule)
              me.qbRules.splice(index, 1)
            }
          })

          this.$set(data, 'is_deleted', true)
        }
      }
    },
    recoverField (node, data) {
      let me = this
      this.qbDelRules.forEach(function (rule, index) {
        if (rule.id === data.id) {
          me.qbRules.push(rule)
          me.qbDelRules.splice(index, 1)
        }
      })

      this.$delete(data, 'is_deleted')
    },
    getXrefNode (curNode) {
      let node = null

      if (curNode.data !== undefined) {
        if (this.availableEntityType.indexOf(curNode.data.entity_type_id) !== -1) {
          node = curNode
        } else {
          if (curNode.parent !== null) {
            node = this.getXrefNode(curNode.parent)
          }
        }
      }

      return node
    },
    findJoinByXref (xref) {
      let resJoin = this.value.extended_object_joins.find(join => join.xref_path === xref)

      return resJoin !== undefined ? resJoin : null
    },
    buildJoinXrefTest (node) {
      let xref = ''

      if (node !== null) {
        xref = '' + node.data.id

        let parent = this.getXrefNode(node.parent)

        if (parent !== null) {
          xref += '_' + this.buildJoinXrefTest(parent)
        }
      }

      return xref
    },
    async buildJoins (node) {
      let join = null

      if (node !== null) {
        if (node.data !== undefined) {
          let sourceFieldId = null
          let targetFieldId = null

          let xrefFieldId
          node.data.properties.forEach(property => {
            if (property.id === 'xref') {
              xrefFieldId = parseInt(property.value)
            }
          })

          if (['xref_field', 'xref_multi_field'].indexOf(node.data.entity_type_id) !== -1) {
            sourceFieldId = node.data.id
          } else {
            targetFieldId = xrefFieldId
          }

          let response = await Entity.$find(xrefFieldId)

          join = {
            extended_object_id: this.value.id,
            source_field_id: sourceFieldId,
            target_object_id: response.object_id,
            target_field_id: targetFieldId,
            xref_id: node.data.id,
            xref_path: this.buildJoinXrefTest(this.getXrefNode(node)),
            is_inner: false
          }

          let parentJoin = null
          let existJoin = null
          if (node.parent !== null) {
            parentJoin = await this.buildJoins(this.getXrefNode(node.parent))
            existJoin = parentJoin !== null ? this.findJoinByXref(parentJoin.xref_path) : null
          }

          if (existJoin !== null) {
            join.parent_id = existJoin.id
          } else {
            join.parent = parentJoin
          }
        }
      }

      return join
    },
    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities(this.value.object_id, node))
      } else {
        if (this.availableEntityType.indexOf(node.data.entity_type_id) !== -1) {
          let response = {}
          response = await Entity.$find(node.data.id)
          let propertyFieldId
          response.properties.forEach(item => {
            if (item.id === 'xref') {
              propertyFieldId = item.value !== null ? parseInt(item.value) : null
            }
          })
          if (propertyFieldId !== null) {
            response = await Entity.$find(propertyFieldId)

            resolve(await this.loadEntities(response.object_id, node))
          } else {
            console.log('Не проставлена ссылка!')
          }
        } else {
          resolve(await this.loadEntities(node.data.id, node))
        }
      }
    },
    async loadEntities (entityId, node) {
      let data = []

      data = await new Entity({ id: entityId }).children().$get()

      data.forEach(entity => {
        if (this.availableEntityType.indexOf(entity.entity_type_id) !== -1) {
          entity.leaf = false
        }

        entity.properties.forEach(property => {
          if (property.id === 'xref' && property.value === null) {
            entity.leaf = true
          }
        })
      })

      return data
    },
    validate (callback) {
      if (this.filter.children.length > 0) {
        this.localValue.filter = this.getFilter(this.filter)
      } else {
        this.localValue.filter = null
      }

      this.$refs.form.validate(callback)
    }
  }
}
</script>

<style type="text/css">
  .tree-fix-button .custom-tree-node .el-button {
    position: absolute;
    right: 0;
    /* margin: 10px 10px 10px 10px; */
    margin-top: -12px;
    background-color: #fff;
    padding: 5px 10px 5px 10px;
  }

  .tree-fix-button .el-tree-node__content:hover > .custom-tree-node .el-button.el-button--text,
  .tree-fix-button .el-tree-node.is-current > .el-tree-node__content > .custom-tree-node .el-button.el-button--text {
    background-color: #F5F7FA !important;
  }

  /*.tree-fix-button .custom-tree-node .el-button.el-button--text:focus,
  .tree-fix-button .custom-tree-node .el-button.el-button--text:hover {
    color: #66b1ff;
    border-color: transparent;
    background-color: #F5F7FA !important;
  }*/

  .scroll-tree-field {
    border: 1px solid #e6e6e6;
    height: calc(100vh - 350px);
  }

  .scroll-filter {
    height: calc(100vh - 289px);
  }

  .tree-field {
    padding: 10px;
  }

  .el-form-item.is-error .tree-field {
    border-color: #F56C6C;
  }

  .bi_editor .el-select {
    display: block;
  }

  .bi_editor .extended-form {
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;

    z-index: 0;
  }

  .bi_editor .extended-tabs .el-tabs__content {
    padding: 15px;
  }

  .bi_editor .el-tabs--border-card {
    box-shadow: none;
  }

  .formula-box {
    margin-bottom: 15px;
  }

  .formula-box > h4 {
    margin: 0;
    padding-bottom: 10px;
    line-height: 32px;
  }

  .formula-box > div {
    margin-bottom: 10px;
    border: 1px solid #DCDFE6;
    min-height: 70px;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Courier New', sans-serif;
    color: #409EFF;
  }

  .extended_object .el-dialog .el-dialog__body {
    height: 85vh;
  }
</style>
