import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'

export namespace SmevSettingAPI {

    export class AddSmevSetting implements APIRequest<string> {
        response: string;
        path = '/v2/accesseditor/smev/settings';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: SmevSettingDTO) {}
    }

    export class UpdateSmevSetting implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: SmevSettingDTO) {
            this.path = `/v2/accesseditor/smev/settings/${params.guid}`;
        }
    }

    export class DeleteSmevSetting implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/v2/accesseditor/smev/settings/${guid}`;
        }
    }

    export class GetSmevSettings implements APIRequest<SmevSettingDTO> {
        response: SmevSettingDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/v2/accesseditor/smev/settings?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetSmevSettingByGuid implements APIRequest<SmevSettingDTO> {
        response: SmevSettingDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/v2/accesseditor/smev/settings/${guid}`;
        }
    }    
}