import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import { TopicRightDTO } from '@/services/AccessEditor/domain/model/Right/TopicRight'

export namespace RoleAPI {

    export class AddRole implements APIRequest<string> {
        response: string;
        path = '/v2/accesseditor/roles';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: RoleDTO) {}
    }

    export class UpdateRole implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: RoleDTO) {
            this.path = `/v2/accesseditor/roles/${params.guid}`;
        }
    }

    export class DeleteRole implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/v2/accesseditor/roles/${guid}`;
        }
    }

    export class CountRoles implements APIRequest<RoleDTO> {
        response: RoleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/v2/accesseditor/roles?*[func]=count'
        }
    }

    export class GetRoles implements APIRequest<RoleDTO> {
        response: RoleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/v2/accesseditor/roles?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetRoleByGuid implements APIRequest<RoleDTO> {
        response: RoleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/v2/accesseditor/roles/${guid}`;
        }
    }

    export class GetTopicRights implements APIRequest<TopicRightDTO> {
        response: TopicRightDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, payload: string[][]) {
            this.path = `/v2/accesseditor/roles/${guid}/rights/topics?` +  (new URLSearchParams(payload).toString());
        }
    }

    export class CountTopicRights implements APIRequest<TopicRightDTO> {
        response: TopicRightDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, payload: string[][]) {
            this.path = `/v2/accesseditor/roles/${guid}/rights/topics?*[func]=count&` + (new URLSearchParams(payload).toString())
        }
    }

    export class UpdateTopicRight implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, public params: TopicRightDTO) {
            this.path = `/v2/accesseditor/roles/${guid}/rights/topics`;
        }
    }

    export class GetRootMenu implements APIRequest<any> {
        response: any;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor() {
            this.path = '/menueditor/menu?root=true';
        }
    }

    export class GetDashboards implements APIRequest<any> {
        response: any;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/interfaceeditor/dashboards?fields=id,name';
        }
    }
}