<template>
  <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="value" size="mini">
    <el-form-item :label="$locale.notify_editor.label.name" prop="name">
      <el-input v-model="value.name"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'groupForm',
  props: {
    value: Object,
    rules: Object
  },
  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
}
</script>

<style scoped>

</style>
