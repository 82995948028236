






import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import SnapshotsHandler from '@/services/SyncService/application/handler/query/SnapshotsHandler'
import SnapshotsCountHandler from '@/services/SyncService/application/handler/query/SnapshotsCountHandler'
import SnapshotRepository from '@/services/SyncService/infrastructure/domain/repository/SnapshotRepository'
import SnapshotPanel from '@/services/SyncService/infrastructure/components/SnapshotPanel/index.vue'

export default {
  name: 'SyncService',
  components: {
    SnapshotPanel
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
            'SnapshotsQuery': new SnapshotsHandler(
              new SnapshotRepository()
            ),
            'SnapshotsCountQuery': new SnapshotsCountHandler(
              new SnapshotRepository()
            )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    }
  },
  mounted() {
    
  }
}
