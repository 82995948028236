export class FilterBuilder {
  static MAP = {
    'textField': FilterBuilder.string,
    'stringField': FilterBuilder.string,
    'floatField': FilterBuilder.number,
    'integerField': FilterBuilder.number,
    'dateField': FilterBuilder.date,
    'xrefField': FilterBuilder.xref,
    'xrefMultiField': FilterBuilder.xref
  }

  static build (columnType: string): object {
    const filterBuildFunction = this.MAP[columnType]
    return filterBuildFunction ? filterBuildFunction() : {}
  }

  static string (): object {
    return {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'notEqual', 'contains', 'notContains']
      }
    }
  }

  static date (): object {
    return {
      filter: 'agDateColumnFilter'
    }
  }

  static number (): object {
    return {
      filter: 'agNumberColumnFilter'
    }
  }

  static xref (): object {
    return {
      filter: 'xrefFilter'
    }
  }
}
