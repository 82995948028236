<template>
  <div class="role-panel">
    <el-dialog
      :visible.sync="isCreateRoleWindowVisible"
      width="30%"
      ref="createRoleWindow"
      class="create-role-window"
      @close="closeCreateRoleWindow"
      :close-on-click-modal="false">
      <span>
        <template v-if="roleDto">
          <el-form :rules="createRoleRules" :model="roleDto" :disabled="isRoleLoading" size="mini" label-width="100px" label-position="left">
            <el-form-item prop="name" :label="$locale.main.fields.name">
                <el-input v-model="roleDto.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_readable_default" :label="$locale.access_editor.roles_table.read" name="is_readable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_addable_default" :label="$locale.access_editor.roles_table.add" name="is_addable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_editable_default" :label="$locale.access_editor.roles_table.edit" name="is_editable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_deletable_default" :label="$locale.access_editor.roles_table.delete" name="is_deletable_default"></el-checkbox>
            </el-form-item>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_menu_visible_default" :label="$locale.access_editor.roles_table.is_menu_visible_default" name="is_menu_visible_default"></el-checkbox>
            </el-form-item>
            <el-form-item prop="root_menu_id" label-width="200px" :label="$locale.access_editor.roles_table.root_menu_id">
              <treeselect
                v-model="roleDto.root_menu_id"
                :placeholder="$locale.main.fields.select"
                :normalizer="(node) => {return {id: node.id, label: node.name}}"
                :options="menu"
                :clear-value-text="$locale.main.message.clear"
                :loading-text="$locale.main.message.loading"
                :disable-immediate-search="true"
                :async="true"
                :cache-options="false"
                :append-to-body="false"
                :load-options="loadMenu"
                :clearable="true"
                :delete-removes="false"
                :backspace-removes="false"
                @open="loadMenu"
                style="width: 100%"
              ></treeselect>
            </el-form-item>
            <el-form-item prop="dashboards" label-width="200px" :label="$locale.access_editor.roles_table.default_dashboard">
              <treeselect
                v-model="roleDto.dashboards"
                :multiple="true"
                :placeholder="$locale.main.fields.select"
                :normalizer="(node) => {return {id: node.id, label: node.name}}"
                :options="dashboards"
                :clear-value-text="$locale.main.message.clear"
                :loading-text="$locale.main.message.loading"
                :disable-immediate-search="true"
                :async="true"
                :cache-options="false"
                :append-to-body="false"
                :load-options="loadDashboards"
                :clearable="true"
                :delete-removes="false"
                :backspace-removes="false"
                @open="loadDashboards"
                style="width: 100%"
              ></treeselect>
            </el-form-item>
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <span v-loading="true" v-if="isRoleLoading"></span>
          <el-button icon="el-icon-close" :disabled="isRoleLoading" size="small" @click="closeCreateRoleWindow">{{$locale.main.button.cancel}}</el-button>
          <el-button icon="el-icon-success" :disabled="roleDto == null || isRoleLoading" size="small" @click="saveRole" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createRole"></el-button>
      <el-button icon="edit-button" size="mini" circle  @click="updateRole"></el-button>
      <el-button icon="delete-button" size="mini" circle  @click="deleteRole"></el-button>
    </el-container>
    <el-row class="main-table role-table">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="registry custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="roles"
          stripe
          border
          ref="roles_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeRole"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="is_readable_default"
          :label="$locale.access_editor.roles_table.read"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_readable_default ? 'success' : 'primary'"
            >{{scope.row.is_readable_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_addable_default"
          :label="$locale.access_editor.roles_table.add"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_addable_default ? 'success' : 'primary'"
            >{{scope.row.is_addable_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_editable_default"
          :label="$locale.access_editor.roles_table.edit"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_editable_default ? 'success' : 'primary'"
            >{{scope.row.is_editable_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_deletable_default"
          :label="$locale.access_editor.roles_table.delete"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_deletable_default ? 'success' : 'primary'"
            >{{scope.row.is_deletable_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_menu_visible_default"
          :label="$locale.access_editor.roles_table.is_menu_visible_default"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_menu_visible_default ? 'success' : 'primary'"
            >{{scope.row.is_menu_visible_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="role-pagination"
            :page-size="rolesPageSize"
            :layout="'total, prev, pager, next'"
            :total="rolesCount"
            @current-change="handleRolesPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Treeselect from '@bingosoftnn/vue-treeselect'
import RoleCreateCommand from '@/services/AccessEditor/application/command/RoleCreateCommand'
import RoleUpdateCommand from '@/services/AccessEditor/application/command/RoleUpdateCommand'
import RoleDeleteCommand from '@/services/AccessEditor/application/command/RoleDeleteCommand'
import DashboardsQuery from '@/services/AccessEditor/application/query/DashboardsQuery'
import MenuQuery from '@/services/AccessEditor/application/query/MenuQuery'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'
import RolesCountQuery from '@/services/AccessEditor/application/query/RolesCountQuery'
import RoleByGuidQuery from '@/services/AccessEditor/application/query/RoleByGuidQuery'
import Role, { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'

export default {
  name: 'RolePanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      rolesPageSize: 0,
      defaultRolesPageSize: 100,
      rolesCurrentPage: 0,
      rolesPageLimit: 100,
      rolesCount: 0,
      allRolesCount: 0,
      roles: [],
      role: null,
      roleDtoPrev: null,
      roleDto: null,
      informationPanelHeight: 0,
      isCreateRoleWindowVisible: false,
      menu: [],
      dashboards: [],
      createRoleRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    roleCreated: function (location) {
      const guid = location.replace('/roles/', '');
      this.loadRoles(() => {
        this.closeCreateRoleWindow();
        for (let i = 0; i < this.roles.length; i += 1) {
          let el = this.roles[i];
          if (el.guid == guid) {
            this.$refs.roles_table.setCurrentRow(el);
            break;
          }
        }
      });
    },
    isRoleLoading: (state) => state,
    getSaveRoleError: function (err) {
      
    },
    roleDto: {
      handler: async function(curDto) {
        
      },
      deep: true
    }
  },
  computed: {
    isRoleLoading() {
      return this.$store.getters['Role/isLoading'];     
    },
    roleCreated() {
      return this.$store.getters['Role/getLocation'];
    },
    getSaveRoleError() {
      return this.$store.getters['Role/getError']; 
    }
  },
  methods: {
    loadRoles(callback) {
      if (this.allRolesCount == 0) {
        this.countAndLoadRoles(callback);
      } else {
        this.loadAllRoles(callback);
      }
    },
    async countAndLoadRoles(callback) {
      await this.getQueryBus().execute(
        new RolesCountQuery()
      ).then(data => {
        this.rolesCount = data[0].count;      
        this.loadAllRoles(callback);
      });
    },
    async loadAllRoles(callback) {
      await this.getQueryBus().execute(
        new RolesQuery({
          limit: this.rolesPageLimit,
          offset: this.rolesCurrentPage
        })
      ).then(data => {
        this.rolesPageSize = this.rolesPageLimit;
        this.roles = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createRole() {
      this.roleDtoPrev = this.roleDto;
      this.roleDto = new RoleDTO({});    
      this.isCreateRoleWindowVisible = true;
    },
    async updateRole() {
      if (this.role !== null) {
        this.isCreateRoleWindowVisible = true;
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveRole() {
      let role = Role.create(this.roleDto);
      if (role.getId() == null) {
        this.getCommandBus().execute(
          new RoleCreateCommand(
            role.getName(),
            role.getIsReadable(),
            role.getIsAddable(),
            role.getIsEditable(),
            role.getIsDeletable(),
            role.getIsMenuVisible(),
            role.getRootMenuId(),
            role.getDashboards()
          )
        );
      } else {
        this.getCommandBus().execute(
            new RoleUpdateCommand(
              role.getGuid(),
              role.getName(),
              role.getIsReadable(),
              role.getIsAddable(),
              role.getIsEditable(),
              role.getIsDeletable(),
              role.getIsMenuVisible(),
              role.getRootMenuId(),
              role.getDashboards()
            )
          ).then(async () => {
            await this.getQueryBus().execute(
              new RoleByGuidQuery(role.getGuid())
            ).then(data => {
              this.roleDtoPrev = new RoleDTO(data);
              this.roleDto = this.roleDtoPrev;
              for (let i = 0; i < this.roles.length; i += 1) {
                if (this.roles[i].guid == this.roleDto.guid) {
                  this.roles[i] = this.roleDto;
                  break;
                }
              }           
            });
            this.closeCreateRoleWindow();
          });
      }
    },
    closeCreateRoleWindow() {
      this.isCreateRoleWindowVisible = false;
      if (this.roleDtoPrev !== null) {
        this.roleDto = this.roleDtoPrev;
        this.role = Role.create(this.roleDto);
      }
    },
    changeRole(roleDto) {
      this.passwordLocked = true;
      if (roleDto !== null) {
        this.role = Role.create(roleDto);
        this.roleDto = roleDto;
        if (this.roleDto.root_menu_id !== null) {
          this.menu = [{"id": this.roleDto.root_menu_id, "name": this.roleDto.root_menu_name}];
        }
        this.dashboards = this.roleDto.default_dashboards;
        this.getEventBus().$emit('selectRole', this.roleDto.guid);
      } else {
        this.getEventBus().$emit('selectRole', null);  
      }
    },
    deleteRole() {
      if (this.role == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new RoleDeleteCommand(
              this.role.getGuid()
            )
          ).then((response) => {
            this.role = null;
            this.$refs.roles_table.setCurrentRow(null);
            this.loadRoles();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    handleRolesPageChange(val) {
      val--;
      this.rolesCurrentPage = (val * this.rolesPageLimit);
      this.loadRoles();
    },
    async loadMenu() {
      await this.getQueryBus().execute(
        new MenuQuery()
      ).then(data => {
        this.menu = data;
      });
    },
    async loadDashboards() {
      await this.getQueryBus().execute(
        new DashboardsQuery({})
      ).then(data => {
        this.dashboards = data;
      });
    }
  },
  mounted () {
    this.loadRoles();
  }
}
</script>