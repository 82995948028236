<template>
  <div class="vqb-group" :class="classObject">
    <div class="vqb-group__tools">
      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.add_rule" placement="top">
        <el-popover
          popper-class="vqb-add-rule-form"
          placement="left"
          width="500"
          trigger="click">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item>
                <TreeSelect v-model="selectedRuleId" :props="treeSelectProps" :data="rules" node-key="id" :placeholder="labels.rulePlaceholder" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-select v-model="selectedFilterType" :placeholder="labels.filterTypePlaceholder">
                  <el-option
                    v-for="item in filterTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button
                style="width: 100%;"
                @click="addRule"
                size="mini"
                type="default"
                v-html="labels.addRule">
              </el-button>
            </el-col>
          </el-row>
          <el-button
            slot="reference"
            circle
            size="mini"
            type="success"
            icon="el-icon-plus">
          </el-button>
        </el-popover>
      </el-tooltip>

      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.add_group" placement="top">
        <el-button
          @click="addGroup"
          size="mini"
          type="primary"
          circle
          v-html="labels.addGroup"
          v-if="this.depth < this.maxDepth">
        </el-button>
      </el-tooltip>

      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.delete_group" placement="top">
        <el-button
          @click="remove"
          circle
          size="mini"
          type="default"
          v-html="labels.removeGroup"
          v-if="this.depth > 1">
        </el-button>
      </el-tooltip>
    </div>

    <el-form-item :label="labels.groupLabel" :size="sizeControl" class="vqb-group-body">
      <el-form-item :size="sizeControl" class="form-item-group">
        <el-select v-model="query.logical_operator">
          <el-option
            v-for="(label, index) in labels.matchTypes"
            :key="index"
            :label="label.label"
            :value="label.id">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="vqb-children">
        <component
          class="vqb-child"
          v-for="(child, index) in query.children"
          :key="index"
          :is="objectTypeToComponentName[child.type]"
          :query.sync="child.query"
          :rules="rules"
          :rule="recursiveFindById(rules, child.query.field)"
          :index="index"
          :maxDepth="maxDepth"
          :depth="depth + 1"
          :styled="styled"
          :labels="labels"
          :type="type"
          :size-control="sizeControl"
          v-on:child-deletion-requested="removeChild">
        </component>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import TreeSelect from './../TreeSelect.vue'
import QueryBuilderRule from './QueryBuilderRule.vue'
import deepClone from './utilities.js'

export default {
  name: 'query-builder-group',

  props: [
    'valueType',
    'query', 'rules', 'index',
    'maxDepth', 'depth', 'styled',
    'labels', 'sizeControl', 'type'
  ],

  components: {
    QueryBuilderRule,
    TreeSelect
  },

  data () {
    return {
      treeSelectProps: {
        isLeaf: 'leaf',
        disabled: (data, node) => {
          return !data.leaf
        }
      },
      selectedRule: this.rules[0],
      selectedRuleId: null,
      selectedFilterType: 'constant',
      filterTypeList: [
        {
          value: 'constant',
          label: this.$locale.main.query_builder.filter_types.constant
        },
        {
          value: 'current_user',
          label: this.$locale.main.query_builder.filter_types.current_user
        },
        {
          value: 'current_datetime',
          label: this.$locale.main.query_builder.filter_types.current_datetime
        }
      ],
      objectTypeToComponentName: {
        condition: 'query-builder-rule',
        condition_group: 'query-builder-group'
      }
    }
  },

  computed: {
    classObject () {
      var classObject = {}

      classObject['depth-' + this.depth.toString()] = this.styled

      return classObject
    }
  },

  methods: {
    recursiveFindById (data, id) {
      let object = null

      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          object = data[i]
          break
        } else {
          if (data[i].children.length > 0) {
            object = this.recursiveFindById(data[i].children, id)

            if (object !== null) {
              break
            }
          }
        }
      }

      return object
    },

    addRule () {
      if (this.selectedRuleId) {
        this.selectedRule = this.recursiveFindById(this.rules, this.selectedRuleId)

        console.log(this.rules, this.selectedRuleId, this.selectedRule)
      }

      if (!this.selectedRule) {
        this.$alert('Выберите поле!', 'Ошибка', {
          confirmButtonText: 'OK'
        })

        return
      }

      let updatedQuery = deepClone(this.query)

      let child = {
        type: 'condition',
        query: {
          field: this.selectedRule.id,
          operator: this.selectedRule.operators[0].id,
          filter_type: this.selectedFilterType,
          value: null
        }
      }
      // A bit hacky, but `v-model` on `select` requires an array.
      if (this.selectedRule.operator === 'in') {
        child.query.value = []
      }
      updatedQuery.children.push(child)
      this.$emit('update:query', updatedQuery)
    },

    addGroup () {
      let updatedQuery = deepClone(this.query)
      if (this.depth < this.maxDepth) {
        updatedQuery.children.push({
          type: 'condition_group',
          query: {
            logical_operator: this.labels.matchTypes[0].id,
            children: []
          }
        })
        this.$emit('update:query', updatedQuery)
      }
    },

    remove () {
      if (this.query.children.length > 0) {
        this.$confirm(
          this.$locale.main.message.delete_group,
          this.$locale.main.message_title.warning,
          {
            confirmButtonText: this.$locale.main.button.delete,
            cancelButtonText: this.$locale.main.button.cancel,
            type: 'warning'
          }
        ).then(() => {
          this.$emit('child-deletion-requested', this.index)
        })
      } else {
        this.$emit('child-deletion-requested', this.index)
      }
    },

    removeChild (index) {
      let updatedQuery = deepClone(this.query)
      updatedQuery.children.splice(index, 1)
      this.$emit('update:query', updatedQuery)
    }
  }
}
</script>

<style>
  .vqb-group .vqb-group-body,
  .vqb-group-body .form-item-group,
  .vqb-add-rule-form .el-form-item,
  .vqb-group .vqb-children > .vqb-child:last-child {
    margin-bottom: 0;
  }

  .vqb-group-body .form-item-group .el-form-item__label {
    max-width: 50%;
  }

  .vqb-group__tools {
    float: right;
    display: inline-block;
  }

  .vqb-group__tools .el-button {
    margin-left: 10px;
  }
</style>
