import {Model} from 'vue-api-query'

export default class TreeElement extends Model {
  resource () {
    return 'rule_tree_elements'
  }

  // define a base url for a REST API
  baseURL () {
    return `${config.api}/taskeditor`
  }

  // implement a default request method
  request (config) {
    return this.$http.request(config)
  }
}
