<template>
  <div>
    <el-form-item class="subMenu" :label="$locale.menu_editor.label.select_object">
      <el-select v-model="localPorperties.value">
        <el-option
        v-for="item in listRegistry"
        :key="item.id"
        :label="item.name"
        :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="subMenu" :label="$locale.menu_editor.label.title">
      <el-input v-model="localPorperties.title"></el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'openObject',
  props: ['properties'],
  watch: {
    localPorperties: {
      handler: function (val) {
        console.log(val)
        this.$emit('properties', val)
      },
      deep: true
    },
    properties: {
      handler: function (val) {
        this.localPorperties = JSON.parse(val) || {}
      },
      deep: true
    }
  },
  data () {
    return {
      localPorperties: JSON.parse(this.properties) || {},
      listRegistry: []
    }
  },
  mounted () {
    this.$http
      .get(`${this.$config.api}/objecteditor/entities?is_object=true`)
      .then(response => {
        this.listRegistry = response.data.data
      })
      .catch(error => console.log(error))
  }
}
</script>

<style scoped>

</style>
