<template>
  <el-input
    v-model="name"
    :disabled="isEdit"
    :placeholder="$locale.logic_editor.state.state_fields.parameter">
  </el-input>
</template>

<script>
export default {
  name: 'parameter',
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    getValue () {
      return this.name + ''
    }
  },
  mounted () {
    if (this.state) {
      console.log('parament', this.state)
      this.name =
        this.state.properties && this.state.properties.name
          ? this.state.properties.name
          : null
    }
  }
}
</script>

<style scoped>
</style>
