<template>
  <div>
    <h1 style="margin-top: 10px">{{$locale.logic_editor.listeners.headline}}</h1>
    <div class="listener__item2">
      <div class="demonstration">{{$locale.menu_editor.label.name}}</div>
      <el-input size="mini" class="listener_row__item" v-model="name"></el-input>
    </div>
    <div class="listener__item2">
      <div class="demonstration">{{$locale.logic_editor.listeners.label_for_list}}</div>
      <el-select value-key="id" class="listener_row__item" v-model="event" size="mini">
        <el-option v-for="item in events" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <div class="demonstration">{{$locale.logic_editor.listeners.label_for_card}}</div>
      <el-select
        multiple
        collapse-tags
        value-key="id"
        class="listener_row__item"
        v-model="card"
        size="mini"
      >
        <el-option v-for="item in cards" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
      <div class="demonstration">{{$locale.logic_editor.listeners.label_for_command}}</div>
      <el-select
        multiple
        collapse-tags
        value-key="id"
        class="listener_row__item"
        v-model="command"
        size="mini"
      >
        <el-option v-for="item in commands" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
    </div>
    <el-footer style="height: 60px;text-align: right;margin-top: 50px;">
      <el-button
        @click="saveCard"
        :disabled='name === ""'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
  </div>
</template>

<script>
import Card from '../Models/interfaceeditor/Card'
import Events from '../Models/Events'
import Commands from '../Models/Commands'
import Listeners from '../Models/Listeners'
export default {
  name: 'listeners',
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  data () {
    return {
      // открытое действие
      addAction: this.propslocal.listOpenAction,
      commands: [],
      cards: [],
      events: [],
      name: '',
      logic_id: this.propslocal.businesslogic.id,
      card: [],
      command: [],
      event: ''
    }
  },
  methods: {
    async saveCard () {
      // новое subAction
      if (this.propslocal.isNewAction) {
        let listener = new Listeners({})
        listener.name = this.name
        listener.logic_id = this.logic_id
        listener.listener_cards = this.card.map(el => el.id)
        listener.listener_commands = this.command.map(el => el.id)
        listener.event_id = this.event
        let response = await listener.save()
        this.addAction.items.push(response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let listener = new Listeners({ id: id })
        listener.name = this.name
        listener.listener_cards = this.card.map(el => el.id)
        listener.listener_commands = this.command.map(el => el.id)
        listener.event_id = this.event
        let response = await listener.save()
        // this.addAction.filter(el => el.id === id)[0].name = response.name
        this.addAction.items
          ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
          : (this.addAction.filter(el => el.id === id)[0].name = response.name)
      }
      this.propslocal.isNewAction = false
    }
  },
  async mounted () {
    let card = await Card.params({
      entity_id: this.propslocal.businesslogic.registry_id
    }).get()
    let events = await Events.get()
    let commands = await Commands.get()
    let stateFields = this.$locale.logic_editor.listeners.translate
    let obj = events.map(el => ({ id: el.id, name: stateFields[el.name] }))
    this.cards = card
    this.events = obj
    this.commands = commands
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.name = this.propslocal.getAnswer.name
      this.event = this.propslocal.getAnswer.event_id
      this.card = this.propslocal.getAnswer.listener_cards.map(el => ({
        id: el
      }))
      this.command = this.propslocal.getAnswer.listener_commands.map(el => ({
        id: el
      }))
    }
  }
}
</script>

<style scoped>
.listener_wrapper {
  display: flex;
  justify-content: space-between;
}
.listener__row {
  width: 100%;
  margin-right: 15px;
}
.listener__row:last-child {
  margin-right: 0px;
}
.listener__item2 {
  margin-top: 25px;
  width: 65%;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.listener_row__item {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
</style>
