import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_SNAPSHOTS = 'get_snapshots',
    COUNT_SNAPSHOTS = 'count_snapshots'
}

export class GetSnapshots implements FluxStandardAction {
    type = 'Snapshot/' + ActionTypes.GET_SNAPSHOTS
    constructor(public payload: object){}
}

export class CountSnapshots implements FluxStandardAction {
    type = 'Snapshot/' + ActionTypes.COUNT_SNAPSHOTS
    constructor(public payload: object){}
}