import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import XmlRouteCommandRepositoryInterface from '@/services/Databus/domain/repository/XmlRouteCommandRepositoryInterface'
import XmlRouteCreateCommand from '@/services/Databus/application/command/XmlRouteCreateCommand'
import { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'

export default class XmlRouteCreateHandler implements CommandHandlerInterface
{
    private repo: XmlRouteCommandRepositoryInterface;

    constructor(repo: XmlRouteCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: XmlRouteCreateCommand): Promise<void>
    {
        let item: XmlRouteDTO = {
            name: command.getName(),
            topic: command.getTopic(),
            task_id: command.getTaskId(),
            dag_id: command.getDagId(),           
            action_type: command.getActionType()
        };
        return this.repo.insert(item);
    }
}