<template>
<el-form-item class="subMenu" :label="$locale.menu_editor.label.select_report">
    <el-select v-model="localPorperties.value">
      <el-option v-for="item in listReport" :key="item.id" :label="item.name" :value="item.guid"></el-option>
    </el-select>
    </el-form-item>
</template>

<script>
import Reports from '../Models/report/Reports'
export default {
  name: 'report',
  props: ['properties'],
  watch: {
    localPorperties: {
      handler: function (val) {
        // console.log(val)
        this.$emit('properties', val)
      },
      deep: true
    },
    properties: {
      handler: function (val) {
        this.localPorperties = JSON.parse(val) || {}
      },
      deep: true
    }
  },
  data () {
    return {
      localPorperties: JSON.parse(this.properties) || {},
      listReport: []
    }
  },
  async mounted () {
    let report = await Reports.get()
    this.listReport = report
  }
}
</script>

<style scoped>
</style>
