import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RouteDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    topic?: Nullable<string>;
    task_id?: Nullable<number>;
    file_name?: Nullable<string>;
    priority?: Nullable<number>;

    constructor({ id, guid, topic, task_id, file_name, priority}: {id?: Nullable<number>, guid?: Nullable<string>, topic?: Nullable<string>, task_id?: Nullable<number>, file_name?: Nullable<string>, priority?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.topic = topic;
        this.task_id = task_id;
        this.file_name = file_name;
        this.priority = priority;
    }
}

export default class Route extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private topic: string;
    private taskId: number;
    private fileName: string;
    private priority: number;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        topic: string,
        taskId: number,
        fileName: string,
        priority: number
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.topic = topic;
        this.taskId = taskId;
        this.fileName = fileName;
        this.priority = priority;
    }

    static create(dto: RouteDTO): Route
    {
        return new Route(
            dto.id,
            dto.guid,
            dto.topic,
            dto.task_id,
            dto.file_name,
            dto.priority
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setTopic(topic: string): void
    {
        this.topic = topic;
    }

    getTopic(): string
    {
        return this.topic;
    }

    setTaskId(taskId: number): void
    {
        this.taskId = taskId;
    }

    getTaskId(): number
    {
        return this.taskId;
    }

    setFileName(fileName: string): void
    {
        this.fileName = fileName;
    }

    getFileName(): string
    {
        return this.fileName;
    }

    setPriority(priority: number): void
    {
        this.priority = priority;
    }

    getPriority(): number
    {
        return this.priority;
    }
}
