<template>
  <el-collapse-item :name="ind">
    <template slot="title">{{fullnameStages}}</template>
    <div class="extraAccordion_block">
      <div class="extraAccordion_block__item2">
        <div class="demonstration required">{{$locale.logic_editor.routes.names}}</div>
        <el-input
          size="mini"
          class="extraAccordion__row"
          v-model="approvals.name"
        ></el-input>
      </div>
    </div>
    <div class="extraAccordion_block__item2">
    <div class="demonstration">{{$locale.logic_editor.routes.action_stages}}</div>
    <el-select
      multiple
      collapse-tags
      value-key="id"
      class="extraAccordion__row"
      v-model="approvals.close_commands"
      size="mini">
      <el-option
        v-for="item in propsFromAppravals.listCommand"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
    </div>
    <el-row>
      <el-col :sm="20" :md="20" :lg="15" :xl="18">
        <div class="extraAccordion">
          <h3 class="accordion_mrgn">{{ $locale.logic_editor.routes.positive }}</h3>
              <div class="demonstration">{{$locale.logic_editor.routes.action}}</div>
              <el-select
                multiple
                class="extraAccordion__row"
                collapse-tags
                value-key="id"
                v-model="approvals.approve_commands"
                size="mini"
              >
                <el-option
                  v-for="item in propsFromAppravals.listCommand"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div class="demonstration">{{$locale.logic_editor.routes.button}}</div>
              <el-select
                class="extraAccordion__row"
                value-key="id"
                v-model="approvals.approve_button_id"
                size="mini"
              >
                <el-option
                  v-for="item in propsFromAppravals.listBtns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
                <div class="demonstration">{{$locale.logic_editor.routes.text_btn}}</div>
              <el-input
                class="extraAccordion__row"
                size="mini"
                v-model="approvals.approve_text"
              ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="20" :md="20" :lg="15" :xl="18">
        <div class="extraAccordion">
          <h3 class="accordion_mrgn">{{ $locale.logic_editor.routes.negative }}</h3>
          <div class="demonstration">{{$locale.logic_editor.routes.action}}</div>
              <el-select
                multiple
                collapse-tags
                value-key="id"
                class="extraAccordion__row"
                v-model="approvals.cancel_commands"
                size="mini"
              >
                <el-option
                  v-for="item in propsFromAppravals.listCommand"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div class="demonstration">{{$locale.logic_editor.routes.button}}</div>
              <el-select
                class="extraAccordion__row"
                value-key="id"
                v-model="approvals.cancel_button_id"
                size="mini"
              >
                <el-option
                  v-for="item in propsFromAppravals.listBtns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div class="demonstration">{{$locale.logic_editor.routes.text_btn}}</div>
              <el-input
                size="mini"
                class="extraAccordion__row"
                v-model="approvals.cancel_text"
              ></el-input>
              <div class="demonstration">{{$locale.logic_editor.routes.back_stages}}</div>
              <el-select
                value-key="id"
                multiple
                collapse-tags
                class="extraAccordion__row"
                v-model="approvals.cancel_stages"
                size="mini"
              >
                <el-option
                  v-for="item in listStagesBlock"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="20" :md="20" :lg="15" :xl="15">
          <div class="extraAccordion_block__item2">
        <div class="demonstration">{{$locale.logic_editor.routes.blocking_stages}}</div>
        <el-select
          multiple
          collapse-tags
          class="extraAccordion__row"
          v-model="approvals.approval_blocking_stages"
          size="mini"
        >
          <el-option
            v-for="item in listStagesBlock"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        </div>
        <div class="extraAccordion_block__item2">
          <div class="demonstration required">{{$locale.logic_editor.routes.members_stage}}</div>
        <el-select
          class="extraAccordion__row"
          v-model="approvals.approval_stage_type_id"
          size="mini"
        >
          <el-option
            v-for="item in listTypes"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          ></el-option>
        </el-select>
        <template>
          <custom-popover-tree v-show="approvals.approval_stage_type_id === 'field'" class="costum_tree_style extraAccordion__row" :model.sync='approvals.member_field_id' :data='propsFromAppravals.listFieldsAroundUser'></custom-popover-tree>
        </template>
        <el-select
          v-show="approvals.approval_stage_type_id === 'state'"
          class="extraAccordion__row"
          v-model="approvals.member_state_id"
          size="mini"
        >
          <el-option
            v-for="item in propsFromAppravals.listStatesAroundUser"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-show="approvals.approval_stage_type_id === 'list'"
          multiple
          collapse-tags
          class="extraAccordion__row"
          v-model="approvals.approval_stage_members"
          size="mini"
        >
          <el-option
            v-for="item in fullname()"
            :key="item.id"
            :label="item.surname + ' ' + item.name + ' ' + item.midname "
            :value="item.id"
          ></el-option>
        </el-select>
        </div>
      </el-col>
    </el-row>
    <el-checkbox
      v-model="approvals.is_approvable_by_all"
      :label="$locale.logic_editor.routes.is_approvable_by_all"
    ></el-checkbox>
    <el-row type="flex" justify="end">
      <slot></slot>
      <el-button
        @click="saveStage"
        :disabled="!approvals.name || !approvals.approval_stage_type_id"
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-row>
  </el-collapse-item>
</template>

<script>
import Stages from '../../Models/Stages'
import customPopoverTree from '@/components/LogicEditor/components/customPopoverTree'
export default {
  components: {
    customPopoverTree
  },
  props: {
    propslocal: {},
    propsFromAppravals: {
      listStatesAroundUser: {
        type: Array
      },
      listFieldsAroundUser: {
        type: Array
      },
      entityStages: {
        type: Array
      },
      listEmployees: {
        type: Array
      },
      listCommand: {
        type: Array
      },
      listBtns: {
        type: Array
      },
      approvalId: {
        type: Number
      }
    },
    ind: {
      type: Number,
      required: true
    },
    localAnswer: {
      type: Object
    }
  },
  watch: {
    'approvals.approval_stage_type_id': function (newVal, oldval) {
      if (oldval !== null) {
        console.log(newVal)
        this.$nextTick(function () {
          this.approvals.approval_stage_members = null
          this.approvals.member_state_id = null
          this.approvals.member_field_id = null
        })
      }
    },
    'propsFromAppravals.entityStages': function (newVal, oldval) {
      this.listStagesBlock = this.propsFromAppravals.entityStages
    },
    localAnswer: function (newVal, oldVal) {
      console.log('localAnswer wathc', newVal)
      this.approvals = {}
      this.approvals = this.localAnswer
    },
    'approvals.member_field_id': function (val) {
      console.log(val)
      if (val !== '' && val != null) this.deepSearch(this.propsFromAppravals.listFieldsAroundUser)
    }
  },
  computed: {
    fullnameStages () {
      return ` ${this.approvals.name || ''}`
    }
  },
  data () {
    return {
      approvals: {
        name: null,
        approve_commands: [],
        approve_text: null,
        approve_button_id: null,
        cancel_commands: [],
        cancel_text: null,
        cancel_button_id: null,
        approval_blocking_stages: [],
        cancel_stages: [],
        close_commands: [],
        approval_stage_type_id: null,
        member_field_id: null,
        member_state_id: null,
        approval_stage_members: [],
        is_approvable_by_all: false
      },
      listField: [],
      listStagesBlock: [],
      IsSelectField: false,
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      },
      listTypes: [
        {
          type: 'field',
          name: this.$locale.logic_editor.routes.field
        },
        {
          type: 'state',
          name: this.$locale.logic_editor.routes.states
        },
        {
          type: 'list',
          name: this.$locale.logic_editor.routes.list
        }
      ]
    }
  },
  methods: {
    // поиск по дереву имя поля
    deepSearch (arr) {
      if (arr) {
        for (let el of arr) {
          if (this.approvals.member_field_id === el.id) {
            this.approvals.member_field_id = el
            break
          }
          if (el.children instanceof Array) this.deepSearch(el.children)
        }
      }
    },
    selectField (data) {
      this.approvals.member_field_id = data
      this.IsSelectField = false
    },
    fullname () {
      let arr = this.propsFromAppravals.listEmployees.map(el =>
        ({
          id: el.id,
          surname: el.surname !== null ? el.surname : '',
          name: el.name !== null ? el.name : '',
          midname: el.midname !== null ? el.midname : ''
        })
      )
      return arr
    },
    async saveStage () {
      if (this.localAnswer !== undefined) {
        console.log('edit')
        let stage = new Stages({ id: this.approvals.id }, this.approvals)
        stage.approval_id = this.propslocal.getAnswer.id
        if (this.approvals.member_field_id) {
          stage.member_field_id = this.approvals.member_field_id.id || null
        }
        await stage.save()
      } else {
        console.log('new')
        let stage = new Stages({}, this.approvals)
        stage.approval_id = this.propsFromAppravals.approvalId
        if (this.approvals.member_field_id) {
          stage.member_field_id = this.approvals.member_field_id.id || null
        }
        await stage.save()
      }
      this.$emit('changeListStages')
    }
  },
  mounted () {
    if (this.propsFromAppravals.entityStages) {
      this.listStagesBlock = this.propsFromAppravals.entityStages
    }
    // редактирование
    if (!this.propslocal.isNewAction && this.localAnswer !== undefined) {
      this.approvals = this.localAnswer
    }
    // новое
    if (this.propslocal.isNewAction && this.localAnswer !== undefined) {
      this.approvals = this.localAnswer
    }
  }
}
</script>

<style >
.extraApprovals {
  margin-left: 10px;
  margin-bottom: 10px;
}
.extraAccordion_block {
  display: flex;
}
.demonstration {
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left
}
.accordion_mrgn {
  margin: 0px;
}
.approvals__input {
  display: inline-block;
  width: 130px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.extraAccordion__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
.approvals__inputName {
  width: 45%;
}
.extraAccordion {
  margin: 20px;
  margin-left: 3px;
  padding: 20px;
  text-align: center;
  box-shadow: -2px 4px 11px 6px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.accordion_wrapper {
  display: flex;
  align-items: center;
}
.extraAccordion_block__item1 {
  width: 20%;
}
.extraAccordion_block__item2 {
  width: 72%;
}
.accordion__select {
  display: block;
}
/* .el-collapse-item.demonstration .el-collapse-item__header {
  color: #8492a6;
  font-size: 14px;
} */
</style>
