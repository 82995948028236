import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_ACTIVITIES = 'get_activities',
    COUNT_ACTIVITIES = 'count_activities'
}

export class GetActivities implements FluxStandardAction {
    type = 'Activity/' + ActionTypes.GET_ACTIVITIES
    constructor(public payload: object){}
}

export class CountActivities implements FluxStandardAction {
    type = 'Activity/' + ActionTypes.COUNT_ACTIVITIES
    constructor(public payload: object){}
}