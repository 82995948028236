<template>
  <div class="menu_form">
    <el-form ref="form" :model="form" size="mini" label-position="top" :rules="rules">
      <el-form-item :label="$locale.menu_editor.label.name" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="form.is_visible" :label="$locale.menu_editor.label.visible"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="form.is_badged" :label="$locale.menu_editor.label.badged"></el-checkbox>
      </el-form-item>
      <el-form-item class="subMenu" :label="$locale.menu_editor.label.type">
        <el-select v-model="form.menu_type_id">
          <el-option
            v-for="item in listType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <component @properties='getProperties' :properties='form.properties' :is="form.menu_type_id"></component>
      <el-form-item  class="subMenu" :label="$locale.menu_editor.label.tag">
        <el-select clearable v-model="form.icon_value">
          <el-option
            v-for="item in listTag"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right;">
              <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  class="subMenu" :label="$locale.menu_editor.label.show_menu">
        <el-select
          multiple
          collapse-tags
          clearable v-model="form.roles_denied">
          <el-option
            v-for="item in listRole"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <div style="text-align: right;">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-success"
          @click="submit">
          {{ $locale.menu_editor.button.save }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import OpenObject from '../elementSubMenu/object'
import Dashboard from '../elementSubMenu/dashboard'
import Report from '../elementSubMenu/report'
import Command from '../elementSubMenu/command'
import Editor from '../elementSubMenu/editor'
import Plugin from '../elementSubMenu/plugin'
import Url from '../elementSubMenu/url'
import separator from '../elementSubMenu/separator'
import Menu from '../Models/Menu'
import Icons from '../components/el-icons'
import Role from '../Models/accesseditor/Role'

export default {
  name: 'ChildMenuForm',

  props: ['activeNode', 'value', 'save'],

  components: {
    OpenObject,
    Dashboard,
    Report,
    Command,
    Editor,
    Plugin,
    Url,
    separator
  },

  watch: {
    'form.menu_type_id': function (newValue) {
      if (newValue === 'object') {
        this.form.menu_type_id = 'OpenObject'
      }
      if (this.changeId === this.form.id) {
        this.form.properties = null
      }
    },

    form: function (val, old) {
      this.changeId = val.id
    }
  },

  data () {
    return {
      form: {
        roles_denied: []
      },
      tag: null,
      checked: true,
      listType: [
        {
          value: 'title',
          label: this.$locale.menu_editor.label.title
        },
        {
          value: 'separator',
          label: this.$locale.menu_editor.label.separator
        },
        {
          value: 'OpenObject',
          label: this.$locale.menu_editor.label.object
        },
        {
          value: 'dashboard',
          label: this.$locale.menu_editor.label.dashbord
        },
        {
          value: 'report',
          label: this.$locale.menu_editor.label.report
        },
        {
          value: 'command',
          label: this.$locale.menu_editor.label.command
        },
        {
          value: 'editor',
          label: this.$locale.menu_editor.label.editor
        },
        {
          value: 'plugin',
          label: this.$locale.menu_editor.label.plugin
        },
        {
          value: 'url',
          label: this.$locale.menu_editor.label.url
        }
      ],
      listTag: [],
      listRole: [],
      changeId: null,
      rules: {
        name: [{ required: true, message: this.$locale.menu_editor.rules.name, trigger: 'blur' }]
      }
    }
  },

  async mounted () {
    this.listTag = Icons.map(el => ({ value: el, label: el }))
    this.form = Object.assign({}, this.form, this.value)
    this.listRole = await Role.params({ fields: 'id,name' }).get()
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.changeId = null
          if (this.form.menu_type_id === 'OpenObject') this.form.menu_type_id = 'object'
          const menu = new Menu(this.form)
          menu.icon_value = this.form.icon_value || null
          menu.icon_type = this.form.icon_type || null
          let response = await menu.save()
          this.activeNode.data = Object.assign({}, this.activeNode.data, { ...response })
        }
      })
    },

    getProperties (data) {
      this.form.properties = JSON.stringify(data)
    }
  }
}
</script>

<style>
  .subMenu {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .subMenu .el-select.el-select--small {
    display: block;
  }

  .subMenuEditor .el-form-item.required label.el-form-item__label:before {
    content: '* ';
    color: #ff4949;
    font-weight: bold;
  }
</style>
