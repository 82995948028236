<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'extended_object', name: 'Расширенная таблица' },
            { id: 'query', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <el-form-item>
      <el-select
        :value="value.entityId"
        filterable
        @change="$set(value ,'entityId', $event)"
      >
        <el-option
          v-for="(item, index) in data"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
export default {
  name: 'editor-analytic-table-source',
  components: {
    EditorSelect
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      async handler (type) {
        if (type) {
          this.data = await this.getData(this.value.type)
          this.$set(this.value, 'entityId', null)
        }
      }
    }
  },
  async mounted () {
    if (this.value.type) {
      this.data = await this.getData(this.value.type)
    }
  },
  methods: {
    async getData (type) {
      let data = []
      switch (type) {
        case 'extended_object':
          data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          break
        case 'query':
          data = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          break
      }

      return data.data
    }
  }
}
</script>

<style scoped>

</style>
