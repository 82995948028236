<template>
  <div class="child-report">
    <source-append-form
      ref="sourceAppendForm"
      v-model="appendSource"
      :report-source-types="reportSourceTypes"
      :extended-object-list="extendedObjects"
      :query-list="queries"
      :report-type="value.report_type"
      :save="saveSource" />

    <el-form ref="reportForm" size="mini" label-position="top" :model="value" :rules="formRules">
      <el-form-item :label="$locale.report_editor.form.name" prop="name">
        <el-input v-model="value.name"></el-input>
      </el-form-item>
      <el-tooltip class="item" effect="dark" :content="$locale.report_editor.source.add" placement="top">
        <el-button style="float: right;" circle size="mini" icon="el-icon-plus" type="success" @click="addSource()"></el-button>
      </el-tooltip>
      <el-form-item :label="$locale.report_editor.form.sources">
        <el-scrollbar class="scroll-source">
          <source-tree
            ref="sourceTree"
            :child-append="value.report_type !== 'report'"
            :sources="value.report_sources"
            :key="this.generateGuid()"
            :append="addSource"
            :edit="sourceEdit"
            :remove="deleteSource" />
        </el-scrollbar>
      </el-form-item>
      <el-form-item v-if="sourceEditor !== null" :label="`Редактирование: ${sourceEditor.name}`">
        <el-scrollbar class="scroll-children">
          <el-form-item style="margin-bottom: 0;">
            <source-form
              ref="sourceForm"
              v-model="value.report_sources[sourceEditor.originIndex]"
              :key="this.generateGuid()"
              :sources="value.report_sources"
              :report-source-types="reportSourceTypes"
              :extended-object-list="extendedObjects"
              :query-list="queries"
              :filter-type-list="filterTypeList" />
          </el-form-item>
        </el-scrollbar>
      </el-form-item>
      <el-form-item style="margin-bottom: 0;">
        <el-button @click="cancel" size="small">
          <span class="el-icon-close"></span> {{ $locale.main.button.cancel }}
        </el-button>
        <el-button style="float: right" type="primary" @click="save" size="small">
          <span class="el-icon-success"></span> {{ $locale.main.button.save }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ExtendedObject from '../../BIEditor/Models/ExtendedObject'
import Query from '../../BIEditor/Models/Query'
import SourceForm from './SourceForm'
import FilterType from '../Models/FilterType'
import SourceTree from './SourceTree'
import SourceAppendForm from './SourceAppendForm'
import Source from '../Models/Source'

export default {
  name: 'ReportForm',

  props: ['value', 'activeNode'],

  components: {
    SourceTree,
    SourceForm,
    SourceAppendForm
  },

  watch: {
    value () {
      this.localValue = this.value
    },
    activeNode () {
      this.localNode = this.activeNode
    }
  },

  data () {
    return {
      localValue: this.value,
      localNode: this.activeNode,
      extendedObjects: [],
      queries: [],
      registries: [],
      tag: null,
      checked: true,
      listTag: [],
      listRole: [],
      changeId: null,
      formRules: {
        name: [{ required: true, message: 'Please input name', trigger: 'blur' }]
      },
      reportSourceTypes: [
        {
          id: 'query',
          value: 'query',
          label: this.$locale.report_editor.object_types.query
        },
        {
          id: 'extended_object',
          value: 'extended_object',
          label: this.$locale.report_editor.object_types.extended_object
        },
        {
          id: 'registry',
          value: 'registry',
          label: this.$locale.report_editor.object_types.registry
        }
      ],
      filterTypeList: [],
      sourceEditor: null,
      appendSource: {
        report_id: null,
        parent_id: null,
        report_source_type_id: null,
        properties: {
          id: null,
          name: null,
          alias: null,
          relation: []
        },
        report_filters: []
      }
    }
  },

  async mounted () {
    this.extendedObjects = await new ExtendedObject().params({ fields: 'id,name', order: 'name:asc' }).$get()
    this.queries = await new Query().params({ fields: 'id,name', order: 'name:asc' }).$get()
    this.filterTypeList = await new FilterType().$get()
  },

  methods: {
    sourceEdit (data) {
      console.log('sourceEdit', data)
      this.sourceEditor = data
    },
    addSource (parentId = null) {
      let properties = { id: null, name: null, alias: null }
      if (parentId) {
        properties = {
          id: null,
          name: null,
          alias: null,
          relation: []
        }
      }
      this.appendSource = new Source({
        report_id: this.value.id,
        parent_id: parentId,
        report_source_type_id: null,
        properties: properties
      })

      this.$refs.sourceAppendForm.open()
    },
    async saveSource () {
      console.log('saveSource', this.appendSource)

      let response = await this.appendSource.save()

      this.localValue.report_sources.push(response)

      this.$refs.sourceTree.getTree().append(response, response.parent_id)
    },
    async deleteSource (sourceId, sourceIndex) {
      let source = await Source.find(sourceId)

      if (this.sourceEditor !== null && this.sourceEditor.id === sourceId) {
        this.sourceEditor = null
      }

      this.localValue.report_sources.splice(sourceIndex, 1)

      source.delete()
    },
    cancel () {
      this.$emit('cancelCard', null)
    },
    multipleValidate () {
      let invalidQuantity = 0

      this.$refs.reportForm.validate(async (valid) => {
        if (!valid) {
          invalidQuantity++
        }
      })

      if (typeof this.$refs.sourceForm !== 'undefined') {
        if (!this.$refs.sourceForm.isValid()) {
          console.log('source error!')
          invalidQuantity++
        }
      }

      if (typeof this.$refs.filterForm !== 'undefined') {
        this.$refs.filterForm.forEach((filterForm) => {
          if (!filterForm.isValid()) {
            invalidQuantity++
          }
        })
      }

      return !(invalidQuantity)
    },
    async save () {
      if (this.multipleValidate()) {
        let response = await this.localValue.save()

        this.localValue = response

        this.localNode.data = Object.assign({}, this.activeNode.data, { ...response })
      }
    }
  }
}
</script>

<style>
.child-report .el-select.el-select--mini {
  width: 100%;
}

.multi-form {
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-left: 2px solid #ddd;
  padding: 10px 15px;
}

.multi-form .el-form-item:last-child {
  margin-bottom: 0;
}

.multi-form.el-form-item--mini.el-form-item,
.multi-form.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}

.multi-form.el-form-item--mini.el-form-item:last-child,
.multi-form.el-form-item--small.el-form-item:last-child {
  margin-bottom: 0;
}

.child-report .scroll-source {
  border: 1px solid #ddd;
  min-height: 150px;
  height: calc(100vh / 2 - 650px / 2);
  border-radius: 5px;
}

.child-report .scroll-container, .child-report .relation-container {
  border: 1px solid #ddd;
  height: calc(100vh / 2 - 420px / 2);
  border-radius: 5px;
}

.child-report .relation-container {
  height: calc(100vh / 2 - 620px / 2);
}

.child-report .el-scrollbar__wrap {
  overflow-x: hidden;
}

.child-report .el-scrollbar__view {
  padding: 15px;
}
.child-report .scroll-children:hover {
  border-color: #C0C4CC;
}
/*.report_editor .scroll-children:focus {
  border-color: #409EFF;
  outline: 0;
}*/
.child-report .scroll-children {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  height: calc(100vh - 596px);
  padding: 5px 0;
  margin-bottom: 15px;
}
</style>
