<template>
    <span v-html="localValue"></span>
</template>

<script>
export default {
  name: 'a-stub',
  props: {
    attribute: {
      type: String
    }
  },
  inject: {
    getRawData: {
      default: () => () => {}
    }
  },
  computed: {
    localValue () {
      let value = this.getRawData()[this.attribute]
      try {
        value = JSON.parse(value)
      } catch (e) {

      }
      if (value instanceof Array) {
        value = value.map(item => item.name).join(',')
      }

      return value
    }
  }
}
</script>

<style scoped>

</style>
