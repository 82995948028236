<template>
  <div>
    <span v-if="error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  // props: {
  //   state: {
  //     type: String,
  //     default: null
  //   }
  // },
  name: 'EsiaPage',
  data () {
    return {
      code: this.$route.query.code,
      state: this.$route.query.state
    }
  },
  computed: {
    error () {
      let errors = []
      if (!this.state) {
        errors.push(this.$locale.login.errors.oauth_provider_id_required)
      }
      if (!this.code) {
        errors.push(this.$locale.login.errors.oauth_code_required)
      }

      return errors.join('\r\n')
    }
  },
  mounted () {
    if (!this.error) {
      this.login()
    }
  },
  methods: {
    async login () {
      try {
        let response = await this.$http.get(`${this.$config.api}/esiaauthentication/auth/login?code=${this.code}&state=${this.state}`)
        if (response.data.status === 'identified') {
          await this.$store.dispatch(`Authorization/login`, response.data.uuid)
        }
        if (response.data.status === 'pdp_agreement_need') {
          await this.$http.post(response.data.url, { state: response.data.state })
          await this.$store.dispatch(`Authorization/EsiaLogin`)
        }
        this.$router.push('/')
      } catch (error) {
        this.$router.push(' ')
      }
    }
  }
}
</script>

<style scoped>

</style>
