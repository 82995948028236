export default class TopicRightUpdateCommand
{
    private roleGuid: string;
    private topicId: number;
    private isTopicProducer: boolean;
    private isTopicConsumer: boolean;

    constructor(
        roleGuid: string,
        topicId: number,
        isTopicProducer: boolean,
        isTopicConsumer: boolean
    ) {
        this.roleGuid = roleGuid;
        this.topicId = topicId;
        this.isTopicProducer = isTopicProducer;
        this.isTopicConsumer = isTopicConsumer;
    }

    getClassName(): string
    {
        return 'TopicRightUpdateCommand';
    }

    getRoleGuid(): string
    {
        return this.roleGuid;
    }

    getTopicId(): number
    {
        return this.topicId;
    }

    getIsTopicProducer(): boolean
    {
        return this.isTopicProducer;
    }

    getIsTopicConsumer(): boolean
    {
        return this.isTopicConsumer;
    }
}