<template>
  <el-row style="margin-bottom: 5px">
    <span>{{$locale.interface_editor.component_editor.options.option}}</span>
    <el-button
      style="float: right"
      @click="addOption"
    >{{$locale.interface_editor.component_editor.options.add_option}}</el-button>
    <template v-if="type === 'basic' || type === 'button'">
      <el-row v-for="(condition, index) in value" :key="index">
        <el-col :span="8">
          <el-input v-model="condition.name"></el-input>
        </el-col>
        <el-col :span="14">
          <el-input v-model="condition.value"></el-input>
        </el-col>
        <el-col :span="2" style="float: right;">
          <el-button @click="deleteOption(index)" size="mini" type="danger">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </template>
    <template v-else-if="type === 'lights'">
      <el-row v-for="(condition, index) in value" :key="index" style="background-color: #f5f5f5; margin-bottom: 8px; border-left: 2px solid green; padding-left: 3px">
        <el-col :span="15">
          <el-input v-model="condition.value" placeholder="value"></el-input>
        </el-col>
        <el-col :span="15">
          <div style="display: flex;">
            <el-input v-model="condition.color" :placeholder="$locale.interface_editor.component_editor.options.color"></el-input>
            <el-color-picker v-model="condition.color" :title="$locale.interface_editor.component_editor.options.color"></el-color-picker>
           </div>
        </el-col>
        <el-col :span="15">
          <el-input v-model="condition.tooltip" :placeholder="$locale.interface_editor.component_editor.options.tooltip"></el-input>
        </el-col>
        <el-col :span="2" :offset="3">
          <el-button @click="deleteOption(index)" size="mini" type="danger" :title="$locale.interface_editor.component_editor.options.remove">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-row>
</template>

<script>
export default {
  name: 'editor-filters',
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    otherProperties: {
      type: Object
    }
  },
  data () {
    return {
      type: this.otherProperties.type
    }
  },
  methods: {
    addOption () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.value.push({
        name: null,
        attribute: null,
        color: null,
        alias: null
      })
    },
    deleteOption (index) {
      this.value.splice(index, 1)
    }
  },
  watch: {
    'otherProperties.type': {
      handler (value) {
        this.type = value
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
</style>
