
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RoleDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    is_readable_default?: Nullable<boolean>;
    is_addable_default?: Nullable<boolean>;
    is_editable_default?: Nullable<boolean>;
    is_deletable_default?: Nullable<boolean>;
    is_menu_visible_default?: Nullable<boolean>;
    root_menu_id?: Nullable<number>;
    dashboards?: Array<number> = [];

    constructor({ id, guid, name, is_readable_default, is_addable_default, is_editable_default, is_deletable_default, is_menu_visible_default, root_menu_id, dashboards}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, is_readable_default?: Nullable<boolean>, is_addable_default?: Nullable<boolean>, is_editable_default?: Nullable<boolean>, is_deletable_default?: Nullable<boolean>, is_menu_visible_default?: Nullable<boolean>, root_menu_id?: Nullable<number>, dashboards?: Array<number>}) {
        this.id = id;
        this.guid = guid;        
        this.name = name;
        this.is_readable_default = is_readable_default;
        this.is_addable_default = is_addable_default;
        this.is_editable_default = is_editable_default;        
        this.is_deletable_default = is_deletable_default;        
        this.is_menu_visible_default = is_menu_visible_default;        
        this.root_menu_id = root_menu_id;
        this.dashboards = dashboards;
    }
}

export default class Role extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private isReadableDefault: Nullable<boolean>;
    private isAddableDefault: Nullable<boolean>;
    private isEditableDefault: Nullable<boolean>;
    private isDeletableDefault: Nullable<boolean>;
    private isMenuVisibleDefault: Nullable<boolean>;
    private rootMenuId: Nullable<number>;
    private dashboards: Array<number> = [];

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        isReadableDefault: Nullable<boolean>,
        isAddableDefault: Nullable<boolean>,
        isEditableDefault: Nullable<boolean>,
        isDeletableDefault: Nullable<boolean>,
        isMenuVisibleDefault: Nullable<boolean>,
        rootMenuId: Nullable<number>,
        dashboards: Array<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.isReadableDefault = isReadableDefault ?? false;
        this.isAddableDefault = isAddableDefault ?? false;
        this.isEditableDefault = isEditableDefault ?? false;
        this.isDeletableDefault = isDeletableDefault ?? false;
        this.isMenuVisibleDefault = isMenuVisibleDefault ?? false;
        this.rootMenuId = rootMenuId;
        this.dashboards = dashboards;
    }

    static create(dto: RoleDTO): Role
    {
        return new Role(
            dto.id,
            dto.guid,
            dto.name,
            dto.is_readable_default,
            dto.is_addable_default,
            dto.is_editable_default,
            dto.is_deletable_default,
            dto.is_menu_visible_default,
            dto.root_menu_id,
            dto.dashboards
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setIsReadable(isReadableDefault: boolean): void
    {
        this.isReadableDefault = isReadableDefault;
    }

    getIsReadable(): boolean
    {
        return this.isReadableDefault;
    }

    setIsAddable(isAddableDefault: boolean): void
    {
        this.isAddableDefault = isAddableDefault;
    }

    getIsAddable(): boolean
    {
        return this.isAddableDefault;
    }

    setIsEditable(isEditableDefault: boolean): void
    {
        this.isEditableDefault = isEditableDefault;
    }

    getIsEditable(): boolean
    {
        return this.isEditableDefault;
    }

    setIsDeletable(isDeletableDefault: boolean): void
    {
        this.isDeletableDefault= isDeletableDefault;
    }

    getIsDeletable(): boolean
    {
        return this.isDeletableDefault;
    }

    setIsMenuVisible(isMenuVisibleDefault: boolean): void
    {
        this.isMenuVisibleDefault= isMenuVisibleDefault;
    }

    getIsMenuVisible(): boolean
    {
        return this.isMenuVisibleDefault;
    }

    setRootMenuId(rootMenuId: number): void
    {
        this.rootMenuId = rootMenuId;
    }

    getRootMenuId(): number
    {
        return this.rootMenuId;
    }

    setDashboards(dashboards: Array<number>): void
    {
        this.dashboards = dashboards;
    }

    getDashboards(): Array<number>
    {
        return this.dashboards;
    }
}
