import InlineEditorBase from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'

import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Font from '@ckeditor/ckeditor5-font/src/font'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import List from '@ckeditor/ckeditor5-list/src/list'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import Base64Image from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import Placeholder from './plugins/placeholder/placeholder'
import TodoList from '@ckeditor/ckeditor5-list/src/todolist'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'

export default class InlineEditor extends InlineEditorBase {}

// Plugins to include in the build.
InlineEditor.builtinPlugins = [
  EssentialsPlugin,
  BoldPlugin,
  ItalicPlugin,
  LinkPlugin,
  ParagraphPlugin,
  Table, TableToolbar,
  TableProperties, TableCellProperties,
  Font, List, Alignment, HorizontalLine, Image, Base64Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload, ImageResize,
  Placeholder, TodoList, MediaEmbed
]

InlineEditor.defaultConfig = {
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
  },
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],
    styles: [
      'full',
      'alignLeft',
      'alignRight'
    ]
  },
  toolbar: {
    items: [
      'fontFamily',
      'fontSize', 'fontColor',
      'fontBackgroundColor', '|',
      'bold',
      'italic',
      'link', 'numberedList', 'bulletedList', 'todoList', '|',
      'alignment', 'horizontalLine', '|', 'imageUpload', 'mediaEmbed', 'insertTable'
    ]
  },
  language: 'ru'
}
