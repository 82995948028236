<template>
  <el-select v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="placeholder" size="mini">
    <el-option
      v-for="item in userList"
      :key="item.id"
      :label="item.fullName()"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import User from '../../../AccessEditor/Models/User'

export default {
  name: 'UsersTable',

  props: ['value', 'placeholder'],

  data () {
    return {
      userList: []
    }
  },

  async mounted () {
    this.userList = await new User().params({ fields: 'id,name,surname,midname' }).$get()
    this.userList.sort((a, b) => {
      if (a.surname > b.surname) {
        return 1
      } else if (a.surname < b.surname) {
        return -1
      }
      return 0
    })
  }
}
</script>

<style type="text/css">

</style>
