<template>
  <div :style="css" :class="{'hover_effect': clickTable}">
    <span :class="{'notExtended': !extended}" style="text-align: center">{{ numberWithSpaces(value) }}</span>
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  name: 'integer_field',
  mixins: [clickTableMixin],
  props: ['value', 'extended', 'css'],
  methods: {
    numberWithSpaces (x) {
      return (x || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>

<style scoped>
  .notExtended {
    white-space: pre;
    text-align: center;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
