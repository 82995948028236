import { MutationTree } from 'vuex'
import { RoleState, initialState } from './state'
import { MutationTypes, RoleCreatedEvent, RoleUpdatedEvent, RoleDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RoleState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.ROLE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.ROLE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.ROLE_CREATED_EVENT]: (state, action: RoleCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.ROLE_UPDATED_EVENT]: (state, action: RoleUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.ROLE_DELETED_EVENT]: (state, action: RoleDeletedEvent) => {
    state.isLoading = false;
  }
}