<template>
  <el-form class="task_registry_entity__form" ref="form" :model="model" :rules="rules" label-position="top" size="mini">
    <el-scrollbar style="height: 50vh;">
      <el-form-item :label="$locale.task_editor.config.properties.registry">
        <registry-select-tree v-model="model.registry" value-as="number" type="registry"></registry-select-tree>
      </el-form-item>

      <template v-if="model.registry">
        <el-form-item :label="$locale.task_editor.config.properties.create_date">
          <registry-select-tree v-model="model.create_date" value-as="number" type="datetime" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.author">
          <registry-select-tree v-model="model.author" value-as="number" type="xref" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.executors">
          <registry-select-tree v-model="model.executors" value-as="number" type="xref" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.type">
          <registry-select-tree v-model="model.type" value-as="number" type="xref" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.status">
          <registry-select-tree v-model="model.status" value-as="number" type="xref" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.plan_date">
          <registry-select-tree v-model="model.plan_date" value-as="number" type="datetime" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.name">
          <registry-select-tree v-model="model.name" value-as="number" type="text" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.content">
          <registry-select-tree v-model="model.content" value-as="number" type="text" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.fact_date">
          <registry-select-tree v-model="model.fact_date" value-as="number" type="datetime" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.status_id">
          <registry-select-tree v-model="model.status_id" value-as="number" type="number" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.task_editor.config.properties.rule_id">
          <registry-select-tree v-model="model.rule_id" value-as="number" type="number" :parent-id="model.registry"></registry-select-tree>
        </el-form-item>
      </template>
    </el-scrollbar>
  </el-form>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'TaskConfigForm',

  props: {
    value: Array
  },

  components: {
    RegistrySelectTree
  },

  data () {
    return {
      model: {
        registry: null,
        create_date: null,
        author: null,
        executors: null,
        type: null,
        status: null,
        plan_date: null,
        name: null,
        content: null,
        fact_date: null,
        status_id: null,
        rule_id: null
      },

      properties: [
        'registry', 'create_date', 'author',
        'executors', 'type', 'status',
        'plan_date', 'name', 'content',
        'fact_date', 'status_id', 'rule_id'
      ],

      rules: {
      }
    }
  },

  mounted () {
    this.value.forEach((item) => {
      this.model[item.id] = item.entity_id
    })
  },

  methods: {
    save (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let newValue = this.value
          this.properties.forEach(property => {
            let index = newValue.findIndex(lv => lv.id === property)
            if (index !== -1) {
              newValue[index].entity_id = this.model[property]
            }
          })
          this.$emit('input', newValue)
          callback()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style type="text/css"></style>
