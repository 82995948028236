import SourceCommandRepositoryInterface from '@/services/MapEditor/domain/repository/SourceCommandRepositoryInterface'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'
import store from '@/store'
import { AddSource, UpdateSource, DeleteSource } from '@/services/MapEditor/infrastructure/store/modules/Source/action-types'

export default class SourceCommandRepository implements SourceCommandRepositoryInterface
{
    insert(item: SourceDTO): Promise<void>
    {
        return store.dispatch(new AddSource(item));
    }

    update(item: SourceDTO): Promise<void>
    {
        return store.dispatch(new UpdateSource(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteSource(guid));
    }
}
