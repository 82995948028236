import { Nullable } from '@/core/domain/type/types'

export default class RouteCreateCommand
{
    private topic: string;
    private taskId: number;
    private fileName: string;
    private priority: number;

    constructor(topic: string, taskId: number, fileName: string, priority: number)
    {
        this.topic = topic;
        this.taskId = taskId;
        this.fileName = fileName;
        this.priority = priority;
    }

    getClassName(): string
    {
        return 'RouteCreateCommand';
    }

    getTopic(): string
    {
        return this.topic;
    }

    getTaskId(): number
    {
        return this.taskId;
    }

    getFileName(): string
    {
        return this.fileName;
    }

    getPriority(): number
    {
        return this.priority;
    }
}