import { ActionTree } from 'vuex'
import { ProjectState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectUpdatedEvent, ProjectLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectAPI } from '@/services/MapEditor/infrastructure/api/ProjectAPI'

export const actions: ActionTree<ProjectState, any> = {
    [ActionTypes.UPDATE_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            await APIClient.shared.request(new ProjectAPI.UpdateProject(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_PROJECT_SOURCES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectSources(data.projectGuid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_COORDINATE_SYSTEMS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectCoordinateSystems(data.projectGuid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
