import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TopicCommandRepositoryInterface from '@/services/Databus/domain/repository/TopicCommandRepositoryInterface'
import TopicCreateCommand from '@/services/Databus/application/command/TopicCreateCommand'
import { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export default class TopicCreateHandler implements CommandHandlerInterface
{
    private repo: TopicCommandRepositoryInterface;

    constructor(repo: TopicCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: TopicCreateCommand): Promise<void>
    {
        let item: TopicDTO = {
            code: command.getCode(),
            name: command.getName(),
            description: command.getDescription(),
            type: command.getType()
        };
        return this.repo.insert(item);
    }
}