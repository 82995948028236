<template>
  <div v-loading="loading">
    <div>
      <span class="label">{{ $locale.logic_editor.command.create_report.report }}</span>
      <select-tree
        class="property"
        v-model="properties.report_id"
        type-field="report_type"
        :clearable="false"
        :standartTree='true'
        :dataTable="reports"
      />
      <span class="label">{{ $locale.logic_editor.command.create_report.format }}</span>
      <el-select
        clearable
        class="property"
        v-model="properties.format"
        size="mini"
      >
        <el-option
          v-for="item in reportFormats"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <span class="label">{{ $locale.logic_editor.command.create_report.operation_type }}</span>
      <el-select
        clearable
        class="property"
        v-model="properties.operation_type"
        size="mini"
      >
        <el-option
          v-for="item in $locale.logic_editor.command.create_report.operation_types"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <template v-if="properties.operation_type === 'send_to_databus'">
        <span class="label">{{ $locale.logic_editor.command.create_report.topic }}</span>
        <el-select
          clearable
          class="property"
          v-model="properties.topic_code"
          size="mini"
        >
          <el-option
            v-for="item in topics"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
            <span>{{ item.name }} <b>{{ item.code }}</b></span>
          </el-option>
        </el-select>
      </template>
    </div>
  </div>
</template>

<script>
import SelectTree from '@/components/LogicEditor/components/treeSelect'
import Report from '@/components/LogicEditor/Models/reporteditor/Report'
import Topic from '@/components/LogicEditor/Models/databusservice/Topic'

export default {
  name: 'CreateReportEvent',
  components: {
    SelectTree
  },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          report_id: null,
          operation_type: null,
          topic_code: null,
          format: null
        }
      }
    }
  },
  data () {
    return {
      reports: [],
      topics: [],
      loading: false,
      reportFormats: ['xml', 'docx', 'pdf']
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    async load () {
      this.loading = true

      let reports = await Report.params({ 'order': 'report_type:asc,row_order:asc,name:desc' }).get()
      this.reports = this.createReportsTree(reports)
      this.topics = await Topic.get()

      this.loading = false
    },
    createReportsTree (data) {
      data = data.map((item) => {
        item.report_type = this.$locale.report_editor.types[item.report_type]
        item.leaf = !item.has_children
        return item
      })
      let hashTable = Object.create(null)
      data.forEach((item) => {
        hashTable[item.id] = { ...item, children: [] }
      })

      let dataTree = []
      data.forEach(record => {
        if (record.parent_id) {
          hashTable[record.parent_id].children.push(hashTable[record.id])
        } else {
          dataTree.push(hashTable[record.id])
        }
      })
      return dataTree
    }
  },
  watch: {
    properties: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.label {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.property {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
</style>
