<template>
  <grid-layout
    :layout.sync="layoutData"
    :col-num="12"
    :row-height="30"
    :is-draggable="true"
    :is-resizable="true"
    :vertical-compact="true"
    :margin="[10, 10]"
    :use-css-transforms="true"
    :autoSize="true"
  >
    <grid-item
      v-for="(container, index) in containers"
      :is-draggable="true"
      :is-resizable="true"
      :key="index"
      :x="container.x"
      :y="container.y"
      :w="container.w"
      :h="container.h"
      :i="container.i"
      drag-allow-from=".vue-draggable-handle"
      drag-ignore-from=".no-drag"
      :style="container.extended ? 'display: inline-table;' : ''"
      :class="{'edit-container' : true, 'active-container' : activeContainerGuid === container.guid}"
    >
      <div class="grid-item custom_scrollbar" @click="setActiveContainer(container.guid)" :style="container.extended ? `display: contents; ${container.style}` : `${container.style}; background-image: url(${container.urlImage}); background-size: ${container.fitImage};`">
        <el-button size="small" class="vue-draggable-handle" style="z-index: 102" icon="el-icon-rank" circle/>
        <default-container
                v-if="!container.isHtml"
                class="no-drag"
                :ref="'container_' + container.guid"
                :components="componentsByContainers[container.guid]"
                :active-component-guid="activeComponentGuid"
                :model="model"
          ></default-container>
        <html-container
                v-else
                class="no-drag"
                :ref="'container_' + container.guid"
                :components="componentsByContainers[container.guid]"
                :active-component-guid="activeComponentGuid"
                :model="model"
                v-model="container.html"
        ></html-container>
        <div style="position: absolute;
                    width: calc(100% - 8px);
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    opacity: 0;
                    z-index: 100;"></div>
      </div>
    </grid-item>
  </grid-layout>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'

import DefaultContainer from './default-container'
import HtmlContainer from './html-container'

export default {
  name: 'layout',
  inject: {
    setActiveComponent: {
      default: () => {}
    },
    setActiveContainer: {
      default: () => {}
    }
  },
  props: {
    'layout-data': {
      type: Array,
      default () { return [] }
    },
    containers: {
      type: Array,
      default () { return [] }
    },
    components: {
      type: Array,
      default () { return [] }
    },
    availableComponents: {
      type: Array,
      default () { return [] }
    },
    model: {
      type: Object,
      default () { return {} }
    },
    activeContainerGuid: {
      type: String,
      default: null
    },
    activeComponentGuid: {
      type: String,
      default: null
    }
  },
  components: {
    HtmlContainer,
    DefaultContainer,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  watch: {
    activeContainerGuid (value) {
      if (this.activeComponentGuid && !this.getComponentsFromContainer(value).find(item => item.guid === this.activeComponentGuid)) {
        this.setActiveComponent(null)
      }
    }
  },
  provide () {
    return { getDashboardComponents: this.getDashboardComponents }
  },
  computed: {
    componentsByContainers () {
      let result = {}
      this.components.forEach((component) => {
        if (typeof result[component.containerGuid] === 'undefined') {
          result[component.containerGuid] = []
        }
        result[component.containerGuid].push(component)
      })
      for (let key in result) {
        if (result.hasOwnProperty(key)) {
          result[key] = result[key].sort((a, b) => {
            return a.orderId - b.orderId
          })
        }
      }

      return result
    }
  },
  methods: {
    /* возвращает все дочерние компоненты дэшборда */
    getDashboardComponents () {
      return this.$refs
    },
    getComponentsFromContainer (guid) {
      return this.components.filter((item) => {
        return item.containerGuid === guid
      }).sort((a, b) => {
        return a.orderId - b.orderId
      })
    },
    getComponent (guid) {
      let componentData = this.components.find((item) => item.guid === guid)
      let container = this.$refs[`container_${componentData.containerGuid}`][0]

      return container.$refs['component_' + guid][0]
    }
  }
}
</script>

<style scoped>
  .component-settings {
    position: absolute;
    width: 30px;
    height: 20px;
    z-index: 999;
    opacity: 0.7;
  }
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }

  .grid-item {
    height: 100%;
  }

  .edit-container:hover {
    outline: 1px #409eff solid;
    background-color: rgba(238, 245, 255, 0.5);
  }

  .edit-container.active-container {
    outline: 2px #409eff solid;
    background-color: rgba(238, 245, 255, 0.8);
  }

  .edit-container > .grid-item > .container-settings {
    position: absolute;
    right: 3px;
    display: flex;
    z-index: 99999;
    padding: 5px;
  }

  .edit-container:hover > .grid-item > .container-settings button {
    display: inline-block;
  }

  .edit-container {
    outline: 1px #606266 dashed;
  }

  .grid-item .vue-draggable-handle {
    bottom: -16px !important;
    position: absolute !important;
    left: 45% !important;
  }

  .vue-grid-item.vue-grid-placeholder {
    background: none;
  }

  .vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .edit-container .grid-item .container-settings button {
    display: none;
  }

  .no-drag .wrapper {
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
  }

  .grid-item {
    overflow-y: auto;
  }
</style>
<style src="../InterfaceViewer.css">
</style>
<style>
  .vue-grid-item .vue-resizable-handle
  {
    z-index: 105;
  }
</style>
