















import Vue from 'vue'
import MainToolbar from '@/services/MapEditor/infrastructure/components/MainToolbar/index.vue'
import LayerPanel from '@/services/MapEditor/infrastructure/components/LayerPanel/index.vue'
import ProjectPanel from '@/services/MapEditor/infrastructure/components/ProjectPanel/index.vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import ProjectTreeElementProjectCreateHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementProjectCreateHandler'
import ProjectTreeElementGroupCreateHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementGroupCreateHandler'
import ProjectTreeElementDeleteHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementDeleteHandler'
import ProjectUpdateHandler from '@/services/MapEditor/application/handler/command/ProjectUpdateHandler'
import ProjectGroupUpdateHandler from '@/services/MapEditor/application/handler/command/ProjectGroupUpdateHandler'
import ProjectTreeElementByGuidHandler from '@/services/MapEditor/application/handler/query/ProjectTreeElementByGuidHandler'
import ProjectTreeElementsHandler from '@/services/MapEditor/application/handler/query/ProjectTreeElementsHandler'
import ProjectSourcesHandler from '@/services/MapEditor/application/handler/query/ProjectSourcesHandler'
import ProjectCoordinateSystemsHandler from '@/services/MapEditor/application/handler/query/ProjectCoordinateSystemsHandler'
import SourceCreateHandler from '@/services/MapEditor/application/handler/command/SourceCreateHandler'
import SourceUpdateHandler from '@/services/MapEditor/application/handler/command/SourceUpdateHandler'
import SourceDeleteHandler from '@/services/MapEditor/application/handler/command/SourceDeleteHandler'
import CoordinateSystemCreateHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemCreateHandler'
import CoordinateSystemUpdateHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemUpdateHandler'
import CoordinateSystemDeleteHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemDeleteHandler'
import CoordinateSystemBySridHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemBySridHandler'
import CoordinateSystemsHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemsHandler'
import CoordinateSystemsCountHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemsCountHandler'
import SourcesHandler from '@/services/MapEditor/application/handler/query/SourcesHandler'
import SourcesCountHandler from '@/services/MapEditor/application/handler/query/SourcesCountHandler'
import SourceByGuidHandler from '@/services/MapEditor/application/handler/query/SourceByGuidHandler'
import SourceProjectsHandler from '@/services/MapEditor/application/handler/query/SourceProjectsHandler'
import SourceTypesHandler from '@/services/MapEditor/application/handler/query/SourceTypesHandler'
import SourceTypePropertiesHandler from '@/services/MapEditor/application/handler/query/SourceTypePropertiesHandler'
import EntitiesHandler from '@/services/MapEditor/application/handler/query/EntitiesHandler'
import EntityByIdHandler from '@/services/MapEditor/application/handler/query/EntityByIdHandler'
import ProjectTreeElementQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectTreeElementQueryRepository'
import ProjectTreeElementCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectTreeElementCommandRepository'
import ProjectCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectCommandRepository'
import ProjectGroupCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectGroupCommandRepository'
import SourceQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/SourceQueryRepository'
import SourceCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/SourceCommandRepository'
import EntitуQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/EntitуQueryRepository'
import CoordinateSystemCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/CoordinateSystemCommandRepository'
import CoordinateSystemQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/CoordinateSystemQueryRepository'

export default {
  name: 'MapEditor',
  components: {
    MainToolbar,
    LayerPanel,
    ProjectPanel
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'ProjectTreeElementProjectCreateCommand': new ProjectTreeElementProjectCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeElementGroupCreateCommand': new ProjectTreeElementGroupCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeElementDeleteCommand': new ProjectTreeElementDeleteHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectUpdateCommand': new ProjectUpdateHandler(
            new ProjectCommandRepository()
          ),
          'ProjectGroupUpdateCommand': new ProjectGroupUpdateHandler(
            new ProjectGroupCommandRepository()
          ),
          'SourceCreateCommand': new SourceCreateHandler(
            new SourceCommandRepository()
          ),
          'SourceUpdateCommand': new SourceUpdateHandler(
            new SourceCommandRepository()
          ),
          'SourceDeleteCommand': new SourceDeleteHandler(
            new SourceCommandRepository()
          ),
          'CoordinateSystemCreateCommand': new CoordinateSystemCreateHandler(
            new CoordinateSystemCommandRepository()
          ),
          'CoordinateSystemUpdateCommand': new CoordinateSystemUpdateHandler(
            new CoordinateSystemCommandRepository()
          ),
          'CoordinateSystemDeleteCommand': new CoordinateSystemDeleteHandler(
            new CoordinateSystemCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ProjectTreeElementByGuidQuery': new ProjectTreeElementByGuidHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'ProjectTreeElementsQuery': new ProjectTreeElementsHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'SourcesQuery': new SourcesHandler(
            new SourceQueryRepository()
          ),
          'SourcesCountQuery': new SourcesCountHandler(
            new SourceQueryRepository()
          ),
          'SourceByGuidQuery': new SourceByGuidHandler(
            new SourceQueryRepository()
          ),
          'SourceProjectsQuery': new SourceProjectsHandler(
            new SourceQueryRepository()
          ),
          'SourceTypesQuery': new SourceTypesHandler(
            new SourceQueryRepository()
          ),
          'SourceTypePropertiesQuery': new SourceTypePropertiesHandler(
            new SourceQueryRepository()
          ),
          'ProjectSourcesQuery': new ProjectSourcesHandler(
            new SourceQueryRepository()
          ),
          'ProjectCoordinateSystemsQuery': new ProjectCoordinateSystemsHandler(
            new CoordinateSystemQueryRepository()
          ),
          'EntitiesQuery': new EntitiesHandler(
            new EntitуQueryRepository()
          ),
          'EntityByIdQuery': new EntityByIdHandler(
            new EntitуQueryRepository()
          ),
          'CoordinateSystemsQuery': new CoordinateSystemsHandler(
            new CoordinateSystemQueryRepository()
          ),
          'CoordinateSystemsCountQuery': new CoordinateSystemsCountHandler(
            new CoordinateSystemQueryRepository()
          ),
          'CoordinateSystemBySridQuery': new CoordinateSystemBySridHandler(
            new CoordinateSystemQueryRepository()
          )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus,
      getProjectTabsContainer: this.getProjectTabsContainer
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    },
    getProjectTabsContainer() {
      return this.$refs.projectTabsContainer;
    }
  },
  mounted() {
    
  }
}
