





















































































































































import ActivitiesQuery from '@/services/ActivityService/application/query/ActivitiesQuery'
import ActivitiesCountQuery from '@/services/ActivityService/application/query/ActivitiesCountQuery'

export default {
  name: 'SnapshotPanel',
  components: {
    
  },
  data () {
    return {
      loading: false,
      activitiesPageSize: 0,
      defaultActivitiesPageSize: 100,
      activitiesCurrentPage: 0,
      activitiesPageLimit: 100,
      activitiesCount: 0,
      allActivitiesCount: 0,
      activities: [],
      activity: null,
      informationPanelHeight: 0
    }
  },
  inject: ['getEventBus', 'getQueryBus'],
  methods: {
    loadActivities() {
      if (this.allActivitiesCount == 0) {
        this.countAndLoadActivities();
      } else {
        this.loadAllActivities();
      }
    },
    async countAndLoadActivities() {
      await this.getQueryBus().execute(
        new ActivitiesCountQuery()
      ).then(data => {
        this.activitiesCount = data[0].count;      
        this.loadAllActivities();
      });
    },
    async loadAllActivities() {
      await this.getQueryBus().execute(
        new ActivitiesQuery({
          limit: this.activitiesPageLimit,
          offset: this.activitiesCurrentPage
        })
      ).then(data => {
        this.activitiesPageSize = this.activitiesPageLimit;
        this.activities = data;
      });
    },
    handleActivitiesPageChange(val) {
      val--;
      this.activitiesCurrentPage = (val * this.activitiesPageLimit);
      this.loadActivities();
    },
    parseDump(record, service, resource){
      let res = [];
      if (typeof record.id !== "undefined") {
        res.push("<strong>id</strong>: " + record.id)
      }
      if (typeof record.name !== "undefined") {
        res.push("<strong>" + this.$locale.main.fields.name + "</strong>: " + record.name)
      }

      if (service == 'reporteditor') {
        res.push("<strong>" + this.$locale.main.fields.type + "</strong>: " + this.$locale.report_editor.types[record.report_type]);
      }

      if (service == 'menueditor') {
        if (resource == 'menu') {
          res.push("<strong>" + this.$locale.main.fields.type + "</strong>: " + this.$locale.menu_editor.label[record.menu_type_id]);

          for (const [key, value] of Object.entries(record)) {
            if (key !== 'name' && this.$locale.menu_editor.label.hasOwnProperty(key)) {
              res.push("<strong>" + this.$locale.menu_editor.label[key] + "</strong>: " + (value ?? ""));
            }
          }

          if (record.properties) {
            for (const [key, value] of Object.entries(record.properties)) {
              if (this.$locale.menu_editor.label.hasOwnProperty(key)) {
                res.push("<strong>" + this.$locale.menu_editor.label[key] + "</strong>: " + (value ?? ""));
              }
            }
          }
        }
      }

      if (service == 'objecteditor') {
        if (resource == 'entities') {
          let props = record.properties;
          if (props != null) {
            props.forEach(prop => {
              if (this.$locale.object_editor.settings.properties.hasOwnProperty(prop.id)) {
                let val = "<strong>" + this.$locale.object_editor.settings.properties[prop.id] + "</strong>: ";
                if (prop.value == true) {
                  val += this.$locale.main.fields.true;
                } else if (prop.value == false) {
                  val += this.$locale.main.fields.false;
                } else {
                  val += (prop.value ?? "");
                }

                res.push(val);
              }
            });
          }
        }
      }

      return res.join(", ");
    }
  },
  mounted () {
    this.loadActivities();
  }
}
