import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import TopicType from './TopicType'

export class TopicDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    code?: Nullable<string>;
    description?: Nullable<string>;
    type?: Nullable<string>;

    constructor({ id, guid, name, code, description, type}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, code?: Nullable<string>, description?: Nullable<string>, type?: Nullable<string>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.code = code;
        this.description = description;
        this.type = type;
    }
}

export default class Topic extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private code: string;
    private description: Nullable<string>;
    private type: TopicType;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        code: string,
        description: Nullable<string>,
        type: TopicType
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.code = code;
        this.description = description;
        this.type = type;
    }

    static create(dto: TopicDTO): Topic
    {
        return new Topic(
            dto.id,
            dto.guid,
            dto.name,
            dto.code,
            dto.description,
            TopicType[dto.type.toUpperCase()]
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setCode(code: string): void
    {
        this.code = code;
    }

    getCode(): string
    {
        return this.code;
    }

    setDescription(description: string): void
    {
        this.description = description;
    }

    getDescription(): string
    {
        return this.description;
    }

    setType(type: TopicType): void
    {
        this.type = type;
    }

    getType(): TopicType
    {
        return this.type;
    }
}
