<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
      <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
    </el-form-item>

    <el-form-item :label="$locale.etl_editor.form.fields.file" prop="file_id">
      <el-input :placeholder="$locale.etl_editor.form.fields.file" size="mini" :value="typeof $refs.upload !== 'undefined' ? ($refs.upload.uploadFiles.length ? $refs.upload.uploadFiles[0].name : null) : null">
        <template slot="append">
          <el-upload
            :headers="getHeaders()"
            class="upload-demo"
            ref="upload"
            :action="`${this.$config.api}/registryservice/files`"
            :file-list="fileList"
            :auto-upload="false"
            :show-file-list="false"
            :limit="1"
            :multiple="false">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="this.$locale.etl_editor.form.upload_file" placement="top">
              <el-button size="mini"  icon="el-icon-download" type="primary"></el-button>
            </el-tooltip>
          </el-upload>
        </template>
      </el-input>
    </el-form-item>

    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: 'TemplateCreate',

  props: {
    value: {
      type: Object,
      default: null
    },
    title: String
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'change' }
        ]
      },
      fileList: []
    }
  },

  methods: {
    submit (save) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$refs.upload.uploadFiles.length) {
            const fd = new FormData()
            fd.append('file', this.$refs.upload.uploadFiles[0].raw)
            this.$http.post(`${this.$config.api}/registryservice/files`, fd)
              .then((response) => {
                this.localValue.file_id = response.data.id
                this.$emit('input', this.localValue)
              })
              .then(async () => {
                await save(true)
              })
          }
        }
      })
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  }
}
</script>

<style type="text/css">

</style>
