<template>
  <div style="height: 100%">
    <el-form v-show="!showCard" ref="validateForm" v-loading="loading" style="height: calc(100% - 46px);">
      <InterfaceViewer
        ref="interface_viewer"
        :model="interfaceModel"
      ></InterfaceViewer>
    </el-form>
  </div>
</template>

<script>
import InterfaceViewer from '@/components/InterfaceViewer'
import Dashboard from '@/components/DashboardEditor/Models/Dashboard'

export default {
  components: {
    InterfaceViewer
  },
  props: ['id', 'model', 'main'],
  name: 'Dashboard',
  data () {
    return {
      loading: true,
      openedCards: [],
      showCard: false,
      interfaceModel: this.model
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return false
      }
      this.loading = true
      let dashboard = await new Dashboard().find(this.id)
      this.$refs.interface_viewer.loadState(JSON.parse(dashboard.structure))
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
