<template>
  <div>
    <treeselect
        ref="treeselect"
        :options="this.deepSearch(this.dataTable)"
        :multiple="multiple"
        :disabled="disabled"
        :placeholder="$locale.main.placeholder.select"
        :normalizer="normalizer"
        :disable-branch-nodes="true"
        :append-to-body="appendToBody"
        :clear-on-select="false"
        :clearable="clearable"
        :clearValueText="clearValueText"
        :noChildrenText="noChildrenText"
        :noResultsText="noResultsText"
        :limitText="limitText"
        :matchKeys="matchKeys"
        :limit="3"
        @select="selectAlias"
        :value-consists-of="valueConsistsOf"
        :value='value'
        @input="$emit('input', $event)">
          <div slot="value-label" slot-scope="{ node }">{{ node.label }} <b> (id: {{ node.raw.id }})</b></div>
          <label v-if="standartTree" :title="node.label" slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
            {{ node.label }}(id: {{node.id}}, <b>{{ $locale.object_editor.entity_types[node.raw[typeField]] || node.raw[typeField] }}</b>)
          </label>
          <label v-else slot="option-label" :title="node.label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
            <b>{{ node.label }}</b><br><span style="font-size: 10px">attr_{{node.id}}_</span><span style="float: right; font-size: 10px;">{{ $locale.object_editor.entity_types[node.raw[typeField]] || node.raw[typeField] }}</span>
          </label>
      </treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    Treeselect
  },
  props: {
    dataTable: {
      type: Array,
      default: () => []
    },
    selectAlias: {
      type: Function,
      default: () => {}
    },
    multiple: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    standartTree: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    typeField: {
      type: String,
      default: 'entity_type_id'
    },
    value: {}
  },
  data () {
    return {
      matchKeys: ['name', 'id'],
      valueConsistsOf: 'LEAF_PRIORITY',
      clearValueText: 'Очистить',
      noChildrenText: 'Нет данных',
      noResultsText: 'Не найдено',
      limitText: count => `+ ${count}`,
      normalizer (node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isLeaf: node.leaf
        }
      }
    }
  },
  methods: {
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array && el.children.length) this.deepSearch(el.children)
      }
      return arr
    }
  },
  mounted () {
    // this.deepSearch(this.dataTable)
  }
}
</script>

<style >
.vue-treeselect__menu::-webkit-scrollbar-corner {
    background: transparent;
}
.vue-treeselect__menu::-webkit-scrollbar {
    width: 8px;
    height: 14px;
}
.vue-treeselect__menu::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.vue-treeselect__menu::-webkit-scrollbar-thumb {
    border: 40px none #ffffff;
    border-radius: 50px;
    background-color: rgba(144,147,153,.3);
}
.vue-treeselect__menu::-webkit-scrollbar-thumb:active {
    background: rgba(144,147,153);
}
.vue-treeselect__menu::-webkit-scrollbar-track {
    background: #ffffff;
    border: 44px none #ffffff;
    border-radius: 50px;
}
.vue-treeselect__menu::-webkit-scrollbar-corner {
    background: transparent;
}
.vue-treeselect__value-container .vue-treeselect__single-value {
    color: #606266;
    line-height: 35px;
}

.vue-treeselect__input {
    color: #606266;
    vertical-align: baseline;
}

.vue-treeselect__placeholder {
    line-height: 34px;
}

.vue-treeselect__value-container {
    line-height: 20px;
}
</style>
