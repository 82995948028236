import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import SnapshotRepositoryInterface from '@/services/SyncService/domain/repository/SnapshotRepositoryInterface'
import store from '@/store'
import { GetSnapshots, CountSnapshots } from '@/services/SyncService/infrastructure/store/modules/Snapshot/action-types'

export default class SnapshotQueryRepository extends AbstractQueryRepository implements SnapshotRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetSnapshots(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountSnapshots(payload));
    }
}