<template>
    <div class="editor_box task_editor">
      <el-container>
        <el-header height="">
          {{ $locale.main.system.task_editor }}

          <el-tooltip
            class="item"
            effect="dark"
            placement="left-start"
            :open-delay="300"
            :content="$locale.task_editor.config.title">
            <el-button style="float: right;" type="default" icon="el-icon-edit" @click="editConfig()" size="mini"></el-button>
          </el-tooltip>
        </el-header>
        <el-container>
          <Split style="height: calc(100vh - 96px);">
            <SplitArea :size="50">
              <tree-element-layout
                ref="treeElement"
                :create-element="createElement"
                :update-element="updateElement"
                :delete-element="deleteElement"
                :cancel="cancelEditor">
              </tree-element-layout>
            </SplitArea>
            <SplitArea :size="50">
              <editor-element-layout
                ref="editorElement"
                v-model="editor.model"
                :title="editor.title"
                :form="editor.form">
                <el-button @click="save()" icon="el-icon-success" type="primary" size="small">
                  {{ $locale.main.button.save }}
                </el-button>
              </editor-element-layout>
            </SplitArea>
          </Split>
        </el-container>
      </el-container>

      <el-dialog :title="$locale.task_editor.config.title" :visible.sync="isTaskConfig" width="40%" :close-on-click-modal="false" :destroy-on-close="true">
        <span>
          <task-config-form ref="taskConfigForm" v-model="modelConfig"></task-config-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="isTaskConfig = false">{{ $locale.main.button.cancel }}</el-button>
          <el-button size="small" type="primary" @click="saveConfig">{{ $locale.main.button.save }}</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import TreeElementLayout from '@/services/TaskEditor/infrastructure/components/TreeElementLayout'
import EditorElementLayout from '@/services/TaskEditor/infrastructure/components/EditorElementLayout'
import Rule from '@/services/TaskEditor/infrastructure/domain/model/Rule'
import RuleGroup from '@/services/TaskEditor/infrastructure/domain/model/RuleGroup'
import TreeElement from '@/services/TaskEditor/infrastructure/domain/model/TreeElement'
import TaskConfigForm from '@/services/TaskEditor/infrastructure/components/forms/TaskConfigForm'

export default {
  name: 'TaskEditor',

  components: {
    TreeElementLayout,
    EditorElementLayout,
    TaskConfigForm
  },

  data () {
    return {
      editor: {
        isNew: true,
        title: '',
        model: {},
        form: null,
        node: null
      },

      models: {
        rule: Rule,
        group: RuleGroup
      },

      defaultModel: {
        rule: {
          name: null,
          object_id: null,
          related_record_field_id: null,
          is_divided_by_executors: false,
          state_match_type: 'all',
          rule_states: [],
          close_match_type: 'all',
          rule_close_commands: [],
          target_registry_state_match_type: 'all',
          rule_target_registry_states: [],
          task_registry_state_match_type: 'all',
          rule_task_registry_states: [],
          rule_fields: [],
          parent_tree_element_id: null
        },

        group: {
          parent_tree_element_id: null,
          name: null
        }
      },

      form: {
        rule: 'RuleForm',
        group: 'RuleGroupForm'
      },

      modelConfig: [],
      isTaskConfig: false,

      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
        })
      )
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  methods: {
    cancelEditor () {
      this.editor = {
        isNew: true,
        model: {},
        form: null,
        title: '',
        node: null
      }
    },

    createElement (elementType, parentNode) {
      this.editor.isNew = true
      this.editor.model = new this.models[elementType](this.defaultModel[elementType])
      this.editor.model.parent_tree_element_id = parentNode ? parentNode.data.id : null
      this.editor.form = this.form[elementType]
      this.editor.title = this.$locale.task_editor.title[elementType]
      this.editor.node = parentNode
    },

    async updateElement (node, data) {
      this.editor.isNew = false
      this.editor.model = await new this.models[data.element_type]().find(data.element_guid)
      this.editor.form = this.form[data.element_type]
      this.editor.title = this.$locale.task_editor.title[data.element_type]
      this.editor.node = node
    },

    async deleteElement (node, data) {
      let deleteModel = await new this.models[data.element_type]().find(data.element_guid)

      if (deleteModel) {
        await deleteModel.delete()
      }
    },

    async save () {
      let response = await this.$refs.editorElement.submit()

      if (response) {
        let model = await new TreeElement().find(response.guid)
        if (this.editor.isNew) {
          this.$refs.treeElement.addNode(model, this.editor.node)
        } else {
          this.editor.node.data.name = model.name
          this.$refs.treeElement.updateNode(this.editor.node)
        }
      }
    },

    editConfig () {
      this.$http
        .get(`${this.$config.api}/taskeditor/task_registry_entities`)
        .then((response) => {
          this.modelConfig = []
          this.modelConfig.push(...response.data)
          this.isTaskConfig = true
        })
    },

    saveConfig () {
      this.$refs.taskConfigForm.save(() => {
        this.$http
          .put(`${this.$config.api}/taskeditor/task_registry_entities`, this.modelConfig)
          .then((response) => {})
          .catch((error) => {
            console.error(error)
          })
      })
    },

    getEventBus () {
      return this.eventBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>

<style src="./TaskEditor.css" type="text/css"></style>
