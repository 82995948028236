import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CoordinateSystemCommandRepositoryInterface from '@/services/MapEditor/domain/repository/CoordinateSystemCommandRepositoryInterface'
import CoordinateSystemCreateCommand from '@/services/MapEditor/application/command/CoordinateSystemCreateCommand'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export default class CoordinateSystemCreateHandler implements CommandHandlerInterface
{
    private repo: CoordinateSystemCommandRepositoryInterface;

    constructor(repo: CoordinateSystemCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: CoordinateSystemCreateCommand): Promise<void>
    {
        let item: CoordinateSystemDTO = {
            srid: command.getSrid(),
            auth_srid: command.getSrid(),
            epsg_type: command.getEpsgType(),
            auth_name: command.getName(),
            description: command.getDescription(),
            proj4text: command.getProj4text(),
            project_id: command.getProjectId()
        };
        return this.repo.insert(item);
    }
}