<template>
  <div>
    <span>{{_title || $locale.interface_editor.component_editor.filters.filter}}</span>
    <el-button style="float: right" @click="addFilter">
      {{$locale.interface_editor.component_editor.filters.add_filter}}
    </el-button>
    <div v-for="(condition, index) in value" :key="index" style="margin-bottom: 8px; border-left: 2px solid green; padding-left: 3px">
      <el-row :gutter="12" style="margin-bottom: 3px">
        <el-col :span="12">
          <el-input v-model="condition.alias"></el-input>
        </el-col>
        <el-col :span="12">
          <el-select v-model="condition.type">
            <el-option
              v-for="(item, index) in [{id:'field', name:'поле'},{id:'constant', name:'константа'},{id:'current_user',name:'текущий пользователь'}]"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :span="12" v-if="condition.type==='field'">
          <el-select v-model="condition.attribute">
            <el-option
              v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
              :key="index"
              :label="`${item.name} (${item.properties.name})`"
              :value="item.properties.name"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12" v-else-if="condition.type==='constant'">
          <el-input v-model="condition.attribute" />
        </el-col>
        <el-col :span="12" v-if="_showXrefOption">
          <el-checkbox v-model="condition.isXref">{{$locale.interface_editor.component_editor.filters.xref}}</el-checkbox>
        </el-col>
        <el-col :span="12" v-if="_showEqualsTypes">
          <el-select v-model="condition.equalsType">
            <el-option
              v-for="(item, index) in $locale.interface_editor.component_editor.filters.equals_types"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="24">
          <el-button @click="deleteFilter(index)"
            style="float: right"
            size="mini"
            type="danger"
            :title="$locale.main.button.delete">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editor-filters',
  props: {
    value: {
      type: Array,
      default () { return [] }
    },
    options: {
      type: Object
    },
    title: {
      type: String,
      default: null
    },
    showXrefOption: {
      type: Boolean,
      default: true
    },
    showEqualsTypes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _title () {
      return this.title || (this.options ? this.options.title : '')
    },
    _showXrefOption () {
      return typeof (this.options || {}).showXrefOption !== 'undefined' ? (this.options || {}).showXrefOption : this.showXrefOption
    },
    _showEqualsTypes () {
      return this.showEqualsTypes || (this.options || {}).showEqualsTypes
    }
  },
  data () {
    return {
      components: this.getComponents()
    }
  },
  inject: ['getComponents'],
  methods: {
    addFilter () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.value.push({
        attribute: null,
        alias: null,
        type: 'field',
        isXref: false,
        equalsType: 'eq'
      })
    },
    deleteFilter (index) {
      this.value.splice(index, 1)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
</style>
