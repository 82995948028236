<template>
  <el-dialog
    :title="title"
    :visible.sync="isShow"
    :before-close="close">
    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="value" size="mini"
      @submit.native.prevent="save">
      <el-form-item :label="$locale.bi_editor.form.label.name" prop="name">
        <el-input v-model="value.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">
        {{ $locale.bi_editor.form.btn_cancel }}
      </el-button>
      <el-button type="primary" @click="save" size="small">
        {{ $locale.bi_editor.form.btn_save }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'CreateGroup',
  props: {
    title: String,
    value: Object,
    rules: Object,
    isShow: Boolean,
    beforeClose: {
      type: Function,
      default: function (isNewEntity) {
      }
    },
    submit: {
      type: Function,
      default: async function (isNewEntity) {
      }
    }
  },
  methods: {
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submit(true)
        } else {
          return false
        }
      })
    },
    close (isNewEntity = true) {
      this.beforeClose(isNewEntity)
    }
  }
}
</script>

<style type="text/css">

</style>
