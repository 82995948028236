<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_calendar">
    <!-- {{value}} -->
    <el-form-item class="checbox" :label="$locale.interface_editor.component_editor.editor_calendar.time">
      <el-checkbox v-model="value.time"></el-checkbox>
    </el-form-item>
    <el-form-item class="checbox" :label="$locale.interface_editor.component_editor.editor_calendar.mini">
      <el-checkbox v-model="value.mini"></el-checkbox>
    </el-form-item>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.interface_editor.component_editor.editor_calendar.disable_views }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_calendar.disable_views"
      :modal="false"
      width="20%"
      :visible.sync="dialogSettingsVisible">
      <el-checkbox-group v-model="value.disableViews">
        <el-checkbox style="display: block" v-for="item in controlsViews" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-dialog>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.active_view">
      <editor-select
        :value="value.activeView"
        @change="$set(value ,'activeView', $event)"
        :options="{
          multiple: false,
          options: controlsViews
        }"
      ></editor-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'registry', name: 'Реестр' },
            { id: 'extend_object', name: 'Расширенная таблица' },
            { id: 'requests', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.registry" v-if="value.type === 'registry'">
      <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'extend_object'">
      <editor-select
        :value="value.extendObject"
        @change="$set(value ,'extendObject', $event)"
        :options="{multiple: false, options: extendObjects}"
      ></editor-select>
    </el-form-item>
    <template v-if="value.type === 'registry' || value.type === 'extend_object'">
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.start">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.start"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.end">
        <selectTree class="extraLine_select" :clearable="true" :standartTree='false' :dataTable="listField" v-model="value.events.end"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.title">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.title"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.content">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.content"/>
      </el-form-item>
      <div style="position: relative">
        <el-tooltip  placement="top-start" effect="dark" style="position: absolute; left: 35px; top: 7px">
          <div slot="content" v-html="`blue<br>orange<br>yellow<br>red<br>`"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.class">
          <selectTree class="extraLine_select" :clearable="true" :standartTree='false' :dataTable="listField" v-model="value.events.class"/>
        </el-form-item>
      </div>
    </template>
    <template v-if="value.type === 'requests'">
      <el-form-item>
        <editor-select
          :value="value.requestsId"
          @change="$set(value ,'requestsId', $event)"
          :options="{
            multiple: false,
            options: requests
          }"
        ></editor-select>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.start">
        <el-input type="text" size="small" v-model="value.events.start"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.end">
        <el-input type="text" size="small" v-model="value.events.end"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.title">
        <el-input type="text" size="small" v-model="value.events.title"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.content">
        <el-input type="text" size="small" v-model="value.events.content"></el-input>
      </el-form-item>
      <div style="position: relative">
        <el-tooltip  placement="top-start" effect="dark" style="position: absolute; left: 35px; top: 7px">
          <div slot="content" v-html="`red<br>orange<br>yellow<br>green<br>blue<br>purple<br>grey`"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.class">
          <el-input type="text" size="small" v-model="value.events.class"></el-input>
        </el-form-item>
      </div>
    </template>
    <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.action">
      <editor-select
        :value="value.actionType"
        @change="$set(value, 'actionType', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
            { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
            { id: 'execute_plugin', name: $locale.interface_editor.component_editor.button_action.start_plugin }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <template v-if="value.actionType === 'open_card' && this.listField.length">
      <editor-open-card :value="value" :isTreeSelect="true" :listField="listField" :isButton='false'></editor-open-card>
    </template>
    <template v-if="value.actionType === 'open_card' && value.type === 'requests'">
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.registry_id">
        <el-input type="text" size="small" v-model="value.card.registryId"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.card_id">
        <el-input type="text" size="small" v-model="value.card.cardId"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.field_id">
        <el-input type="text" size="small" v-model="value.card.fieldId"></el-input>
      </el-form-item>
    </template>
    <template v-if="value.actionType === 'open_dashboard'">
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.dashbord">
        <editor-dashboards @change="$set(value.dashboard ,'id', $event)" :value="value.dashboard.id" ></editor-dashboards>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.window_title">
        <el-input v-model="value.dashboard.window_title"/>
      </el-form-item>
      <el-form-item class="checbox" :label="$locale.etl_editor.tooltip.full_screen">
        <el-checkbox v-model="value.dashboard.isFullscreen"></el-checkbox>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.window_width">
        <editor-select
          :value="value.dashboard.window_width"
          @change="$set(value.dashboard ,'window_width', $event)"
          :options="{
            multiple: false,
            options: [
              { id: '25', name:'25%' },
              { id: '50', name:'50%' },
              { id: '75', name:'75%' },
              { id: '100', name:'100%' },
            ]
          }"
        ></editor-select>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
import EditorOpenCard from './editor-open-card'
import EditorDashboards from './editor-dashboards'
import EditorRegistrySelect from './editor-registry-select'
import Registry from '@/components/LogicEditor/Models/Registry'
import SelectTree from '@/components/LogicEditor/components/treeSelect'
export default {
  name: 'editor-calendar',
  props: {
    value: {
      type: Object
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  components: {
    SelectTree,
    EditorSelect,
    EditorOpenCard,
    EditorDashboards,
    EditorRegistrySelect
  },
  data () {
    return {
      listField: [],
      requests: [],
      extendObjects: [],
      dialogSettingsVisible: false,
      controlsViews: [
        {
          id: 'years',
          name: this.$locale.interface_editor.component_editor.editor_calendar.years
        },
        {
          id: 'year',
          name: this.$locale.interface_editor.component_editor.editor_calendar.year
        },
        {
          id: 'month',
          name: this.$locale.interface_editor.component_editor.editor_calendar.month
        },
        {
          id: 'week',
          name: this.$locale.interface_editor.component_editor.editor_calendar.week
        },
        {
          id: 'day',
          name: this.$locale.interface_editor.component_editor.editor_calendar.day
        }
      ]
    }
  },
  methods: {
    async getRequests () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/queries`)
      return response.data
    },
    async getExtendObjects (id = null) {
      if (id) {
        let listField = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${id}`)
        return listField.data.map((el) => ({ id: el.id, name: el.name }))
      }
      let extendObjects = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
      return extendObjects.data
    }
  },
  watch: {
    'value.registryId': {
      async handler (id) {
        if (id) {
          let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
          this.listField = registryTree.data
        }
      },
      immediate: true
    },
    'value.extendObject': {
      async handler (id) {
        if (id) {
          this.listField = await this.getExtendObjects(id)
        }
      },
      immediate: true
    },
    'value.type': {
      async handler (val) {
        this.$set(this.value, 'filters', [])
        this.$set(this.value, 'extendObject', null)
        this.$set(this.value, 'registryId', null)
        this.$set(this.value, 'requestsId', null)
        this.value.events.start = this.value.events.end = this.value.events.title = this.value.events.content = this.value.events.class = null
        this.listField = []
        this.requests = []
        if (val === 'requests') {
          this.requests = await this.getRequests()
        }
        if (val === 'extend_object') {
          this.extendObjects = await this.getExtendObjects()
        }
      }
    },
    'value.actionType': {
      handler (value) {
        if (value === 'open_dashboard') {
          this.$set(this.value, 'dashboard', {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null
          })
        } else if (value === 'open_card') {
          this.$set(this.value, 'card', {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          })
        }
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.value.requestsId) {
      this.requests = await this.getRequests()
    }
    if (this.value.extendObject) {
      this.extendObjects = await this.getExtendObjects()
    }
  }
}
</script>

<style lang="scss">
.editor_calendar .el-form-item__label {
  padding: 0px;
}
.editor_calendar .el-form-item {
  margin-bottom: 10px;
}
.editor_calendar .checbox .el-form-item__content {
  display: inline-block;
  margin-left: 10px;
}
</style>
