import { MutationTree } from 'vuex'
import { UserState, initialState } from './state'
import { MutationTypes, UserCreatedEvent, UserUpdatedEvent, UserDeletedEvent } from './mutation-types'

export const mutations: MutationTree<UserState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.USER_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.USER_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.USER_CREATED_EVENT]: (state, action: UserCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.USER_UPDATED_EVENT]: (state, action: UserUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.USER_DELETED_EVENT]: (state, action: UserDeletedEvent) => {
    state.isLoading = false;
  }
}