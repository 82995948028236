<template>
  <el-input-number
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :style="{ width: '100%' }"
    :placeholder="placeholder"
  ></el-input-number>
</template>

<script>
export default {
  name: 'NumberInput',

  props: ['value', 'placeholder']
}
</script>

<style type="text/css"></style>
