<template>
  <el-popover trigger="click" placement="top">
    <registry-select-tree
      v-model="localValue"
      @input="$emit('change', localValue)"
      type="registry"
      value-as="number">
    </registry-select-tree>
    <el-button type="text" slot="reference">{{ $locale.main.button.edit }}</el-button>
  </el-popover>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
export default {
  props: ['value'],
  components: {
    RegistrySelectTree
  },
  name: 'editor-registry-select',
  data () {
    return {
      localValue: this.value
    }
  }
}
</script>

<style scoped>

</style>
