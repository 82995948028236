import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    USER_LOADING_EVENT = 'user_loading_event',
    USER_LOADED_EVENT = 'user_loaded_event',
    USER_CREATED_EVENT = 'user_created_event',
    USER_UPDATED_EVENT = 'user_updated_event',
    USER_DELETED_EVENT = 'user_deleted_event' 
}

export class UserLoadingEvent implements FluxStandardAction {
    type = MutationTypes.USER_LOADING_EVENT
}

export class UserLoadedEvent implements FluxStandardAction {
    type = MutationTypes.USER_LOADED_EVENT
}

export class UserCreatedEvent implements FluxStandardAction {
    type = MutationTypes.USER_CREATED_EVENT
    constructor(public location: string){}
}

export class UserUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.USER_UPDATED_EVENT
}

export class UserDeletedEvent implements FluxStandardAction {
    type = MutationTypes.USER_DELETED_EVENT
    constructor(public guid: string){}
}