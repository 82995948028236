<template>
  <div class="loginPage_wrapper" :class="`${project}_background`">
    <el-card class="loginPage_box">
      <div class="project_logo">
        <component :is="component"/>
      </div>
      <div class="loginPage_title">{{ $locale.login.title }}</div>
      <el-form :rules="rules" :model="model" ref="form" @submit.native.prevent="submit">
        <el-form-item prop="login">
          <el-input
            v-model="model.login"
            class="loginPage_input"
            autocomplete="off"
            focus
            :placeholder="$locale.access_editor.users_item.login"
            ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="model.password"
            class="loginPage_input"
            type="password"
            autocomplete="off"
            :placeholder="$locale.access_editor.users_item.password"
          ></el-input>
        </el-form-item>
          <el-checkbox v-if="ldap" v-model="model.ldap">{{$locale.login.ldap}}</el-checkbox>
          <div style="margin-top: 10px">
            <el-link v-if="esia" @click="esiaLogin" type="primary">{{$locale.login.esia}}</el-link>
          </div>
        <el-form-item>
          <el-button
            :loading="loading"
            class="loginPage_btn"
            native-type="submit"
          >{{ $locale.login.submit }}</el-button>
        </el-form-item>
      </el-form>
      <el-alert v-show="error" :title="error" type="error" :closable="false" show-icon></el-alert>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'login-page',
  data () {
    return {
      model: {
        login: null,
        password: null,
        ldap: !!this.$config.ldap_enabled
      },
      error: null,
      loading: false,
      component: null,
      rules: {
        login: {
          required: true,
          message: this.$locale.login.errors.login_required,
          trigger: 'change'
        },
        password: {
          required: true,
          message: this.$locale.login.errors.password_required,
          trigger: 'change'
        }
      }
    }
  },
  computed: {
    project () {
      return this.$config.project
    },
    esia () {
      return this.$config.esia_enabled
    },
    ldap () {
      return this.$config.ldap_enabled
    }
  },
  methods: {
    esiaLogin () {
      this.$store.dispatch(`Authorization/EsiaLogin`)
    },
    submit () {
      this.error = null
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const { login, password, ldap } = this.model
          let type = ldap ? 'LDAP' : 'default'
          this.$store.dispatch(`Authorization/${type}Login`, { login, password, ldap })
            .then((res) => {
              this.$router.push('/')
            })
            .catch(() => {
              this.loading = false
              this.error = this.$locale.login.errors.wrong_user
            })
        } else {
          this.loading = false
        }
      })
    }
  },
  async mounted () {
    console.log(this.$config.project)
    try {
      await import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
      this.component = () => import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
    } catch (e) {
      try {
        await import(`@/components/LoginPage/${this.$config.project}`)
        this.component = () => import(`@/components/LoginPage/${this.$config.project}`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
.loginPage_wrapper {
  display: flex;
  height: 100%;
  background: #e5e5e5;
}
.loginPage_box {
  margin: auto;
  width: 380px;
  height: auto;
}
.loginPage_logo {
  height: 68px;
}
.loginPage_title {
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  color: #2c2d35;
}
.loginPage_btn {
  width: 100%;
  margin-top: 28px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  background: #2139C4;
  line-height: 25px;
}
.loginPage_btn:hover,
.loginPage_btn:active,
.loginPage_btn:focus {
  background: #2139C4;
    color: #ffffff;
}
.loginPage_input .el-input__inner{
  height: 50px;
  font-size: 15px;
  line-height: 50px;
}
.project_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  /* background: #F4F5F6; */
}
.project_title {
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  color: #004099;
}
.PublicHearing_background {
  background: url(./PublicHearing/resources/background.jpg) no-repeat;
}
.Rgis_background {
  background: url(./Rgis/resources/background.jpg) no-repeat;
  background-size: cover;
}
</style>
