<template>
  <div>
    <h1 style="margin-top: 10px">{{$locale.logic_editor.command.headline}}</h1>
    <div class="commands__item2">
      <div class="demonstration">{{$locale.menu_editor.label.name}}</div>
      <el-input size="mini" class="commands__item" v-model="form.name"></el-input>
    </div>
    <div class="commands__item2">
      <div class="demonstration">{{$locale.logic_editor.command.condition}}</div>
      <el-select
        multiple
        collapse-tags
        value-key="id"
        class="commands__item"
        v-model="form.command_states"
        size="mini"
      >
        <el-option v-for="item in listState" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="commands__item2">
      <el-row class="commands__mb">
        <el-col :span="24">
          <el-checkbox v-model="form.is_plugin">{{$locale.logic_editor.command.plugin}}</el-checkbox>
        </el-col>
      </el-row>
    </div>
    <div v-show="form.is_plugin" class="commands__item2">
      <div class="demonstration">{{$locale.logic_editor.command.list_plugins}}</div>
      <el-select
        value-key="id"
        class="commands__row"
        v-model="form.plugin_id"
        :placeholder="this.$locale.logic_editor.command.list_plugins"
        size="mini"
      >
        <el-option v-for="item in listPlugin" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div v-show="!form.is_plugin">
        <div class="commands__item2">
          <div class="demonstration">{{$locale.logic_editor.command.event}}</div>
          <el-select value-key="id" class="commands__item" v-model="form.event_id" size="mini">
            <el-option v-for="item in listEvent" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      <div class="commands__item2 " v-if="form.event_id === 'update_external_records_event'">
        <div class="demonstration">{{$locale.logic_editor.command.external_state}}</div>
        <el-select
          value-key="id"
          class="commands__item"
          multiple
          v-model="form.command_external_states"
          size="mini"
        >
          <el-option-group
            v-for="(group, index) in Array.from(new Set(allStates.map(item => item.registry)))"
            :key="index"
            :label="group">
            <el-option
              v-for="item in allStates.filter(item => item.registry === group)"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="commands__item2 " v-if="form.event_id !== 'create_report_event'">
          <div class="demonstration">{{$locale.logic_editor.command.state}}</div>
          <el-select
            v-if="form.event_id === 'add_external_record_event' || form.event_id === 'update_external_records_event'"
            value-key="id"
            class="commands__item"
            v-model="form.target_state_id"
            size="mini"
          >
            <el-option-group
              v-for="(group, index) in Array.from(new Set(allStates.map(item => item.registry)))"
              :key="index"
              :label="group">
              <el-option
                v-for="item in allStates.filter(item => item.registry === group)"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
          <el-select
            v-else
            value-key="id"
            class="commands__item"
            v-model="form.target_state_id"
            size="mini"
          >
            <el-option
              v-for="item in listTargetState"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      <div class="commands__item2" v-else>
        <create-report-event
          :properties="propslocal.getAnswer.properties || undefined"
          @input="$set(form, 'properties', $event)"
        ></create-report-event>
      </div>
    </div>
    <el-footer class="commands_footer">
      <el-button
        @click="saveCard"
        :disabled='form.name === ""'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
  </div>
</template>

<script>
import Plugins from '../Models/Plugins'
import Events from '../Models/Events'
import States from '../Models/States'
import Commands from '../Models/Commands'
import CreateReportEvent from '@/components/LogicEditor/components/CommandProperties/CreateReportEvent'
export default {
  name: 'command',
  components: { CreateReportEvent },
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  data () {
    return {
      addAction: this.propslocal.listOpenAction,
      form: {
        name: '',
        command_states: [],
        command_external_states: [],
        is_plugin: false,
        event_id: null,
        plugin_id: null,
        target_state_id: null,
        logic_id: this.propslocal.businesslogic.id,
        properties: {}
      },
      listState: [],
      listPlugin: [],
      listEvent: [],
      listTargetState: [],
      allStates: []
    }
  },
  methods: {
    async saveCard () {
      // новое subAction
      if (this.propslocal.isNewAction) {
        let commands = new Commands({}, this.form)
        if (this.form.is_plugin) {
          commands.event_id = null
          commands.target_state_id = null
        }
        let response = await commands.save()
        this.addAction.items.push(response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let commands = new Commands({ id: id }, this.form)
        if (this.form.is_plugin) {
          commands.event_id = null
          commands.target_state_id = null
        }
        let response = await commands.save()
        this.addAction.items
          ? this.addAction.items.filter(el => el.id === id)[0].name = response.name
          : this.addAction.filter(el => el.id === id)[0].name = response.name
      }
      this.propslocal.isNewAction = false
    }
  },
  async mounted () {
    let states = await States.params({}).$get()
    let events = await Events.get()
    let plugins = await Plugins.get()
    this.allStates = states
    this.listState = states.filter((item) => item.logic_id === this.propslocal.businesslogic.id)
    this.listEvent = events.map(el => ({
      id: el.id,
      name: this.$locale.logic_editor.listeners.translate[el.name]
    }))
    this.listPlugin = plugins
    this.listTargetState = states.filter((item) => item.logic_id === this.propslocal.businesslogic.id)
    // редактирование
    if (!this.propslocal.isNewAction) this.form = this.propslocal.getAnswer
  }
}
</script>

<style scoped>
.commands__mt {
  margin-top: 50px;
}
.commands__mb {
  margin-bottom: 20px;
}
.commands__row {
  margin-bottom: 25px;
  margin-right: 25px;
  width: 35%;
}
.commands_footer {
  height: 60px;
  text-align: right;
  margin-top: 25px;
}
.commands__item2 {
  margin-top: 10px;
  width: 65%;
}
.command__mt {
  margin-top: 0px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.commands__item {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
</style>
