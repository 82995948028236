import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import XmlRouteQueryRepositoryInterface from '../../../domain/repository/XmlRouteQueryRepositoryInterface'
import XmlRoutesCountQuery from '../../query/XmlRoutesCountQuery'

export default class XmlRoutesCountHandler implements QueryHandlerInterface
{
    private repo: XmlRouteQueryRepositoryInterface;

    constructor(repo: XmlRouteQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: XmlRoutesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}