<template>
  <div class="element_editor_layout">
    <div v-if="form" class="tool_box">
      <div class="tool_header">
        {{ title }}
      </div>
    </div>

    <el-scrollbar style="height: calc(100vh - 222px);">
      <el-main v-if="form">
        <component
          ref="form"
          v-model="value"
          :is="form" />
      </el-main>
    </el-scrollbar>

    <el-main v-if="form">
      <slot></slot>
    </el-main>
  </div>
</template>

<script>
import RuleForm from './forms/RuleForm'
import RuleGroupForm from './forms/RuleGroupForm'
export default {
  name: 'EditorElementLayout',

  components: {
    RuleForm,
    RuleGroupForm
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    save: {
      type: Function,
      default: function () {
      }
    }
  },

  methods: {
    async submit () {
      let response = null
      this.$refs.form.submit(() => {
        response = this.value.save()
      })
      return response
    }
  }
}
</script>

<style type="text/css">
  .element_editor_layout .el-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
