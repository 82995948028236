export interface XmlRouteState {
    isLoading: boolean;
    location: string;
    error: object;
  }
  
  export const initialState = (): XmlRouteState => {
    return {
      isLoading: false,
      location: null,
      error: null
    }
  }