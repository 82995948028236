<template>
  <el-container>
    <el-header height="auto">
      <h1>{{$locale.logic_editor.state.headline}}</h1>
    </el-header>
    <el-main>
      <el-row :gutter="10">
       <el-col :span="24">
         <el-form ref="form" :model="formStates" size="mini">
           <el-row :gutter="10">
             <el-col :span="10">
               <el-form-item>
                 <el-input type="text" v-model="formStates.name"></el-input>
               </el-form-item>
             </el-col>
             <el-col :span="10">
               <el-form-item>
                 <el-select
                   v-model="formStates.isComplex"
                   :placeholder="$locale.logic_editor.state.state_type"
                   @change="changeIsComplex"
                   style="width: 100%;">
                   <el-option :value="false" :label="$locale.logic_editor.state.state_types.simple"></el-option>
                   <el-option :value="true" :label="$locale.logic_editor.state.state_types.hard"></el-option>
                 </el-select>
               </el-form-item>
             </el-col>
             <el-col :span="4">
               <el-form-item>
                 <el-button
                   style="width: 100%;"
                   @click="saveNameStates"
                   :disabled='formStates.name === ""'
                   type="primary">
                   {{ $locale.main.button.save }}
                 </el-button>
               </el-form-item>
             </el-col>
           </el-row>
         </el-form>
       </el-col>
      </el-row>
      <el-row :gutter="10">
       <el-col :span="24">
         <template v-if="isVisibleComponents && !formStates.isComplex">
           <el-tooltip :content="$locale.logic_editor.state.btn_add" placement="bottom">
             <el-button @click="addLine" type="success" size="small" icon="el-icon-plus" circle style="margin-bottom: 15px;"></el-button>
           </el-tooltip>
           <el-scrollbar wrap-style="height: calc(95vh - 250px);">
             <ExtraLine
               v-for="(item, index) in numberComponent"
               :key="item.id"
               :ref="index"
               :localAnswer="localAnswer[index]"
               :isEdit="item.isEdit"
               :propslocal="propslocal"
               :listField="listField"
               :listFiledTypes="listFiledTypes"
             >
               <el-tooltip v-if="!item.isEdit" :content="$locale.main.button.save" placement="bottom">
                 <el-button
                   @click="saveField(item, index)"
                   size="small"
                   type="success"
                   icon="el-icon-check"
                   circle
                 ></el-button>
               </el-tooltip>
               <el-tooltip v-else :content="$locale.main.button.edit" placement="bottom">
                 <el-button
                   @click="item.isEdit = !item.isEdit"
                   type="primary"
                   size="small"
                   icon="el-icon-edit"
                   circle
                 ></el-button>
               </el-tooltip>
               <el-tooltip :content="$locale.main.button.delete" placement="bottom">
                 <el-button
                   @click="remove(item, index)"
                   type="danger"
                   size="small"
                   icon="el-icon-close"
                   circle
                 ></el-button>
               </el-tooltip>
             </ExtraLine>
           </el-scrollbar>
         </template>
         <template v-else-if="isComplex">
           <el-scrollbar wrap-style="height: calc(100vh - 260px);">
             <el-form label-position="top" ref="form" size="mini">
               <query-builder
                 :key="propslocal.getAnswer.guid"
                 :fields.sync="qbFields"
                 :field-type-to-operation-type="qbOperationEntityMapping"
                 :operation-types="qbOperationTypes"
                 v-model="stateFilters">
               </query-builder>
             </el-form>
           </el-scrollbar>
         </template>
       </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import ExtraLine from './elementStates/extraLine'
import States from '../Models/States'
import Logic from '../Models/Logic'
import Registry from '../Models/Registry'
import QueryBuilder from '../../Common/QueryBuilder/index'

export default {
  components: {
    ExtraLine,
    QueryBuilder
  },
  name: 'states',
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  computed: {
    isComplex () {
      return this.formStates.isComplex &&
        (
          this.qbOperationTypes.length > 0 &&
          Object.keys(this.qbOperationEntityMapping).length > 0
        )
    }
  },
  data () {
    return {
      // открытое действие
      addAction: this.propslocal.listOpenAction,
      // список полей
      listField: [],
      // список полей
      listFiledTypes: [],
      formStates: {
        name: '',
        isComplex: false,
        logic_id: this.propslocal.businesslogic.id,
        state_fields: [],
        properties: []
      },
      // количество компонентов
      numberComponent: [],
      localAnswer: [],
      isVisibleComponents: false,
      stateFilters: {
        logical_operator: 'and',
        children: []
      },
      qbFields: [],
      qbRules: [],
      qbOperationTypes: [],
      qbOperationEntityMapping: {}
    }
  },
  methods: {
    changeIsComplex (value) {
      if (value === true && this.qbFields.length < 1) {
        this.qbFields = this.buildFieldList(this.listField)
      }
    },
    buildFieldList (fields) {
      let fieldList = []
      fields.forEach((field) => {
        if ([
          'string_field', 'text_field', 'integer_field',
          'float_field', 'date_field', 'time_field',
          'datetime_field', 'boolean_field', 'xref_field',
          'xref_multi_field', 'field_group'
        ].indexOf(field.entity_type_id) !== -1) {
          let qbField = {
            id: `attr_${field.id}_`,
            fieldId: field.id,
            name: field.name,
            fieldTypeId: field.entity_type_id,
            children: []
          }

          if (field.entity_type_id === 'field_group' && field.children.length) {
            qbField.children.push(...this.buildFieldList(field.children))
          }

          fieldList.push(qbField)
        }
      })
      return fieldList
    },
    // добавить компонент
    addLine () {
      this.numberComponent.push({ isNew: true, isEdit: false })
    },
    // удалить выбранный компнонент
    remove (item, index) {
      console.log(this.numberComponent[index].id)
      if (this.numberComponent[index].id === undefined) {
        this.numberComponent.splice(index, 1)
      } else {
        this.$confirm(
          this.$locale.main.message.confirm,
          this.$locale.main.message.attention,
          {
            confirmButtonText: this.$locale.main.button.delete,
            cancelButtonText: this.$locale.main.button.cancel,
            type: 'warning'
          })
          .then(() => {
            this.$http.delete(`${this.$config.api}/logiceditor/fields/${this.numberComponent[index].id}`)
            const ind = this.numberComponent.findIndex(el => el.id === this.numberComponent[index].id)
            const indexID = this.localAnswer.findIndex(el => el.id === this.numberComponent[index].id)
            if (indexID >= 0) this.localAnswer.splice(indexID, 1)
            if (ind >= 0) this.numberComponent.splice(ind, 1)
          })
      }
    },
    async saveNameStates () {
      this.isVisibleComponents = true
      if (this.propslocal.isNewAction) {
        let state = new States({})
        state.name = this.formStates.name
        state.logic_id = this.formStates.logic_id
        state.is_complex = this.formStates.isComplex
        state.properties = {}
        state.properties.filters = this.stateFilters
        let response = await state.save()
        this.addAction.items.push(response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let state = await States.find(id)
        state.name = this.formStates.name
        state.logic_id = this.formStates.logic_id
        state.is_complex = this.formStates.isComplex
        if (state.properties === null || typeof state.properties !== 'object') state.properties = {}
        state.properties.filters = this.stateFilters
        let response = await state.save()
        console.log(response)
        this.addAction.items
          ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
          : (this.addAction.filter(el => el.id === id)[0].name = response.name)
      }
      this.propslocal.isNewAction = false
    },
    saveField (item, index) {
      if (item.isNew) {
        console.log('new')
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let date = this.$refs[index][0].getValue()
        date.state_id = id
        this.$http.post(`${this.$config.api}/logiceditor/fields`, date)
          .then(res => {
            if (item.state_id === undefined) {
              this.$set(this.numberComponent[index], 'id', res.data.id)
              this.localAnswer.push(res.data)
              // this.numberComponent[index] = Object.assign({}, this.numberComponent[index], { id: res.data.id })
            }
            // this.numberComponent[index] = this.$set(this.numberComponent[index], { ...res.data })
            // this.numberComponent[index] = Object.assign({}, this.numberComponent[index], { ...res.data })
          })
      } else {
        console.log('edit')
        this.$http.put(`${this.$config.api}/logiceditor/fields/${item.id}`, this.$refs[index][0].getValue())
      }
      item.isEdit = !item.isEdit
      this.$set(item, 'isNew', false)
    }
  },
  async mounted () {
    let id = this.propslocal.businesslogic.registry_id
    try {
      if (!Object.keys(this.qbOperationEntityMapping).length) {
        await this.$http.get(`${this.$config.api}/logiceditor/states/operation_entity_mappings`)
          .then(response => {
            this.qbOperationEntityMapping = response.data
          })
          .catch(error => console.log(error))
      }
      if (!this.qbOperationTypes.length) {
        await this.$http.get(`${this.$config.api}/logiceditor/states/operation_types`)
          .then(response => {
            this.qbOperationTypes = response.data
          })
          .catch(error => console.log(error))
      }
      // список полей реестра ввиде дерева
      // список типов состояний
      let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
      let logic = await Logic.custom(`fields/types`).get()
      let stateFields = this.$locale.logic_editor.state.state_fields
      let obj = logic.map((el, index) => ({ id: el.id, name: stateFields[el.name] }))
      this.listField = registryTree.data
      this.listFiledTypes = obj
    } catch (error) {
      console.log(error)
    }
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.isVisibleComponents = true
      let getAnswer = this.propslocal.getAnswer
      this.formStates.name = this.propslocal.getAnswer.name
      this.formStates.isComplex = this.propslocal.getAnswer.is_complex
      this.changeIsComplex(this.formStates.isComplex)
      if (this.propslocal.getAnswer.properties !== null) {
        this.stateFilters = typeof this.propslocal.getAnswer.properties.filters !== 'undefined' ? this.propslocal.getAnswer.properties.filters : this.stateFilters
      }
      this.numberComponent = []
      if (getAnswer.state_fields.length > 0) {
        for (let i = 0; i < getAnswer.state_fields.length; i++) {
          this.numberComponent.push({
            id: getAnswer.state_fields[i].id,
            isNew: false,
            isEdit: true
          })
        }
        getAnswer.state_fields.forEach(el => {
          this.localAnswer.push(el)
        })
      }
    }
  }
}
</script>

<style scoped>
.el-row {
  margin-top: 20px;
}
.states__item2 {
  margin-top: 30px;
  /* width: 96%; */
  margin-right: 41px;
}
.states__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.state_name {
  display: flex;
  align-items: baseline;
}
</style>
