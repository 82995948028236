export default {
  methods: {
    checkConditions (data, sourceRegistry = false, fields = {}) {
      if (!data || data.type === 'never') {
        return false
      } else if (data.type === 'always') {
        return true
      }
      let result = false
      data.conditions.forEach((condition) => {
        if (!condition.attribute || !condition.type) {
          return false
        }
        let conditionResult = false
        let attribute
        if (sourceRegistry) {
          attribute = fields[`attr_${condition.attribute}_`]
        } else {
          attribute = this.getModel()[condition.attribute]
        }
        try {
          attribute = JSON.parse(attribute)
        } catch (e) {

        }
        if (attribute instanceof Array) {
          attribute = attribute.map(item => item.name).join(',')
        }
        let value = condition.value
        if (value === 'user_id') {
          value = this.$store.getters['Authorization/userId']
        }
        switch (condition.type) {
          case 'equals':
            if (attribute == value) {
              conditionResult = true
            }
            break
          case 'not_equals':
            if (attribute != value) {
              conditionResult = true
            }
            break
          case 'is_null':
            if (
              attribute === null ||
              typeof attribute === 'undefined' ||
              attribute === ''
            ) {
              conditionResult = true
            }
            break
          case 'is_not_null':
            if (
              attribute !== null &&
              typeof attribute !== 'undefined' &&
              attribute !== ''
            ) {
              conditionResult = true
            }
            break
          default:
            break
        }
        condition.result = conditionResult
      })

      if (data.condition_type === 'and') {
        if (data.conditions.length === data.conditions.filter(item => item.result).length) {
          result = true
        }
      } else if (data.condition_type === 'or') {
        if (data.conditions.find(item => item.result)) {
          result = true
        }
      }

      return result
    }
  }
}
