<template>
  <div>
    <components
      class="extraline__wdt"
      v-if="chooseFiled && chooseFiled.entity_type_id"
      @input="postValue"
      :ref="chooseFiled.entity_type_id"
      :state="state"
      :isEdit="isEdit"
      :listField="listField"
      :chooseFiled="chooseFiled"
      :is="toCamelCase(chooseFiled.entity_type_id)"
    ></components>
  </div>
</template>

<script>
import integerField from '../elementStates/elementForConstant/integerField'
import dateField from '../elementStates/elementForConstant/dateField'
import datetimeField from '../elementStates/elementForConstant/datetimeField'
import booleanField from '../elementStates/elementForConstant/booleanField'
import floatField from '../elementStates/elementForConstant/floatField'
import stringField from '../elementStates/elementForConstant/stringField'
import textField from '../elementStates/elementForConstant/textField'
import timeField from '../elementStates/elementForConstant/timeField'
import xrefField from '../elementStates/elementForConstant/xrefField'
import xrefMultiField from '../elementStates/elementForConstant/xrefMultiField'
export default {
  name: 'constant',
  props: {
    // выбранное поле
    chooseFiled: {},
    state: {
      type: Object
    },
    listField: {
      type: Array
    },
    isEdit: {
      type: Boolean
    }
  },
  components: {
    integerField,
    dateField,
    datetimeField,
    booleanField,
    floatField,
    stringField,
    textField,
    timeField,
    xrefField,
    xrefMultiField
  },
  data () {
    return {
      localValue: null
    }
  },
  methods: {
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/gi, $1 => {
        return $1
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    postValue (val) {
      // console.log('getValue', val)
      this.localValue = val
    },
    getValue (val) {
      return this.localValue
    }
  },
  mounted () {
    if (this.state && this.state.properties) {
      this.localValue =
        this.state.properties && this.state.properties.value
          ? this.state.properties.value
          : ''
    }
  }
}
</script>

<style scoped>
.extraline__wdt {
  width: 100%;
}
</style>
