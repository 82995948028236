<template>
  <div :style="`width:100%;height: 100%;display: flex;overflow: hidden;${componentStyle}`">
   <div v-for="(field, index) in resultFields" :key="index" :style="`background-color: ${field.color}; width: ${field.percent}%; height: 100%; padding: 0px; margin: 0px;`"></div>
  </div>
</template>

<script>
import properties from './mixins/properties'
export default {
  name: 'indicators-block',
  mixins: [properties],
  props: {
    componentStyle: {
      type: String,
      default: '',
      description: 'Стили'
    },
    fields: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'IndicatorFieldsEditor'
    }
  },
  data () {
    return {
      resultFields: []
    }
  },
  mounted () {
    let result = []
    let sum = 0
    this.fields.forEach((item) => {
      sum = sum + parseFloat(this.data[item.field] || 0)
    })

    this.fields.map((item) => {
      return Object.assign(item, {
        data: parseFloat(this.data[item.field])
      })
    }).forEach((item) => {
      result.push(
        {
          color: item.color,
          percent: sum / 100 * item.data
        }
      )
    })

    this.resultFields = result
  }
}
</script>

<style scoped>

</style>
