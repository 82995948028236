<template>
  <el-scrollbar wrap-style="height: calc(100vh - 132px);" class="approvals_container">
    <h1>{{$locale.logic_editor.routes.headline}}</h1>
    <div class="extraAccordion">
      <el-row>
        <el-col :sm="20" :md="20" :lg="22" :xl="22">
          <div class="extraAccordion_block__item2">
            <div class="demonstration">{{$locale.menu_editor.label.name}}</div>
            <el-input size="mini" class="extraAccordion__row" v-model="form.name"></el-input>
          </div>
          <div class="extraAccordion_block__item2">
            <div class="demonstration">{{$locale.logic_editor.routes.condition}}</div>
            <el-select
              multiple
              collapse-tags
              value-key="id"
              class="extraAccordion__row"
              v-model="form.approval_states"
              size="mini"
            >
              <el-option
                v-for="item in listStateBusinesslogic"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <el-row type="flex" justify="end">
            <el-col :sm="4" :md="4" :lg="2" :xl="2">
              <el-button
                @click="saveCard"
                :disabled="form.name === ''"
                type="primary"
              >{{ $locale.main.button.save }}</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-main v-show="isVisible">
      <el-row>
        <el-col :span="24" class="approval_wrapper">
          <el-tooltip :content="$locale.logic_editor.routes.add_stage" placement="bottom">
            <el-button @click="addLine" size="small" icon="el-icon-plus" circle></el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-collapse v-show="numberComponent.length > 0" v-model="activeName" accordion>
        <ExtraAccordion
          style="position: relative"
          v-for="(item, index) in numberComponent"
          :ref="item"
          :key="item"
          :ind="index"
          :localAnswer="localAnswer[index]"
          :propslocal="propslocal"
          :propsFromAppravals="propsFromAppravals"
          @changeListStages="changeListStages = !changeListStages"
        >
          <el-button
            type="danger"
            @click="remove(item, index)"
            plain
            icon="el-icon-delete"
          >{{ $locale.main.button.delete }}</el-button>
          <!-- <el-tooltip :content="$locale.logic_editor.tooltip_delete" placement="bottom">
            <el-button
              @click="remove(item, index)"
              style="font-size: 9px; position: absolute; top: 10px"
              size="small"
              type="danger"
              icon="el-icon-minus"
              circle
            ></el-button>
          </el-tooltip>-->
        </ExtraAccordion>
      </el-collapse>
    </el-main>
    <el-footer class="logitEditor_footer"></el-footer>
  </el-scrollbar>
</template>

<script>
import ExtraAccordion from '../components/elementApprovals/ExtraAccordion'
import Approvals from '../Models/Approvals'
import Stages from '../Models/Stages'
import States from '../Models/States'
import Logic from '../Models/Logic'
import Users from '../Models/accesseditor/Users'
import Commands from '../Models/Commands'
import Registry from '@/components/LogicEditor/Models/Registry'
export default {
  name: 'approvals',
  components: {
    ExtraAccordion
  },
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  watch: {
    // изменение этапа
    changeListStages: {
      handler: async function (val, oldval) {
        this.propsFromAppravals.approvalId = this.propslocal.selectSubAction.id || this.addAction.items[this.addAction.items.length - 1].id
        console.log('changeListStages approvalId', this.propsFromAppravals.approvalId)
        await Stages.params({ approval_id: this.propsFromAppravals.approvalId })
        // await Stages.params({ approval_id: this.propslocal.selectSubAction.id })
          // .orderBy('id:asc')
          .get()
          .then(response => {
            this.propsFromAppravals.entityStages = response
            this.localAnswer = []
            this.numberComponent = []
            // кол-во компонентов (этапов)
            for (let i = 0; i < this.propsFromAppravals.entityStages.length; i++) {
              this.numberComponent.push(this.propsFromAppravals.entityStages[i].id)
              this.localAnswer.push(this.propsFromAppravals.entityStages[i])
            }
          })
        // const id = this.propslocal.selectSubAction.id
        // let listStages = await Stages.params({ approval_id: id }).orderBy('id:asc').get()
        // this.propsFromAppravals.entityStages = listStages
        // this.localAnswer = listStages
      }
    }
  },
  data () {
    return {
      propsFromAppravals: {
        // список состояний вокруг таблицы сотрудников
        listStatesAroundUser: [],
        //  список полей текущего реестра ссылающихся на  таблицу сотрудников
        listFieldsAroundUser: [],
        // список этапов
        entityStages: [],
        // список сотрудников
        listEmployees: [],
        // список команды
        listCommand: [],
        // список кнопок
        listBtns: [],
        // id настраиваемого этапа
        approvalId: null
      },
      isVisible: false,
      changeListStages: false,
      activeName: '',
      addAction: this.propslocal.listOpenAction,
      form: {
        approval_states: [],
        name: '',
        logic_id: this.propslocal.businesslogic.id
      },
      numberComponent: [],
      // список состояний из текущей бизнес логики
      listStateBusinesslogic: [],
      localAnswer: []
    }
  },
  methods: {
    // удалить выбранный компнонент
    async remove (item, index) {
      this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
        .then(async () => {
          const ind = this.numberComponent.indexOf(item)
          this.numberComponent.splice(ind, 1)
          this.activeName = ''
          let stages = new Stages({ id: item })
          await stages.delete()
          this.changeListStages = !this.changeListStages
          let indexID = this.localAnswer.findIndex(el => el.id === item)
          if (indexID) this.localAnswer.splice(indexID, 1)
        })
    },
    // добавить компонент
    async addLine () {
      // список этапов
      this.propsFromAppravals.approvalId = this.propslocal.selectSubAction.id || this.addAction.items[this.addAction.items.length - 1].id
      console.log('approvalId', this.propsFromAppravals.approvalId)
      let array = await Stages.params({ approval_id: this.propsFromAppravals.approvalId })
              // .orderBy('id:asc')
        .get()
      if (array.length) {
        this.numberComponent.push(array[array.length - 1].id + 1)
      } else {
        this.numberComponent.push(this.numberComponent.length + 1)
      }
      this.activeName = this.numberComponent.length - 1
    },
    async saveCard () {
      this.isVisible = true
      // новое subAction
      if (this.propslocal.isNewAction) {
        let approvals = new Approvals({}, this.form)
        let response = await approvals.save()
        console.log(response)
        this.addAction.items.push(response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let approvals = new Approvals({ id: id }, this.form)
        let response = await approvals.save()
        console.log(response)
        // this.addAction.filter(el => el.id === id)[0].name = response.name
        this.addAction.items
          ? this.addAction.items.filter(el => el.id === id)[0].name = response.name
          : this.addAction.filter(el => el.id === id)[0].name = response.name
      }
      this.propslocal.isNewAction = false
    }
  },
  async mounted () {
    // id реестра
    let id = this.propslocal.businesslogic.registry_id
    // id бизнес логики
    let idBusinesslogic = this.propslocal.businesslogic.id
    // список состояний из текущей бизнес логики
    let states = await States.params({ logic_id: idBusinesslogic }).get()
    this.listStateBusinesslogic = states
    // список состояний вокруг таблицы сотрудников
    this.$http
      .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true`)
      .then(response => response.data.data[0].id)
      .then(async id => {
        let logicData = await new Logic().custom('/logics').params({ registry_id: id }).get()
        if (logicData[0]) {
          let states = await States.params({ logic_id: logicData[0].id }).get()
          this.propsFromAppravals.listStatesAroundUser = states
        } else {
          console.warn(`реестр в бизнес-логики не найден ID: ${this.value.object_id}`)
        }
      })
      .catch(error => console.log(error))
    // список полей текущего реестра ссылающихся на  таблицу сотрудников
    // this.$http.get(`${this.$config.api}/objecteditor/entities?properties[is_approval_button]=true&object_id=666`)
    //   .then(response => response.data.data[0].id)
    //   .then(async (id) => {
    //     let states = await States.params({ registryId: id }).get()
    //     this.propsFromAppravals.listStatesAroundUser = states
    //   })
    //   .catch(error => console.log(error))
    // список полей текущего реестра
    let registryTree = await Registry.params({ parent_id: id, show_children: true }).$get()
    this.propsFromAppravals.listFieldsAroundUser = registryTree
    // список сотрудников
    let users = await Users.params({ fields: 'id,name,surname,midname' }).get()
    this.propsFromAppravals.listEmployees = users
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.form = this.propslocal.getAnswer
      this.isVisible = true
      // список этапов
      await Stages.params({ approval_id: this.propslocal.selectSubAction.id })
        // .orderBy('id:asc')
        .get()
        .then(response => {
          this.propsFromAppravals.entityStages = response
          // кол-во компонентов (этапов)
          for (let i = 0; i < this.propsFromAppravals.entityStages.length; i++) {
            this.numberComponent.push(this.propsFromAppravals.entityStages[i].id)
            this.localAnswer.push(this.propsFromAppravals.entityStages[i])
          }
        })
    }
    // список команды
    let commands = await Commands.get()
    this.propsFromAppravals.listCommand = commands
    // список кнопок
    this.$http.get(`${this.$config.api}/objecteditor/entities?properties[is_approval_button]=true&object_id=${id}`)
      .then(response => {
        this.propsFromAppravals.listBtns = response.data.data
      })
      .catch(error => console.log(error))
  }
}
</script>

<style scoped>
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.approvals_container {
  /* display: block; */
  margin-top: 50px;
  /* height: calc(100vh - 100px); */
  /* overflow-y: auto */
}
.approval_wrapper {
  margin-bottom: 25px;
}
/* .extraAccordion__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
} */
.logitEditor_footer {
  height: 60px;
  text-align: right;
  margin-top: 50px;
}
.extraAccordion {
  margin: 20px;
  padding: 20px;
  text-align: left;
  box-shadow: -2px 4px 11px 6px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.extraAccordion_block__item2 {
  width: 72%;
}
</style>
