import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import XmlRouteQueryRepositoryInterface from '@/services/Databus/domain/repository/XmlRouteQueryRepositoryInterface'
import store from '@/store'
import { GetXmlRoutes, CountXmlRoutes, GetXmlRouteByGuid } from '@/services/Databus/infrastructure/store/modules/XmlRoute/action-types'

export default class XmlRouteQueryRepository extends AbstractQueryRepository implements XmlRouteQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetXmlRoutes(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountXmlRoutes(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetXmlRouteByGuid(guid));
    }
}