<template>
  <el-select
    v-model="localModel"
    filterable
    clearable
    @clear="localModel = null;$emit('change', localModel)"
    @change="$emit('change', localModel)"
  >
    <el-option
      v-for="(item, index) in cards"
      :key="index"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import Card from '@/components/ObjectEditor/Models/Card'

export default {
  name: 'editor-cards',
  props: ['value', 'options', 'other-properties'],
  data () {
    return {
      localModel: this.value,
      cards: []
    }
  },
  async mounted () {
    if (this.options && this.options.registryId) {
      await this.loadCards(this.options.registryId)
    } else if (
      this.otherProperties &&
      this.otherProperties.registry_properties &&
      this.otherProperties.registry_properties.find((item) => item.id === 'xref') &&
      this.otherProperties.registry_properties.find((item) => item.id === 'xref').value
    ) {
      let outerXrefId = this.otherProperties.registry_properties.find((item) => item.id === 'xref').value
      let response = await this.$http.get(`${this.$config.api}/objecteditor/entities/${outerXrefId}`)
      if (response.data.data.object_id) {
        await this.loadCards(response.data.data.object_id)
      }
    }
  },
  methods: {
    async loadCards (entityId) {
      this.cards = await new Card().params({ entity_id: entityId, fields: ['id', 'name'] }).$get()
    }
  }
}
</script>

<style scoped>

</style>
