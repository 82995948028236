<template>
    <img :src="imageSrc" width="100%" height="100%"/>
</template>

<script>
import properties from './mixins/properties'
export default {
  name: 'image-block',
  mixins: [properties],
  props: {
    src: {
      type: String,
      default: null,
      description: 'Источник',
      editor: 'ImageSourceEditor'
    },
    componentStyle: {
      type: String,
      default: '',
      description: 'Стили'
    }
  },
  computed: {
    imageSrc () {
      if (this.src) {
        if (this.src.startsWith('http')) {
          return this.src
        } else {
          return `${this.$config.api}${this.src}`
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
