import { ActionTree } from 'vuex'
import { XmlRouteState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, XmlRouteLoadingEvent, XmlRouteLoadedEvent, XmlRouteCreatedEvent, XmlRouteUpdatedEvent, XmlRouteDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { XmlRouteAPI } from '@/services/Databus/infrastructure/api/XmlRouteAPI'

export const actions: ActionTree<XmlRouteState, any> = {
    [ActionTypes.ADD_XML_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new XmlRouteLoadingEvent());
            let location = await APIClient.shared.request(new XmlRouteAPI.AddXmlRoute(data.payload));
            commit(new XmlRouteCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_XML_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new XmlRouteLoadingEvent());
            let location = await APIClient.shared.request(new XmlRouteAPI.UpdateXmlRoute(data.payload));
            commit(new XmlRouteUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_XML_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new XmlRouteAPI.DeleteXmlRoute(data.guid));
            commit(new XmlRouteDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_XML_ROUTES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new XmlRouteLoadingEvent());
            let res = await APIClient.shared.request(new XmlRouteAPI.GetXmlRoutes(data.payload));
            commit(new XmlRouteLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_XML_ROUTES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new XmlRouteLoadingEvent());
            let res = await APIClient.shared.request(new XmlRouteAPI.CountXmlRoutes(data.payload));
            commit(new XmlRouteLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_XML_ROUTE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new XmlRouteAPI.GetXmlRouteByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
