
















































import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import RoutesHandler from '@/services/Databus/application/handler/query/RoutesHandler'
import RoutesCountHandler from '@/services/Databus/application/handler/query/RoutesCountHandler'
import RouteByGuidHandler from '@/services/Databus/application/handler/query/RouteByGuidHandler'
import DagsHandler from '@/services/Databus/application/handler/query/DagsHandler'
import TasksHandler from '@/services/Databus/application/handler/query/TasksHandler'
import TopicsHandler from '@/services/Databus/application/handler/query/TopicsHandler'
import TopicsCountHandler from '@/services/Databus/application/handler/query/TopicsCountHandler'
import TopicByGuidHandler from '@/services/Databus/application/handler/query/TopicByGuidHandler'
import PackagesHandler from '@/services/Databus/application/handler/query/PackagesHandler'
import PackagesCountHandler from '@/services/Databus/application/handler/query/PackagesCountHandler'
import XmlRoutesHandler from '@/services/Databus/application/handler/query/XmlRoutesHandler'
import XmlRoutesCountHandler from '@/services/Databus/application/handler/query/XmlRoutesCountHandler'
import XmlRouteByGuidHandler from '@/services/Databus/application/handler/query/XmlRouteByGuidHandler'
import DagRunHandler from '@/services/Databus/application/handler/command/DagRunHandler'
import RouteCreateHandler from '@/services/Databus/application/handler/command/RouteCreateHandler'
import RouteUpdateHandler from '@/services/Databus/application/handler/command/RouteUpdateHandler'
import RouteDeleteHandler from '@/services/Databus/application/handler/command/RouteDeleteHandler'
import TopicCreateHandler from '@/services/Databus/application/handler/command/TopicCreateHandler'
import TopicUpdateHandler from '@/services/Databus/application/handler/command/TopicUpdateHandler'
import TopicDeleteHandler from '@/services/Databus/application/handler/command/TopicDeleteHandler'
import PackageFileDownloadHandler from '@/services/Databus/application/handler/command/PackageFileDownloadHandler'
import XmlRouteCreateHandler from '@/services/Databus/application/handler/command/XmlRouteCreateHandler'
import XmlRouteUpdateHandler from '@/services/Databus/application/handler/command/XmlRouteUpdateHandler'
import XmlRouteDeleteHandler from '@/services/Databus/application/handler/command/XmlRouteDeleteHandler'
import EventsHandler from '@/services/Databus/application/handler/query/EventsHandler'
import EventsCountHandler from '@/services/Databus/application/handler/query/EventsCountHandler'
import DagRepository from '@/services/Databus/infrastructure/domain/repository/DagRepository'
import RouteQueryRepository from '@/services/Databus/infrastructure/domain/repository/RouteQueryRepository'
import TaskQueryRepository from '@/services/Databus/infrastructure/domain/repository/TaskQueryRepository'
import TopicQueryRepository from '@/services/Databus/infrastructure/domain/repository/TopicQueryRepository'
import PackageQueryRepository from '@/services/Databus/infrastructure/domain/repository/PackageQueryRepository'
import XmlRouteQueryRepository from '@/services/Databus/infrastructure/domain/repository/XmlRouteQueryRepository'
import PackageCommandRepository from '@/services/Databus/infrastructure/domain/repository/PackageCommandRepository'
import EventQueryRepository from '@/services/Databus/infrastructure/domain/repository/EventQueryRepository'
import RouteCommandRepository from '@/services/Databus/infrastructure/domain/repository/RouteCommandRepository'
import TopicCommandRepository from '@/services/Databus/infrastructure/domain/repository/TopicCommandRepository'
import XmlRouteCommandRepository from '@/services/Databus/infrastructure/domain/repository/XmlRouteCommandRepository'
import TopicPanel from '@/services/Databus/infrastructure/components/TopicPanel/index.vue'
import PackagePanel from '@/services/Databus/infrastructure/components/PackagePanel/index.vue'
import EventPanel from '@/services/Databus/infrastructure/components/EventPanel/index.vue'
import RoutePanel from '@/services/Databus/infrastructure/components/RoutePanel/index.vue'
import XmlRoutePanel from '@/services/Databus/infrastructure/components/XmlRoutePanel/index.vue'
import DagPanel from '@/services/Databus/infrastructure/components/DagPanel/index.vue'
import PackageFileDownloadCommand from '@/services/Databus/application/command/PackageFileDownloadCommand'

export default {
  name: 'Databus',
  components: {
    TopicPanel, PackagePanel, EventPanel, RoutePanel, XmlRoutePanel, DagPanel
  },
  data() {
    return {
      activeEntity: 'topics',
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'DagRunCommand': new DagRunHandler(
            new DagRepository()
          ),
          'PackageFileDownloadCommand': new PackageFileDownloadHandler(
            new PackageCommandRepository()
          ),
          'TopicCreateCommand': new TopicCreateHandler(
            new TopicCommandRepository()
          ),
          'TopicUpdateCommand': new TopicUpdateHandler(
            new TopicCommandRepository()
          ),
          'TopicDeleteCommand': new TopicDeleteHandler(
            new TopicCommandRepository()
          ),
          'RouteCreateCommand': new RouteCreateHandler(
            new RouteCommandRepository()
          ),
          'RouteUpdateCommand': new RouteUpdateHandler(
            new RouteCommandRepository()
          ),
          'RouteDeleteCommand': new RouteDeleteHandler(
            new RouteCommandRepository()
          ),
          'XmlRouteCreateCommand': new XmlRouteCreateHandler(
            new XmlRouteCommandRepository()
          ),
          'XmlRouteUpdateCommand': new XmlRouteUpdateHandler(
            new XmlRouteCommandRepository()
          ),
          'XmlRouteDeleteCommand': new XmlRouteDeleteHandler(
            new XmlRouteCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
            'DagsQuery': new DagsHandler(
              new DagRepository()
            ),
            'TasksQuery': new TasksHandler(
              new TaskQueryRepository()
            ),
            'TopicsQuery': new TopicsHandler(
              new TopicQueryRepository()
            ),
            'TopicsCountQuery': new TopicsCountHandler(
              new TopicQueryRepository()
            ),
            'TopicByGuidQuery': new TopicByGuidHandler(
              new TopicQueryRepository()
            ),
            'PackagesQuery': new PackagesHandler(
              new PackageQueryRepository()
            ),
            'PackagesCountQuery': new PackagesCountHandler(
              new PackageQueryRepository()
            ),
            'EventsQuery': new EventsHandler(
              new EventQueryRepository()
            ),
            'EventsCountQuery': new EventsCountHandler(
              new EventQueryRepository()
            ),
            'RoutesQuery': new RoutesHandler(
              new RouteQueryRepository()
            ),
            'RoutesCountQuery': new RoutesCountHandler(
              new RouteQueryRepository()
            ),
            'RouteByGuidQuery': new RouteByGuidHandler(
              new RouteQueryRepository()
            ),
            'XmlRoutesQuery': new XmlRoutesHandler(
              new XmlRouteQueryRepository()
            ),
            'XmlRoutesCountQuery': new XmlRoutesCountHandler(
              new XmlRouteQueryRepository()
            ),
            'XmlRouteByGuidQuery': new XmlRouteByGuidHandler(
              new XmlRouteQueryRepository()
            )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus,
      getDownloadPackage: this.getDownloadPackage 
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    },
    async getDownloadPackage(topic, guid, fileName) {
      await this.getCommandBus().execute(
        new PackageFileDownloadCommand(topic, guid, fileName)
      );
    }
  },
  mounted() {
    
  }
}
