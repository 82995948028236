<template>
  <div class="vqb-group" :class="classObject">
    <div class="vqb-group-heading" :class="{ 'panel-heading': styled }">
      <div class="match-type-container" :class="{ 'form-inline': styled }">
        <el-button circle type="danger" size="mini" :style="{ 'float': 'right' }" v-if="this.depth > 1" @click="remove" v-html="labels.removeGroup"></el-button>

        <el-form-item :size="sizeControl" class="form-item-group">
          <template slot="label">
            {{ labels.matchType }}
          </template>

          <el-select id="vqb-match-type" :class="{ 'form-control': styled }" v-model="query.logicalOperator">
            <el-option v-for="(label, index) in labels.matchTypes" :key="index" :value="label.id" :label="label.label"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>

    <div class="vqb-group-body" :class="{ 'panel-body': styled }">
      <div class="rule-actions" :class="{ 'form-inline': styled }">
        <el-form-item>
          <el-select v-model="selectedIndex" @change="selectedChange" :placeholder="labels.rulePlaceholder">
            <el-option
              v-for="(rule, index) in rules"
              :key="index"
              :label="rule.label"
              :value="rule.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="default" @click="addRule" :class="{ 'btn btn-default': styled }" v-html="labels.addRule" size="small"></el-button>
          <el-button type="default" :class="{ 'btn btn-default': styled }" v-if="this.depth < this.maxDepth" @click="addGroup" v-html="labels.addGroup" size="small"></el-button>
        </el-form-item>
      </div>

      <div class="children">
        <component
          v-for="(child, index) in query.children"
          :key="index"
          :is="child.type"
          :type="child.type"
          :query.sync="child.query"
          :ruleTypes="ruleTypes"
          :rules="rules"
          :rule="ruleById(child.query.rule)"
          :index="index"
          :maxDepth="maxDepth"
          :depth="depth + 1"
          :styled="styled"
          :labels="labels"
          :size-control="sizeControl"
          v-on:child-deletion-requested="removeChild">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from './QueryBuilderRule.vue'
import deepClone from '../utilities.js'

export default {
  name: 'query-builder-group',

  components: {
    QueryBuilderRule
  },

  props: ['ruleTypes', 'type', 'valueType', 'query', 'rules', 'index', 'maxDepth', 'depth', 'styled', 'labels', 'sizeControl'],

  methods: {
    selectedChange () {
      this.selectedRule = this.rules.find(rule => rule.id === this.selectedIndex)
    },

    ruleById (ruleId) {
      var rule = null

      this.rules.forEach(function (value) {
        if (value.id === ruleId) {
          rule = value
          return false
        }
      })

      return rule
    },

    addRule () {
      if (!this.selectedRule || !this.selectedIndex) {
        this.$alert('Выберите поле!', 'Ошибка', {
          confirmButtonText: 'OK'
        })

        return
      }

      let updatedQuery = deepClone(this.query)

      let child = {
        type: 'query-builder-rule',
        query: {
          rule: this.selectedRule.id,
          selectedOperator: this.selectedRule.operators[0],
          selectedOperand: typeof this.selectedRule.operands === 'undefined' ? this.selectedRule.label : this.selectedRule.operands[0],
          value: null
        }
      }
      // A bit hacky, but `v-model` on `select` requires an array.
      if (this.ruleById(child.query.rule).type === 'multi-select') {
        child.query.value = []
      }
      updatedQuery.children.push(child)
      this.$emit('update:query', updatedQuery)
    },

    addGroup () {
      let updatedQuery = deepClone(this.query)
      if (this.depth < this.maxDepth) {
        updatedQuery.children.push({
          type: 'query-builder-group',
          query: {
            logicalOperator: this.labels.matchTypes[0].id,
            children: []
          }
        })
        this.$emit('update:query', updatedQuery)
      }
    },

    remove () {
      this.$confirm('Вы действительно хотите удалить эту группу?', 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(() => {
        this.$emit('child-deletion-requested', this.index)
      })
    },

    removeChild (index) {
      let updatedQuery = deepClone(this.query)
      updatedQuery.children.splice(index, 1)
      this.$emit('update:query', updatedQuery)
    }
  },

  data () {
    return {
      selectedRule: this.rules[0],
      selectedIndex: null
    }
  },

  computed: {
    classObject () {
      var classObject = {
        'panel panel-default': this.styled
      }

      classObject['depth-' + this.depth.toString()] = this.styled

      return classObject
    }
  }
}
</script>

<style>
  .form-item-group .el-form-item__label {
    max-width: 50%;
  }
</style>
