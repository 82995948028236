














































































































































































import Treeselect from '@bingosoftnn/vue-treeselect'
import XmlRouteCreateCommand from '@/services/Databus/application/command/XmlRouteCreateCommand'
import XmlRouteUpdateCommand from '@/services/Databus/application/command/XmlRouteUpdateCommand'
import XmlRouteDeleteCommand from '@/services/Databus/application/command/XmlRouteDeleteCommand'
import XmlRoutesQuery from '@/services/Databus/application/query/XmlRoutesQuery'
import XmlRoutesCountQuery from '@/services/Databus/application/query/XmlRoutesCountQuery'
import XmlRoute, { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'
import ActionType from '@/services/Databus/domain/model/XmlRoute/ActionType'
import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
import TasksQuery from '@/services/Databus/application/query/TasksQuery'
import DagsQuery from '@/services/Databus/application/query/DagsQuery'

export default {
  name: 'RoutePanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      xmlRoutesPageSize: 0,
      defaultXmlRoutesPageSize: 100,
      xmlRoutesCurrentPage: 0,
      xmlRoutesPageLimit: 100,
      xmlRoutesCount: 0,
      allXmlRoutesCount: 0,
      xmlRoutes: [],
      route: null,
      xmlRouteDto: null,
      xmlRouteDtoInit: null,
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateRouteWindowVisible: false,
      topics: [],
      tasks: [],
      dags: [],
      currentTopicId: null,
      currentTaskId: null,
      topicMap: {},
      taskMap: {},
      actionType: null,
      actionTypes: [{
        id: "import_by_task",
        name: this.$locale.databus.xml_route_panel.import_by_task
      },{
        id: "import_by_integration_procedure",
        name: this.$locale.databus.xml_route_panel.import_by_integration_procedure
      }],
      createXmlRouteRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        action_type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        topic: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    xmlRouteDto: {
      handler: async function(curDto) {
        if (curDto != null) {
          if (curDto.topic_id !== this.currentTopicId && typeof curDto.topic_id !== 'undefined' && curDto.topic_id !== null) {
            this.topics = this.topicMap[curDto.topic_id] || await this.getQueryBus().execute(
              new TopicsQuery({"id": curDto.topic_id})
            );
            this.currentTopicId = curDto.topic_id;
            this.topicMap[curDto.topic_id] = this.topics;
          } else if (curDto.task_id !== this.currentTaskId && typeof curDto.task_id !== 'undefined' && curDto.task_id !== null) {
            this.tasks = this.taskMap[curDto.task_id] || await this.getQueryBus().execute(
              new TasksQuery({"id": curDto.task_id})
            );
            this.currentTaskId = curDto.task_id;
            this.taskMap[curDto.task_id] = this.tasks;
          }
          if (curDto.action_type !== this.actionType) {
            this.actionType = curDto.action_type;
            if (curDto.action_type == ActionType.IMPORT_BY_TASK) {
              this.xmlRouteDto.dag_id = null;
              delete this.createXmlRouteRules.topic;
              this.createXmlRouteRules.task_id = {
                required: true,
                message: this.$locale.main.message.required_field,
                trigger: 'change'
              };
            } else {
              this.xmlRouteDto.task_id = null;
              delete this.createXmlRouteRules.task_id;
              this.createXmlRouteRules.dag_id = {
                required: true,
                message: this.$locale.main.message.required_field,
                trigger: 'change'
              };
            }
          }
        }
      },
      deep: true
    },
    routeCreated: function (location) {
      this.loadXmlRoutes(() => {
        this.closeCreateRouteWindow();
      });
    },
    isRouteLoading: (state) => state,
    getSaveRouteError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.databus.xml_route_panel.duplicate_route_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isRouteLoading() {
      return this.$store.getters['XmlRoute/isLoading'];     
    },
    routeCreated() {
      return this.$store.getters['XmlRoute/getLocation'];
    },
    getSaveRouteError() {
      return this.$store.getters['XmlRoute/getError']; 
    }
  },
  methods: {
    loadXmlRoutes(callback) {
      if (this.allXmlRoutesCount == 0) {
        this.countAndLoadXmlRoutes(callback);
      } else {
        this.loadAllXmlRoutes(callback);
      }
    },
    async countAndLoadXmlRoutes(callback) {
      await this.getQueryBus().execute(
        new XmlRoutesCountQuery()
      ).then(data => {
        this.xmlRoutesCount = data[0].count;      
        this.loadAllXmlRoutes(callback);
      });
    },
    async loadAllXmlRoutes(callback) {
      await this.getQueryBus().execute(
        new XmlRoutesQuery({
          limit: this.xmlRoutesPageLimit,
          offset: this.xmlRoutesCurrentPage
        })
      ).then(data => {
        this.xmlRoutesPageSize = this.xmlRoutesPageLimit;
        this.xmlRoutes = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createRoute() {
      this.xmlRouteDto = new XmlRouteDTO({});
      this.isCreateRouteWindowVisible = true;
    },
    updateRoute() {
      if (this.route !== null) {
        this.isCreateRouteWindowVisible = true;
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveRoute() {
      let form = this.$refs.xml_route_panel;
      form.validate(valid => {
        if (valid) {
          let route = XmlRoute.create(this.xmlRouteDto);
          if (route.getId() == null) {
            this.getCommandBus().execute(
              new XmlRouteCreateCommand(
                route.getName(),                
                route.getTopic(),
                route.getTaskId(),
                route.getDagId(),
                route.getActionType()
              )
            );
          } else {
            this.getCommandBus().execute(
                new XmlRouteUpdateCommand(
                  route.getGuid(),
                  route.getName(),                  
                  route.getTopic(),
                  route.getTaskId(),
                  route.getDagId(),
                  route.getActionType()
                )
              ).then(() => {
                if (this.isDuplicate) {
                  this.isDuplicate = false;
                } else {
                  this.closeCreateRouteWindow();
                }
              });
          }
        }
      });
    },
    closeCreateRouteWindow() {
      this.isCreateRouteWindowVisible = false;
    },
    changeRoute(xmlRouteDto) {
      if (xmlRouteDto !== null) {
        this.route = XmlRoute.create(xmlRouteDto);
        this.xmlRouteDto = xmlRouteDto;
      } else {
        this.route = null;
        this.xmlRouteDto = null;
      }
    },
    deleteRoute() {
      if (this.route == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new XmlRouteDeleteCommand(
              this.route.getGuid()
            )
          ).then((response) => {
            this.route = null;
            this.$refs.xml_routes_table.setCurrentRow(null);
            this.loadXmlRoutes();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    handleXmlRoutesPageChange(val) {
      val--;
      this.xmlRoutesCurrentPage = (val * this.xmlRoutesPageLimit);
      this.loadXmlRoutes(); 
    },
    async loadTopics() {
      this.topics = await this.getQueryBus().execute(
        new TopicsQuery()
      );
    },
    async loadTasks() {
      this.tasks = await this.getQueryBus().execute(
        new TasksQuery()
      );
    },
    async loadDags() {
      this.dags = await this.getQueryBus().execute(
        new DagsQuery()
      );
    }
  },
  mounted () {
    this.loadXmlRoutes();
  }
}
