<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item :label="$locale.notify_editor.label.choose_icon" style="margin-top: 10px">
      <el-select clearable size="mini" v-model="value.icon">
        <el-option
          v-for="item in icons"
          :key="item.value"
          :label="item.label"
          :value="item.value">
          <span style="float: left">{{ item.label }}</span>
          <span style="float: right;">
            <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
          </span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.action">
      <editor-select
        :value="value.type"
        @change="$set(value, 'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
            { id: 'execute_plugin', name: $locale.interface_editor.component_editor.button_action.start_plugin },
            { id: 'open_report', name: $locale.interface_editor.component_editor.button_action.open_report },
            { id: 'open_document', name: $locale.interface_editor.component_editor.button_action.open_document },
            { id: 'open_xml', name: $locale.interface_editor.component_editor.button_action.open_xml },
            { id: 'execute_command', name: $locale.interface_editor.component_editor.button_action.execute_command },
            { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
          ]
        }"
      ></editor-select>
    </el-form-item>
    <template v-if="value.type === 'open_card'">
      <editor-open-card :value="value"></editor-open-card>
    </template>
    <template v-if="value.type === 'execute_command'">
      <el-form-item :label="this.$locale.logic_editor.command.headline" >
        <el-select
                :value="value.command.id"
                value-key="id"
                filterable
                @change="$set(value.command ,'id', $event )"
        >
          <el-option
                  v-for="(item, index) in commands"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.success_text" v-if="value.type === 'execute_command'">
        <el-input v-model="value.command.success_text"/>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.failure_text" v-if="value.type === 'execute_command'">
        <el-input v-model="value.command.failure_text"/>
      </el-form-item>
    </template>
    <template v-if="value.type === 'open_report'">
      <editor-report :value="value" />
      <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
    </template>
    <template v-if="value.type === 'open_document'">
      <editor-document :value="value" type="document" />
      <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
    </template>
    <template v-if="value.type === 'open_xml'">
      <editor-document :value="value" type="xml" />
      <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
    </template>
    <template v-if="value.type === 'open_dashboard'">
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.dashbord">
        <editor-dashboards @change="$set(value.dashboard ,'id', $event)" :value="value.dashboard.id" ></editor-dashboards>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.window_title">
        <el-input v-model="value.dashboard.window_title"/>
      </el-form-item>
      <el-form-item :label="$locale.etl_editor.tooltip.full_screen">
        <el-checkbox v-model="value.dashboard.isFullscreen"></el-checkbox>
      </el-form-item>
      <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.window_width">
        <editor-select
          :value="value.dashboard.window_width"
          @change="$set(value.dashboard ,'window_width', $event)"
          :options="{
            multiple: false,
            options: [
              { id: '25', name:'25%' },
              { id: '50', name:'50%' },
              { id: '75', name:'75%' },
              { id: '100', name:'100%' },
            ]
          }"
        ></editor-select>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
import EditorDashboards from './editor-dashboards'
import EditorFilters from './editor-filters'
import EditorOpenCard from './editor-open-card'
import EditorDocument from './editor-document'
import EditorReport from './editor-report'
import Icons from '@/components/MenuEditor/components/el-icons'

export default {
  components: {
    EditorSelect,
    EditorDashboards,
    EditorFilters,
    EditorOpenCard,
    EditorDocument,
    EditorReport
  },
  name: 'editor-button-action',
  inject: {
    getRegistryId: {
      default: () => null
    }
  },
  props: {
    value: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler (value) {
        this.$set(this.value, 'filters', [])
        if (value === 'execute_command') {
          this.$set(this.value, 'command', {
            id: null,
            success_text: null,
            failure_text: null
          })
        } else if (value === 'open_dashboard') {
          this.$set(this.value, 'dashboard', {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null
          })
        } else if (value === 'open_card') {
          this.$set(this.value, 'card', {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          })
        } else if (value === 'open_report') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'open_document') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'open_xml') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'xml',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        }
      }
    }
  },
  data () {
    return {
      commands: [],
      icons: Icons.map(el => ({ value: el, label: el }))
    }
  },
  methods: {
  },
  async mounted () {
    console.log(this.value.reports)
    if (this.getRegistryId()) {
      this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${this.getRegistryId()}`)
        .then((response) => {
          if (response.data[0]) {
            this.$http.get(`${this.$config.api}/logiceditor/commands?logic_id=${response.data[0].id}`)
              .then((res) => {
                this.commands = res.data
              })
          }
        })
    }
  }
}
</script>

<style type="text/css">
  .el-select {
    width: 100%;
  }
</style>
