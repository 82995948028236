export default class XmlRouteUpdateCommand
{
    private guid: string;
    private name: string;
    private topic: string;
    private taskId: number;
    private dagId: string; 
    private actionType: string;

    constructor(guid: string, name: string, topic: string, taskId: number, dagId: string, actionType: string)
    {
        this.guid = guid;
        this.name = name;
        this.topic = topic;
        this.taskId = taskId;
        this.dagId = dagId;      
        this.actionType = actionType;
    }

    getClassName(): string
    {
        return 'XmlRouteUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getTopic(): string
    {
        return this.topic;
    }

    getTaskId(): number
    {
        return this.taskId;
    }

    getDagId(): string
    {
        return this.dagId;
    }

    getActionType(): string
    {
        return this.actionType;
    }
}