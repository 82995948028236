<template>
<el-form-item class="subMenu" :label="$locale.menu_editor.label.select_plugin">
    <el-select v-model="localPorperties.value" filterable>
      <el-option v-for="item in plugins" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    </el-form-item>
</template>

<script>
export default {
  name: 'plugin',
  props: ['properties'],
  watch: {
    localPorperties: {
      handler: function (val) {
        // console.log(val)
        this.$emit('properties', val)
      },
      deep: true
    },
    properties: {
      handler: function (val) {
        this.localPorperties = JSON.parse(val) || {}
      },
      deep: true
    }
  },
  data () {
    return {
      localPorperties: JSON.parse(this.properties) || {},
      plugins: []
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/logiceditor/plugins`)
    this.plugins = response.data
  }
}
</script>

<style scoped>
</style>
