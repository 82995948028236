<template>
    <vue-draggable-resizable
            :w="ResultWidth"
            :h="ResultHeight"
            :x="ResultX"
            :y="ResultY"
            :parent="true"
            :active.sync="isActive"
            @dragging="onDrag"
            @resizing="onResize"
            :grid="[2,2]"
            >
        <component
                :is="component"
                v-bind="properties"
                style="overflow: hidden;"
                ref="component"></component>
    </vue-draggable-resizable>
</template>

<script>
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import VueDraggableResizable from 'vue-draggable-resizable'

import TextLabel from '../Components/TextLabel'
import ImageBlock from '../Components/ImageBlock'
import IndicatorsBlock from '../Components/IndicatorsBlock'

export default {
  name: 'draggable-block',
  props: {
    component: {
      type: String,
      default: 'TextLabel'
    },
    properties: {
      type: [Object, Array],
      default () { return {} }
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    }
  },
  components: {
    VueDraggableResizable,
    TextLabel,
    ImageBlock,
    IndicatorsBlock
  },
  data () {
    return {
      ResultWidth: this.width,
      ResultHeight: this.height,
      ResultX: this.x,
      ResultY: this.y,
      isActive: false
    }
  },
  watch: {
    isActive (value) {
      this.$emit('change-active', value)
    }
  },
  methods: {
    getStructure () {
      return this.$refs.component.getStructure()
    },
    getProperties () {
      return this.$refs.component.getProperties()
    },
    onResize (x, y, width, height) {
      this.ResultX = x
      this.ResultY = y
      this.ResultWidth = width
      this.ResultHeight = height
      this.$emit('change-size', {
        width: width,
        height: height,
        x: x,
        y: y
      })
    },
    onDrag (x, y) {
      this.$x = x
      this.$y = y
      this.$emit('change-size', {
        width: this.ResultWidth,
        height: this.ResultHeight,
        x: x,
        y: y
      })
    }
  }
}
</script>

<style scoped>
/deep/ .vdr {
    outline: 1px dashed;
    border: none;
}
</style>
