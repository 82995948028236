<template>
  <el-row class="tac" style="padding-top: 15px">
    <div class="title">{{$locale.logic_editor.main.title}}</div>
    <el-col style="margin-left: 5px" :span="isCollapse ? 2 : 5" :sm="isCollapse ? 2 : 8" :md="isCollapse ? 2 : 8" :lg="isCollapse ? 2 : 5" :xl="isCollapse ? 1 : 5">
      <div class="logicEditor_wrapper">
        <el-button
          class="logicEditor_btn"
          :style="isCollapse ? 'width: 54px' :'width: 17%'"
          @click="isCollapse = !isCollapse"
          :icon="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"
        ></el-button>
        <el-dropdown
          v-show="!isCollapse"
          placement="bottom-start"
          trigger="click"
          @command="addlogic($event)"
        >
        <el-tooltip :content="$locale.logic_editor.main.btn_add_stage" placement="bottom">
          <el-button icon="el-icon-plus" circle size="small"></el-button>
        </el-tooltip>
          <el-dropdown-menu slot="dropdown" class="logicEditor_dropdown custom_scrollbar">
            <el-dropdown-item
              :command="registry"
              v-for="(registry, index) in listRegistries"
              :key="index"
            >{{registry.name}} <b>(id: {{registry.id}})</b></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-menu class="el-menu-vertical-demo" :collapse="isCollapse">
        <el-scrollbar wrap-style="height: calc(100vh - 133px);">
          <el-menu-item
            v-for="(item,ind) in listLogics"
            :key="item.id"
            :index="ind.toString()"
            @click="openLogic(item)"
          >
            <i class="el-icon-mobile"></i>
            <span slot="title">{{ item.name }} <b>(id: {{item.id}})</b></span>
            <i
              @click.stop="removeLogic(item.id)"
              size="medium"
              v-show="!isCollapse"
              class="logicEditor_icon hover el-icon-remove"
            ></i>
          </el-menu-item>
        </el-scrollbar>
      </el-menu>
    </el-col>
    <el-col :span="isCollapse ? 5 : 16" :sm="6" :md="5" :lg="5" :xl="4">
      <div class="logicEditor_headline">
        <h2 v-if="propslocal.businesslogic">{{propslocal.businesslogic.name}} <b>(id: {{propslocal.businesslogic.id}})</b></h2>
        <div v-else class="logicEditor_headline2"></div>
      </div>
      <properties
        ref="propertiesLogic"
        v-show="propslocal.businesslogic"
        :activeLogic="activeLogic"
        :propslocal="propslocal"
      />
    </el-col>
    <el-col v-show="isCollapse" :sm="{span: 14, offset: 2}" :md="{span: 14, offset: 2}" :lg="{span: 14, offset: 2}" :xl="{span: 15, offset: 1}" class="logicEditor_margin">
      <component :is="propslocal.type" :propslocal="propslocal" :key="propslocal.getAnswer.id"></component>
    </el-col>
  </el-row>
</template>

<script>
import Logic from './Models/Logic'
import Registry from './Models/Registry'

import Properties from '@/components/LogicEditor/properties/properties.vue'
import Formulas from '@/components/LogicEditor/components/column_formula'
import Rows from '@/components/LogicEditor/components/rows'
import States from '@/components/LogicEditor/components/states'
import Listeners from '@/components/LogicEditor/components/listeners'
import Commands from '@/components/LogicEditor/components/commands'
import Constraints from '@/components/LogicEditor/components/constraints'
import ViewConstraint from '@/components/LogicEditor/components/view_constraint'
import DisableConstraint from '@/components/LogicEditor/components/disable_constraint'
import Approvals from '@/components/LogicEditor/components/approvals'
import Relates from '@/components/LogicEditor/components/relates'
export default {
  components: {
    Properties,
    Formulas,
    Rows,
    States,
    Listeners,
    Commands,
    Constraints,
    'view_constraint': ViewConstraint,
    'disable_constraint': DisableConstraint,
    Approvals,
    Relates
  },
  data () {
    return {
      propslocal: {
        // новое действие
        isNewAction: false,
        // выбранная бизнес логика
        businesslogic: '',
        // список для построения subAction
        listOpenAction: null,
        // выбранное subAction
        selectSubAction: '',
        // значение полей от сервера
        getAnswer: {},
        type: null
      },
      isCollapse: false,
      // активное действие
      activeSubAction: '',
      listLogics: [],
      // список реестров
      listRegistries: [],
      // список действий
      activeLogic: null
    }
  },
  watch: {
    'propslocal.businesslogic': function (val) {
      console.log(val)
      this.activeLogic = val
      this.$refs.propertiesLogic.$refs.menu.openedMenus = []
      this.propslocal.listOpenAction = []
      this.propslocal.selectSubAction = ''
      this.activeSubAction = ''
      this.isCollapse = true
    }
  },
  methods: {
    async addlogic (type) {
      let logic = new Logic({})
      logic.registry_id = type.id
      logic.description = type.descriptionLogic || ''
      let response = await logic.save()
      this.listLogics.push(response)
    },
    openLogic (registry) {
      this.propslocal.businesslogic = registry
    },
    removeLogic (logicId) {
      this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }
      )
        .then(async () => {
          let entity = new Logic({ id: logicId })
          await entity.delete()
          this.listLogics.forEach((el, ind) => {
            if (el.id === logicId) this.listLogics.splice(ind, 1)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  async mounted () {
    // список логик
    let logic = await Logic.get()
    this.listLogics = logic
    // список реестров
    let registry = await Registry.params({ is_object: true }).get()
    this.listRegistries = registry.data
    if (this.$attrs.registryId) {
      let findLogic = this.listLogics.find(item => item.registry_id === this.$attrs.registryId)
      if (findLogic) {
        this.openLogic(findLogic)
      } else {
        await this.addlogic({ id: this.$attrs.registryId })
        let findLogics = this.listLogics.find(item => item.registry_id === this.$attrs.registryId)
        this.openLogic(findLogics)
      }
    }
  }
}
</script>

<style scoped>
/* .logic_editor__menu {
  width: 110%;
} */
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 350px;
}
.el-submenu__title:hover .hover {
  display: inline-block;
}
.el-submenu:hover .hover {
  display: block;
}
.el-menu-item:hover .hoverSubBtn {
  display: block;
}
.el-menu-item:hover .hover {
  display: block;
}
.el-menu-item {
  white-space: normal;
  overflow: hidden;
}
.hover,
.hoverSubBtn {
  display: none;
}
.logicEditor_btn {
  margin-right: 5%;
}
.logicEditor_wrapper {
  padding: 0px 0px 10px 10px;
}
.logicEditor_dropdown {
  height: 350px;
  overflow-y: auto;
}
.logicEditor_icon {
  float: right;
  line-height: 40px;
}
/* .logicEditor_icon2 {
  float: right;
  line-height: 40px;
  margin-right: 10%;
} */
.logicEditor_headline {
  padding-left: 10px;
  margin-bottom: 5px;
}
.logicEditor_headline2 {
  padding: 5px 0px 10px 10px;
  margin-bottom: 5px;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  height: 28px;
  color: #2C2D35;
}

</style>
