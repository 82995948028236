var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('treeselect',{ref:"treeselect",attrs:{"options":this.deepSearch(this.dataTable),"multiple":_vm.multiple,"disabled":_vm.disabled,"placeholder":_vm.$locale.main.placeholder.select,"normalizer":_vm.normalizer,"disable-branch-nodes":true,"append-to-body":_vm.appendToBody,"clear-on-select":false,"clearable":_vm.clearable,"clearValueText":_vm.clearValueText,"noChildrenText":_vm.noChildrenText,"noResultsText":_vm.noResultsText,"limitText":_vm.limitText,"matchKeys":_vm.matchKeys,"limit":3,"value-consists-of":_vm.valueConsistsOf,"value":_vm.value},on:{"select":_vm.selectAlias,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(_vm._s(node.label)+" "),_c('b',[_vm._v(" (id: "+_vm._s(node.raw.id)+")")])])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return (_vm.standartTree)?_c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+"(id: "+_vm._s(node.id)+", "),_c('b',[_vm._v(_vm._s(_vm.$locale.object_editor.entity_types[node.raw[_vm.typeField]] || node.raw[_vm.typeField]))]),_vm._v(") ")]):_c('label',{class:labelClassName,attrs:{"title":node.label}},[_c('b',[_vm._v(_vm._s(node.label))]),_c('br'),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("attr_"+_vm._s(node.id)+"_")]),_c('span',{staticStyle:{"float":"right","font-size":"10px"}},[_vm._v(_vm._s(_vm.$locale.object_editor.entity_types[node.raw[_vm.typeField]] || node.raw[_vm.typeField]))])])}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }