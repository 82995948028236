<template>
  <el-select
    multiple
    collapse-tags
    value-key="id"
    class="extraLine_select"
    v-model="value"
    :disabled="isEdit"
    v-on:input="$emit('input', $event)"
    :placeholder="this.$locale.logic_editor.state.boolean_field"
    size="medium"
  >
    <el-option v-for="item in listXref" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'xrefMultiField',
  props: {
    state: {
      type: Object
    },
    listField: {
      type: Array
    },
    chooseFiled: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  watch: {
    listField: function (val) {
      console.log('watch xref_multi_field', val)
      // val.forEach(el => {
      //   if (el.entity_type_id === 'xref_multi_field') {
      //   // список простых ссылок
      //     this.xrefRequest(el)
      //   }
      // })
    }
  },
  data () {
    return {
      value: [],
      listXref: []
    }
  },
  methods: {
    // xrefRequest (el) {
    //   this.$http
    //     .get(`${this.$config.api}/registryservice/xref/${el.id}/data`)
    //     .then(response => {
    //       this.listXref = response.data.data
    //     })
    //     .catch(error => console.log(error))
    // }
  },
  mounted () {
    if (this.chooseFiled) {
      this.$http
        .get(`${this.$config.api}/registryservice/xref/${this.chooseFiled.id}/data`)
        .then(response => {
          this.listXref = response.data
        })
        .catch(error => console.log(error))
    }
    // if (this.listField.length > 0) {
    //   console.log('created xref_multi_field', this.listField)
    //   this.listField.forEach(el => {
    //     if (el.entity_type_id === 'xref_multi_field') {
    //     // список простых ссылок
    //       this.xrefRequest(el)
    //     }
    //   })
    // }
    if (this.state) {
      this.value =
        this.state.properties && this.state.properties.value
          ? this.state.properties.value
          : null
    }
  }
}
</script>

<style scoped>
</style>
