<template>
    <el-form style="height: calc(100% - 12px)">
        <InterfaceEditor ref="interface_editor" @save="saveStructure"
                         :entityTitle="name">
        </InterfaceEditor>
    </el-form>
</template>

<script>
import Dashboard from './Models/Dashboard'
import InterfaceEditor from '@/components/InterfaceEditor'
export default {
  components: {
    InterfaceEditor
  },
  props: ['dashboard-id'],
  name: 'viewer',
  data () {
    return {
      editorModel: null
    }
  },
  computed: {
    name () {
      if (this.editorModel === null) {
        return null
      }
      return this.editorModel.name
    }
  },
  async mounted () {
    if (!this.dashboardId) {
      return false
    }
    this.editorModel = await new Dashboard().find(this.dashboardId)
    this.$refs.interface_editor.loadState(JSON.parse(this.editorModel.structure))
  },
  methods: {
    async saveStructure (structure) {
      if (!this.editorModel) {
        return false
      }
      let dashboard = new Dashboard(this.editorModel)
      dashboard.structure = structure
      await dashboard.save()
    }
  }
}
</script>

<style scoped>

</style>
