import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'

export enum ActionTypes {
    UPDATE_PROJECT = 'update_project',
    GET_PROJECT_SOURCES = 'get_project_sources',
    GET_PROJECT_COORDINATE_SYSTEMS = 'get_project_coordinate_systems'
}

export class UpdateProject implements FluxStandardAction {
    type = 'Project/' + ActionTypes.UPDATE_PROJECT
    constructor(public payload: ProjectDTO){}
}

export class GetProjectSources implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_SOURCES
    constructor(public projectGuid: string){}
}

export class GetProjectCoordinateSystems implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_COORDINATE_SYSTEMS
    constructor(public projectGuid: string){}
}