













import DatabusPanel from '@/services/AccessEditor/infrastructure/components/RightPanel/DatabusPanel.vue'

export default {
  name: 'RightPanel',
  components: {
    DatabusPanel
  },
  data() {
    return {
      activeEntity: 'databus'
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getSelectedRole'],
  methods: {
    
  },
  mounted() {
    
  }
}
