var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{staticClass:"editor_calendar",attrs:{"label-width":"120px","label-position":"top","size":"mini"}},[_c('el-form-item',{attrs:{"label":_vm.$locale.interface_editor.component_editor.table.source_type}},[_c('editor-select',{attrs:{"value":_vm.value.type,"options":{
        multiple: false,
        options: [
          { id: 'extended_object', name: 'Расширенная таблица' },
          { id: null, name: 'Без источника' },
          { id: 'requests', name: 'Запросы' }
        ]
      }},on:{"change":function($event){return _vm.$set(_vm.value ,'type', $event)}}})],1),(_vm.value.type === 'extended_object')?[_c('el-form-item',[_c('editor-select',{attrs:{"value":_vm.value.extendObjectId,"options":{multiple: false, options: _vm.listExtendObject}},on:{"change":function($event){return _vm.$set(_vm.value ,'extendObjectId', $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$locale.interface_editor.component_editor.editor_chart.value_field}},[_c('el-select',{attrs:{"value-key":"name"},on:{"change":function($event){return _vm.$set(_vm.value ,'valueField', $event)}},model:{value:(_vm.valueField),callback:function ($$v) {_vm.valueField=$$v},expression:"valueField"}},_vm._l((_vm.filedExtendObject),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item}})}),1)],1)]:_vm._e(),(_vm.value.type === 'requests')?[_c('el-form-item',[_c('editor-select',{attrs:{"value":_vm.value.requestsId,"options":{
          multiple: false,
          options: _vm.requests
        }},on:{"change":function($event){return _vm.$set(_vm.value ,'requestsId', $event)}}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }