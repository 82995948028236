<template>
  <div>
    <el-header style="height: 24px; overflow-y: auto;">
      <el-row>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <a @click="$emit('back-registry')">
              Назад
            </a>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, index) in openedCards" :key="index">
            <a @click="$emit('back', index)">
              {{ (item.name || '').length > 100 ? (item.name || '').substring(0, 100) + '...' : (item.name || '')}}
            </a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </el-row>
    </el-header>
    <div v-if="showCard" style="height: calc(100% - 24px)">
      <!--<registry-card @close="this.closeCard" @recordAdded="$refs.registry_table.addRecord"
                     @recordEdited="$refs.registry_table.updateRecord"
                     :card-id="activeCardId" :registry-id="id" v-bind:record-id.sync="activeRecordId"></registry-card>-->
      <template v-for="(card, index) in openedCards">
        <registry-card
          v-if="!card.isDashboard"
          v-show="index === (openedCards.length - 1)"
          :card-id="card.id" :registry-id="card.registryId" v-bind:record-id.sync="card.recordId"
          :initial-data="card.initialData"
          :key="index"
          @recordAdded="(event) => {if (card.registry) {card.registry.addRecord(event)}}"
          @recordEdited="(event) => {if (card.registry) {card.registry.updateRecord(event)}}"
          :readonly="card.readonly"
          @cancelChanges="$emit('cancelChanges')"
          @change-name="$set(card, 'name', $event)"
        ></registry-card>
        <dashboard-simple
          v-else
          v-show="index === (openedCards.length - 1)"
          :key="index"
          :model="card.initialData"
          :id="card.id"
        ></dashboard-simple>
        <!--@close="this.closeCard"
        @recordAdded="$refs.registry_table.addRecord"
        @recordEdited="$refs.registry_table.updateRecord"-->
      </template>
    </div>
  </div>
</template>

<script>
import RegistryCard from '@/components/RegistryCard'
import DashboardSimple from '@/components/Dashboard/simple.vue'
export default {
  props: [
    'opened-cards',
    'show-card'
  ],
  components: {
    DashboardSimple,
    RegistryCard
  },
  name: 'cards'
}
</script>

<style scoped>

</style>
