<script type="ts">

import ElTree from 'element-ui/packages/tree/src/tree.vue'
import Node from 'element-ui/packages/tree/src/model/node'

export default {
  extends: ElTree,
  name: 'ElEditorTree',
  props: {
    elementBuilder: {
      type: Function,
      default: () => {}
    },
    selectCommand: {
      type: Function,
      default: () => {}
    },
    removeCommand: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    addEditorElement(elementData) {
      let me = this;
      let element = this.elementBuilder.create(elementData);
      let parentId = element.getParentId();
      if (parentId !== null) {
        let parentNode = this.getNode(parentId);
        if (!parentNode.loaded) {
          parentNode.expand(function() {
            me.selectEditorNode(elementData);
          });
        } else if (!parentNode.expanded) {
          parentNode.expand(function() {
            me.addEditorNode(elementData, parentNode);
          });
        } else {
          this.addEditorNode(elementData, parentNode);
        }
      } else {
        this.addEditorNode(elementData, this.root);
      }
    },
    addEditorNode(elementData, parentNode) {
      let node = new Node({
        parent: parentNode,
        store: this.store,
        data: elementData,
        expanded: true
      });
      parentNode.childNodes.unshift(node);
      node.loaded = true;  
      node.level = parentNode.level + 1;  
      this.selectEditorNode(elementData);
    },
    selectEditorNode(elementData) {
      let element = this.elementBuilder.create(elementData)
      this.setCurrentKey(element.getId());
      this.selectCommand(elementData);
    },
    removeEditorNode(elementData) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        this.removeCommand(elementData);
      });
    }
  }
}

</script>