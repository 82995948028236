import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import MenuQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/MenuQueryRepositoryInterface'
import store from '@/store'
import { GetRootMenu } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class MenuQueryRepository extends AbstractQueryRepository implements MenuQueryRepositoryInterface
{
    getRootMenu(): Promise<any>
    {
        return store.dispatch(new GetRootMenu());
    }
}