<template>
  <div class="editor-document">
    <el-form-item :label="this.$locale.interface_editor.component_editor.button_action.report" >
      <el-select
        v-model="report"
        value-key="id"
        filterable
        @change="$set(value, 'reports', $event)">
        <el-option
          v-for="(item, index) in reports"
          :key="index"
          :label="item.name"
          :value="item">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import Report from '@/components/ReportEditor/Models/Reports'

export default {
  name: 'editor-report',

  props: {
    value: Object
  },

  data () {
    return {
      report: {
        id: null,
        guid: null,
        name: null
      },
      reports: []
    }
  },

  async mounted () {
    this.reports = await new Report().params({ fields: 'id,guid,name', report_type: 'report' }).$get()

    this.report = Object.assign(this.report, this.value.reports)
  }
}
</script>

<style type="text/css"></style>
