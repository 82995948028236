import Event from '@/services/History/domain/model/Event'
import EventType from '@/services/History/domain/model/EventType'

export default class EventFactory {
  public static build (eventsData: object[] = []): Event[] {
    let events = []

    eventsData.forEach((data : {
      user, event_type, event_date, deltas, fields
    }) => {
      let eventType = EventType[data.event_type.toUpperCase()]

      let deltas = []
      if (eventType === EventType.UPDATE) {
        deltas = data.deltas.map((delta) => this.parseUpdateDelta(delta, data.fields, EventType[data.event_type.toUpperCase()]))
      } else if (eventType === EventType.INSERT) {
        deltas = this.getInsertData(data)
      }

      let event = new Event(
        (data.user ? data.user.name : null),
        eventType,
        new Date(parseInt(data.event_date.$date.$numberLong)),
        deltas
      )
      events.push(event)
    })

    return events
  }

  private static parseUpdateDelta (delta: { field, before, after }, fields: object[], eventType: EventType): object {
    let result = {
      field: null,
      before: null,
      after: null
    }

    result.field = `${fields[delta.field]} (${delta.field})`

    if (Array.isArray(delta.before)) {
      result.before = delta.before.map((item) => `${item.name} (${item.id})`).join(',')
    } else {
      result.before = delta.before
    }
    if (Array.isArray(delta.after)) {
      result.after = delta.after.map((item) => `${item.name} (${item.id})`).join(',')
    } else {
      result.after = delta.after
    }

    return result
  }

  private static getInsertData (event: {fields}): object[] {
    let result = []

    for (let field in event.fields) {
      if (event.fields.hasOwnProperty(field)) {
        if (event[field]) {
          let data = event[field]
          if (Array.isArray(event[field]) && event[field].length > 0) {
            data = data.map((item) => `${item.name} (${item.id})`).join(',')
          } else if (Array.isArray(event[field])) {
            continue
          }

          result.push({
            field: `${event.fields[field]} (${field})`,
            after: data
          })
        }
      }
    }

    return result
  }
}
