<template>
  <el-scrollbar wrap-style="height: calc(100vh - 100px);" class="relates_container">
    <h1 style="margin-top: 10px">{{$locale.logic_editor.relates.headline}}</h1>
    <div class="relates_block__item2">
      <div class="demonstration">{{$locale.menu_editor.label.name}}</div>
      <el-input size="mini" class="relates__row" v-model="relates.name"></el-input>
    </div>
    <div class="relates_block__item">
      <div class="demonstration">{{$locale.logic_editor.relates.relates_registry}}</div>
      <el-select
        value-key="id"
        filterable
        class="relates__row"
        v-model="relates.object_id"
        size="mini"
      >
        <el-option
          v-for="item in listRelaterRegistry"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
    <el-row>
      <el-col :sm="20" :md="22" :lg="17" :xl="16" class="relates_shadow">
        <h1>{{$locale.logic_editor.relates.bind}}</h1>
        <div class="relates_block__item3">
          <div class="demonstration">{{$locale.logic_editor.relates.select}}</div>
          <el-input
            type="textarea"
            class="relates__row"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="relates.sql_statements.select"
          ></el-input>
        </div>
        <div class="relates_block__item3">
          <div class="demonstration">{{$locale.logic_editor.relates.join}}</div>
          <el-input
            type="textarea"
            class="relates__row"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="relates.sql_statements.join"
          ></el-input>
        </div>
        <div class="relates_block__item3">
          <div class="demonstration">{{$locale.logic_editor.relates.group_by}}</div>
          <el-input
            type="textarea"
            class="relates__row"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="relates.sql_statements.groupBy"
          ></el-input>
        </div>
        <div class="relates_block__item3">
          <div class="demonstration">{{$locale.logic_editor.relates.field_alias}}</div>
          <custom-popover-tree :model.sync='relates.alias_field_id' :data='fields.listAlias'></custom-popover-tree>
          <!-- <el-select
          v-else
            filterable
            value-key="id"
            class="relates__row"
            v-model="relates.alias_field_id"
            size="mini"
          >
            <el-option v-for="item in fields.listAlias" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
        </div>
      </el-col>
    </el-row>
    <div class="relates__footer">
      <el-button
        @click="saveCard"
        :disabled='disBtn'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </div>
    <el-row v-show="isVisible">
      <el-col :span="23">
        <div class="relates_accorion">
          <h1>{{$locale.logic_editor.relates.filters}}</h1>
          <el-button
            @click="addFilter"
            class="relates__footer2"
            size="small"
            icon="el-icon-plus"
          >{{$locale.logic_editor.relates.btn_add}}</el-button>
          <el-collapse v-show="numberFilter.length > 0" v-model="activeName" accordion>
            <extraFilter
            v-for="(item, index) in numberFilter"
            :key="item.id"
            :numberFilter="numberFilter[index]"
            :ind="index"
            :fields='fields'
            :propslocal='propslocal'>
              <el-button
                type="danger"
                @click="removeFilter(item, index)"
                plain
                icon="el-icon-delete"
              >{{ $locale.main.button.delete }}</el-button>
            </extraFilter>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  </el-scrollbar>
</template>

<script>
import Registry from '../Models/Registry'
import Relates from '../Models/relates'
import Filters from '../Models/filters'
import extraFilter from './elementRelates/extraFilter'
import customPopoverTree from './customPopoverTree'
export default {
  name: 'relates',
  components: {
    extraFilter,
    customPopoverTree
  },
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  watch: {
    'relates.object_id': async function (val, oldVal) {
      console.log(val)
      if (oldVal) this.relates.alias_field_id = {}
      // список полей реестра ввиде дерева
      let registryTree = await Registry.params({ parent_id: val, show_children: true }).get()
      // let registry = await Registry.params({ object_id: val, is_field: 'true' }).get()
      this.deepSearch(registryTree.data)
      this.fields.listAlias = registryTree.data
    }
  },
  computed: {
    disBtn () {
      if (this.relates.name === '' || Object.keys(this.relates.alias_field_id).length === 0) {
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      // открытое действие
      addAction: this.propslocal.listOpenAction,
      relates: {
        name: '',
        logic_id: this.propslocal.businesslogic.id,
        object_id: null,
        alias_field_id: {},
        sql_statements: {
          select: null,
          join: null,
          group_by: null
        }
      },
      // список связанных реестров
      listRelaterRegistry: [],
      // список полей
      fields: {
        // псевдонимов
        listAlias: [],
        // поле текущей логики
        listCurrentdObject: []
      },
      // количество фильтров
      numberFilter: [],
      activeName: '',
      isVisible: false
    }
  },
  methods: {
    // добавить фильтр
    addFilter () {
      this.numberFilter.push({ isNewFilter: true })
      this.activeName = this.numberFilter.length - 1
    },
    // поиск по дереву имя поля
    deepSearch (arr) {
      for (let el of arr) {
        if (this.relates.alias_field_id === el.id) {
          this.relates.alias_field_id = el
          break
        }
        if (el.children instanceof Array) this.deepSearch(el.children)
      }
    },
    // удалить выбранный компнонент
    removeFilter (item, index) {
      this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
        .then(async () => {
          let filter = new Filters({ id: item.id })
          await filter.delete()
          const ind = this.numberFilter.indexOf(item)
          this.numberFilter.splice(ind, 1)
        })
    },
    async saveCard () {
      this.isVisible = true
      // новое subAction
      if (this.propslocal.isNewAction) {
        let relates = new Relates({}, this.relates)
        relates.alias_field_id = this.relates.alias_field_id.id
        let response = await relates.save()
        this.addAction.items.push(response)
      } else {
        let id = this.propslocal.selectSubAction.id
        for (let key in this.relates.sql_statements) {
          if (this.relates.sql_statements[key] === '') this.relates.sql_statements[key] = null
        }
        let relates = new Relates({ id: id }, this.relates)
        relates.alias_field_id = this.relates.alias_field_id.id
        let response = await relates.save()
        this.addAction.items
          ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
          : (this.addAction.filter(el => el.id === id)[0].name = response.name)
      }
    }
  },
  async mounted () {
    // список связанных реестров
    let registry = await Registry.params({ is_object: true }).get()
    this.listRelaterRegistry = registry.data
    // поля текущей логики
    const id = this.propslocal.businesslogic.registry_id
    let currentdObject = await Registry.params({ parent_id: id, show_children: true }).get()
    // let currentdObject = await Registry.params({ object_id: id, is_field: 'true' }).get()
    this.fields.listCurrentdObject = currentdObject.data
    // редактирование
    if (!this.propslocal.isNewAction) {
      console.log('редактирование')
      this.isVisible = true
      this.relates = this.propslocal.getAnswer
      for (let i = 0; i < this.relates.filters.length; i++) {
        this.numberFilter.push(this.relates.filters[i])
      }
    }
  }
}
</script>

<style>
.relates_container {
  margin-left: 10px;
}
.relates_block__item2 {
  margin-top: 30px;
  width: 65%;
}
.relates_block__item {
  margin-top: 25px;
  width: 65%;
}
.relates_block__item3 {
  margin-top: 15px;
  width: 71%;
}
.relates_container .demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.relates__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
.relates__footer {
  text-align: right;
  margin-top: 35px;
  margin-right: 25%;
  margin-bottom: 15px;
}
.relates__footer2 {
  margin-bottom: 15px;
}
.relates_shadow {
  margin: 20px;
  margin-left: 2px;
  padding: 20px;
  text-align: left;
  box-shadow: -2px 4px 11px 6px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.relates_accorion {
  margin-bottom: 50px
}
.relates_accorion .el-collapse-item__header {
  background-color: #eeeeff;
  color: #000000;
  cursor: pointer;
  border-bottom: 1px solid #a89797;
}
/* .relates__row2 {
  display: inline-block;
  position: relative;
  width: 29%;
  margin: 10px;
  margin-top: 0px;
} */
</style>
