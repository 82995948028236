<template>
  <div class="tree_element_layout">
    <div class="tool_box">
      <el-dropdown @command="add($event)" trigger="click">
        <el-button style="display: block;" size="small">
          {{ $locale.main.button.add }}<span class="el-icon-arrow-down el-icon--right"></span>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="group">{{ $locale.task_editor.element_types.rule_group }}</el-dropdown-item>
          <el-dropdown-item command="rule">{{ $locale.task_editor.element_types.rule }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-scrollbar class="tree-scroll">
      <el-tree
        ref="tree"
        lazy
        :props="treeProps"
        :load="loadNode"
        node-key="id"
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span
            :class="{ 'selected-node': selectedNode !== null && selectedNode.data.id === data.id }"
            style="width: 100%;"
            @click="selected(node)">
            <span :class="treeIcon[data.element_type]"></span> {{ node.label }}
          </span>
          <span v-if="selectedNode !== null && selectedNode.data.id === data.id">
            <el-dropdown
              v-if="selectedNode.data.element_type === 'group'"
              @command="add($event, node)"
              trigger="click">
              <el-button
                class="text_success"
                type="text"
                icon="el-icon-plus"
                size="mini">
                {{ $locale.main.button.add }}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="group">{{ $locale.task_editor.element_types.rule_group }}</el-dropdown-item>
                <el-dropdown-item command="rule">{{ $locale.task_editor.element_types.rule }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="text"
              size="mini"
              icon="el-icon-edit"
              @click="edit(node, data)">
              {{ $locale.main.button.edit }}
            </el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="remove(node, data)"
              class="text_danger">
              {{ $locale.main.button.delete }}
            </el-button>
          </span>
        </span>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
import TreeElement from '@/services/TaskEditor/infrastructure/domain/model/TreeElement'
import Node from 'element-ui/packages/tree/src/model/node'
export default {
  name: 'TreeElementLayout',

  props: {
    createElement: {
      type: Function,
      default: function (elementType, parentNode) {
      }
    },

    updateElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    deleteElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    cancel: {
      type: Function,
      default: function cancel () {
      }
    }
  },

  data () {
    return {
      selectedNode: null,

      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },

      treeIcon: {
        rule: 'el-icon-document-checked',
        group: 'el-icon-folder'
      }
    }
  },

  mounted () {
  },

  methods: {
    getTree () {
      return this.$refs.tree
    },

    selected (node) {
      if (this.selectedNode === node) {
        this.selectedNode = null
        this.cancel()
        return
      }

      if (this.selectedNode !== node) {
        this.cancel()
      }

      this.selectedNode = node
    },

    addNode (model, parentNode) {
      if (parentNode) {
        this.$refs.tree.append(model, parentNode)
      } else {
        let node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: model
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    updateNode (node) {
      this.selectedNode = node
    },

    add (elementType = null, parentNode = null) {
      this.selectedNode = parentNode
      this.createElement(elementType, parentNode)
    },

    edit (node, data) {
      this.selectedNode = node

      this.updateElement(node, data)
    },

    remove (node, data) {
      this.$confirm(this.$locale.etl_editor.form.messages.delete, this.$locale.etl_editor.form.messages.warningTitle, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        this.deleteElement(node, data)
        this.$refs.tree.remove(node)
      })
    },

    async loadNode (node, resolve) {
      resolve(await this.loadElements(node.level === 0 ? null : node.data.id))
    },

    async loadElements (entityId = null) {
      if (entityId) {
        return await new TreeElement().params({ parent_id: entityId }).$get()
      }

      return await new TreeElement().$get()
    }
  }
}
</script>

<style type="text/css">
  .el-dropdown+.el-button {
    margin-left: 10px;
  }
</style>
