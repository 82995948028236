<template>
  <el-dialog
    title="Добавить источник"
    :visible.sync="show"
    width="30%"
    :before-close="_close">
    <span>
      <el-form :model="value" :rules="formRules" ref="form" size="mini">
        <el-form-item :label="$locale.report_editor.form.select_source_type" prop="report_source_type_id">
          <el-select
            style="width: 100%;"
            v-model="value.report_source_type_id"
            :placeholder="$locale.report_editor.form.select_source_type"
            @change="_sourceTypeChange">
            <el-option
              v-for="item in reportSourceTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="value.report_source_type_id !== null"
          :label="$locale.report_editor.form.source"
          prop="properties.id">
          <template v-if="value.report_source_type_id === 'extended_object'">
            <el-select
              style="width: 100%;"
              v-model="value.properties.id"
              :placeholder="$locale.report_editor.object_types.extended_object"
              @change="_selectedExtendedObject">
              <el-option
                v-for="item in extendedObjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>

          <template v-if="value.report_source_type_id === 'query'">
            <el-select
              style="width: 100%;"
              v-model="value.properties.id"
              :placeholder="$locale.report_editor.object_types.query"
              @change="_selectedQuery">
              <el-option
                v-for="item in queryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>

          <template v-if="value.report_source_type_id === 'registry'">
            <registry-select-tree
              style="width: 100%;"
              type="registry"
              v-model="value.properties"
              :placeholder="$locale.report_editor.object_types.registry"/>
          </template>
        </el-form-item>
        <el-form-item
          v-if="['document', 'xml'].indexOf(reportType) !== -1"
          :label="$locale.report_editor.form.alias"
          prop="properties.alias">
          <el-input
            :placeholder="$locale.report_editor.form.alias"
            v-model="value.properties.alias"
            @input="aliasChange">
          </el-input>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button type="default" @click="_close">
        {{ $locale.main.button.cancel }}
      </el-button>
      <el-button type="primary" @click="_save">
        {{ $locale.main.button.save }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'SourceAppendForm',

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    reportType: {
      type: String,
      default () {
        return 'report'
      }
    },

    reportSourceTypes: {
      type: Array,
      default () {
        return []
      }
    },

    extendedObjectList: {
      type: Array,
      default () {
        return []
      }
    },

    queryList: {
      type: Array,
      default () {
        return []
      }
    },

    save: {
      type: Function,
      default () {
        return function () {
        }
      }
    },

    close: {
      type: Function,
      default () {
        return function () {
        }
      }
    }
  },

  components: {
    RegistrySelectTree
  },

  watch: {
    value () {
      this.localValue = this.value
    },
    'value.properties': (value, oldValue) => {
      if (value.id !== oldValue.id) {
        value.alias = !value.name ? null : value.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
        value.relation = []
      }
    }
  },

  data () {
    return {
      show: false,

      localValue: this.value,

      formRules: {
        report_source_type_id: [
          {
            required: true,
            message: this.$locale.report_editor.errors.report_source_type_id,
            trigger: 'change'
          }
        ],
        'properties.id': [
          {
            required: true,
            message: this.$locale.report_editor.errors.properties_id,
            trigger: 'change'
          }
        ],
        'properties.alias': [
          {
            required: true,
            message: this.$locale.report_editor.errors.properties_alias,
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    open () {
      this.show = true
    },

    _save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.save()
          this.show = false
        }
      })
    },

    _close (done) {
      this.close()

      this.show = false
    },

    _sourceTypeChange (value) {
      this.localValue.properties = {
        id: null,
        name: null,
        alias: null
      }
      if (this.localValue.parent_id) {
        this.localValue.properties.relation = []
      }
    },

    _selectedExtendedObject (value) {
      let extendedObject = this.extendedObjectList.find(item => value === item.id)

      this.localValue.properties.name = extendedObject.name
      this.localValue.properties.alias = extendedObject.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    _selectedQuery (value) {
      let query = this.queryList.find(item => value === item.id)

      this.localValue.properties.name = query.name
      this.localValue.properties.alias = query.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    aliasChange (value) {
      value = value.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.localValue.properties.alias = value
    }
  }
}
</script>

<style type="text/css"></style>
