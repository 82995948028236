<template>
  <div>
    <span class="registry_fields_label" style="display: inline-block">{{ label }}</span>
    <el-tooltip v-if="options && options.tooltip.show" placement="top-start" effect="dark" :content="options.tooltip.content">
      <div slot="content" v-html="options.tooltip.content"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>
      <el-input size="small" v-model="local_value" v-on:input="$emit('change', local_value)"></el-input>
  </div>
</template>

<script>
export default {
  data () {
    return {
      local_value: this.value
    }
  },
  props: {
    value: String,
    options: Object,
    label: String
  },
  name: 'editor-string'
}
</script>

<style scoped>

</style>
