<template>
  <selectTree
    :clearable="false"
    :standartTree="true"
    :dataTable="dataTable"
    :selectAlias="selectAlias"
    :value="localValue"
    @input="localValue = $event"/>
</template>

<script>
import selectTree from '@/components/LogicEditor/components/treeSelect'
import Registry from '@/components/LogicEditor/Models/Registry'
export default {
  name: 'CurrentTableField',

  props: {
    value: Number,
    objectId: Number
  },

  components: {
    selectTree
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      dataTable: []
    }
  },

  async mounted () {
    this.dataTable = await Registry.params({ parent_id: this.objectId, show_children: true }).$get()
  },

  methods: {
    selectAlias (data) {
      this.$emit('input', data.id)
    }
  }
}
</script>

<style scoped>

</style>
