<template>
  <div class="rule_field_form">
    <el-form ref="ruleFieldForm" :model="model" :rules="rules" label-position="top" size="mini">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item>
            <el-checkbox v-model="model.is_parameter" @change="changeParameter">{{ $locale.task_editor.labels.parameter }}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <template v-if="model.is_parameter">
            <el-form-item prop="properties.name">
              <el-input v-model="model.properties.name" :placeholder="$locale.task_editor.labels.parameter_name"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item prop="field_id">
              <registry-select-tree
                style="width: 100%;"
                type="field"
                value-as="number"
                v-model="model.field_id"
                :parent-id="objectId"
                :placeholder="$locale.task_editor.labels.field" />
            </el-form-item>
          </template>
        </el-col>
        <el-col :span="9">
          <template v-if="model.is_parameter">
            <el-form-item prop="properties.value">
              <el-input v-model="model.properties.value" :placeholder="$locale.task_editor.labels.parameter_value"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item prop="rule_field_type_id">
              <el-select v-model="model.rule_field_type_id" :placeholder="$locale.task_editor.labels.field_type">
                <el-option
                  v-for="item in ruleFieldTypeList"
                  :key="item.id"
                  :label="$locale.task_editor.rule_field_types[item.name]"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="model.rule_field_type_id === 'condition'"
              prop="condition_field_type_id">
              <el-select v-model="model.condition_field_type_id" :placeholder="$locale.task_editor.labels.condition">
                <el-option
                  v-for="item in conditionFieldTypeList"
                  :key="item.id"
                  :label="$locale.task_editor.condition_types[item.name]"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else-if="model.rule_field_type_id === 'users_table'"
              prop="users_field_id">
              <users-table v-model="model.users_field_id" :placeholder="$locale.task_editor.labels.users_table"></users-table>
            </el-form-item>
            <el-form-item
              v-else-if="model.rule_field_type_id === 'current_table_field'"
              prop="current_table_field_id">
              <current-table-field v-model="model.current_table_field_id" :object-id="objectId"></current-table-field>
            </el-form-item>
            <el-form-item
              v-else-if="model.rule_field_type_id === 'external_table_field'"
              prop="properties">
              <external-table-field v-model="model.properties"></external-table-field>
            </el-form-item>
            <el-form-item
              v-else-if="model.rule_field_type_id === 'parameter'"
              prop="properties.name">
              <el-input v-model="model.properties.name" :placeholder="$locale.task_editor.labels.parameter_name"></el-input>
            </el-form-item>
          </template>
        </el-col>
        <el-col :span="2">
          <div style="text-align: right;">
            <slot></slot>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import UsersTable from './fields/UsersTable'
import ExternalTableField from './fields/ExternalTableField'
import CurrentTableField from './fields/CurrentTableField'

export default {
  name: 'RuleFieldForm',

  components: {
    RegistrySelectTree,
    UsersTable,
    ExternalTableField,
    CurrentTableField
  },

  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {
          field_id: null,
          rule_field_type_id: null,
          condition_field_type_id: null,
          users_field_id: null,
          current_table_field_id: null,
          external_table_field_id: null,
          is_parameter: false,
          properties: {
            name: null,
            value: null
          }
        }
      }
    },

    objectId: {
      type: Number,
      default: null
    }
  },

  watch: {
    value () {
      this.model = this.value
    }
  },

  computed: {
  },

  data () {
    return {
      model: this.value,

      rules: {
        field_id: [
          { required: true, message: 'Please select field', trigger: 'change' }
        ],
        rule_field_type_id: [
          { required: true, message: 'Please select type', trigger: 'change' }
        ],
        condition_field_type_id: [
          { required: true, message: 'Please select condition type', trigger: 'change' }
        ],
        users_field_id: [
          { required: true, message: 'Please select users field', trigger: 'change' }
        ],
        current_table_field_id: [
          { required: true, message: 'Please select current table field', trigger: 'change' }
        ],
        external_table_field_id: [
          { required: true, message: 'Please select external table field', trigger: 'change' }
        ],
        'properties.name': [
          { required: true, message: 'Please input parameter name', trigger: 'blur' }
        ],
        'properties.value': [
          { required: true, message: 'Please input parameter value', trigger: 'blur' }
        ]
      },

      ruleFieldTypeList: [
        {
          'id': 'constant',
          'name': 'constant'
        },
        {
          'id': 'current_user',
          'name': 'current_user'
        },
        {
          'id': 'current_datetime',
          'name': 'current_datetime'
        },
        {
          'id': 'parameter',
          'name': 'parameter'
        },
        {
          'id': 'condition',
          'name': 'condition'
        },
        {
          'id': 'users_table',
          'name': 'users_table'
        },
        {
          'id': 'current_table_field',
          'name': 'current_table_field'
        },
        {
          'id': 'external_table_field',
          'name': 'external_table_field'
        }
      ],
      conditionFieldTypeList: [
        {
          'id': 'empty',
          'name': 'empty'
        },
        {
          'id': 'not_empty',
          'name': 'not_empty'
        }
      ]
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.ruleFieldForm.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    changeParameter (value) {
      this.$refs.ruleFieldForm.resetFields()
      this.model = {
        field_id: null,
        rule_field_type_id: null,
        condition_field_type_id: null,
        users_field_id: null,
        current_table_field_id: null,
        external_table_field_id: null,
        is_parameter: value,
        properties: {
          name: null,
          value: null
        }
      }
    }
  }
}
</script>

<style type="text/css"></style>
