import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'

export enum ActionTypes {
    ADD_XML_ROUTE = 'add_xml_route',
    UPDATE_XML_ROUTE = 'update_xml_route',
    DELETE_XML_ROUTE = 'delete_xml_route',
    GET_XML_ROUTES = 'get_xml_routes',
    COUNT_XML_ROUTES = 'count_xml_routes',
    GET_XML_ROUTE_BY_GUID = 'get_xml_route_by_guid'
}

export class AddXmlRoute implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.ADD_XML_ROUTE
    constructor(public payload: XmlRouteDTO){}
}

export class UpdateXmlRoute implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.UPDATE_XML_ROUTE
    constructor(public payload: XmlRouteDTO){}
}

export class DeleteXmlRoute implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.DELETE_XML_ROUTE
    constructor(public guid: string){}
}

export class GetXmlRoutes implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.GET_XML_ROUTES
    constructor(public payload: object){}
}

export class CountXmlRoutes implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.COUNT_XML_ROUTES
    constructor(public payload: object){}
}

export class GetXmlRouteByGuid implements FluxStandardAction {
    type = 'XmlRoute/' + ActionTypes.GET_XML_ROUTE_BY_GUID
    constructor(public guid: string){}
}