<template>
  <div :style="css">
      <el-checkbox :value="value"></el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'boolean_field',
  props: {
    value: Boolean,
    css: String
  }
}
</script>

<style scoped>

</style>
