<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.url">
    <el-input v-model="localPorperties.value"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'url',
  props: ['properties'],
  watch: {
    localPorperties: {
      handler: function (val) {
        // console.log(val)
        this.$emit('properties', val)
      },
      deep: true
    },
    properties: {
      handler: function (val) {
        this.localPorperties = JSON.parse(val) || {}
      },
      deep: true
    }
  },
  data () {
    return {
      localPorperties: JSON.parse(this.properties) || {}
    }
  }
}
</script>

<style>
.subMenu .el-form-item__content {
  width: 100%;
}
</style>
