
import ElForm from 'element-ui/packages/form/src/form.vue'
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import ElInput from 'element-ui/packages/input/src/input.vue'
import ElTabs from 'element-ui/packages/tabs/src/tabs.vue'
import ElTabPane from 'element-ui/packages/tabs/src/tab-pane.vue'
import ElDropdown from 'element-ui/packages/dropdown/src/dropdown.vue'
import ElDropdownMenu from 'element-ui/packages/dropdown/src/dropdown-menu.vue'
import ElDropdownItem from 'element-ui/packages/dropdown/src/dropdown-item.vue'
import ElButtonGroup from 'element-ui/packages/button/src/button-group.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import ElRow from 'element-ui/packages/row/src/row.js'
import ElCol from 'element-ui/packages/col/src/col.js'
import SelectOptions from '@/core/infrastructure/components/SelectOptions.vue'
import Treeselect from '@bingosoftnn/vue-treeselect'
import Layer, { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'
import SourceInformationPanel from '@/services/MapEditor/infrastructure/components/SourceInformationPanel/index.vue'
import ProjectCoordinateSystemsQuery from '@/services/MapEditor/application/query/ProjectCoordinateSystemsQuery'

export default {
  name: 'LayerForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  data() {
    return {
      dto: null,
      source: null,
      inputValue: null,
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        source_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        srs_handling_type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      projectNativeCoordinateSystemOptions: [],
      projectDeclaredCoordinateSystemOptions: [],
      srsHandlingOptions: [{
        id: 'forced_declared',
        name: this.$locale.map_editor.layer_form.forced_declared
      },{
        id: 'reproject',
        name: this.$locale.map_editor.layer_form.reproject
      },{
        id: 'keep_native',
        name: this.$locale.map_editor.layer_form.keep_native
      }]
    }
  },
  methods: {
    createTabs() {
      return this.$createElement(ElTabs, {
        props: {
          tabPosition: 'left'
        }
      }, [
        this.createInformationTab(),
        this.createSourceTab(),
        this.createStyleTab()
      ]);
    },
    createInformationTab() {
      let me = this;
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.information
        }
      }, [
        this.createForm()
      ]);
    },
    createForm() {
      return this.$createElement(ElForm, {
        props: {
          model: this.dto,
          rules: {
            name: {
              required: true,
              message: this.$locale.main.message.required_field,
              trigger: 'change'
            },
            source_id: {
              required: true,
              message: this.$locale.main.message.required_field,
              trigger: 'change'
            }
          }
        },
        class: {
          "layer-form-inner-body": true,
          "custom_scrollbar": true
        },
        style: {
          "overflow-y": "auto"
        }
      }, this.createFormItems());
    },
    createFormItems() {
      let me = this;
      return [
        this.$createElement(ElFormItem, {
          props: {
            prop: 'name',
            label: this.$locale.main.fields.name
          }
        },[
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              value: this.dto.name
            },
            on: {
              input: (value) => {
                this.dto.name = value;
              }
            }
          },[])
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'description',
            label: this.$locale.main.fields.description
          }
        }, [
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              type: 'textarea',
              value: this.dto.description
            },
            on: {
              input: (value) => {
                this.dto.description = value;
              }
            }
          })
        ]),
        this.$createElement('fieldset', {},[
          this.$createElement('legend', {}, [
            this.$createElement('div', {
              class: {
               'el-form-item__label': true
              },
              style: {
                "width": "100%",
                "text-align": "left"
              }
            }, [
              this.$locale.map_editor.entities.coordinate_system
            ])
          ]), ...this.createCoordinateSystemFields() 
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'source_id',
            label: this.$locale.map_editor.layer_form.source
          },
          class: {
            "hidden-input": true
          }
        }, [
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              value: this.dto.source_id
            },
            on: {
              input: (value) => {
                this.dto.source_id = value;
              }
            }
          })
        ])
      ]
    },
    createCoordinateSystemFields() {
      let fields = [];
      let declaredCs = this.$createElement(ElRow, {
        },[
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
              props: {
                label: this.$locale.map_editor.layer_form.declared_cs,
              }
            },[
              this.$createElement(Treeselect, {
                props: {
                  placeholder: this.$locale.main.fields.select,
                  noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                  loadingText: this.$locale.main.fields.loading_text,
                  noResultsText: this.$locale.main.fields.no_results_text,
                  normalizer: (node) => {return {id: node.srid, label: node.auth_name}},
                  searchable: true,
                  cacheOptions: true,
                  appendToBody: true,
                  options: this.projectDeclaredCoordinateSystemOptions,
                  value: this.dto.declared_coordinate_system_id
                },
                on: {
                  open: async () => {
                    this.projectDeclaredCoordinateSystemOptions = await this.getQueryBus().execute(
                      new ProjectCoordinateSystemsQuery(this.projectGuid)
                    );
                  }
                }
              })
            ])            
          ]),
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
            },[
              this.$createElement(SelectOptions, {
                props: {
                  addRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system', {'parentFormId': this.formId, 'field': 'declaredCoordinateSystem'});
                  },
                  selectRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system_select', {'parentFormId': this.formId, 'field': 'declaredCoordinateSystem'});
                  }
                }
              })
            ])
          ])
      ]);
      let nativeCs = this.$createElement(ElRow, {
        },[
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
              props: {
                label: this.$locale.map_editor.layer_form.native_cs,
                disabled: true
              }
            },[
              this.$createElement(Treeselect, {
                props: {
                  disabled: false,
                  placeholder: this.$locale.main.fields.select,
                  noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                  loadingText: this.$locale.main.fields.loading_text,
                  noResultsText: this.$locale.main.fields.no_results_text,
                  normalizer: (node) => {return {id: node.srid, label: node.auth_name}},
                  searchable: true,
                  cacheOptions: true,
                  appendToBody: true,
                  options: this.projectNativeCoordinateSystemOptions,
                  value: this.dto.native_coordinate_system_id
                },
                on: {
                  open: async () => {
                    this.projectNativeCoordinateSystemOptions = await this.getQueryBus().execute(
                      new ProjectCoordinateSystemsQuery(this.projectGuid)
                    );
                  }
                }
              })
            ])            
          ]),
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
            },[
              this.$createElement(SelectOptions, {
                props: {
                  addRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system', {'parentFormId': this.formId, 'field': 'nativeCoordinateSystem'});
                  },
                  selectRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system_select', {'parentFormId': this.formId, 'field': 'nativeCoordinateSystem'});
                  }
                }
              })
            ])
          ])
      ]);
      let srsHandling = this.$createElement(ElCol, {
          props: {
            span: 12
          }
        },[
          this.$createElement(ElFormItem, {
            props: {
              label: this.$locale.map_editor.layer_form.srs_handling_cs,
            }
          },[
            this.$createElement(Treeselect, {
              props: {
                placeholder: this.$locale.main.fields.select,
                noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                loadingText: this.$locale.main.fields.loading_text,
                noResultsText: this.$locale.main.fields.no_results_text,
                normalizer: (node) => {return {id: node.id, label: node.name}},
                searchable: false,
                cacheOptions: true,
                appendToBody: true,
                options: this.srsHandlingOptions,
                value: this.dto.srs_handling_type,
                clearable: false,
                deleteRemoves: false,
                backspaceRemoves: false
              }
            })
          ])            
      ]);
      return [nativeCs, declaredCs, srsHandling];
    },
    createSourceTab() {
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.source
        }
      }, [
        this.$createElement("div", {
          class: {
            "layer-source-options": true,
            "custom_scrollbar": true
          },
          style: {
            "overflow-y": "auto"
          }
        }, [
          ...this.createSourceOptions(),
          this.createSourceInformationPanel()         
        ])
      ]);
    },
    createSourceOptions() {      
      let options = [
        this.$createElement(ElDropdown, {
          props: {
            placement: "bottom-start",
            trigger: "click"
          },
          on: {
            command: (type) => {
              if (type == 'add_new') {
                this.addSource()
              } else if (type == 'select_existing') {
                this.selectSource()
              }
            }
          }
        },[
          this.$createElement(ElButton, {
            props: {
              type: "info",
              plain: true,
              icon: "el-icon-plus"
            }
          },[]),
          this.$createElement(ElDropdownMenu, {            
          },[
            this.$createElement(ElDropdownItem, {  
              props: {
                command: "add_new"
              }
            },[this.$locale.main.button.add_new]),
            this.$createElement(ElDropdownItem, {  
              props: {
                command: "select_existing"
              }
            },[this.$locale.main.button.select_existing])
          ])
        ]),
        this.$createElement(ElButton, {
            props: {
              type: "info",
              plain: true,
              icon: "el-icon-edit",
              disabled: !this.source
            },
            style: {
              "margin-left": "8px"
            },
            on: {
              click: () => {
                this.editSource()
              }
            }
        },[])
      ];

      return options;
    },
    createStyleTab() {
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.style
        }
      }, [
        
      ]);
    },
    addSource() {
      this.getEventBus().$emit('createForm', 'source', {'parentFormId': this.formId});
    },
    editSource() {
      this.getEventBus().$emit('createForm', 'source_edit', {'parentFormId': this.formId, 'source': this.source});
    },
    selectSource() {
      this.getEventBus().$emit('createForm', 'source_select', {'parentFormId': this.formId});
    },
    sourceCreated(source, formId) {
      this.source = null;
      if (this.formId == formId) {
        this.source = source;
        this.dto.source_id = source.getId();
      }
    },
    coordinateSystemCreated(coordinateSystem, formId, field) {
      if (this.formId == formId) {
        let value = coordinateSystem.getSrid();
        let item = [{
          "srid": value,
          "auth_name": coordinateSystem.getName()
        }];        
        if (field == 'nativeCoordinateSystem') {
          this.projectNativeCoordinateSystemOptions = item;
          this.dto.native_coordinate_system_id = value;
        } else if (field == 'declaredCoordinateSystem') {
          this.projectDeclaredCoordinateSystemOptions = item;
          this.dto.declared_coordinate_system_id = value;
        }
      }
    },
    createSourceInformationPanel() {
      if (this.source) {
        return this.$createElement(SourceInformationPanel, {
          props: {
            source: this.source
          }
        });
      }
    },
    setReactiveProperties() {
      this.$set(this.dto, 'name', "");
      this.$set(this.dto, 'description', "");
      this.$set(this.dto, 'source_id', null);
      this.$set(this.dto, 'srs_handling_type', this.dto.srs_handling_type);
      this.$set(this.dto, 'native_coordinate_system_id', null);
      this.$set(this.dto, 'declared_coordinate_system_id', null);   
    },
    submit(callback) {

      /*let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          
        }
      });*/
    }
  },
  render (createElement) {
    return createElement("div", {
      class: {
          "layer-form": true
      }
    }, [this.dto ? this.createTabs() : null]);
  },
  mounted() {
    this.dto = new LayerDTO();
    this.setReactiveProperties();
    this.getEventBus().$on('sourceCreated', this.sourceCreated);
    this.getEventBus().$on('sourceSelected', this.sourceCreated);
    this.getEventBus().$on('coordinateSystemCreated', this.coordinateSystemCreated);
    this.getEventBus().$on('coordinateSystemSelected', this.coordinateSystemCreated);
  }
}
