

















export default {
  name: 'MapPanel',
  components: {
  },
  data () {
    return {
      center: [43.9567765, 56.2441181],
      zoom: 10,
      rotation: 0,
      baseLayers: [{
        name: 'osm',
        title: 'OpenStreetMap',
        visible: true
      }]
    }
  },
  inject: ['getEventBus'],
  methods: {
    mapUpdateSize () {
      setTimeout(() => { this.$refs.map.updateSize() })
    }
  },
  mounted () {
    this.getEventBus().$on('mapUpdateSize', this.mapUpdateSize)
  }
}
