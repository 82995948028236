
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class UserDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    role_id?: Nullable<number>;
    name?: Nullable<string>;
    midname?: Nullable<string>;
    surname?: Nullable<string>;
    avatar?: Nullable<File>;
    avatar_id?: Nullable<number>;
    is_avatar_deleted?: Nullable<boolean>;
    login?: Nullable<string>;
    password?: Nullable<string> = "";
    is_admin?: Nullable<boolean>;
    is_blocked?: Nullable<boolean>;
    is_system?: Nullable<boolean>;
    api_key?: Nullable<string> = "";
    system_ips?: Array<string> = [];

    constructor({ id, guid, role_id, name, midname, surname, avatar, avatar_id, is_avatar_deleted, login, is_admin, is_blocked, is_system, api_key, system_ips}: {id?: Nullable<number>, guid?: Nullable<string>, role_id?: Nullable<number>, name?: Nullable<string>, midname?: Nullable<string>, surname?: Nullable<string>, avatar?: Nullable<File>, avatar_id?: Nullable<number>, is_avatar_deleted?: Nullable<boolean>, login?: Nullable<string>, is_admin?: Nullable<boolean>, is_blocked?: Nullable<boolean>, is_system?: Nullable<boolean>, api_key?: Nullable<string>, system_ips?: Array<string>}) {
        this.id = id;
        this.guid = guid;
        this.role_id = role_id;      
        this.name = name;
        this.midname = midname;
        this.surname = surname;
        this.avatar = avatar;
        this.avatar_id = avatar_id;
        this.is_avatar_deleted = is_avatar_deleted ?? false;
        this.login = login;
        this.password = "";
        this.is_admin = is_admin ?? false;
        this.is_blocked = is_blocked ?? false;
        this.is_system = is_system ?? false;
        this.api_key = api_key;
        this.system_ips = system_ips ?? [];
    }
}

export default class User extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private roleId: number;
    private name: string;
    private midname: Nullable<string>;
    private surname: Nullable<string>;
    private avatar: Nullable<File>;
    private avatarId: Nullable<number>;
    private isAvatarDeleted: boolean;
    private login: Nullable<string>;
    private password: Nullable<string>;
    private isAdmin: boolean;
    private isBlocked: boolean;
    private isSystem: boolean;
    private apiKey: Nullable<string>;
    private systemIps: Array<string>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        roleId: number,
        name: string,
        midname: Nullable<string>,
        surname: Nullable<string>,
        avatar: Nullable<File>,        
        avatarId: Nullable<number>,
        isAvatarDeleted: boolean,
        login: Nullable<string>,    
        password: Nullable<string>,      
        isAdmin: boolean,
        isBlocked: boolean,
        isSystem: boolean,
        apiKey: Nullable<string>,
        systemIps: Array<string>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.roleId = roleId;  
        this.name = name;        
        this.midname = midname;
        this.surname = surname;
        this.avatar = avatar;
        this.avatarId = avatarId;
        this.isAvatarDeleted = isAvatarDeleted;
        this.login = login;
        this.password = password;
        this.isAdmin = isAdmin;
        this.isBlocked = isBlocked;
        this.isSystem = isSystem;
        this.apiKey = apiKey;
        this.systemIps = systemIps;
    }

    static create(dto: UserDTO): User
    {
        return new User(
            dto.id,
            dto.guid,
            dto.role_id,
            dto.name,
            dto.midname,
            dto.surname,
            dto.avatar,
            dto.avatar_id,
            dto.is_avatar_deleted,
            dto.login,
            dto.password,
            dto.is_admin,
            dto.is_blocked,
            dto.is_system,
            dto.api_key,
            dto.system_ips
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setRoleId(roleId: number): void
    {
        this.roleId = roleId;
    }

    getRoleId(): number
    {
        return this.roleId;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setMidname(midname: Nullable<string>): void
    {
        this.midname = midname;
    }

    getMidname(): string
    {
        return this.midname;
    }

    setSurname(surname: Nullable<string>): void
    {
        this.surname = surname;
    }

    getSurname(): string
    {
        return this.surname;
    }

    setAvatar(avatar: File): void
    {
        this.avatar = avatar;
    }

    getAvatar(): Nullable<File>
    {
        return this.avatar;
    }

    setAvatarId(avatarId: number): void
    {
        this.avatarId = avatarId;
    }

    getAvatarId(): number
    {
        return this.avatarId;
    }

    getIsAvatarDeleted(): boolean
    {
        return this.isAvatarDeleted;
    }

    setLogin(login: Nullable<string>): void
    {
        this.login = login;
    }

    getLogin(): string
    {
        return this.login;
    }

    setIsAdmin(isAdmin: boolean): void
    {
        this.isAdmin = isAdmin;
    }

    getIsAdmin(): boolean
    {
        return this.isAdmin;
    }

    setIsBlocked(isBlocked: boolean): void
    {
        this.isBlocked = isBlocked;
    }

    getIsBlocked(): boolean
    {
        return this.isBlocked;
    }

    setIsSystem(isSystem: boolean): void
    {
        this.isSystem = isSystem;
    }

    getIsSystem(): boolean
    {
        return this.isSystem;
    }

    setPassword(password: Nullable<string>): void
    {
        this.password = password;
    }

    getPassword(): string
    {
        return this.password;
    }

    setApiKey(apiKey: Nullable<string>): void
    {
        this.apiKey = apiKey;
    }

    getApiKey(): string
    {
        return this.apiKey;
    }

    setSystemIps(systemIps: Array<string>): void
    {
        this.systemIps = systemIps;
    }

    getSystemIps(): Array<string>
    {
        return this.systemIps;
    }
}

export class UserUploadDTO
{
    role_id?: Nullable<number>;
    is_login_dynamic?: Nullable<boolean>;
    upload_file?: Nullable<File>;
}