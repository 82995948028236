<template>
    <div style="height: 100%;">
        <el-dialog width="30%" :title="$locale.registry.approvals.added_approve"
                   :visible.sync="approvalAddUserDialogVisible">
            <el-select filterable style="width: 100%" v-model="approvalAddUserModel">
                <el-option
                        v-for="item in approvalUsers"
                        :key="item.value"
                        :label="item.full_name"
                        :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="addApprovalUser">{{ $locale.main.button.ok }}</el-button>
            </span>
        </el-dialog>
        <el-dropdown trigger="click" @command="handleOperation"
                     style="position: absolute; right: 16px; margin-top: 40px; z-index: 3;"
                     v-if="operations.filter(item => item.visible).length > 0">
          <span class="el-dropdown-link">
              <el-button type="primary">
                  {{$locale.registry.approvals.title}}
              <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                      :command="{type: 'add_user', stage_id: operations.filter(item => item.visible)[0].stage_id}">
                  {{$locale.registry.approvals.add_approve}}
              </el-dropdown-item>
              <el-divider></el-divider>
              <el-dropdown-item :command="operation"
                                v-for="(operation, index) in operations.filter(item => item.visible)" :key="index">
                  {{ operation.name }}
              </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--<div style="height: calc(100% - 46px);">-->
        <el-form ref="validateForm" v-loading="loading" :element-loading-text="$locale.main.message.loading"
                 :model="data" style="height: calc(100% - 46px);" @submit.native.prevent>
            <InterfaceViewer
                    :registry-record-id="activeRecordId"
                    :registry-id="registryId"
                    :model="data"
                    ref="interface_viewer"
            ></InterfaceViewer>
        </el-form>
        <!--</div>-->
        <el-row style="padding-right: 50px; ">
            <!--<el-col :span="12">
              <el-dropdown @command="handleApproval" trigger="click" v-if="!!approvalStage.id">
                <el-button size="medium" style="margin: 7px 0 0 10px; background-color: #1E33AF; color: white">{{
                  $locale.registry.approvals.title
                  }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="approve" icon="el-icon-plus">{{ approvalStage.approve_text ||
                    $locale.registry.approvals.default_approve }}
                  </el-dropdown-item>
                  <el-dropdown-item command="cancel" icon="el-icon-circle-plus">{{ approvalStage.cancel_text ||
                    $locale.registry.approvals.default_cancel }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span v-else style="opacity: 0">bug fix</span>
            </el-col>-->
            <el-col style="text-align: right; margin-top: 7px">
                <el-button type="primary" plain @click="saveRecord()" v-if="!isBlocked" :disabled="loading"
                           size="medium">{{ this.structure.buttonSaveText || $locale.main.button.save }}
                </el-button>
                <el-button type="primary" plain @click="$emit('cancelChanges')" size="medium">{{
                    $locale.main.button.cancel }}
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import InterfaceViewer from '@/components/InterfaceViewer'
import Card from './Models/Card'
import Registry from './Models/Registry'
import Record from './Models/Record'
import { eventBus } from '@/eventBus'

export default {
  props: ['card-id', 'registry-id', 'record-id', 'initial-data', 'readonly', 'quick'],
  name: 'RegistryCard',
  components: {
    InterfaceViewer
  },
  data () {
    return {
      listeners: {},
      loading: true,
      error: false,
      structure: {},
      operations: [],
      approvalAddUserDialogVisible: false,
      approvalAddUserModel: null,
      approvalUsers: [],
      name: null,
      registry: null,
      data: {},
      recordData: {},
      isBlocked: this.readonly,
      blockingFields: [],
      showingFields: [],
      hiddenFields: [],
      activeStageId: null,
      activeRecordId: this.recordId,
      defaultState: null,
      recalculateFields: [
        'registry/string_field',
        'registry/text_field',
        'registry/integer_field',
        'registry/float_field',
        'registry/xref_field',
        'registry/xref_multi_field',
        'registry/date_field',
        'registry/datetime_field',
        'registry/boolean_field',
        'basic/a-file'
      ],
      excludingAttributes: [
        'create_date',
        'create_user_id',
        'delete_date',
        'delete_user_id',
        'update_date',
        'update_user_id'
      ],
      fileAttributes: [],
      excludingComponents: ['registry/file_field', 'registry/xref_outer_field']
    }
  },
  provide () {
    return {
      getCard: this.getCard,
      // openDashboardCard: function () {
      // },
      getRawData: this.getRecordData
    }
  },
  computed: {
    preparedData () {
      let data = { card_id: this.cardId }
      Object.keys(this.data).forEach((field) => {
        if (!this.excludingAttributes.includes(field) && !this.fileAttributes.includes(field)) {
          data[field] = this.data[field]
        }
      })

      return data
    }
  },
  async mounted () {
    console.log('card mounted event')
    if (!this.cardId || !this.registryId) {
      this.error = true
      return false
    }
    // this.$store.commit('setRegistryId', this.registryId)
    this.registry = new Registry({ id: this.registryId })
    this.loading = true
    let card = await Card.find(this.cardId)
    if (card) {
      this.name = card.name
      this.structure = JSON.parse(card.structure)
      this.operations = JSON.parse(card.structure).operations || []
      this.defaultState = JSON.parse(card.structure).defaultState
      this.listeners = JSON.parse(card.structure).listeners || {}
      if (this.activeRecordId) {
        await this.loadData(this.activeRecordId)
      } else {
        if (this.defaultState) {
          await this.loadDefaultState()
        }
        let data = await this.getDefaultConstraints()
        this.blockingFields = this.getBlockedFields(data.disable_constraints)
        this.showingFields = this.getShowingFields(data.view_constraints)
        this.hiddenFields = this.getHiddenFields(data.view_constraints)
      }
      this.$emit('change-name', this.getCardName({
        titleForAdd: JSON.parse(card.structure).titleForAdd,
        titleForEdit: JSON.parse(card.structure).titleForEdit,
        name: card.name
      }, this.recordId))
      if (this.structure) {
        this.structure.components.forEach((item, index, array) => {
          if (!this.data[item.properties.name] && /attr_[0-9]+_/i.test(item.properties.name)) {
            this.$set(this.data, item.properties.name, this.initialData[item.properties.name] || this.recordData[item.properties.name])
            if (this.excludingComponents.includes(item.initialType)) {
              this.excludingAttributes.push(item.properties.name)
            }
            if (item.initialType === 'basic/a-file' && item.properties.name) {
              this.fileAttributes.push(item.properties.name)
            }
          }
          let alwaysActive = false
          if (item.properties.alwaysActive) {
            alwaysActive = item.properties.alwaysActive
          }
          if (!alwaysActive) {
            if (this.isBlocked ||
                (this.blockingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                /attr_[0-9]+_/i.test(item.properties.name))) {
              this.$set(item.properties, 'readonly', true)
            }
          }
          if (this.showingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
            item.properties.hiddenCondition = { type: 'never' }
          }
          if (this.hiddenFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                /attr_[0-9]+_/i.test(item.properties.name)) {
            item.properties.hiddenCondition = { type: 'always' }
          }
        })
        this.$refs.interface_viewer.loadState(this.structure)
      }
    } else {
      this.error = true
    }
    this.loading = false
  },
  methods: {
    async getDefaultConstraints () {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/constraints`)
      return data.data
    },
    getRecordData () {
      return this.recordData
    },
    getCardName (settings, recordId) {
      if (recordId) {
        return settings.titleForEdit ? this.calculateCardName(settings.titleForEdit) : settings.name
      }
      return settings.titleForAdd ? this.calculateCardName(settings.titleForAdd) : settings.name
    },
    calculateCardName (name) {
      let attributes = name.match(/\{{(.*?)\}}/g) || []
      let me = this
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value = me.recordData[attribute]
        try {
          value = JSON.parse(value)
        } catch (e) {

        }
        if (value instanceof Array) {
          value = value.map(item => item.name).join(',')
        }
        name = name.replace(`{{${attribute}}}`, value || '')
      })

      return name
    },
    getCard () {
      return this
    },
    setLoading (value = true) {
      this.loading = value
    },
    async addApprovalUser () {
      if (this.approvalAddUserModel && this.activeStageId && this.activeRecordId) {
        this.approvalAddUserDialogVisible = false
        this.$http.post(`${this.$config.api}/registryservice/registry/stages/${this.activeStageId}/users`, {
          user_id: this.approvalAddUserModel,
          record_id: this.activeRecordId
        })
      } else {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: 'Не выбран согласующий'
        })
      }
    },
    async loadDefaultState () {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/state/${this.defaultState}`)
      this.data = data.data
    },
    getIsBlocked (constraints = []) {
      return constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardId)) && item.full
      }).length > 0
    },
    getBlockedFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardId)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields)
      })
      return fields
    },
    getShowingFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardId)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields.filter((field) => field.type === 'view_field').map((field) => field.field))
      })
      return fields
    },
    getHiddenFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardId)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields.filter((field) => field.type === 'hide_field').map((field) => field.field))
      })
      return fields
    },
    async handleOperation (operation) {
      if (operation.type === 'approval') {
        this.handleApproval(operation.approval_type, operation.stage_id)
      } else if (operation.type === 'add_user' && operation.stage_id) {
        await this.loadUsers()
        this.activeStageId = operation.stage_id
        this.approvalAddUserDialogVisible = true
      }
    },
    async loadUsers () {
      if (this.approvalUsers.length === 0) {
        let users = await this.$http.get(`${this.$config.api}/accesseditor/users`)
        this.approvalUsers = users.data.map((item) => {
          item.full_name = `${item.surname} ${item.name} ${item.midname}`
          return item
        })
      }
    },
    handleApproval (type, stageId) {
      this.$prompt(this.$locale.registry.approvals.comment, this.$locale.registry.approvals.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        inputErrorMessage: this.$locale.main.message.error
      }).then(({ value }) => {
        this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/records/${this.activeRecordId}/${type}`, {
          stage_id: stageId,
          comment: value
        })
          .then(() => {
            this.$emit('cancelChanges')
          })
      }).catch(() => {
      })
    },
    async loadData (recordId) {
      if (!recordId) {
        return false
      }
      this.loading = true
      let data = await this.registry.data().find(recordId)
      this.$set(this, 'recordData', data)
      this.$set(this.data, 'id', recordId)
      this.$set(this.data, 'guid', data.guid)
      this.prepareApprovalStages(data.approval_stages)
      this.isBlocked = this.isBlocked || this.getIsBlocked(data.disable_constraints)
      if (!this.isBlocked) {
        this.blockingFields = this.getBlockedFields(data.disable_constraints)
      }
      this.showingFields = this.getShowingFields(data.view_constraints)
      this.hiddenFields = this.getHiddenFields(data.view_constraints)
      this.loading = false
    },
    saveRecord (parameters = {}) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$refs.validateForm.validate(async (valid) => {
          if (valid) {
            if (!this.quick) {
              let recordData = JSON.parse(JSON.stringify(this.preparedData))

              const event = recordData.id ? 'recordEdited' : 'recordAdded'
              if (parameters.force) {
                recordData.force = parameters.force
              }
              if (parameters.commandId) {
                recordData.command_id = parameters.commandId
              }

              let formData = new FormData()

              for (let key in recordData) {
                formData.append(key, JSON.stringify(recordData[key]))
              }

              this.fileAttributes.forEach((item) => {
                (this.data[item] || []).forEach((file, index) => {
                  formData.append(`${item}[${index}]`, file.raw)
                })
              })

              let answer
              try {
                let requestFunction = recordData.id ? this.$http.put : this.$http.post
                answer = await requestFunction(`${this.$config.api}/registryservice/registry/${this.registryId}/records${recordData.id ? `/${recordData.id}` : ''}`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                )
              } catch (e) {
                if (e.response.data.error === 'record_constraint_forbidden') {
                  this.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: e.response.data.message.replace('\r\n', '<br>'),
                    type: 'error'
                  })
                } else if (e.response.data.error === 'record_constraint_notify') {
                  this.$confirm(e.response.data.message, this.$locale.main.message.attention, {
                    confirmButtonText: this.$locale.main.button.ok,
                    cancelButtonText: this.$locale.main.button.cancel,
                    type: 'warning'
                  }).then(() => {
                    this.saveRecord({ force: true })
                  }).catch(() => {

                  })
                }
                reject(e.response.data)
                return false
              }
              resolve()
              this.activeRecordId = recordData.id || answer.data.id
              if (this.listeners.update_event && event === 'recordEdited') {
                    import(`@/plugins/${this.$config.project}/${this.listeners.update_event}`)
                      .then((Plugin) => {
                        let instance = new Plugin.default(this)
                        instance.execute()
                      }).catch(() => {
                        console.log(`Plugin: ${this.listeners.update_event} not found (listeners.update_event)`)
                      })
              }
              this.$emit(event, this.activeRecordId)

              await this.loadData(this.activeRecordId)
              this.recalculateData()
              eventBus.$emit('registry-card-saved')
              if (event === 'recordEdited' && this.structure.closeCardOnEdit) {
                this.$emit('cancelChanges')
              }
              if (event === 'recordAdded' && this.structure.closeCardOnAdd) {
                this.$emit('cancelChanges')
              }
            } else {
              this.$emit('quick-add', this.data)
            }
          } else {
            this.$message.error('Заполните обязательные поля')
            reject('Не заполнены обязательные поля')
            return false
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    recalculateData () {
      this.structure.components.forEach((item) => {
        if (/attr_[0-9]+_/i.test(item.properties.name) && this.recalculateFields.includes(item.initialType)) {
          let value = this.recordData[item.properties.name]
          let parsed
          switch (item.initialType) {
            case 'registry/xref_field':
              if (!value) {
                value = null
                break
              }
              parsed = null
              try {
                parsed = JSON.parse(value)
              } catch (e) {
              }
              if (parsed instanceof Array && parsed.length > 0) {
                value = parsed[0].id
              } else if (typeof parsed === 'number') {
                value = parsed
              } else {
                value = null
              }
              break
            case 'registry/xref_multi_field':
              if (!value) {
                value = []
                break
              }
              parsed = []
              try {
                parsed = JSON.parse(value)
              } catch (e) {
              }
              if (parsed instanceof Array && parsed.length > 0) {
                value = parsed.map((item) => item.id)
              } else if (typeof parsed === 'number') {
                value = [parsed]
              } else {
                value = []
              }
              break
            default:
              break
          }
          this.$set(this.data, item.properties.name, value)
        }
        if (
          this.isBlocked ||
              (this.blockingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                  /attr_[0-9]+_/i.test(item.properties.name))
        ) {
          this.$set(item.properties, 'readonly', true)
        }
        if (this.showingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
          this.$set(item.properties, 'hiddenCondition', { type: 'never' })
        }
        if (this.hiddenFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
          this.$set(item.properties, 'hiddenCondition', { type: 'always' })
        }
      })
    },
    prepareApprovalStages (stages) {
      if (!stages) {
        return false
      }
      stages.forEach((stage) => {
        if (stage.approve_button_id) {
          const operation = this.operations.find((item) => item.id === stage.approve_button_id)
          if (operation) {
            operation.name = stage.approve_text || operation.name
            operation.approval_type = 'approve'
            operation.stage_id = stage.id
            operation.visible = true
          }
        }
        if (stage.approve_button_id) {
          const operation = this.operations.find((item) => item.id === stage.cancel_button_id)
          if (operation) {
            operation.name = stage.cancel_text || operation.name
            operation.approval_type = 'cancel'
            operation.stage_id = stage.id
            operation.visible = true
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
