<template>
  <el-dialog :title="form.title" :visible.sync="form.show" :before-close="cancel" :close-on-click-modal="false">
    <component
      :ref="form.component"
      :is="form.component"
      v-model="form.model"
      :title="form.title"
      :cancel="cancel"
      :save="save"
      :show-type="true"
      :in-modal="true">
    </component>

    <span slot="footer" class="dialog-footer">
      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.cancel" placement="top">
        <el-button @click="cancel" size="small">{{ $locale.main.button.cancel }}</el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.etl_editor.tooltip.save" placement="top">
        <el-button :open-delay="300" type="primary" @click="submit" size="small">
          <span class="el-icon-success"></span> {{ $locale.main.button.save }}
        </el-button>
      </el-tooltip>
    </span>
  </el-dialog>
</template>

<script>
import LoaderCreate from '../render/forms/LoaderCreate'
import LoaderUpdate from '../render/forms/LoaderUpdate'
import ExtractorCreate from '../render/forms/ExtractorCreate'
import ExtractorUpdate from '../render/forms/ExtractorUpdate'
import TransformerCreate from '../render/forms/TransformerCreate'
import TransformerUpdate from '../render/forms/TransformerUpdate'

export default {
  name: 'DialogForm',

  props: {
    form: Object,
    cancel: {
      type: Function,
      default: function () {
      }
    },
    save: {
      type: Function,
      default: async function (isNew) {
      }
    }
  },

  components: {
    LoaderCreate,
    LoaderUpdate,
    ExtractorCreate,
    ExtractorUpdate,
    TransformerCreate,
    TransformerUpdate
  },

  methods: {
    submit () {
      this.$refs[this.form.component].submit(this.save)
    }
  }
}
</script>

<style type="text/css"></style>
