import CoordinateSystemCommandRepositoryInterface from '@/services/MapEditor/domain/repository/CoordinateSystemCommandRepositoryInterface'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'
import store from '@/store'
import { AddCoordinateSystem, UpdateCoordinateSystem, DeleteCoordinateSystem } from '@/services/MapEditor/infrastructure/store/modules/CoordinateSystem/action-types'

export default class CoordinateSystemCommandRepository implements CoordinateSystemCommandRepositoryInterface
{
    insert(item: CoordinateSystemDTO): Promise<void>
    {
        return store.dispatch(new AddCoordinateSystem(item));
    }

    update(item: CoordinateSystemDTO): Promise<void>
    {
        return store.dispatch(new UpdateCoordinateSystem(item));
    }

    delete(srid: number): Promise<void>
    {
        return store.dispatch(new DeleteCoordinateSystem(srid));
    }
}
