import CommandInterface from '@/core/application/command/CommandInterface'

export default class CoordinateSystemDeleteCommand implements CommandInterface
{
    private srid: number;

    constructor(srid: number)
    {
        this.srid = srid;
    }

    getSrid(): number
    {
        return this.srid;
    }

    getClassName(): string
    {
        return 'CoordinateSystemDeleteCommand';
    }  
}