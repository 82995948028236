<template>
<el-collapse-item :name="ind">
  <template slot="title">{{filters.name}}</template>
    <div class="extraFilter__container">
      <div>
        <div class="relates_block__item2">
          <div class="demonstration">{{$locale.logic_editor.relates.name}}</div>
          <el-input class="relates__row" size="mini" v-model="filters.name"></el-input>
        </div>
        <div style="margin-left: 10px">
          <el-button @click="addField" size="small" icon="el-icon-plus">{{$locale.logic_editor.relates.btn_add_field}}</el-button>
        </div>
        <template v-if="this.fields.listAlias.length && this.fields.listCurrentdObject.length">
          <extraFieldsForFilters
            v-for="(item, index) in numberFields"
            :key="index"
            :numberFields="numberFields[index]"
            :ref="index"
            :fields='fields'>
            <el-tooltip :content="$locale.main.button.delete" placement="bottom">
              <el-button
                @click="removeField(item, index)"
                style="font-size: 15px; border: none; margin-left: 4%;"
                size="medium"
                icon="el-icon-close"
                circle
              ></el-button>
            </el-tooltip>
          </extraFieldsForFilters>
        </template>
        <el-row type="flex" justify="end" style="margin-right: 8%; margin-top: 35px;">
          <slot></slot>
            <el-button
              @click="saveFilters"
              :disabled="!filters.name"
              type="primary"
            >{{ $locale.main.button.save }}</el-button>
        </el-row>
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
import extraFieldsForFilters from '../elementRelates/extraFieldsForFilters'
import Filters from '../../Models/filters'
export default {
  props: {
    propslocal: {
      type: Object
    },
    ind: {
      type: Number,
      required: true
    },
    fields: {
      type: Object
    },
    numberFilter: {
      type: Object
    }
  },
  components: {
    extraFieldsForFilters
  },
  data () {
    return {
      filters: {
        name: null,
        filter_fields: []
      },
      numberFields: []
    }
  },
  methods: {
    // добавить поле
    addField () {
      this.numberFields.push({ isNewFilelds: true })
    },
    async saveFilters () {
      this.filters.filter_fields = []
      for (let i = 0; i < this.numberFields.length; i++) {
        this.filters.filter_fields.push(this.$refs[i][0].getValue())
      }
      if (!this.filters.isNewFilter) {
        console.log('edit')
        let filter = new Filters({ id: this.numberFilter.id })
        filter.name = this.filters.name
        filter.filter_fields = this.filters.filter_fields
        await filter.save()
      } else {
        console.log('new')
        let id
        this.propslocal.selectSubAction.id
          ? (id = this.propslocal.selectSubAction.id)
          : (id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id)
        let filter = new Filters({})
        filter.name = this.filters.name
        filter.related_object_id = id
        filter.filter_fields = this.filters.filter_fields
        let res = await filter.save()
        this.filters.isNewFilter = false
        this.numberFilter.id = res.id
      }
    },
    // удалить поле
    removeField (item, index) {
      this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
        .then(() => {
          const ind = this.numberFields.findIndex(el => el.filter_id === item.filter_id)
          this.numberFields.splice(ind, 1)
        })
    }
  },
  mounted () {
    // редактирование
    if (!this.numberFilter.isNewFilter) {
      console.log('редактирование фильтр')
      this.filters = this.numberFilter
      if (this.filters.filter_fields) {
        for (let i = 0; i < this.filters.filter_fields.length; i++) {
          this.numberFields.push(this.filters.filter_fields[i])
        }
      }
    } else {
      console.log('новый фильтр')
      this.filters = this.numberFilter
    }
  }
}
</script>

<style scoped>
.relates_block__item2 {
  width: 65%;
  margin-bottom: 15px;
}
.relates__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
.extraFilter__container {
  margin-bottom: 10%;
}
.extraFilter__item5 {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 10px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.extraLine_select {
  margin-right: 19px;
}
</style>
