<template>
  <div class="select-panel" ref="affine_transformation_select_panel">
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createAffineTransformation"></el-button>
      <el-button icon="edit-button" size="mini" circle  @click="editAffineTransformation"></el-button>
      <el-button icon="delete-button" size="mini" circle  @click="deleteAffineTransformation"></el-button>
      <el-checkbox v-if="!hideAll" v-model="all" :label="$locale.map_editor.affine_transformation_panel.all_affine_transformations"></el-checkbox>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%" v-bind:class="{ 'mini': affineTransformation !== null }">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="affineTransformations"
          stripe
          border
          ref="affine_transformations_table"
          row-key="srid"
          current-row-key="srid"
          highlight-current-row
          @current-change="changeAffineTransformation"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="240"
        ></el-table-column>
        <el-table-column
          prop="description"
          :label="$locale.main.fields.description"
          style="width: 100%"
        ></el-table-column>        
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="coordinate-system-pagination"
            :page-size="pageSize"
            :layout="all ? 'total, prev, pager, next' : 'total'"
            :total="count"
            @current-change="handlePageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
      <el-col v-if="affineTransformation !== null" v-bind:class="{ 'mini': affineTransformation !== null }">
        <AffineTransformationInformationPanel label-position="top" :style="{
          'max-height': `${informationPanelHeight}px`,
          'overflow-y': 'auto'
        }" class="information custom_scrollbar" :affineTransformation="affineTransformation" />
      </el-col>
    </el-row>     
  </div>
</template>

<script type="ts">
import ProjectAffineTransformationsQuery from '@/services/MapEditor/application/query/ProjectAffineTransformationsQuery'
import AffineTransformationsQuery from '@/services/MapEditor/application/query/AffineTransformationsQuery'
import AffineTransformationsCountQuery from '@/services/MapEditor/application/query/AffineTransformationsCountQuery'
import AffineTransformationDeleteCommand from '@/services/MapEditor/application/command/AffineTransformationDeleteCommand'
import AffineTransformation from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'
import AffineTransformationInformationPanel from '@/services/MapEditor/infrastructure/components/AffineTransformationInformationPanel/index.vue'

export default {
  name: 'AffineTransformationSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'projectGuid', 'hideAll'],
  components: {
    AffineTransformationInformationPanel
  },
  data() {
    return {
      loading: false,
      pageSize: 0,
      defaultPageSize: 100,
      currentPage: 0,
      pageLimit: 100,
      count: 0,
      allCount: 0,
      all: false,
      affineTransformations: [],
      affineTransformation: null,
      informationPanelHeight: 0
    }
  },
  computed: {
    affineTransformationCreated() {
      return this.$store.getters['AffineTransformation/getLocation'];
    }
  },
  watch: {
    all (val) {
      this.affineTransformation = null;
      this.$refs.affine_transformations_table.setCurrentRow(null);
      if (val) {        
        this.pageSize = this.defaultPageSize;
      }
      this.loadAffineTransformations();
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.affine_transformation_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.affine_transformation_select_panel.clientHeight - 36;
      }
    },
    changeAffineTransformation(affineTransformationDto) {
      this.affineTransformation = AffineTransformation.create(affineTransformationDto);
    },
    submit(callback) {
      this.getEventBus().$emit('affineTransformationSelected', this.affineTransformation, this.parentFormId);
      if (typeof callback == 'function') {
        callback();
      }
    },
    createAffineTransformation() {
      this.getEventBus().$emit('createForm', 'affineTransformation', {'parentFormId': this.formId, 'affineTransformationCreatedCallback': this.refreshAffineTransformation});
    },
    editAffineTransformation() {
      if (this.affineTransformation == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.getEventBus().$emit('createForm', 'affine_transformation_edit', {'parentFormId': this.formId, 'affineTransformation': this.affineTransformation, 'affineTransformationUpdatedCallback': this.refreshAffineTransformation});
      }
    },
    async refreshAffineTransformation(affineTransformation) {
      await this.loadAffineTransformations();
      this.selectAffineTransformation(affineTransformation);
    },
    deleteAffineTransformation() {
      if (this.affineTransformation == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new AffineTransformationDeleteCommand(
              this.affineTransformation.getSrid()
            )
          ).then((response) => {
            this.affineTransformation = null;
            this.$refs.affine_transformations_table.setCurrentRow(null);
            this.loadAffineTransformations();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    selectAffineTransformation(affineTransformation) {
      let rows = this.affineTransformations.filter(el => {
        return el.srid == AffineTransformation.getSrid()
      });
      if (rows.length) {
        this.$refs.affine_transformations_table.setCurrentRow(rows[0]);
      }
    },
    handlePageChange(val) {
      val--;
      this.currentPage = (val * this.pageLimit);
      this.loadAffineTransformations();
    },
    loadAffineTransformations(callback) {
      if (!this.all) {
        this.loadProjectAffineTransformations(callback);        
      } else {
        if (this.allCount == 0) {
          this.countAndLoadAffineTransformations(callback);
        } else {
          this.loadall(callback);
        }      
      }
    },
    async loadProjectAffineTransformations(callback) {
      await this.getQueryBus().execute(
        new ProjectAffineTransformationsQuery(this.projectGuid)
      ).then(data => {
        this.pageSize = data.length;
        this.count = data.length;
        this.affineTransformations = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async countAndLoadAffineTransformations(callback) {
      await this.getQueryBus().execute(
        new AffineTransformationsCountQuery()
      ).then(data => {
        this.count = data[0].count;      
        this.loadall(callback);
      });
    },
    async loadall(callback) {
      await this.getQueryBus().execute(
        new AffineTransformationsQuery({
          limit: this.pageLimit,
          offset: this.currentPage
        })
      ).then(data => {
        this.pageSize = this.pageLimit;
        this.affineTransformations = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    }
  },
  mounted() {
    this.loadAffineTransformations(() => {
      this.resizePanel();
    });   
  }
}
</script>