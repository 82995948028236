<template>
    <div :id="`stimulsoft_viewer_${componentId}`" :key="componentId" class="viewer"></div>
</template>

<script>
import StiHelper from '../StimulsoftReport/StiHelper'
export default {
  name: 'StimulsoftViewer',
  props: {
    filename: {
      type: String,
      default () {
        return 'SimpleList.mrt'
      }
    },
    variables: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      componentId: this.generateGuid()
    }
  },
  async mounted () {
    if (typeof window.Stimulsoft === 'undefined') {
      this.loadStyles()
      await this.loadScripts()
    }
    let jsHelper = new StiHelper(`${this.$config.api}/stimulsoftreports/handler.php`, 30)

    window.Stimulsoft.Base.StiLicense.loadFromFile(`${this.$config.api}/stimulsoftreports/stimulsoft/license.php`)
    window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
      `${this.$config.api}/stimulsoftreports/localization/ru.xml`, false, 'Russian'
    )
    window.Stimulsoft.Base.Localization.StiLocalization.cultureName = 'Russian'

    let options = new window.Stimulsoft.Viewer.StiViewerOptions()
    options.appearance.fullScreenMode = false
    options.appearance.scrollbarsMode = true
    options.toolbar.showSendEmailButton = true
    options.height = '100%'
    console.log(options)
    let viewer = new window.Stimulsoft.Viewer.StiViewer(options, 'StiViewer_' + this.componentId, false)

    viewer.onBeginProcessData = function (event, callback) {
      jsHelper.process(arguments[0], arguments[1])
    }

    viewer.onBeginExportReport = function (args) {
    }

    viewer.onEmailReport = function (event) {
      jsHelper.process(arguments[0], arguments[1])
    }

    let report = new window.Stimulsoft.Report.StiReport()
    report.loadFile(`${this.$config.api}/reports/${this.filename}`)
    this.variables.forEach((item) => {
      report.setVariable(item.key, item.value)
    })
    viewer.report = report

    viewer.renderHtml(`stimulsoft_viewer_${this.componentId}`)
  },
  methods: {
    async loadScripts () {
      await this.loadScript('/stimulsoft/stimulsoft.reports.js')
      await this.loadScript('/stimulsoft/stimulsoft.reports.maps.js')
      await this.loadScript('/stimulsoft/stimulsoft.dashboards.js')
      await this.loadScript('/stimulsoft/stimulsoft.viewer.js')
      await this.loadScript('/stimulsoft/stimulsoft.designer.js')
    },
    loadScript (src) {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.onload = () => {
          resolve()
        }
        script.src = src
        document.head.appendChild(script)
      })
    },
    loadStyles () {
      [
        '/stimulsoft/stimulsoft.designer.office2013.whiteblue.css',
        '/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css'
      ].forEach((src) => {
        let style = document.createElement('link')
        style.rel = 'stylesheet'
        style.href = src
        document.head.appendChild(style)
      })
    }
  }
}
</script>

<style scoped>
.viewer {
  height: 100%;
}
.viewer>div /*deep*/ {
  height: 100% !important;
}
</style>
