<template>
  <div>
    <div :class="{ tree_mapping: true, 'full_screen': isFullScreen }">
      <Split class="split_container">
        <SplitArea :size="50" class="split_column">
          <div class="tool_box">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.add" placement="top">
              <el-button class="tool_button" type="default" icon="el-icon-plus" size="mini" :disabled="addRootDisabled" @click="addEl()">
                {{ $locale.main.button.add }}
              </el-button>
            </el-tooltip>
            <el-tooltip v-if="extractorType === 'xml'" :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.import" placement="top">
              <el-button style="float: right;" class="tool_button" type="default" icon="el-icon-upload" size="mini" @click="importXSD()"></el-button>
            </el-tooltip>
          </div>
          <el-scrollbar>
            <el-tree
              v-if="!importDialog.show"
              ref="tree"
              lazy
              node-key="id"
              :load="loadNode"
              :props="treeProps"
              :expand-on-click-node="false"
              :default-expanded-keys="expanded">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span
                :class="{ 'selected-node': selectNode === node }"
                @click="editEl(node, data)"
                style="width: 100%;">
                <span :class="`node node__${data.element_type_id}`">
                  {{ `[${$locale.etl_editor.element_types[data.element_type_id]}]` }}
                </span> {{ node.label }}
              </span>
              <span v-if="selectNode && selectNode.data.guid === data.guid">
                <template v-if="['element', 'array'].indexOf(selectNode.data.element_type_id) !== -1">
                  <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.add" placement="top">
                    <el-dropdown trigger="click" @command="addEl($event)">
                      <el-button type="text" icon="el-icon-plus" size="small">
                      {{ $locale.main.button.add }}
                    </el-button>
                      <el-dropdown-menu v-if="selectNode.data.element_type_id === 'element'" slot="dropdown">
                        <el-dropdown-item command="element">{{ $locale.etl_editor.element_types.element }}</el-dropdown-item>
                        <el-dropdown-item v-if="extractorType === 'xml'" command="attribute">
                          {{ $locale.etl_editor.element_types.attribute }}
                        </el-dropdown-item>
                        <el-dropdown-item command="array">{{ $locale.etl_editor.element_types.array }}</el-dropdown-item>
                      </el-dropdown-menu>
                      <el-dropdown-menu v-if="selectNode.data.element_type_id === 'array'" slot="dropdown">
                        <el-dropdown-item command="element">{{ $locale.etl_editor.element_types.element }}</el-dropdown-item>
                        <el-dropdown-item command="array">{{ $locale.etl_editor.element_types.array }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>
                </template>
                <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.delete" placement="top">
                  <el-button class="text_danger" type="text" icon="el-icon-delete" size="small" @click="deleteEl(node, data)">
                    {{ $locale.main.button.delete }}
                  </el-button>
                </el-tooltip>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </SplitArea>
        <SplitArea :size="50" class="split_column">
          <div class="tool_box">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.save" placement="top">
              <el-button
                class="tool_button"
                icon="el-icon-success"
                type="default"
                size="mini"
                :disabled="!editorModel"
                @click="save()">
                {{ $locale.main.button.save }}
              </el-button>
            </el-tooltip>

            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.full_screen" placement="top">
              <el-button
                style="float: right;"
                class="tool_button"
                :type="isFullScreen ? 'info' : 'default'"
                icon="el-icon-full-screen"
                size="mini"
                @click.stop="fullScreen()">
              </el-button>
            </el-tooltip>
          </div>
          <el-scrollbar>
            <el-form v-if="editorModel" :model="editorModel" ref="form" label-position="top" size="mini" :rules="rules">
              <el-form-item :label="$locale.etl_editor.form.table[`name_${editorModel.element_type_id}`]" prop="source_column">
                <el-input v-model="editorModel.source_column" :label="$locale.etl_editor.form.table[`name_${editorModel.element_type_id}`]" @input="maskedElement"></el-input>
              </el-form-item>
              <el-form-item :label="$locale.etl_editor.form.table.alias">
                <el-input v-model="editorModel.source_alias" :label="$locale.etl_editor.form.table.alias"></el-input>
              </el-form-item>
              <el-form-item :label="$locale.etl_editor.form.table.description">
                <el-input v-model="editorModel.description" :label="$locale.etl_editor.form.table.description"></el-input>
              </el-form-item>
              <template v-if="['element', 'attribute'].indexOf(editorModel.element_type_id) !== -1">
                <el-form-item :label="$locale.etl_editor.form.table.field">
                  <el-select v-model="editorModel.target_field_id" :placeholder="$locale.etl_editor.form.table.field" clearable>
                    <el-option
                      v-for="item in selectTargetField"
                      :key="item.id"
                      :label="`${item.name} (attr_${item.id}_)`"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="editorModel.is_key">
                    {{ $locale.etl_editor.form.table.is_key }}
                  </el-checkbox>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="editorModel.is_required">
                    {{ $locale.etl_editor.form.table.is_required }}
                  </el-checkbox>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="editorModel.is_load_xref_table_values">
                    {{ $locale.etl_editor.form.table.is_load_xref_table_values }}
                  </el-checkbox>
                </el-form-item>
                <el-form-item :label="$locale.etl_editor.form.table.xref_field_id">
                  <el-input v-model="editorModel.xref_field_id" :placeholder="$locale.etl_editor.form.table.xref_field_id"></el-input>
                </el-form-item>
                <el-form-item :label="$locale.etl_editor.form.table.transformer_id">
                  <el-select v-model="editorModel.transformer_id" :placeholder="$locale.etl_editor.form.table.transformer_id" size="mini" clearable>
                    <el-option v-for="item in transformers" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item :label="$locale.etl_editor.form.table.loader_id">
                  <el-select v-model="editorModel.loader_id" :placeholder="$locale.etl_editor.form.table.loader_id" size="mini" clearable>
                    <el-option v-for="item in loaders" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-form>
          </el-scrollbar>
        </SplitArea>
      </Split>
    </div>

    <el-dialog
      :title="$locale.etl_editor.tooltip.import"
      :visible.sync="importDialog.show"
      :close-on-click-modal="false"
      width="30%">
      <span>
        <el-form label-position="top">
          <el-form-item :label="$locale.etl_editor.xsd_import.index_file" v-if="importDialog.selected">
            <el-tree
              ref="treeImport"
              show-checkbox
              @check-change="checkNode"
              check-strictly
              :data="importDialog.data"
              node-key="id"
              :props="{ children: 'children', label: 'name' }">
            </el-tree>
          </el-form-item>
          <el-form-item :label="$locale.etl_editor.xsd_import.file">
            <el-upload
              id="xsd-upload"
              name="zipfile"
              :headers="getHeaders()"
              :action="`${this.$config.api}/xsd2jsonconverter/xsdservice/zip_files`"
              list-type="text"
              :auto-upload="true"
              :multiple="false"
              :file-list="importDialog.fileList"
              :on-change="handleChange"
              :on-success="onSuccess"
              :limit="1">
              <el-button size="small" type="primary" class="el-icon-upload">
                {{ $locale.etl_editor.xsd_import.select_file }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $locale.etl_editor.xsd_import.file_info }}
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onImportCancel">
          {{ $locale.main.button.cancel }}
        </el-button>
        <el-button size="small" type="primary" @click="onImport" :disabled="this.importDialog.checkedNode === null" v-loading.fullscreen.lock="importDialog.loading">
          {{ $locale.main.button.import }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Mapping from '../../Models/Mapping'
import Node from 'element-ui/packages/tree/src/model/node'

export default {
  name: 'TreeMapping',

  props: {
    taskId: Number,
    loaders: Array,
    transformers: Array,
    extractorType: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      treeProps: {
        children: 'children',
        label: 'source_column',
        isLeaf: 'is_leaf'
      },

      editorModel: null,
      selectNode: null,

      selectTargetField: [],

      addRootDisabled: false,

      expanded: [],

      isFullScreen: false,

      importDialog: {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      },

      rules: {
        source_column: [{ required: true, message: this.$locale.etl_editor.form.rules.required, trigger: 'blur' }]
      }
    }
  },

  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    async addEl (type = 'element') {
      let node = this.selectNode
      let model = await new Mapping({
        task_id: this.taskId,
        element_type_id: type,
        source_column: `new-${type}`,
        parent_id: node ? node.data.id : null,
        is_leaf: ['element', 'array'].indexOf(type) === -1
      }).save()

      let nodeModel = new Node({
        parent: node || this.$refs.tree.root,
        store: node ? node.store : this.$refs.tree.store,
        data: model,
        level: node ? node.level + 1 : 1
      })

      if (node) {
        // added child
        node.childNodes.push(nodeModel)

        // auto expanded node children
        this.expanded = [node.data.id]
      } else {
        // added root (only one)
        if (this.extractorType === 'xml') {
          if (this.$refs.tree.root.childNodes.length < 1) {
            this.$refs.tree.root.childNodes.push(nodeModel)

            this.addRootDisabled = true
          }
        } else {
          this.$refs.tree.root.childNodes.push(nodeModel)
        }
      }
    },

    editEl (node, data) {
      if (this.selectNode === node) {
        this.selectNode = null
        this.editorModel = null
        return
      }

      this.selectNode = node
      this.editorModel = data

      this.selectTargetField = []
      this.$http
        .get(`${this.$config.api}/objecteditor/entities?object_id=${this.editorModel.loader_object_id}&is_field=true`)
        .then((response) => {
          this.selectTargetField.push(...response.data.data)
        })
    },

    deleteEl (node, data) {
      this.$confirm(this.$locale.etl_editor.form.messages.delete, this.$locale.etl_editor.form.messages.warningTitle, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        this.selectNode = null
        this.editorModel = null

        this.$refs.tree.remove(node.data.id)

        if (this.extractorType === 'xml') {
          this.addRootDisabled = this.$refs.tree.root.childNodes.length > 0
        }

        await data.delete()
      })
    },

    async save () {
      let response = await this.editorModel.save()

      if (response.code === 'mapping_not_found') {
        this.$refs.tree.remove(this.editorModel.id)

        this.selectNode = null
        this.editorModel = null
      }
    },

    async loadNode (node, resolve) {
      resolve(await this.loadObjects(node.level === 0 ? null : node.data.id))
    },

    async loadObjects (parentId = null) {
      let params = { task_id: this.taskId, order: 'row_order:asc,id:desc' }
      if (parentId === null) {
        params = Object.assign({ is_null: 'parent_id' }, params)
      } else {
        params = Object.assign({ parent_id: parentId }, params)
      }

      let response = await new Mapping().params(params).get()

      if (!parentId && this.extractorType === 'xml') {
        this.addRootDisabled = response.length > 0
      }

      response.forEach(item => {
        item.is_leaf = ['element', 'array'].indexOf(item.element_type_id) === -1
      })

      return response
    },

    importXSD () {
      if (this.$refs.tree.root.childNodes.length > 0) {
        this.$message.error(this.$locale.etl_editor.xsd_import.import_root_error)
        return
      }

      this.importDialog.show = true
    },

    handleChange (file, fileList) {
      if (['application/x-zip-compressed', 'application/zip'].indexOf(file.raw.type) === -1) {
        this.importDialog.fileList = []
        this.$message.error(this.$locale.etl_editor.xsd_import.zip_file)
        return false
      }

      this.importDialog.fileList = fileList
    },

    onSuccess (response, file, fileList) {
      this.importDialog.selected = true
      this.importDialog.guid = response.guid
      this.importDialog.data = response.file_list
    },

    onImportCancel () {
      this.importDialog = {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      }
    },

    onImport () {
      if (this.importDialog.checkedNode !== null) {
        this.$http
          .post(
            `${this.$config.api}/xsd2jsonconverter/xsdservice/zip_parse`,
            {
              guid: this.importDialog.guid,
              filename: this.importDialog.checkedNode.name
            }
          )
          .then((response) => {
            this.importDialog.loading = true
            this.$http
              .post(`${this.$config.api}/etleditor/mappings/${this.taskId}/xsd_import`, { element: response.data.json })
              .then((response) => {
                this.importDialog = {
                  show: false,
                  selected: false,
                  data: [],
                  guid: null,
                  fileList: [],
                  checkedNode: null,
                  fileType: '',
                  loading: false
                }
              })
              .finally(() => {
                this.importDialog.loading = false
              })
          })
      }
    },

    checkNode (node, checked) {
      if (checked) {
        this.importDialog.checkedNode = node
        this.$refs.treeImport.setCheckedKeys([node.id])
      }
    },

    saveAllEditMapping (nodes = null) {
      if (!nodes) {
        nodes = this.$refs.tree.root.childNodes
      }

      nodes.forEach((node) => {
        if (node.data.isEdit()) {
          node.data.save()
        }

        if (node.childNodes.length > 0) {
          this.saveAllEditMapping(node.childNodes)
        }
      })
    },

    fullScreen () {
      this.isFullScreen = !this.isFullScreen
    },

    maskedElement (value) {
      value = value.replace(/(\s)+/g, '-').replace(/[^_:\-0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.editorModel.source_column = value
    }
  }
}
</script>

<style lang="scss">
.etl_editor .task_form .tree_mapping .el-select {
  width: 100%;
}

.tree_mapping {
  background-color: #fff;

  &.full_screen {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1001;

    & .split_container {
      height: 100vh;
      border-top: 1px solid #dcdfe6;
    }

    & .split_column .el-scrollbar {
      height: calc(100vh - 57px);
    }
  }

  & .split_container {
    height: calc(100vh - 540px);
    border-top: 1px solid #dcdfe6;
  }

  & .split_column .el-scrollbar {
    height: calc(100vh - 587px);
    width: 100%;
  }

  & .split_column .el-form {
    padding-left: 15px;
    padding-right: 15px;
  }

  & .node {
    font-weight: bold;
    font-size: 11px;

    &__element {
      color: #0066aa;
    }

    &__attribute {
      color: #67C23A;
    }

    &__array {
      color: #e91313;
    }
  }
}
</style>
