import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TopicRightCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/TopicRightCommandRepositoryInterface'
import TopicRightUpdateCommand from '@/services/AccessEditor/application/command/TopicRightUpdateCommand'
import { TopicRightDTO } from '@/services/AccessEditor/domain/model/Right/TopicRight'

export default class TopicRightUpdateHandler implements CommandHandlerInterface
{
    private repo: TopicRightCommandRepositoryInterface;

    constructor(repo: TopicRightCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: TopicRightUpdateCommand): Promise<void>
    {
        let item: TopicRightDTO = {
            role_guid: command.getRoleGuid(),
            topic_id: command.getTopicId(),
            is_topic_producer: command.getIsTopicProducer(),
            is_topic_consumer: command.getIsTopicConsumer()
        };
        return this.repo.update(item);
    }
}