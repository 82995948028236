<template>
  <el-form :model="value" size="mini">
    <el-form-item prop="name" :label="$locale.main.fields.name">
      <el-input v-model="value.name"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'group',
  props: ['value']
}
</script>
