<template>
  <div>
    <registry-select-tree v-model="registryId" :disabled="isEdit" type="registry" value-as="number"></registry-select-tree>
    <select-tree :disabled="isEdit" :clearable="false" :standartTree='false' :dataTable="fields" v-model="fieldId"/>
  </div>
</template>

<script>
import SelectTree from '@/components/LogicEditor/components/treeSelect'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import Registry from '@/components/LogicEditor/Models/Registry'
export default {
  name: 'externalTableField',
  props: {
    state: {
      type: Object
    },
    listField: {
      type: Array
    },
    isEdit: {
      type: Boolean
    }
  },
  components: {
    RegistrySelectTree,
    SelectTree
  },
  data () {
    return {
      registryId: null,
      fieldId: null,
      fields: []
    }
  },
  watch: {
    async registryId () {
      this.fields = await this.getRegistryFields()
    }
  },
  methods: {
    getValue () {
      return {
        registry_id: this.registryId,
        field_id: this.fieldId
      }
    },
    async getRegistryFields () {
      let registryTree = await Registry.params({ parent_id: this.registryId, show_children: true }).get()
      registryTree.data.unshift({
        id: 'id',
        name: 'id',
        entity_type_id: 'id записи'
      })
      return registryTree.data
    }
  },
  mounted () {
    if (this.state && this.state.properties) {
      this.registryId = this.state.properties.registry_id
      this.fieldId = this.state.properties.field_id
    }
  }

}
</script>

<style scoped>

</style>
