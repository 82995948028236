import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'

export interface ProjectState {
  isLoading: boolean
  error: object
}

export const initialState = (): ProjectState => {
  return {
    isLoading: false,
    error: null
  }
}