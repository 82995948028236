import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { EventDTO } from '@/services/Databus/domain/model/Event/Event'

export namespace EventAPI {

    export class CountEvents implements APIRequest<EventDTO> {
        response: EventDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/events?*[func]=count'
        }
    }

    export class GetEvents implements APIRequest<EventDTO> {
        response: EventDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/events?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetEventByGuid implements APIRequest<EventDTO> {
        response: EventDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/events/${guid}`;
        }
    }

    export class GetTypes implements APIRequest<string> {
        response: string;
        path = '/databusservice/event_types';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor() {}
    }

    export class GetTypeProperties implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(val: string) {
            this.path = `/databusservice/event_types/${val}/properties`;
        }
    }

}