<template>
  <div :class="{ 'table_mapping': true, 'full_screen': isFullScreen }">
    <el-table
      :data="mapping.data"
      :height="isFullScreen ? this.window.height - 41 : 394"
      border>
      <el-table-column
        :label="$locale.etl_editor.form.table.source_object"
        header-align="center">
        <el-table-column
          prop="source_field_id"
          :label="$locale.etl_editor.form.table.field"
          width="150"
          header-align="center">
          <template slot-scope="scope">
            <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.source_field_id" :placeholder="$locale.etl_editor.form.table.field" size="mini">
              <el-option v-for="item in selectSourceField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_field_id) }">
              {{ getObjectName(selectSourceField, scope.row.source_field_id) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="source_column"
          :label="$locale.etl_editor.form.table.column"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.source_column" :placeholder="$locale.etl_editor.form.table.column"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_column) }">
              {{ scope.row.source_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="source_alias"
          :label="$locale.etl_editor.form.table.alias"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.source_alias" :placeholder="$locale.etl_editor.form.table.alias"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_alias) }">
              {{ scope.row.source_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        :label="$locale.etl_editor.form.table.target_object"
        header-align="center">
        <el-table-column
          prop="target_field_id"
          :label="$locale.etl_editor.form.table.field"
          width="150"
          header-align="center">
          <template slot-scope="scope">
            <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.target_field_id" :placeholder="$locale.etl_editor.form.table.field" size="mini">
              <el-option v-for="item in selectTargetField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.target_field_id === null }">
              {{ getObjectName(selectTargetField, scope.row.target_field_id) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="target_column"
          :label="$locale.etl_editor.form.table.column"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.target_column" :placeholder="$locale.etl_editor.form.table.column"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_column) }">
              {{ scope.row.target_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="target_alias"
          :label="$locale.etl_editor.form.table.alias"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.target_alias" :placeholder="$locale.etl_editor.form.table.alias"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_alias) }">
              {{ scope.row.target_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="is_key"
        :label="$locale.etl_editor.form.table.is_key"
        width="100"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_key" label="Да"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_required"
        :label="$locale.etl_editor.form.table.is_required"
        width="115"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_required" label="Да"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_load_xref_table_values"
        :label="$locale.etl_editor.form.table.is_load_xref_table_values"
        width="110"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_load_xref_table_values" label="Да"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="xref_field_id"
        :label="$locale.etl_editor.form.table.xref_field_id"
        width="125"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.xref_field_id" :placeholder="$locale.etl_editor.form.table.xref_field_id"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.xref_field_id) }">
            {{ scope.row.xref_field_id || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="xref_condition"
        :label="$locale.etl_editor.form.table.xref_condition"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.xref_condition" :placeholder="$locale.etl_editor.form.table.xref_condition"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.xref_condition) }">
            {{ scope.row.xref_condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="loader_id"
        :label="$locale.etl_editor.form.table.loader_id"
        width="160"
        header-align="center">
        <template slot-scope="scope">
          <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.loader_id" :placeholder="$locale.etl_editor.form.table.loader_id" size="mini">
            <el-option v-for="item in loaders" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.loader_id === null }">
            {{ getObjectName(loaders, scope.row.loader_id) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="value"
        :label="$locale.etl_editor.form.table.value"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.value" :placeholder="$locale.etl_editor.form.table.value"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.value) }">
            {{ scope.row.value || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="row_order"
        :label="$locale.etl_editor.form.table.row_order"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input-number
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.row_order"
            :placeholder="$locale.etl_editor.form.table.row_order"
            controls-position="right"
            :min="0"
            :max="99999999">
          </el-input-number>

          <p v-if="rowEditor !== scope.row.id">
            {{ scope.row.row_order || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="condition"
        :label="$locale.etl_editor.form.table.condition"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.condition" :placeholder="$locale.etl_editor.form.table.condition"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.condition) }">
            {{ scope.row.condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="115"
        header-align="center">
        <template slot="header">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.add" placement="top">
            <el-button icon="el-icon-circle-plus-outline" type="success" size="mini" @click.stop="addMapping()"></el-button>
          </el-tooltip>
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.full_screen" placement="top">
            <el-button
              icon="el-icon-full-screen"
              :type="isFullScreen ? 'info' : 'default'"
              size="mini"
              @click.stop="fullScreen()">
            </el-button>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-tooltip
            :open-delay="300"
            class="item"
            effect="dark"
            :content="rowEditor === scope.row.id ? $locale.etl_editor.tooltip.save : $locale.etl_editor.tooltip.edit"
            placement="top">
            <el-button
              @click.native.prevent="editMapping(scope.$index)"
              :type="rowEditor === scope.row.id ? 'primary' : 'default'"
              size="mini">
              <span :class="rowEditor === scope.row.id ? 'el-icon-success' : 'el-icon-edit'"></span>
            </el-button>
          </el-tooltip>
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.delete" placement="top">
            <el-button
              @click.native.prevent="deleteMapping(scope.$index)"
              type="danger"
              size="mini">
              <span class="el-icon-delete-solid"></span>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="tablePageChange"
      :page-size="pagination.limit"
      layout="total, prev, pager, next"
      style="background-color: #fff;"
      :total="mapping.count">
    </el-pagination>
  </div>
</template>

<script>
import Mapping from '../../Models/Mapping'

export default {
  name: 'TableMapping',

  props: {
    taskId: Number,
    loaders: Array
  },

  data () {
    return {
      selectSourceField: [],
      selectTargetField: [],

      rowEditor: 0,

      mapping: {
        count: 0,
        data: []
      },
      pagination: {
        current: 0,
        limit: 30
      },

      isFullScreen: false,

      window: {
        width: 0,
        height: 0
      }
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    await this.load()
  },

  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },

    empty (value) {
      return value === null || value === ''
    },

    async load () {
      this.$http
        .get(`${this.$config.api}/etleditor/objects/${this.taskId}`)
        .then((response) => {
          this.getObjectList(response.data)
        })

      this.rowEditor = 0

      let count = await Mapping.params({ '*': { func: 'count' }, task_id: this.taskId }).get()
      this.mapping.count = count.length ? count[0].count : 0
      this.mapping.data = await Mapping.params({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit }).orderBy('row_order:asc', 'id:asc').get()
    },

    async editMapping (index) {
      if (this.rowEditor === this.mapping.data[index].id) {
        if (this.mapping.data[index].isEdit()) {
          this.mapping.data[index] = await this.mapping.data[index].save()
        }

        this.rowEditor = 0
      } else {
        this.rowEditor = this.mapping.data[index].id
      }
    },

    saveAllEditMapping () {
      this.mapping.data.forEach(async (item) => {
        if (item.isEdit()) {
          await item.save()
        }
      })
    },

    addMapping () {
      this.mapping.data.push(new Mapping({
        id: 0,
        task_id: this.taskId,
        source_field_id: null,
        source_column: null,
        source_alias: null,
        target_field_id: null,
        target_column: null,
        target_alias: null,
        is_key: false,
        is_required: false,
        is_load_xref_table_values: false,
        xref_field_id: null,
        xref_condition: null,
        loader_id: null,
        value: null,
        row_order: 0,
        condition: null
      }))
    },

    async deleteMapping (index) {
      this.$confirm(this.$locale.main.message.delete, this.$locale.main.message_title.warning, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        if (this.mapping.data[index].id) {
          await this.mapping.data[index].delete()
          this.mapping.count--
          this.mapping.data.splice(index, 1)
        } else {
          this.mapping.data.splice(index, 1)
        }
      })
    },

    getObjectList (data) {
      let me = this
      if (data.extractor_type_id === 'object_table') {
        this.selectSourceField = []
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.extractor_object_id}&is_field=true`)
          .then(response => {
            me.selectSourceField.push(...response.data.data)
          })
      }

      if (data.loader_type_id === 'object_table') {
        this.selectTargetField = []
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.loader_object_id}&is_field=true`)
          .then(response => {
            me.selectTargetField.push(...response.data.data)
          })
      }
    },

    tablePageChange (val) {
      this.mapping.data.forEach(item => {
        if (item.isEdit()) {
          this.$confirm('Сохранить изменения?', this.$locale.main.message_title.warning, {
            confirmButtonText: this.$locale.main.button.ok,
            cancelButtonText: this.$locale.main.button.no,
            type: 'warning'
          }).then(async () => {
            await item.save()

            this.tpc(val)
          }).catch(response => {
            this.tpc(val)
          })
        } else {
          this.tpc(val)
        }
      })
    },

    getObjectName (arrayObjects, searchValue) {
      let object = arrayObjects.find(obj => obj.id === searchValue)

      if (typeof object !== 'undefined') {
        return `${object.name} (attr_${object.id}_)`
      }

      return '(нет данных)'
    },

    async tpc (val) {
      val--
      this.pagination.current = (val * this.pagination.limit)

      let count = await Mapping.params({ '*': { func: 'count' }, task_id: this.taskId }).get()
      this.mapping.count = count.length ? count[0].count : 0
      this.mapping.data = await Mapping.params({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit }).orderBy('row_order:asc', 'id:asc').get()
    },

    fullScreen () {
      this.isFullScreen = !this.isFullScreen
    }
  }
}
</script>

<style lang="scss">
.table_mapping {

  &.full_screen {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
  }
}
</style>
