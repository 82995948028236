import { MutationTree } from 'vuex'
import { ProjectState, initialState } from './state'
import { MutationTypes, ProjectUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<ProjectState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.PROJECT_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.PROJECT_UPDATED_EVENT]: (state) => {
    state.isLoading = false;
  }
}