import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UserAvatarQuery from '../../query/UserAvatarQuery'

export default class UserAvatarHandler implements QueryHandlerInterface
{
    private repo: UserQueryRepositoryInterface;

    constructor(repo: UserQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: UserAvatarQuery): Promise<any>
    {
        return this.repo.getAvatar(
            query.getAvatarId()
        );
    }
}