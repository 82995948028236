<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules">
    <el-row :gutter="5">
      <el-col :span="4">
        <el-form-item prop="properties.field">
          <el-select
            v-model="value.properties.field"
            :placeholder="$locale.report_editor.filter.field"
            :title="value.properties.field"
            @change="selectedField($event)"
            :disabled="!fieldList.length">
            <el-option
              v-for="item in fieldList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="report_filter_type_id">
          <el-select
            v-model="value.report_filter_type_id"
            :placeholder="$locale.report_editor.filter.type"
            :title="$locale.report_editor.filter.types[value.report_filter_type_id]"
            @change="selectedType($event)">
            <el-option
              v-for="item in filterTypeList"
              :key="item.id"
              :label="$locale.report_editor.filter.types[item.name]"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="ifComponent ? (ifOperation ? 4 : 0) : 10">
        <el-form-item prop="operation_type_id">
          <el-select
            v-model="value.operation_type_id"
            v-if="ifOperation"
            :placeholder="$locale.report_editor.filter.operation_type_id"
            :title="value.operation_type_id">
            <el-option
              v-for="item in operationList"
              :key="item.id"
              :label="$locale.report_editor.filter.operations[item.name]"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="ifComponent ? (ifOperation ? 6 : 10) : 0">
        <el-form-item prop="properties.value">
          <component
            class="filter-value"
            v-model="value.properties.value"
            v-if="ifComponent"
            :is="typeComponent"
            :placeholder="$locale.report_editor.filter.value" />
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          style="margin: 0 10px;"
          circle
          size="mini"
          :icon="isDeleted() ? `el-icon-time` : `el-icon-delete`"
          :type="isDeleted() ? `default` : `danger`"
          @click="remove()">
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import Condition from './typeComponents/Condition'
  import Constant from './typeComponents/Constant'
  import UsersTable from './typeComponents/UsersTable'

  export default {
    name: 'FilterForm',

    props: ['value', 'index', 'fieldList', 'filterTypeList', 'removeFilter'],

    components: {
      Condition,
      Constant,
      UsersTable
    },

    computed: {
      ifComponent() {
        return typeof this.availableTypeComponent[this.value.report_filter_type_id] !== 'undefined'
      },
      typeComponent() {
        return typeof this.availableTypeComponent[this.value.report_filter_type_id] !== 'undefined'
          ? this.availableTypeComponent[this.value.report_filter_type_id]
          : Constant
      },
    ifOperation () {
      return this.value.report_filter_type_id !== 'condition'
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    var validateValue = (rule, value, callback) => {
      if (!value && ['current_user', 'current_date', 'current_datetime', 'parameter'].indexOf(this.value.report_filter_type_id) === -1) {
        callback(new Error('Введите значение'))
      } else {
        callback()
      }
    };
    var validateOperation = (rule, value, callback) => {
      if (!value && this.value.report_filter_type_id !== 'condition') {
        callback(new Error('Выберите операнд'))
      } else {
        callback()
      }
    };
    return {
      localValue: this.value,
      formRules: {
        report_filter_type_id: [{ required: true, message: 'Выберите тип фильтра', trigger: 'change' }],
        operation_type_id: [{ validator: validateOperation, trigger: 'change' }],
        properties: {
          field: [{ required: true, message: 'Выберите поле', trigger: 'change' }],
          value: [{ validator: validateValue, trigger: 'blur' }]
        }
      },
      validForm: true,
      operationList: [
        {
          id: 'eq',
          name: 'eq'
        }, {
          id: 'neq',
          name: 'neq'
        }, {
          id: 'lt',
          name: 'lt'
        }, {
          id: 'lte',
          name: 'lte'
        }, {
          id: 'gt',
          name: 'gt'
        }, {
          id: 'gte',
          name: 'gte'
        }
      ],
      availableTypeComponent: {
        condition: Condition,
        constant: Constant,
        users_table: UsersTable
      }
    }
  },

  methods: {
    selectedType (value) {
      this.localValue.properties.value = null
    },
    selectedField (value) {
      let field = this.fieldList.find(item => item.name === value);

      this.localValue.properties.type = field.entity_type_id || null
    },
    validate () {
      this.$refs.form.validate((valid) => {
        this.validForm = valid
      })
    },
    isValid () {
      this.validate();

      return this.validForm
    },
    remove () {
      if (typeof this.localValue.id === 'undefined') {
        this.removeFilter(this.index)
      } else {
        if (typeof this.localValue.is_deleted !== 'undefined') {
          this.$delete(this.localValue, 'is_deleted')
        } else {
          this.$set(this.localValue, 'is_deleted', true)
        }
      }
    },
    isDeleted () {
      return typeof this.localValue.is_deleted !== 'undefined'
    }
  }
}
</script>

<style type="text/css">
.filter-value .el-date-editor.el-input,
.filter-value .el-date-editor.el-input__inner {
  width: 100%;
}
</style>
