<template>
  <div class="vqb-rule">
    <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.query_builder.button.delete_rule" placement="top">
      <el-button
        :style="{ 'float': 'right' }"
        size="mini"
        type="default"
        circle
        @click="remove"
        v-html="labels.removeRule">
      </el-button>
    </el-tooltip>

    <el-form-item :size="sizeControl" class="form-item-rule">
      <template slot="label">
        {{ rule.label }}
      </template>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-select
            v-if="isValidRuleOperators"
            v-model="selectedOperator"
            @change="selectedChange">
            <el-option
              v-for="(operator, index) in rule.operators"
              :key="index"
              :value="operator.id"
              :label="operator.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <template v-if="query.filter_type === 'constant'">
            <template v-if="['in', 'not_in'].indexOf(query.operator) !== -1">
              <MultiInput
                :component="rule.inputType"
                :placeholder="rule.placeholder"
                :xref-list="xrefList"
                v-model="query.value" />
            </template>
            <template v-else-if="['is_not_null', 'is_null'].indexOf(query.operator) !== -1">
              <el-input :disabled="true"></el-input>
            </template>
            <template v-else>
              <component
                :is="rule.inputType"
                :placeholder="rule.placeholder"
                :xref-list="xrefList"
                v-model="query.value" />
            </template>
          </template>
          <template v-else-if="query.filter_type === 'current_user'">
            <el-input value="Current User" :disabled="true"></el-input>
          </template>
          <template v-else-if="query.filter_type === 'current_datetime'">
            <el-input value="Current Date" :disabled="true"></el-input>
          </template>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import deepClone from './utilities.js'

import DateInput from './InputComponents/DateInput'
import DatetimeInput from './InputComponents/DatetimeInput'
import FlagInput from './InputComponents/FlagInput'
import FloatInput from './InputComponents/FloatInput'
import MultiInput from './InputComponents/MultiInput'
import NumberInput from './InputComponents/NumberInput'
import StringInput from './InputComponents/StringInput'
import TextInput from './InputComponents/TextInput'
import TimeInput from './InputComponents/TimeInput'
import XrefInput from './InputComponents/XrefInput'
import XrefMultiInput from './InputComponents/XrefMultiInput'

export default {
  name: 'query-builder-rule',

  props: ['query', 'index', 'rule', 'styled', 'labels', 'sizeControl'],

  components: {
    DateInput,
    DatetimeInput,
    FlagInput,
    FloatInput,
    MultiInput,
    NumberInput,
    StringInput,
    TextInput,
    TimeInput,
    XrefInput,
    XrefMultiInput
  },

  computed: {
    isValidRuleOperators () {
      return typeof this.rule.operators !== 'undefined' && this.rule.operators.length > 0
    }
  },

  watch: {
    query () {
      this.localQuery = this.query
    }
  },

  data () {
    return {
      localQuery: this.query,
      selectedOperator: this.query.operator,
      xrefList: []
    }
  },

  beforeMount () {
    if (this.rule.inputType === 'custom-component') {
      this.$options.components[this.id] = this.rule.component
    }
  },

  mounted () {
    let updatedQuery = deepClone(this.query)

    this.selectedChange(this.selectedOperator)

    // Set a default value for these types if one isn't provided already
    if (this.query.value === null) {
      this.$emit('update:query', updatedQuery)
    }

    this.loadXrefList()
  },

  methods: {
    async loadXrefList () {
      if (['xref_field', 'xref_multi_field'].indexOf(this.rule.fieldTypeId) !== -1) {
        await this.$http
          .get(`${this.$config.api}/registryservice/xref/${this.rule.fieldId}/data`)
          .then((response) => {
            this.xrefList.push(...response.data)
          })
          .catch((error) => console.log(error))
      }
    },

    selectedChange (value) {
      let selectedOperator = this.rule.operators.find(operator => operator.id === value)

      if (['in', 'not_in'].indexOf(selectedOperator.id) !== -1) {
        if (typeof this.query.value !== 'string' && !Array.isArray(this.query.value)) {
          this.$set(this.localQuery, 'value', [''])
        }

        if (typeof this.query.value === 'string') {
          this.$set(this.localQuery, 'value', this.query.value.split('; '))
        }
      } else {
        if (Array.isArray(this.query.value)) {
          if (['TextInput'].indexOf(this.rule.inputType) !== -1) {
            this.$set(this.localQuery, 'value', this.query.value.join('; '))
          } else if (['NumberInput', 'FloatInput'].indexOf(this.rule.inputType) !== -1) {
            this.$set(this.localQuery, 'value', 0)
          } else {
            this.$set(this.localQuery, 'value', null)
          }
        }
      }

      this.localQuery.operator = selectedOperator.id
    },

    remove () {
      this.$emit('child-deletion-requested', this.index)
    },

    updateQuery (value) {
      let updatedQuery = deepClone(this.query)
      updatedQuery.value = value
      this.$emit('update:query', updatedQuery)
    }
  }
}
</script>

<style>
  .form-item-rule .el-form-item__label {
    max-width: 50%;
  }
</style>
