

























































































import EventsQuery from '@/services/Databus/application/query/EventsQuery'
import EventsCountQuery from '@/services/Databus/application/query/EventsCountQuery'
import EventType from '@/services/Databus/domain/model/Event/EventType'

export default {
  name: 'EventPanel',
  components: {
    
  },
  data () {
    return {
      loading: false,
      eventsPageSize: 0,
      defaultEventsPageSize: 100,
      eventsCurrentPage: 0,
      eventsPageLimit: 100,
      eventsCount: 0,
      allEventsCount: 0,
      events: [],
      event: null,
      informationPanelHeight: 0
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getDownloadPackage'],
  methods: {
    loadEvents() {
      if (this.allEventsCount == 0) {
        this.countAndLoadEvents();
      } else {
        this.loadAllEvents();
      }
    },
    async countAndLoadEvents() {
      await this.getQueryBus().execute(
        new EventsCountQuery()
      ).then(data => {
        this.eventsCount = data[0].count;      
        this.loadAllEvents();
      });
    },
    async loadAllEvents() {
      await this.getQueryBus().execute(
        new EventsQuery({
          limit: this.eventsPageLimit,
          offset: this.eventsCurrentPage
        })
      ).then(data => {
        this.eventsPageSize = this.eventsPageLimit;
        this.events = data;
      });
    },
    handleEventsPageChange(val) {
      val--;
      this.eventsCurrentPage = (val * this.eventsPageLimit);
      this.loadEvents();
    },
    eventTableRowClassName({row, rowIndex}) {
      if (
        row.event_type_id == EventType.PACKAGE_READ_FAILURE ||
        row.event_type_id == EventType.PACKAGE_WRITE_FAILURE
      ) {
        return 'error-row';
      }
      if (rowIndex % 2 !== 0) {
        return 'el-table__row--striped'
      }
      return '';
    },
    parseMessage(topic, properties) {
      let res = [];
      properties.forEach((property) => {
        if (property.id == "message") {
          let message = property.value;
          if (typeof message == 'object' && message != null) {
            for (let key in message) {
              if (this.$locale.databus.event_panel.message_keys.hasOwnProperty(key)) {
                res.push("<strong>" + this.$locale.databus.event_panel.message_keys[key] + "</strong>: " + message[key]);                
              }
            }
          }
        }
      });
      return res.join(", ");
    }
  },
  mounted () {
    this.loadEvents();
  }
}
