import { FluxStandardAction } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export enum MutationTypes {
    ERROR = 'error',
    LAYER_LOADING_EVENT = 'layer_loading_event',
    LAYER_CREATED_EVENT = 'layer_created_event'  
}

export class LayerLoadingEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_LOADING_EVENT
}

export class LayerCreatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_CREATED_EVENT
    constructor(public location: string){}
}