<template>
  <div class="extraFields_container">
    <div class="extraFilter__item5">
      <div class="demonstration">{{$locale.logic_editor.relates.related_object_field}}</div>
      <el-tree-select
        style="width: 100%; margin-left: -10px;"
        :styles="styles" v-model='filtersFields.related_object_field_id'
        :selectParams="selectParams"
        :treeRenderFun="renderFun"
        ref="related_object_field_id"
        :treeParams="related_object_field_id">
      </el-tree-select>
      <!-- <el-select
        value-key="id"
        class="extraLine_select"
        v-model="filtersFields.related_object_field_id"
        size="mini"
      >
        <el-option
          v-for="item in fields.listAlias"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select> -->
    </div>
    <div class="extraFilter__item5">
      <div class="demonstration">{{ $locale.logic_editor.relates.current_object_field }}</div>
      <el-tree-select
        style="width: 100%; margin-left: -10px;"
        :styles="styles" v-model='filtersFields.current_object_field_id'
        :selectParams="selectParams"
        :treeRenderFun="renderFun"
        ref="current_object_field_id"
        :treeParams="current_object_field_id">
      </el-tree-select>
      <!-- <el-select
        value-key="id"
        class="extraLine_select"
        v-model="filtersFields.current_object_field_id"
        size="mini"
      >
        <el-option
          v-for="item in fields.listCurrentdObject"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select> -->
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Object
    },
    numberFields: {
      type: Object
    }
  },
  data () {
    return {
      styles: {
        width: '100%',
        'margin-left': '10px'
      },
      selectParams: {
        clearable: true,
        size: 'mini',
        placeholder: 'Выбрать'
      },
      related_object_field_id: {
        clickParent: false,
        'check-strictly': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          isLeaf: 'leaf',
          label: 'name',
          children: 'children',
          value: 'id'
        }
      },
      current_object_field_id: {
        clickParent: false,
        'check-strictly': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          isLeaf: 'leaf',
          label: 'name',
          children: 'children',
          value: 'id'
        }
      },
      renderFun (h, { node, data, store }) {
        return (
          <span class="custom-tree-node">
            <span style="width: 100%;">
              { node.label } (id: { data.id }, <b>{ this.$locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }</b>)
            </span>
          </span>
        )
      },
      filtersFields: {
        related_object_field_id: '',
        current_object_field_id: ''
      }
    }
  },
  methods: {
    getValue (val) {
      return this.filtersFields
    }
  },
  mounted () {
    this.filtersFields = this.numberFields
    this.related_object_field_id.data = this.fields.listAlias
    this.$refs.related_object_field_id.treeDataUpdateFun(this.fields.listAlias)
    this.current_object_field_id.data = this.fields.listCurrentdObject
    this.$refs.current_object_field_id.treeDataUpdateFun(this.fields.listCurrentdObject)
  }

}
</script>

<style scoped>
.extraFields_container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-top: 15px;
}
  .extraFilter__item5 {
  display: flex;
  width: 32%;
  flex-direction: column;
  margin-left: 10px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  /* margin-bottom: 8px; */
}
</style>
