import {Model} from 'vue-api-query'

export default class RuleGroup extends Model {
  primaryKey () {
    return 'guid'
  }

  resource () {
    return 'rule_groups'
  }

  // define a base url for a REST API
  baseURL () {
    return `${config.api}/taskeditor`
  }

  // implement a default request method
  request (config) {
    return this.$http.request(config)
  }
}
