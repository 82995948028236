<template>
    <div class="panel">
        <el-row class="buttons">
            <el-tooltip effect="light" :content="$locale.interface_editor.toolbar.add_container"
                        placement="top-start">
                <el-button icon="el-icon-plus" @click="$emit('add-container')" circle
                          :disabled="showComponents"></el-button>
            </el-tooltip>
            <el-tooltip effect="light" :content="$locale.interface_editor.settings.tabs.components.name"
                        placement="top-start">
                <el-button :disabled="showContainerSettings" icon="el-icon-menu" circle
                          @click="showComponents = !showComponents"></el-button>
            </el-tooltip>
            <el-tooltip effect="light" :content="$locale.interface_editor.container_settings.open_settings"
                        placement="top-start">
                <el-button :disabled="containerGuid === null || showComponents"
                          @click="showContainerSettings = !showContainerSettings"
                          icon="el-icon-setting" circle></el-button>
            </el-tooltip>
            <el-tooltip effect="light" :content="$locale.interface_editor.container_settings.delete"
                        placement="top-start">
                <el-button :disabled="containerGuid === null || showComponents"
                          @click="$emit('delete-container', containerGuid)"
                          icon="el-icon-delete" type="danger" plain circle></el-button>
            </el-tooltip>
        </el-row>
        <el-row v-show="!showComponents && !showContainerSettings" class="select">
            <el-select :disabled="containerGuid === null" :value="activeComponentGuid" clearable filterable size="small"
                      style="width: calc(100% - 43px);padding-left: 4px;" @change="setActiveComponent"
                      @clear="setActiveComponent(null)">
                <el-option
                        v-for="item in components"
                        :key="item.guid"
                        :label="item.name"
                        :value="item.guid">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.initialType.split('/')[1] }}</span>
                </el-option>
            </el-select>
            <el-button-group style="width: 39px">
                <el-button style="border: none;"
                          :disabled="activeComponentGuid === null"
                          @click.stop="reorderComponent(activeComponentGuid, containerGuid, 'down')"
                          icon="el-icon-top"
                          size="mini"></el-button>
                <el-button style="border: none;"
                          :disabled="activeComponentGuid === null"
                          @click.stop="reorderComponent(activeComponentGuid, containerGuid, 'up')"
                          icon="el-icon-bottom"
                          size="mini"></el-button>
            </el-button-group>
        </el-row>
        <el-row v-show="showContainerSettings" style="padding: 15px;">
            <el-form label-width="120px" label-position="top" size="mini">
                <el-form-item label="CSS">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="css"
                            v-model="activeContainerLocal.style">
                    </el-input>
                </el-form-item>
                <el-form-item :label="$locale.interface_editor.settings.tabs.container.stretchable">
                    <el-checkbox
                            v-model="activeContainerLocal.extended">
                    </el-checkbox>
                </el-form-item>
                <el-form-item :label="$locale.interface_editor.settings.tabs.container.is_html">
                    <el-checkbox
                            v-model="activeContainerLocal.isHtml">
                    </el-checkbox>
                </el-form-item>
                <el-form-item :label="$locale.interface_editor.settings.tabs.container.screen">
                    <el-checkbox
                            v-model="activeContainerLocal.extendedToBottom">
                    </el-checkbox>
                </el-form-item>
                <el-form-item :label="$locale.interface_editor.settings.tabs.container.background">
                    <EditorBgCard v-model="activeContainerLocal"></EditorBgCard>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row v-show="!showComponents && !showContainerSettings" class="custom_scrollbar"
                style="height: calc(100% - 65px - 48px); overflow: auto; overflow-x: hidden;">
            <el-form v-show="!showComponents" :key="activeComponentGuid" label-width="120px" label-position="top"
                    size="mini" style="padding: 10px" v-if="activeComponentGuid">
                <div v-for="(property, index) in getComponentPropertiesFields(activeComponentGuid)"
                    :key="index">
                  <span v-if="property.type !== 'editorString'" class="registry_fields_label" style="display: inline-block">
                    {{ property.label }}
                  </span>
                    <el-form-item :style="'margin-bottom: 5px;' + (property.type === 'editorCheckbox' ? 'display:inline-block;' : '')">
                        <component
                                v-bind="property.bind"
                                :is="property.type"
                                :label="property.label"
                                @change="updateComponentProperty($event, property.name, activeComponentGuid)"
                        ></component>
                    </el-form-item>
                </div>
            </el-form>
            <el-button v-show="activeComponentGuid !== null"
                      @click="setActiveComponent(null);deleteComponent(activeComponentGuid)"
                      style="width: 100%; position: sticky; bottom: 0px; z-index: 5;"
                      size="small" type="danger" plain icon="el-icon-delete">{{
                $locale.interface_editor.container_settings.delete }}
            </el-button>
        </el-row>
        <el-row v-show="showComponents" class="custom_scrollbar"
                style="height: calc(100% - 65px); overflow: auto;padding: 10px">
            <el-collapse>
                <el-collapse-item
                                  :title="availableComponents[0].label"
                                  :disabled="containerGuid === null"
                >
                    <el-row>
                        <div v-for="(component, componentIndex) in availableComponents[0].children"
                            :key="componentIndex"
                            :span="12"
                            :style="getCssVars(component.value)"
                            class="available-component a-button-image el-col el-col-12"
                            @click="addComponent([availableComponents[0].value, component.value], containerGuid)"
                        >
                            <span class="label">{{ component.label }}</span>
                        </div>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
            <span style="font-size: 13px;display: block;line-height: 48px;">{{$locale.interface_editor.container_settings.fields_registry }}</span>
            <treeselect
              ref="registryFieldSelect"
              :options="availableComponents[1].children"
              :disabled="containerGuid === null"
              placeholder="Выбрать"
              class="custom_scrollbar"
              :normalizer="registryFieldsSettings.normalizer"
              :disable-branch-nodes="true"
              :clear-on-select="true"
              :clearValueText="registryFieldsSettings.clearValueText"
              :noChildrenText="registryFieldsSettings.noChildrenText"
              :noResultsText="registryFieldsSettings.noResultsText"
              :limitText="registryFieldsSettings.limitText"
              :matchKeys="registryFieldsSettings.matchKeys"
              @select="selectFieldCurrentTable($event, containerGuid)"
              :value-consists-of="registryFieldsSettings.valueConsistsOf">
              <!--<div slot="value-label" slot-scope="{ node }">{{ node.label }} <b> (id: {{ node.raw.id }})</b></div>-->
              <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
                <b>{{ node.label }}</b><br><span style="font-size: 10px">attr_{{node.id}}_</span><span style="float: right; font-size: 10px;">{{ $locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id }}</span>
              </label>
            </treeselect>
        </el-row>
    </div>
</template>

<script>
import EditorCheckbox from './components/editor/editor-checkbox'
import EditorString from './components/editor/editor-string'
import EditorRegistryHeaders from './components/editor/editor-registry-headers'
import EditorFitImage from './components/editor/editor-fit-image'
import EditorPlugins from './components/editor/editor-plugins'
import EditorSelect from './components/editor/editor-select'
import EditorRegistrySelect from './components/editor/editor-registry-select'
import EditorTable from './components/editor/editor-table'
import EditorButtonAction from './components/editor/editor-button-action'
import EditorConditions from './components/editor/editor-conditions'
import EditorNumber from './components/editor/editor-number'
import EditorLink from './components/editor/editor-link-image.vue'
import EditorFilters from './components/editor/editor-filters'
import EditorOptions from './components/editor/editor-options'
import EditorBgCard from './components/editor/editor-background-card'
import EditorShowButton from './components/editor/editor-show-button'
import EditorXrefAlternativeSource from './components/editor/editor-xref-alternative-source'
import EditorColorDonutchart from './components/editor/chart/editor-color-donutchart.vue'
import editorLegendChart from './components/editor/chart/editor-legend-chart.vue'
import editorTitleChart from './components/editor/chart/editor-title-chart.vue'
import editorChart from './components/editor/chart/editor-chart'
import EditorSource from './components/editor/editor-source'
import EditorDropdownMenu from './components/editor/editor-dropdown-menu'
import EditorControlValues from './components/editor/editor-control-values'
import EditorTasksSource from './components/editor/editor-tasks-source'
import EditorYandexMaps from './components/editor/editor-yandex-maps'
import EditorTasksGroups from './components/editor/editor-tasks-groups'
import EditorHtmlDesign from './components/editor/editor-html-design'
import EditorTasksChangeInteractive from './components/editor/editor-tasks-change-interactive'
import EditorAccordionSource from './components/editor/editor-accordion-source'
import EditorCards from './components/editor/editor-cards'
import EditorCalendar from './components/editor/editor-calendar.vue'
import EditorList from './components/editor/editor-list.vue'
import EditorTree from './components/editor/editor-tree.vue'
import EditorFrames from './components/editor/editor-frames'
import Columns from './components/editor/Columns/index'
import EditorAnalyticTableSource from './components/editor/editor-analytic-table-source'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'settings-panel',
  inject: [
    'setActiveComponent',
    'getComponentPropertiesFields',
    'updateComponentProperty',
    'addComponent',
    'deleteComponent',
    'reorderComponent',
    'getContainer',
    'updateContainerStyles'
  ],
  components: {
    EditorCheckbox,
    EditorString,
    EditorRegistryHeaders,
    EditorFitImage,
    EditorPlugins,
    EditorSelect,
    EditorRegistrySelect,
    EditorTable,
    EditorButtonAction,
    EditorConditions,
    EditorNumber,
    EditorLink,
    EditorFilters,
    EditorOptions,
    EditorBgCard,
    EditorShowButton,
    EditorXrefAlternativeSource,
    EditorColorDonutchart,
    editorLegendChart,
    editorTitleChart,
    editorChart,
    EditorSource,
    EditorDropdownMenu,
    EditorControlValues,
    EditorTasksSource,
    EditorYandexMaps,
    EditorTasksGroups,
    EditorHtmlDesign,
    EditorTasksChangeInteractive,
    EditorAccordionSource,
    EditorCards,
    EditorCalendar,
    EditorList,
    EditorTree,
    Treeselect,
    EditorFrames,
    Columns,
    EditorAnalyticTableSource
  },
  props: {
    containerGuid: {
      type: String,
      default: null
    },
    activeComponentGuid: {
      type: String,
      default: null
    },
    components: {
      type: Array,
      default () {
        return []
      }
    },
    availableComponents: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    activeComponentGuid () {
      this.showComponents = false
    },
    containerGuid (value) {
      if (value) {
        this.activeContainerLocal = JSON.parse(JSON.stringify(this.getContainer(value)))
      }
      this.showComponents = false
    },
    activeContainerLocal: {
      deep: true,
      handler (value) {
        this.updateContainerStyles(this.containerGuid, value)
      }
    }
  },
  methods: {
    selectFieldCurrentTable (value, containerGuid) {
      this.addComponent(['registry', value], containerGuid)
      this.$refs.registryFieldSelect.clear()
    },
    getCssVars (component) {
      return {
        '--bg-image': `url(/icon/components/${component}.svg)`
      }
    }
  },
  data () {
    return {
      registryFieldsSettings: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        limitText: count => `и еще ${count}`,
        normalizer (node) {
          return {
            id: node.id,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      },
      showContainerSettings: false,
      showComponents: false,
      activeContainerLocal: {}
    }
  }
}
</script>

<style scoped>
    .panel {
        border-left: 2px solid #E4E7ED;
        width: 100%;
        height: 100%;
    }

    .panel .buttons {
        text-align: center;
        padding-top: 25px;
    }

    .panel .select {
        padding-top: 15px;
    }

    .panel .available-component:nth-child(odd) {
        border-right: 1px solid #E4E7ED;
    }

    .panel .available-component:nth-child(n+3) {
        border-top: 1px solid #E4E7ED;
    }

    .panel .available-component {
        height: 100px;
        cursor: pointer;
        position: relative;
    }

    .panel .available-component .label {
        display: block;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        font-size: 10px;
        color: #7D7D7D;
    }

    .panel .available-component:hover {
        background: rgba(64, 158, 255, 0.1);
    }

    .panel .available-component:hover::before {
        background-color: #409eff;
    }

    .a-button-image:before {
        content: '';
        background-color: black;
        -webkit-mask: var(--bg-image) 50% 50% no-repeat;
        mask: var(--bg-image) 50% 50% no-repeat;
        width: 100%;
        height: 95%;
        position: absolute;
        font-size: 40px;
        text-align: center;
    }
</style>
