









































































































import SnapshotsQuery from '@/services/SyncService/application/query/SnapshotsQuery'
import SnapshotsCountQuery from '@/services/SyncService/application/query/SnapshotsCountQuery'

export default {
  name: 'SnapshotPanel',
  components: {
    
  },
  data () {
    return {
      loading: false,
      snapshotsPageSize: 0,
      defaultSnapshotsPageSize: 100,
      snapshotsCurrentPage: 0,
      snapshotsPageLimit: 100,
      snapshotsCount: 0,
      allSnapshotsCount: 0,
      snapshots: [],
      snapshot: null,
      informationPanelHeight: 0
    }
  },
  inject: ['getEventBus', 'getQueryBus'],
  methods: {
    loadSnapshots() {
      if (this.allSnapshotsCount == 0) {
        this.countAndLoadSnapshots();
      } else {
        this.loadAllSnapshots();
      }
    },
    async countAndLoadSnapshots() {
      await this.getQueryBus().execute(
        new SnapshotsCountQuery()
      ).then(data => {
        this.snapshotsCount = data[0].count;      
        this.loadAllSnapshots();
      });
    },
    async loadAllSnapshots() {
      await this.getQueryBus().execute(
        new SnapshotsQuery({
          limit: this.snapshotsPageLimit,
          offset: this.snapshotsCurrentPage
        })
      ).then(data => {
        this.snapshotsPageSize = this.snapshotsPageLimit;
        this.snapshots = data;
      });
    },
    handleSnapshotsPageChange(val) {
      val--;
      this.snapshotsCurrentPage = (val * this.snapshotsPageLimit);
      this.loadSnapshots();
    },
    parseDump(record, schema, table){
      let res = [];
      if (typeof record.id !== "undefined") {
        res.push("<strong>id</strong>: " + record.id)
      }
      if (typeof record.name !== "undefined") {
        res.push("<strong>" + this.$locale.main.fields.name + "</strong>: " + record.name)
      }

      if (schema == 'report_editor') {
        res.push("<strong>" + this.$locale.main.fields.type + "</strong>: " + this.$locale.report_editor.types[record.report_type]);
      }

      if (schema == 'menu_editor') {
        if (table == 'menu') {
          res.push("<strong>" + this.$locale.main.fields.type + "</strong>: " + this.$locale.menu_editor.label[record.menu_type_id]);

          for (const [key, value] of Object.entries(record)) {
            if (key !== 'name' && this.$locale.menu_editor.label.hasOwnProperty(key)) {
              res.push("<strong>" + this.$locale.menu_editor.label[key] + "</strong>: " + (value ?? ""));
            }
          }

          let props = JSON.parse(record.properties);
          if (props) {
            for (const [key, value] of Object.entries(props)) {
              if (this.$locale.menu_editor.label.hasOwnProperty(key)) {
                res.push("<strong>" + this.$locale.menu_editor.label[key] + "</strong>: " + (value ?? ""));
              }
            }
          }
        }
      }

      if (schema == 'object_editor') {
        if (table == 'entities') {
          let props = record.properties;
          if (props != null) {
            props.forEach(prop => {
              if (this.$locale.object_editor.settings.properties.hasOwnProperty(prop.id)) {
                let val = "<strong>" + this.$locale.object_editor.settings.properties[prop.id] + "</strong>: ";
                if (prop.value == true) {
                  val += this.$locale.main.fields.true;
                } else if (prop.value == false) {
                  val += this.$locale.main.fields.false;
                } else {
                  val += (prop.value ?? "");
                }

                res.push(val);
              }
            });
          }
        }
      }

      return res.join(", ");
    }
  },
  mounted () {
    this.loadSnapshots();
  }
}
