import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UserApiKeyQuery from '../../query/UserApiKeyQuery'

export default class UserApiKeyHandler implements QueryHandlerInterface
{
    private repo: UserQueryRepositoryInterface;

    constructor(repo: UserQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: UserApiKeyQuery): Promise<any>
    {
        return this.repo.getApiKey(
            query.getUserId(),
            query.getRoleId(),
            query.getSystemIps()
        );
    }
}