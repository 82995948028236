












































































import PackagesQuery from '@/services/Databus/application/query/PackagesQuery'
import PackagesCountQuery from '@/services/Databus/application/query/PackagesCountQuery'

export default {
  name: 'PackagePanel',
  components: {
    
  },
  data () {
    return {
      loading: false,
      packagesPageSize: 0,
      defaultPackagesPageSize: 100,
      packagesCurrentPage: 0,
      packagesPageLimit: 100,
      packagesCount: 0,
      allPackagesCount: 0,
      packages: [],
      package_: null,
      informationPanelHeight: 0
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getDownloadPackage'],
  methods: {
    loadPackages() {
      if (this.allPackagesCount == 0) {
        this.countAndLoadPackages();
      } else {
        this.loadAllPackages();
      }
    },
    async countAndLoadPackages() {
      await this.getQueryBus().execute(
        new PackagesCountQuery()
      ).then(data => {
        this.packagesCount = data[0].count;      
        this.loadAllPackages();
      });
    },
    async loadAllPackages() {
      await this.getQueryBus().execute(
        new PackagesQuery({
          limit: this.packagesPageLimit,
          offset: this.packagesCurrentPage
        })
      ).then(data => {
        this.packagesPageSize = this.packagesPageLimit;
        this.packages = data;
      });
    },
    changePackage() {

    },
    handlePackagesPageChange(val) {
      val--;
      this.packagesCurrentPage = (val * this.packagesPageLimit);
      this.loadPackages();
    }
  },
  mounted () {
    this.loadPackages();
  }
}
