import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import 'moment/locale/ru'
import router from './router/index.js'
import locale from './locale/ru.json'
import lang from 'element-ui/lib/locale/lang/ru-RU.js'
import ElementLocale from 'element-ui/lib/locale'
import VueClipboard from 'vue-clipboard2'
import ElTreeSelect from 'el-tree-select'
import VueAgile from 'vue-agile'
// import VueNativeSock from 'vue-native-websocket'
import VueSplit from 'vue-split-panel'
import { Model } from 'vue-api-query'
import VueLayers from 'vuelayers'
import 'vuelayers/dist/vuelayers.css'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/twig/twig.js'
import 'codemirror/addon/merge/merge.js'
import 'codemirror/addon/merge/merge.css'
import 'codemirror/addon/edit/closetag.js'
import VueMask from 'v-mask'

declare const config: any

Vue.use(VueLayers)
Model.$http = axios
ElementLocale.use(lang)
Vue.use(Element)
const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(ElTreeSelect)
Vue.use(VueAgile)
Vue.use(VueSplit)
Vue.use(VueCodemirror)
// console.log(localStorage)
// Vue.use(VueNativeSock, `ws://${config.api.substring(7)}/notificationservice/socket`, {
//   store: store,
//   reconnection: true, // (Boolean) whether to reconnect automatically (false)
//   reconnectionAttempts: 10, // (Number) number of reconnection attempts before giving up (Infinity),
//   reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
//   connectManually: true,
//   protocol: localStorage.getItem('user-token')
// })
Vue.use(VueMask, {
  placeholders: {
    Я: /[а-яА-Я]/
  }
})
Vue.mixin({
  methods: {
    generateGuid () {
      let d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
    },
    toCamelCase (s: string): string {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    toSnakeCase (s: string): string {
      return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    }
  },
  filters: {
    xref (value) {
      try {
        value = JSON.parse(value)
      } catch (e) {

      }

      if (Array.isArray(value)) {
        value = value.map((item) => {
          return (typeof item.name !== 'undefined' ? item.name : item)
        }).join(',')
      }

      return value
    }
  }
})

Vue.prototype.$http = axios
Vue.prototype.$http.interceptors.request.use(function (config) {
  let token = localStorage.getItem('user-token')
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.prototype.$http.interceptors.response.use(function (response) {
  if (response.headers['x-auth-token']) {
    localStorage.setItem('user-token', response.headers['x-auth-token'])
  }
  return response
}, function (error) {
  if (error.response.headers['x-auth-token']) {
    localStorage.setItem('user-token', error.response.headers['x-auth-token'])
  }
  return Promise.reject(error)
})

Vue.prototype.$config = config
Vue.prototype.$locale = locale
let VueApp: any = Vue
export default new VueApp({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted () {
    let lang = this.$route.query.lang
    if (!lang) {
      lang = 'ru'
    }
    import(`./locale/${lang}.json`).then((locale) => {
      Vue.prototype.$locale = locale
    })
    document.title = this.$config.title
    if (this.$config.project === 'Rgis') {
      const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
      link.href = `/homepage/${this.$config.project}/favicon.png`
    }
  }
})
