<template>
  <el-form style="height: calc(100% - 16px)">
    <InterfaceEditor ref="interface_editor" @save="saveCard"
                     :registry-fields="registry_fields" :entityTitle="`${name} (${registry_name})`" :registryId="registry_id">
      <template v-slot:settings>
        <el-form-item :label="$locale.interface_editor.operations">
        <el-select v-model="operations" multiple collapse-tags value-key="id">
          <el-option
                  v-for="(item, index) in available_operations"
                  :key="index"
                  :label="item.name"
                  :value="item">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.default_state">
          <el-select v-model="defaultState" value-key="id" clearable>
            <el-option
                    v-for="(item, index) in states"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_add">
          <el-input v-model="titleForAdd"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_edit">
          <el-input v-model="titleForEdit"></el-input>
        </el-form-item>
        <el-form-item :label="`${$locale.logic_editor.listeners.title} / ${$locale.logic_editor.listeners.translate.update_event}`">
          <el-input v-model="listeners.update_event"></el-input>
        </el-form-item>
      </template>
    </InterfaceEditor>
  </el-form>
  <!--<div>
    <div class="title">{{$locale.interface_editor.main.title}}</div>
    <el-alert
      v-if="error"
      :title="error_text"
      type="error"
      :closable="false">
    </el-alert>
    <div v-else>
      <el-header>
        <el-row class="header">
          <el-col :span="12">
            <h1 class="heading">{{ name }} ({{ registry_name }})</h1>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div style="display: inline-block">
            <span class="registry_fields_label" style="text-align: left;">
              {{ $locale.interface_editor.operations }}
            </span>
            <el-select v-model="operations" multiple size="mini" collapse-tags value-key="id">
              <el-option
                v-for="(item, index) in available_operations"
                :key="index"
                :label="item.name"
                :value="item">
              </el-option>
            </el-select>
            </div>
            <div style="display: inline-block">
              <span class="registry_fields_label" style="text-align: left;">
                {{ $locale.interface_editor.default_state }}
              </span>
            <el-select v-model="defaultState" size="mini" value-key="id" clearable>
              <el-option
                v-for="(item, index) in states"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <keep-alive>
        <el-form>
          <InterfaceEditor ref="interface_editor" @save="saveCard"
                           :registry-fields="registry_fields"></InterfaceEditor>
        </el-form>
      </keep-alive>
    </div>
  </div>-->
</template>

<script>
import Card from './Models/Card'
import InterfaceEditor from '@/components/InterfaceEditor'
import Registry from './Models/Registry'

export default {
  name: 'CardEditor',
  components: {
    InterfaceEditor
  },
  props: ['registry_id', 'card_id'],
  data () {
    return {
      loading: false,
      error: false,
      error_text: 'всё очень плохо',
      structure: {},
      name: null,
      is_default: false,
      registry_fields: [],
      registry_name: null,
      available_operations: [],
      operations: [],
      defaultState: null,
      states: [],
      titleForEdit: null,
      titleForAdd: null,
      listeners: {
        update_event: null
      }
    }
  },
  async mounted () {
    this.loading = true
    if (!this.registry_id) {
      this.error = true
      return false
    } else {
      this.loadRegistryData(this.registry_id)
      this.available_operations = await this.getApprovalButtons(this.registry_id)
      this.states = await this.getRegistryStates(this.registry_id)
    }
    if (this.card_id) {
      this.loadCard(this.card_id)
    }
    this.loading = false
  },
  methods: {
    async loadCard (cardId) {
      this.loading = true
      let card = await Card.find(cardId)
      if (card) {
        this.name = card.name
        this.is_default = card.is_default
        this.structure = JSON.parse(card.structure)
        this.operations = JSON.parse(card.structure).operations || []
        this.defaultState = JSON.parse(card.structure).defaultState
        if (this.structure) {
          this.$refs.interface_editor.loadState(this.structure)
        }
        this.titleForEdit = JSON.parse(card.structure).titleForEdit || card.name
        this.titleForAdd = JSON.parse(card.structure).titleForAdd || card.name
        this.listeners = JSON.parse(card.structure).listeners || this.listeners
      } else {
        this.error = true
      }
      this.loading = false
    },
    async loadRegistryData (registryId) {
      this.loading = true
      let registry = new Registry({ id: registryId })
      let me = this
      let structure = await registry.structure().first().catch(() => {
        me.error = true
      })
      if (structure) {
        this.registry_name = structure.name
        await this.loadRegistryFields(registryId)
      }
      this.loading = false
    },
    async loadRegistryFields (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      this.registry_fields = this.deepSearch(data.data.data)
    },
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array) this.deepSearch(el.children)
      }
      return arr
    },
    async saveCard (data) {
      let card = new Card({ id: this.card_id })
      card.id = this.card_id
      card.name = this.name
      card.is_default = this.is_default
      card.structure = Object.assign(data, {
        operations: this.operations,
        defaultState: this.defaultState,
        titleForAdd: this.titleForAdd,
        titleForEdit: this.titleForEdit,
        listeners: this.listeners
      })

      await card.save()
    },
    async getApprovalButtons (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?object_id=${registryId}&properties[is_approval_button]=true`)
      return data.data.data.map((item) => {
        return { id: item.id, name: item.name, type: 'approval', visible: false }
      })
    },
    async getRegistryStates (registryId) {
      let logicData = await this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${registryId}`)
      let logicId = (logicData.data[0] || {}).id
      if (logicId) {
        let data = await this.$http.get(`${this.$config.api}/logiceditor/states?logic_id=${logicId}`)
        return data.data
      }
      return []
    }
  }
}
</script>

<style scoped>
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-left: 24px;
    margin-bottom: 14px;
    margin-top: 15px;
    /*padding-left: 20px;*/
    height: 28px;
    color: #2C2D35;
  }

  .heading {
    margin-left: 4px;
  }
</style>
