<template>
  <el-input v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="placeholder"></el-input>
</template>

<script>
export default {
  name: 'Constant',

  props: ['value', 'placeholder']
}
</script>

<style type="text/css">

</style>
