<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
      <editor-select :value="value.type" @change="$set(value ,'type', $event)" :options="{multiple: false, options: data_source_types}"></editor-select>
    </el-form-item>
    <el-form-item  v-if="value.type === 'extend_object'">
      <editor-select :value="value.extendObject" @change="$set(value ,'extendObject', $event)" :options="{multiple: false, options: listExtendObject}"></editor-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.registry" v-if="value.type === 'registry'">
      <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.column" v-if="(value.type === 'registry' && value.registryId) || value.type === 'plugin'">
      <editor-registry-headers :value="value.columns" :otherProperties="{list: {}}" :options="{ objectId: value.registryId }" @change="$set(value ,'columns', $event)"></editor-registry-headers>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.search" v-if="value.type === 'registry' && value.registryId">
      <el-input v-model="value.searchAttributes"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.search_show" v-if="value.type === 'registry' && value.registryId">
      <el-checkbox v-model="value.showSearch"></el-checkbox>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.condition" v-if="value.type === 'registry' && value.registryId">
      <el-input v-model="value.stateId"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.plugin" v-if="value.type === 'plugin'">
      <editor-plugin :value="value.plugin" @change="$set(value ,'plugin', $event)"></editor-plugin>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.cell_click_plugin">
      <el-input v-model="value.cellClickPlugin"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.choice" v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.is_selectable"></el-checkbox>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.fast_add" v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.fast_add"></el-checkbox>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.pagination" v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.show_pagination"></el-checkbox>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.summary" v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.show_summary"></el-checkbox>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.filter" v-if="value.type === 'plugin'">
    </el-form-item>
    <el-row v-if="value.type === 'plugin'" style="margin-bottom: 5px">
        <el-row>
          <el-button @click="addFilter">{{$locale.interface_editor.component_editor.table.add}}</el-button>
        </el-row>
        <el-row v-for="(condition, index) in value.filters" :key="index">
          <el-col :span="8">
            <el-select
              v-model="condition.attribute"
            >
              <el-option
                v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                :key="index"
                :label="item.name"
                :value="item.properties.name">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="14">
            <el-input v-model="condition.alias"></el-input>
          </el-col>
          <el-col :span="2" style="float: right;">
            <el-button
              @click="deleteFilter(index)"
              size="mini"
              type="danger"><i class="el-icon-delete"></i></el-button>
          </el-col>
        </el-row>
      </el-row>
    <el-form-item :label="$locale.interface_editor.component_editor.table.card" v-if="value.type === 'plugin'">
      <editor-dashboards :value="value.editor" @change="$set(value ,'editor', $event)"></editor-dashboards>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorPlugin from './editor-plugins'
import EditorSelect from './editor-select'
import EditorRegistrySelect from './editor-registry-select'
import EditorRegistryHeaders from './editor-registry-headers'
import EditorDashboards from './editor-dashboards'

export default {
  components: {
    EditorDashboards,
    EditorSelect,
    EditorRegistrySelect,
    EditorRegistryHeaders,
    EditorPlugin
  },
  name: 'editor-table',
  inject: ['getComponents'],
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      components: this.getComponents(),
      data_source_types: [
        { id: 'registry', name: 'Реестр' },
        { id: 'plugin', name: 'Плагин' }/*,
        { id: 'extend_object', name: 'Расширенная таблица' } */
      ],
      listExtendObject: []
    }
  },
  methods: {
    addFilter () {
      if (typeof this.value.filters === 'undefined') {
        this.$set(this.value, 'filters', [])
      }
      this.value.filters.push({
        attribute: null,
        alias: null
      })
    },
    deleteFilter (index) {
      this.value.filters.splice(index, 1)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.listExtendObject = response.data
  }
}
</script>

<style scoped>

</style>
