<template>
  <Split style="height: 500px">
    <SplitArea :size="50">
      <div>
        <el-popover trigger="click" placement="top">
          <el-button type="text" icon="el-icon-arrow-down" slot="reference">Из источника</el-button>
          <div>
            <el-input
              size="mini"
              :placeholder="$locale.main.fields.search"
              v-model="filterText">
            </el-input>
            <el-scrollbar :style="{height:'300px'}">
              <el-tree
                ref="availableColumnsTree"
                :data="availableColumns"
                :props="properties"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                @node-click="addColumn"
              >
                  <span slot-scope="{ node, data }">
                    <span :class="{'isset-node': hasColumn(value, data.field)}">{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.field }})</b></span>
                  </span>
              </el-tree>
            </el-scrollbar>
          </div>
        </el-popover>
        <el-button type="text" icon="el-icon-plus" style="margin-left: 10px" @click="addCustomColumn">{{ $locale.main.button.add }}</el-button>
      </div>
      <el-scrollbar :style="{height:'calc(100% - 29px)'}">
        <el-tree
          node-key="guid"
          :data="value"
          :props="properties"
          :expand-on-click-node="false"
          @node-click="activeNode = $event"
        >
          <span :class="{'active-node' : activeNode.guid === data.guid }" slot-scope="{ node, data }">
            <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.field }})</b></span>
            <el-button
              type="text"
              class="delete-button"
              @click.stop="deleteColumn(data)">
              {{ $locale.main.button.delete }}
            </el-button>
          </span>
        </el-tree>
      </el-scrollbar>
    </SplitArea>
    <SplitArea :size="50">
      <el-form size="mini" style="padding: 10px" v-if="Object.keys(activeNode).length" class="form">
        <el-col :span="12">
          <el-form-item label="Название">
            <el-input v-model="activeNode.headerName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Аттрибут">
            <el-input v-model="activeNode.field"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Тип поля">
            <el-select
              :value="activeNode.columnType"
              @change="changeColumnType"
            >
              <el-option
                v-for="(item, index) in columnTypes"
                :key="index"
                :label="$locale.object_editor.entity_types[toSnakeCase(item)] || item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox :value="!!activeNode.filter" @change="setFilterable($event, activeNode)">В фильтре</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox :value="activeNode.isExtended" @change="$set(activeNode, 'isExtended', $event)">Растягиваемое</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="activeNode.columnType === 'stringField'">
          <el-form-item>
            <el-checkbox :value="activeNode.isStringSetFilter" @change="$set(activeNode, 'isStringSetFilter', $event)">Фильтр со списком</el-checkbox>
          </el-form-item>
        </el-col>
      </el-form>
    </SplitArea>
  </Split>
</template>

<script>
import { FilterBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/FilterBuilder'
import ColumnTypes from '@/core/infrastructure/components/Grid/ColumnTypesList.json'
const customField = {
  headerName: 'Столбец',
  field: 'Атрибут',
  children: undefined,
  type: ['stringField'],
  enableRowGroup: true,
  columnType: 'stringField'
}
export default {
  name: 'configurator',
  props: {
    sourceType: {
      type: String
    },
    sourceId: {
      type: Number
    },
    availableColumns: {
      type: Array
    },
    value: {
      type: Array
    }
  },
  data () {
    return {
      properties: {
        label: 'headerName'
      },
      filterText: null,
      activeNode: {},
      columnTypes: ColumnTypes
    }
  },
  methods: {
    hasColumn (array, field) {
      let result = false
      array.forEach((item) => {
        if (result) {
          return
        }
        if (item.field === field) {
          result = true
        } else {
          if (Array.isArray(item.children) && item.children.length > 0) {
            result = this.hasColumn(item.children, field)
          }
        }
      })
      return result
    },
    changeColumnType (type) {
      let types = this.activeNode.type
      types[0] = type
      const isFilterable = !!this.activeNode.filter

      isFilterable && this.setFilterable(false, this.activeNode)

      this.$set(this.activeNode, 'type', types)
      this.$set(this.activeNode, 'columnType', type)

      isFilterable && this.setFilterable(true, this.activeNode)
    },
    addCustomColumn () {
      let result = JSON.parse(JSON.stringify(Object.assign(customField, { guid: this.generateGuid() })))
      this.$emit('update:value', [...this.value, result])
    },
    filterNode (value, data) {
      if (!value) return true
      return data.headerName.indexOf(value) !== -1 || data.field.indexOf(value) !== -1
    },
    addColumn (data) {
      const result = Object.assign(JSON.parse(JSON.stringify(data)), { guid: this.generateGuid() })
      this.$emit('update:value', [...this.value, result])
    },
    deleteColumn (data) {
      if (this.activeNode.guid === data.guid) {
        this.activeNode = {}
      }
      this.$emit('update:value', [...this.value.filter(item => item.guid !== data.guid)])
    },
    setFilterable (toSet, data) {
      let filterObject = FilterBuilder.build(data.columnType)

      for (let key in filterObject) {
        if (toSet) {
          this.$set(data, key, filterObject[key])
        } else {
          this.$delete(data, key)
        }
      }
      console.log(data, filterObject, this.value)
    }
  },
  watch: {
    filterText (val) {
      this.$refs.availableColumnsTree.filter(val)
    }
  }
}
</script>

<style scoped>
.form /deep/ .el-form-item__label {
  padding-bottom: 0px;
}
.form /deep/ .el-form-item {
  padding: 0 5px;
}
.active-node {
  font-weight: bold;
}
.delete-button {
  background: white;
  position: absolute;
  right: 5px;
  color: #F56C6C;
  display: none;
}
/deep/ .el-tree-node:hover>.el-tree-node__content>span>.delete-button {
  display: inline-block;
}
.isset-node {
  color: #67c23a;
}
</style>
