<script type="ts">
import ElHeader from 'element-ui/packages/header/src/main.vue'
import ElFooter from 'element-ui/packages/footer/src/main.vue'
import ElMain from 'element-ui/packages/main/src/main.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import ElRow from 'element-ui/packages/row/src/row.js'
import ElBreadcrumb from 'element-ui/packages/breadcrumb/src/breadcrumb.vue'
import ElBreadcrumbItem from 'element-ui/packages/breadcrumb/src/breadcrumb-item.vue'
import LayerForm from '@/services/MapEditor/infrastructure/components/LayerForm/index.vue'
import SourceForm from '@/services/MapEditor/infrastructure/components/SourceForm/index.vue'
import SourceSelectPanel from '@/services/MapEditor/infrastructure/components/SourceSelectPanel/index.vue'
import CoordinateSystemSelectPanel from '@/services/MapEditor/infrastructure/components/CoordinateSystemSelectPanel/index.vue'
import CoordinateSystemForm from '@/services/MapEditor/infrastructure/components/CoordinateSystemForm/index.vue'
import AffineTransformationSelectPanel from '@/services/MapEditor/infrastructure/components/AffineTransformationSelectPanel/index.vue'
import AffineTransformationForm from '@/services/MapEditor/infrastructure/components/AffineTransformationForm/index.vue'

export default {
  name: "FormSet",
  props: ['projectId', 'projectGuid', 'defaultItems'],
  inject: ['getEventBus'],
  data() {
    return {
      items: [],
      itemTemplates: {
        "source": {
          type: "source",
          action: this.$locale.map_editor.layer_form.save_source_action,
          title: this.$locale.map_editor.layer_form.add_source_op  
        },
        "source_edit": {
          type: "source",
          action: this.$locale.map_editor.layer_form.save_source_action,
          title: this.$locale.map_editor.layer_form.edit_source_op  
        },
        "source_select": {
          type: "source_select",
          action: this.$locale.map_editor.layer_form.select_source_action,
          title: this.$locale.map_editor.layer_form.select_source_op  
        },
        "coordinate_system": {
          type: "coordinate_system",
          action: this.$locale.map_editor.layer_form.save_coordinate_system_action,
          title: this.$locale.map_editor.layer_form.add_coordinate_system_op  
        },
        "coordinate_system_select": {
          type: "coordinate_system_select",
          action: this.$locale.map_editor.layer_form.select_coordinate_system_action,
          title: this.$locale.map_editor.layer_form.select_coordinate_system_op  
        },
        "affine_transformation": {
          type: "affine_transformation",
          action: this.$locale.map_editor.layer_form.save_affine_transformation_action,
          title: this.$locale.map_editor.layer_form.add_affine_transformation_op  
        },
        "affine_transformation_select": {
          type: "affine_transformation_select",
          action: this.$locale.map_editor.layer_form.select_affine_transformation_action,
          title: this.$locale.map_editor.layer_form.select_affine_transformation_op  
        }
      }
    }
  },
  methods: {
    createHeader() {
      return this.$createElement(ElHeader, {
        props: {},
        class: {
          "layer-form-header": true
        }
      }, [this.createRow()]);
    },
    createRow() {
      return this.$createElement(ElRow, {
        props: {}
      }, [this.createNavigation()]);
    },
    createNavigation() {
      return this.$createElement(ElBreadcrumb, {
        props: {
            separatorClass: "el-icon-arrow-right"
        }
      }, [this.createBackNavigation(), ...this.createNavigationItems()]);
    },
    createBackNavigation() {
      return this.$createElement(ElBreadcrumbItem, {
      },[
        this.$createElement("a", {
          on: {
            click: () => {
              this.goBack(this.items.length - 1);
            }
          }
        },this.$locale.main.button.back)
      ]);
    },
    createNavigationItems() {
      let items = [];
      for (let i = 0; i < this.items.length; i += 1) {
        items.push(
          this.createNavigationItem(i, this.items[i].title)
        );
      }
      return items;
    },
    createNavigationItem(index, title) {
      if (index < this.items.length - 1) {
        return this.$createElement(ElBreadcrumbItem, {
          },[
            this.$createElement("a", {
              on: {
                click: () => {
                    return this.goBack(index + 1)
                }
              }
            },title)
        ]);
      }
      return this.$createElement(ElBreadcrumbItem, {
      },[title]);
    },
    createForms() {
      return [
        this.$createElement(ElMain, {
          props: {},
          class: {
            "layer-form-body": true
          },
          style: {
            "width": "100%"
          }
        },[...this.createFormItems()])
      ];
    },
    createFormItems() {
      let items = [];
      if (this.items && this.items.length) {      
        for (let i = 0; i < this.items.length; i += 1) {
          items.push(
            this.createFormItem(i, this.items[i].type, this.items[i].params)
          );
        }
      }
      return items;
    },
    createFormItem(index, type, params) {
      switch(type) {
        case "layer":
          return this.createLayerForm(index, params);
        case "source":
          return this.createSourceForm(index, params);
        case "source_select":
        case "listof_sources":
          return this.createSourceSelectPanel(index, params);
        case "coordinate_system":
          return this.createCoordinateSystemForm(index, params);
        case "coordinate_system_select":
        case "listof_coordinate_systems":
          return this.createCoordinateSystemSelectPanel(index, params);
        case "affine_transformation":
          return this.createAffineTransformationForm(index, params);
        case "affine_transformation_select":
        case "listof_affine_transformations":
          return this.createAffineTransformationSelectPanel(index, params);
        default:
          return null;
      }
    },
    createLayerForm(index, params) {
      return this.$createElement(LayerForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createSourceForm(index, params) {
      return this.$createElement(SourceForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createCoordinateSystemForm(index, params) {
      return this.$createElement(CoordinateSystemForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createAffineTransformationForm(index, params) {
      return this.$createElement(AffineTransformationForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createSourceSelectPanel(index, params) {
      return this.$createElement(SourceSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_sources !== 'undefined' && this.items[index].hide_all_sources,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createCoordinateSystemSelectPanel(index, params) {
      return this.$createElement(CoordinateSystemSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all !== 'undefined' && this.items[index].hide_all,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createAffineTransformationSelectPanel(index, params) {
      return this.$createElement(AffineTransformationSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all !== 'undefined' && this.items[index].hide_all,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createFooter() {
      let buttons = [];
      if (this.items.length && (typeof this.items[this.items.length-1].hide_submit == 'undefined' || !this.items[this.items.length-1].hide_submit)) {
        buttons.push(
          this.$createElement(ElButton, {
            props: {
              plain: true,
              type: "primary",
              size: "medium"
            },
            on: {
              click: () => {
                this.submitForm();              
              }
            }
          }, [this.getSubmitButtonText()])
        );
      }
      buttons.push(
        this.$createElement(ElButton, {
          props: {
            plain: true,
            size: "medium"
          },
          on: {
            click: () => {
              this.goBack(this.items.length - 1);
            }
          }
        }, [this.$locale.main.button.cancel]) 
      );
      return this.$createElement(ElFooter, {
        props: {},
        class: {
          "layer-form-footer": true
        }
      }, buttons);
    },
    getSubmitButtonText() {
      console.log(this.items[this.items.length-1], this.items[this.items.length-1].action);
      return this.items[this.items.length-1].action;
    },
    async submitForm() {
      let childForm = this.$refs['item' + (this.items.length - 1)];
      await childForm.submit(() => {
        this.goBack(this.items.length - 1);
      });      
    },
    goBack(index) {
      if (index == 0) {
        this.getEventBus().$emit('hideLayerForm');
      } else {
        this.items = this.items.slice(0, index);
      }
    }, 
    createFormEvent(type, params) {
      let formTemplate = this.itemTemplates[type]; 
      formTemplate.params = params;
      console.log(type, formTemplate, "!!!!!");
      this.items.push(formTemplate);
    }
  },
  render (createElement) {
    return createElement("div", {
      class: {
        "layer-form-set": true
      },
      style: {
        "width": "100%"
      }
    }, [this.createHeader(), ...this.createForms(), this.createFooter()]);
  },
  mounted() {
    if (this.defaultItems && this.defaultItems.length) {
      this.items = this.defaultItems.slice();
    }
    this.getEventBus().$on('createForm', this.createFormEvent); 
  }
}

</script>