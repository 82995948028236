import QueryInterface from './QueryInterface'

export default abstract class AbstractByParentGuidEntitiesQuery implements QueryInterface
{
    private parentGuid: string;

    constructor(parentGuid: string)
    {
        this.parentGuid = parentGuid;
    }

    getParentGuid(): string
    {
        return this.parentGuid;
    }

    abstract getClassName(): string;
}