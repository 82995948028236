<template>
  <selectTree
    class="extraLine_select"
    :disabled="isEdit"
    :clearable="false"
    :standartTree="true"
    ref="entity_tree"
    :selectAlias="selectAlias"
    :dataTable="treeUsers"
    :value="field"
    @input="field = $event"/>
</template>

<script>
import selectTree from '@/components/LogicEditor/components/treeSelect'
export default {
  components: { selectTree },
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      field: null,
      nameUser: null,
      treeUsers: []
    }
  },
  methods: {
    getValue () {
      return this.nameUser.id
    },
    selectAlias (data) {
      this.nameUser = data
    }
  },
  created () {
    if (this.state) {
      this.field = this.state.users_field_id ? this.state.users_field_id : null
    }
    this.$http.get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true&show_children=true`)
      .then(response => { this.treeUsers = response.data.data })
      .catch(error => console.log(error))
  }
}
</script>

<style scoped>
</style>
