import AbstractByJoinTableQuery from './AbstractByJoinTableQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'

export default abstract class AbstractByJoinTableQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractByJoinTableQuery): Promise<any>
    {
        return this.repo.getByJoinTable(query.getParentGuid());
    }
}