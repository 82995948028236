<template>
  <div :style="css" :class="{'hover_effect': clickTable}">
    <span :class="{'notExtended': !extended}">{{ localValue }}</span>
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  name: 'xref_multi_field',
  mixins: [clickTableMixin],
  props: ['value', 'extended', 'css'],
  computed: {
    localValue () {
      let value = this.value
      if (typeof value === 'string') {
        try {
          value = JSON.parse(value)
        } catch (e) {
          value = null
        }
      }
      if (value) {
        return value.map((item) => item.name).join(' ,')
      }

      return null
    }
  }
}
</script>

<style scoped>
  .notExtended {
    white-space: pre;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
