<template>
  <el-date-picker
    size="medium"
    v-model="value"
    :disabled="isEdit"
    v-on:input="$emit('input', $event)"
    :picker-options="{
      firstDayOfWeek: 1
    }"
    type="date"
    :placeholder="$locale.object_editor.entity_types.date_field"
    value-format="yyyy-MM-dd"
    format="dd.MM.yyyy"
  ></el-date-picker>
</template>

<script>
export default {
  name: 'dateField',
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      value: ''
    }
  },
  mounted () {
    if (this.state) {
      this.value =
        this.state.properties && this.state.properties.value
          ? this.state.properties.value
          : null
    }
  }
}
</script>

<style scoped>
</style>
