<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.select_dashbord">
    <el-select v-model="localProperties.value" filterable>
      <el-option
        v-for="item in dashboards"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'dashbord',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        // console.log(val)
        this.$emit('properties', val)
      },
      deep: true
    },
    properties: {
      handler: function (val) {
        this.localProperties = JSON.parse(val) || {}
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: JSON.parse(this.properties) || {},
      dashboards: []
    }
  },
  async mounted () {
    let data = await this.$http.get(`${this.$config.api}/interfaceeditor/dashboards?fields=id,name`)
    this.dashboards = data.data
  }
}
</script>

<style scoped>
</style>
