<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.interface_editor.registry_headers.title" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="editor_registry_header">
      <el-row style="    margin-top: -20px;">
        <el-col :span="12">
          <h2>{{ $locale.interface_editor.registry_headers.available_columns }}</h2>
          <el-scrollbar style="height: 500px; overflow-x: hidden">
            <el-tree
              ref="tree"
              node-key="id"
              :data="registryColumns"
              :props="treeProps"
            >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>(id: {{data.id}})</b></span>
              <span>
                <el-button
                  :disabled="selectedColumnIds.includes(data.id)"
                  @click="addColumn(JSON.parse(JSON.stringify(data)))"
                  type="text"
                  size="small"
                >
                  {{ $locale.main.button.add }}
                </el-button>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </el-col>
        <el-col :span="12">
          <h2>{{ $locale.interface_editor.registry_headers.visible_columns }}
            <el-button style="float: right" @click="addCustomColumn()">{{ $locale.main.button.add }}</el-button>
          </h2>
          <el-scrollbar style="height: 500px; overflow-x: hidden">
            <el-tree
              ref="tree"
              node-key="id"
              :data="localModel"
              :props="treeProps"
              draggable
              :allow-drop="allowDrop"
            >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>(id: {{data.id}})</b></span>
              <span>
                <el-button
                  type="text"
                  size="small"
                  @click="deleteColumn(JSON.parse(JSON.stringify(data)))"
                >
                  {{ $locale.main.button.delete }}
                </el-button>
                <el-button
                        icon="el-icon-plus"
                        @click="addCustomColumn(data.id)"
                />
                <el-popover
                  placement="right"
                  width="500"
                  trigger="click"
                  v-model="visiblePopover[data.id]"
                  @show="showPopover(data.id)">
                  <el-row>
                  <el-scrollbar wrap-style="overflow-x:hidden;" style="height: 700px;">
                    <el-form size="mini" label-width="150px">
                      <el-form-item :label="$locale.main.fields.name">
                    <el-input placeholder="title" v-model.lazy="data.text"></el-input>
                      </el-form-item>
                      <el-form-item v-if="data.isCustom" :label="$locale.interface_editor.component_editor.registry_headers.attr">
                        <el-input v-model="data.value"></el-input>
                      </el-form-item>
                      <el-form-item v-if="data.isCustom" :label="$locale.interface_editor.component_editor.registry_headers.type">
                        <el-select
                          style="width: 100%"
                          v-model="data.type"
                        >
                          <el-option
                            v-for="(item, index) in columnTypes"
                            :key="index"
                            :label="item.name"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.editor">
                  <el-checkbox v-model="data.isEdit"></el-checkbox>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.fix">
                  <el-checkbox
                    v-model="data.fixed"
                  ></el-checkbox>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.stretch">
                  <el-checkbox
                    v-model="data.extended"
                  ></el-checkbox>
                      </el-form-item>
                      <el-form-item v-if="(data.children || []).length > 0" :label="$locale.interface_editor.component_editor.registry_headers.fast_edit">
                  <el-checkbox
                          v-model="data.fastEdit"
                  ></el-checkbox>
                      </el-form-item>
                        <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.parent_horizon">
                  <el-select
                    style="width: 100%"
                    v-model="data.horizontalParent"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in columns.filter(item => item.parentId === data.parentId)"
                      :key="index"
                      :label="item.text"
                      :value="item">
                    </el-option>
                  </el-select>
                        </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.hide_if">
                        <el-input v-model="data.hidden_condition"></el-input>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.under_label">
                        <el-input v-model="data.underLabel"></el-input>
                      </el-form-item>
                    <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.style">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.css">
                      </el-input>
                    </el-form-item>
                     <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.fixed_number" v-if="data.type === 'floatField'">
                      <el-input
                        type="number"
                        v-model="data.fixedNumber">
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="data.type==='htmlField'" :label="$locale.interface_editor.component_editor.registry_headers.html_template">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.htmlTemplate">
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.css_header">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.cssHeader">
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.click_column">
                      <editor-select
                        style="width: 100%"
                        :value="data.clickType"
                        @change="$set(data, 'clickType', $event)"
                        :options="{
                          multiple: false,
                          options: [
                            { id: 'none', name: $locale.interface_editor.component_editor.registry_headers.none },
                            { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
                            { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
                          ]
                        }"
                      ></editor-select>
                    </el-form-item>
                      <editor-open-card
                      v-if="data.clickType === 'open_card' && otherProperties.list.type !== 'requests'"
                      :isTreeSelect="true"
                      :value="data"
                      :isButton="false"
                      :listExtendObject="listExtendObject"
                      :xrefObjectId="xrefObjectId"></editor-open-card>
                      <div v-if="otherProperties.list.type === 'requests' && data.clickType === 'open_card'">
                        <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.registry_id">
                          <el-input type="text" size="small" v-model="data.requestRegistry"></el-input>
                        </el-form-item>
                        <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.card_id">
                          <el-input type="text" size="small" v-model="data.requestCard"></el-input>
                        </el-form-item>
                        <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.field_id">
                          <el-input type="text" size="small" v-model="data.requestRecord"></el-input>
                        </el-form-item>
                      </div>
                  </el-form>
                  </el-scrollbar>
                </el-row>
                  <el-button icon="el-icon-s-tools" slot="reference"></el-button>
                </el-popover>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import EditorSelect from './editor-select'
import EditorOpenCard from './editor-open-card'
export default {
  props: ['other-properties', 'value', 'options'],
  name: 'editor-registry-headers',
  components: {
    EditorSelect,
    EditorOpenCard
  },
  data () {
    return {
      dialogSettingsVisible: false,
      visiblePopover: {},
      treeProps: {
        children: 'children',
        label: 'text'
      },
      localModel: this.value || [],
      registryColumns: [],
      xrefObjectId: null,
      listExtendObject: null,
      columns: [],
      columnTypes: [
        { value: 'booleanField', name: 'Логическое' },
        { value: 'dateField', name: 'Дата' },
        { value: 'datetimeField', name: 'Дата+Время' },
        { value: 'floatField', name: 'Дробное' },
        { value: 'integerField', name: 'Число' },
        { value: 'stringField', name: 'Строка' },
        { value: 'fileField', name: 'Файл' },
        { value: 'textField', name: 'Текст' },
        { value: 'timeField', name: 'Время' },
        { value: 'indicatorField', name: 'Индикатор' },
        { value: 'progressField', name: 'Прогресс' },
        { value: 'monthField', name: 'Месяц' },
        { value: 'htmlField', name: 'HTML' }
      ]
    }
  },
  computed: {
    selectedColumnIds () {
      return this.columns.map(item => item.id)
    }
  },
  async mounted () {
    if (!this.otherProperties.list) {
      this.$set(this.otherProperties, 'list', { type: '' })
    }
    if (this.otherProperties && this.otherProperties.list && this.otherProperties.list.extendObjectId) {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${this.otherProperties.list.extendObjectId}`)
      this.listExtendObject = response.data.extended_object_fields
    }
    this.parseTree(this.localModel)
    let xrefObjectId = null
    if ((this.options || {}).objectId) {
      xrefObjectId = this.options.objectId
    } else {
      if (!(this.otherProperties || {}).registry_properties) {
        return false
      }
      let xref = this.otherProperties.registry_properties.find((item) => item.id === 'xref')
      if (!xref || !xref.value) {
        return false
      }
      let settings = await this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
      xrefObjectId = settings.data.data.object_id
    }
    if (!xrefObjectId) {
      return false
    }
    let fields = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${xrefObjectId}&show_children`)
    fields = fields.data.data
    this.xrefObjectId = xrefObjectId
    this.registryColumns = this.buildOptions(fields)
  },
  methods: {
    showPopover (id) {
      for (let key in this.visiblePopover) {
        this.visiblePopover[key] = false
      }
      this.visiblePopover[id] = true
    },
    addCustomColumn (parentId = null) {
      this.addColumn({
        id: 'c_' + parseInt(Math.max(...(
          this.columns.filter(item => item.isCustom).map(item => parseInt(item.id.match(/\d+/g)[0])).length > 0
            ? this.columns.filter(item => item.isCustom).map(item => parseInt(item.id.match(/\d+/g)[0])) : [0]
        )) + 1),
        parentId: parentId,
        isCustom: true,
        text: 'Новый столбец',
        type: 'stringField',
        value: null,
        underLabel: null
      })
    },
    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    deleteColumn (column, buildTree = true) {
      if (column.children && column.children.length > 0) {
        column.children.forEach((item) => this.deleteColumn(item, false))
      }

      this.columns = this.columns.filter(item => item.id !== column.id)

      if (buildTree) {
        this.localModel = this.buildTree(JSON.parse(JSON.stringify(this.columns)))
      }
      this.onChange()
    },
    addColumn (column, buildTree = true) {
      if (column.children && column.children.length > 0) {
        column.children.forEach((item) => this.addColumn(item, false))
      }
      column.children = []
      if (!this.columns.find(item => item.id === column.id)) {
        this.columns.push(column)
      }

      if (buildTree) {
        this.localModel = this.buildTree(JSON.parse(JSON.stringify(this.columns)))
      }
      this.onChange()
    },
    buildTree (data) {
      let build = function (node) {
        let toDelete = []
        data.forEach((item, index, array) => {
          if (node.id === item.parentId && item.id !== node.id) {
            node.children = (node.children || []).concat(item)
            toDelete.push(item.id)
          }
        })
        data = data.filter(item => !toDelete.includes(item.id))
        if (node.children && node.children.length > 0) {
          node.children.forEach((item) => build(item))
        }
      }

      data.forEach((item) => build(item))
      return data
    },
    parseTree (data) {
      let me = this
      data.forEach((item) => {
        let a = JSON.parse(JSON.stringify(item))

        if (item.children && item.children.length > 0) {
          me.parseTree(item.children)
        }
        a.children = []
        me.columns.push(Object.assign({}, a))
      })
    },
    buildOptions (options, parentId = null) {
      let me = this
      return options.map((column) => {
        let obj = {
          id: column.id,
          text: column.name,
          underLabel: column.underLabel || null,
          type: me.toCamelCase(column.entity_type_id),
          value: `attr_${column.id}_`,
          parentId: parentId
        }
        if (column.children.length > 0) {
          obj.children = me.buildOptions(column.children, column.id)
        }

        return obj
      })
    },
    onChange () {
      this.$emit('change', this.localModel)
    },
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
  }
}
</script>

<style>
.editor_registry_header > .el-dialog {
  width: 60%;
}
.htmlTemplate textarea {
    color: rgb(30, 51, 175);
    font-family: 'Courier New';
    font-size: 14px;
    word-break: break-word;
}
@media screen and (max-width: 1400px) {
.editor_registry_header > .el-dialog{
    width: 90%;
  }
}
</style>
