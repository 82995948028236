import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import XmlRouteCommandRepositoryInterface from '@/services/Databus/domain/repository/XmlRouteCommandRepositoryInterface'
import XmlRouteUpdateCommand from '@/services/Databus/application/command/XmlRouteUpdateCommand'
import { XmlRouteDTO } from '@/services/Databus/domain/model/XmlRoute/XmlRoute'

export default class XmlRouteUpdateHandler implements CommandHandlerInterface
{
    private repo: XmlRouteCommandRepositoryInterface;

    constructor(repo: XmlRouteCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: XmlRouteUpdateCommand): Promise<void>
    {
        let item: XmlRouteDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            topic: command.getTopic(),
            task_id: command.getTaskId(),
            dag_id: command.getDagId(),        
            action_type: command.getActionType()
        };
        return this.repo.update(item);
    }
}