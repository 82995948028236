<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item :label="$locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.interactive">
      <editor-select :value="value.type" @change="$set(value ,'type', $event)"
                     :options="{multiple: false, options: [
                       {id: 'change_field', name:'Изменить поле'}
                     ]}"></editor-select>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
export default {
  name: 'editor-tasks-change-interactive',
  components: {
    EditorSelect
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          type: null,
          properties: {}
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
