<template>
    <el-form label-width="120px" label-position="top" size="mini">
        <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
            <editor-select :value="value.type" @change="$set(value ,'type', $event)"
                           :options="{multiple: false, options: $locale.interface_editor.component_editor.editor_tasks_source.data_types}"></editor-select>
        </el-form-item>
        <el-form-item v-if="value.type === 'registry'" :label="$locale.bi_editor.form.message.object_id.required">
            <editor-registry-select :value="value.entityId" @change="$set(value ,'entityId', $event)"></editor-registry-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.group_list"
                      v-if="value.type !== null">
            <el-input v-model="value.group_list_field"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.additional_fields"
                      v-if="value.type !== null">
            <el-button @click="addField">
                {{$locale.interface_editor.component_editor.filters.add_filter}}
            </el-button>
            <el-row v-for="(field, index) in value.additionalFields" :key="index">
                <el-col :span="10">
                    <el-input v-model="field.attribute"></el-input>
                </el-col>
                <el-col :span="10">
                    <el-select v-model="field.type">
                        <el-option
                                v-for="(item, index) in $locale.interface_editor.component_editor.editor_tasks_source.additional_fields_operations"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button @click="removeField(index)"
                               size="mini"
                               type="danger"
                               :title="$locale.main.button.delete">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </el-col>
            </el-row>
        </el-form-item>
    </el-form>
</template>

<script>
import EditorSelect from './editor-select'
import EditorRegistrySelect from './editor-registry-select'

export default {
  components: {
    EditorSelect,
    EditorRegistrySelect
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          type: null,
          group_list_field: null,
          entityId: null,
          additionalFields: []
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler () {
        this.value.entityId = null
        this.value.group_list_field = null
        this.value.additionalFields = []
      }
    }
  },
  methods: {
    addField () {
      if (typeof this.value.additionalFields === 'undefined') {
        this.$set(this.value, 'additionalFields', [])
      }
      this.value.additionalFields.push({
        attribute: null,
        type: null
      })
    },
    removeField (index) {
      this.value.additionalFields.splice(index, 1)
    }
  },
  name: 'editor-tasks-source',
  data () {
    return {}
  }
}
</script>

<style scoped>

</style>
