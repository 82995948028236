<template>
    <div style="min-width: 900px">
        <el-container>
            <el-main>
                <el-row>
                    <el-col :span="8">
                        <h4>{{ $locale.registry.filter.stored }}</h4>
                        <el-divider></el-divider>
                        <ul class="stored">
                            <el-link @click="loadStoredFilter(item.properties)" :underline="false" type="primary"
                                     v-for="(item, index) in stored" :key="index">
                                {{ item.name }}
                              <el-button size="small" @click.stop="removeStoredFilter(item.id)"
                                      style="border: none; margin-left: auto;"
                                      icon="el-icon-close"
                                      circle>
                              </el-button>
                            </el-link>
                        </ul>
                    </el-col>
                    <el-col :span="16" style="padding: 15px;">
                        <el-row>
                            <el-col :span="16">
                                <el-select
                                        size="small"
                                        v-model="active_fields"
                                        multiple
                                        filterable
                                        @remove-tag="deleteFilter"
                                        collapse-tags
                                        style="width: 100%"
                                        :placeholder="$locale.registry.filter.choose_fields">
                                    <template slot="empty">
                                        no data
                                    </template>
                                    <el-option
                                            v-for="(field, index) in available_fields"
                                            :key="index"
                                            :label="field.name"
                                            :value="field.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="8" style="text-align: right">

                            </el-col>
                        </el-row>
                        <el-row v-for="(item, index) in active_fields_types" :key="index">
                            <div style="padding: 6px;">{{ item.name }}</div>
                            <el-col :span="22">
                                <component v-model="values[item.id]"
                                            :key="item.id"
                                           :attributeId="item.id"
                                           :is="toCamelCase(item.entity_type_id)"></component>
                            </el-col>
                            <el-col :span="2">
                                <el-button size="small" @click="deleteFilter(item.id)" style="border: none;"
                                           icon="el-icon-close"
                                           circle></el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="12">
                        <el-button v-if="!input_filter_name.show" :loading="input_filter_name.loading" :disabled="!active_fields.length" @click="$set(input_filter_name, 'show', true)" type="text">{{ $locale.registry.filter.save_button }}</el-button>
                        <div v-else style="display: inline-block;">
                            <el-input size="small" :placeholder="$locale.registry.filter.input_search" v-model="input_filter_name.value">
                                <template slot="append">
                                    <el-button size="small" icon="el-icon-edit" @click="saveStoredFilter"></el-button>
                                    <el-button size="small" icon="el-icon-close" @click="$set(input_filter_name, 'value', '');$set(input_filter_name, 'show', false)"></el-button></template>
                            </el-input>

                        </div>
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        <el-button type="danger" plain size="small" @click="active_fields = []">
                            {{ $locale.registry.filter.clear_button }} <i class="el-icon-close el-icon--right"></i>
                        </el-button>
                        <el-button type="primary" plain size="small" @click="$emit('search', getValues())">
                            {{ $locale.registry.filter.search_button }} <i class="el-icon-search el-icon--right"></i>
                        </el-button>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import stringField from './Fields/string_field'
import integerField from './Fields/integer_field'
import textField from './Fields/text_field'
import booleanField from './Fields/boolean_field'
import dateField from './Fields/date_field'
import timeField from './Fields/time_field'
import xrefField from './Fields/xref_field'
import xrefMultiField from './Fields/xref_multi_field'

import Registry from '../Models/Registry'
import StoredFilter from '../Models/StoredFilter'

export default {
  components: {
    stringField,
    integerField,
    textField,
    booleanField,
    dateField,
    timeField,
    xrefField,
    xrefMultiField
  },
  props: ['available_fields', 'registry_id'],
  data () {
    return {
      loading: false,
      active_fields: [],
      values: {},
      stored: [],
      input_filter_name: {
        value: null,
        show: false,
        loading: false
      }
    }
  },
  watch: {
    active_fields: {
      handler: function (newVal, old) {
        if (old.length > newVal.length) {
          let delItem = old.filter(item => !newVal.includes(item))
          this.deleteFilter(delItem)
        }
      }
    }
  },
  computed: {
    active_fields_types () {
      return this.available_fields.filter((item) => {
        return this.active_fields.includes(item.id)
      })
    }
  },
  mounted () {
    console.log('filter mounted event')
    this.loading = true
    this.renderStoredFilters()
    this.loading = false
  },
  methods: {
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    getValues () {
      let me = this
      return this.active_fields_types.map((field) => {
        if (me.values[field.id]) {
          return `attr_${field.id}_,${me.values[field.id]}`
        }
      }).filter((field) => {
        return !!field
      })
    },
    deleteFilter (index) {
      this.active_fields = this.active_fields.filter((item) => {
        return item !== index
      })
      this.$set(this.values, index, null)
    },
    async renderStoredFilters () {
      if (!this.registry_id) {
        return false
      }
      this.loading = true
      this.stored = []
      let registry = new Registry({ id: this.registry_id })

      this.stored = await registry.filters().get()

      this.loading = false
    },
    loadStoredFilter (properties) {
      this.active_fields = []
      let me = this
      let regExp = /\d+/ig
      properties.forEach((item) => {
        let field = item.split(',')
        let name = field.shift()
        name = name.match(regExp)
        if (!name) {
          return false
        }
        name = name[0]
        me.active_fields.push(parseInt(name))
        me.$set(me.values, parseInt(name), field.join(','))
      })
    },
    async saveStoredFilter () {
      if (!this.getValues().length || !(this.input_filter_name.value || '').trim().length || !this.registry_id) {
        return false
      }
      this.input_filter_name.show = false
      this.input_filter_name.loading = true
      let registry = new Registry({ id: this.registry_id })
      let filter = new StoredFilter({ name: this.input_filter_name.value, properties: this.getValues() }).for(registry)
      let me = this
      await filter.save()
        .catch((error) => {
          console.log(error)
        })
        .then((response) => {
          me.stored.push(response)
        })
      this.input_filter_name.value = null
      this.input_filter_name.loading = false
    },
    removeStoredFilter (filterId) {
      // this.$confirm(
      //   this.$locale.main.message.confirm,
      //   this.$locale.main.message.attention, {
      //     confirmButtonText: this.$locale.main.button.delete,
      //     cancelButtonText: this.$locale.main.button.cancel,
      //     type: 'warning'
      //   })
      // .then(async () => {
      let registry = new Registry({ id: this.registry_id })
      this.$http.delete(`${this.$config.api}/registryservice/registry/${registry.id}/filters/${filterId}`)
      // let filter = new StoredFilter({ id: registry }).for(filterId)
      // await filter.delete()
      this.stored.forEach((el, ind) => {
        if (el.id === filterId) this.stored.splice(ind, 1)
      })
      this.active_fields = []
      this.input_filter_name.value = null
      // })
    }
  }
}
</script>

<style>
    .stored {
        padding: 0px;
    }

    .stored a {
        height: 40px;
        display: block;
    }
    .stored a .el-link--inner {
      display: flex;
      align-items: center
    }
</style>
