import { Nullable } from '@/core/domain/type/types'

export default class SmevSettingCreateCommand
{
    private name: string;
    private value: Nullable<string>;

    constructor(
        name: string,
        value: Nullable<string>
    ) {
        this.name = name;
        this.value = value;
    }

    getClassName(): string
    {
        return 'SmevSettingCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getValue(): string
    {
        return this.value;
    }
}