<template>
  <div class="external_table_field">
    <registry-select-tree v-model="value.registry_id" type="registry" value-as="number" @change="changeRegistry" />
    <select-tree :clearable="false" :standartTree="false" :dataTable="fields" v-model="value.field_id" />
  </div>
</template>

<script>
import SelectTree from '@/components/LogicEditor/components/treeSelect'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import Registry from '@/components/LogicEditor/Models/Registry'

export default {
  name: 'ExternalTableField',

  props: {
    value: {
      type: Object,
      default () {
        return {
          registry_id: null,
          field_id: null
        }
      }
    }
  },

  components: {
    RegistrySelectTree,
    SelectTree
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      fields: []
    }
  },

  async mounted () {
    if (this.value.registry_id) {
      this.fields = await this.getRegistryFields()
    }
  },

  methods: {
    async getRegistryFields () {
      let registryTree = await Registry.params({ parent_id: this.value.registry_id, show_children: true }).get()
      registryTree.data.unshift({
        id: 'id',
        name: 'id',
        entity_type_id: 'id записи'
      })
      return registryTree.data
    },

    async changeRegistry (value, lastValue) {
      if (value !== lastValue) {
        this.fields = await this.getRegistryFields()
      }
    }
  }
}
</script>

<style scoped>

</style>
