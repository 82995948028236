import { ActionTree } from 'vuex'
import { ProjectTreeElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectTreeElementCreatedEvent, ProjectTreeElementLoadingEvent, ProjectTreeElementDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectTreeElementAPI } from '@/services/MapEditor/infrastructure/api/ProjectTreeElementAPI'

export const actions: ActionTree<ProjectTreeElementState, any> = {
    [ActionTypes.ADD_PROJECT_TREE_PROJECT_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new ProjectTreeElementAPI.AddProjectTreeProjectElement(data.payload));
            commit(new ProjectTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.ADD_PROJECT_TREE_GROUP_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new ProjectTreeElementAPI.AddProjectTreeGroupElement(data.payload));
            commit(new ProjectTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_PROJECT_TREE_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ProjectTreeElementAPI.DeleteProjectTreeElement(data.guid));
            commit(new ProjectTreeElementDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_PROJECT_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectTreeElementAPI.GetProjectTreeElementByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectTreeElementAPI.GetProjectTreeElements(data.parentId));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
