import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import EventQueryRepositoryInterface from '@/services/Databus/domain/repository/EventQueryRepositoryInterface'
import store from '@/store'
import { GetEvents, CountEvents, GetEventByGuid } from '@/services/Databus/infrastructure/store/modules/DatabusEvent/action-types'

export default class EventQueryRepository extends AbstractQueryRepository implements EventQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetEvents(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountEvents(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetEventByGuid(guid));
    }
}