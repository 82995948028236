<template>
  <!--<el-select
    value-key="name"
    class="extraLine_select"
    v-model="value"
    :disabled="isEdit"
    v-on:input="$emit('input', $event)"
    :placeholder="this.$locale.logic_editor.state.boolean_field"
    size="medium"
  >
    <el-option v-for="item in listField" :key="item.value" :label="item.name" :value="item.value"></el-option>
  </el-select>-->
  <el-checkbox
    v-model="value"
    :disabled="isEdit"
    v-on:input="$emit('input', $event)"
    :label="$locale.logic_editor.state.boolean_field"
  ></el-checkbox>
</template>

<script>
export default {
  name: 'booleanField',
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      value: null
    }
  },
  mounted () {
    if (this.state) {
      this.value =
        this.state.properties && this.state.properties.value
          ? JSON.parse(this.state.properties.value)
          : null
    }
  }
}
</script>

<style scoped>
</style>
