<template>
  <div class="user-panel">
    <el-dialog
      :visible.sync="isCreateUserWindowVisible"
      width="30%"
      ref="createUserWindow"
      class="create-user-window"
      @close="closeCreateUserWindow"
      :close-on-click-modal="false">
      <span>
        <template v-if="userDto">
          <el-form :rules="createUserRules" :model="userDto" :disabled="isUserLoading" size="mini" label-width="100px" label-position="right">
            <el-row>
              <el-col :span="9" v-if="!userDto.is_system" class="avatar">
                <fieldset>
                  <legend>{{$locale.access_editor.users_item.avatar}}<span ref="avatar_trigger"></span></legend>
                  <el-upload
                    class="users upload-demo"
                    ref="upload"
                    action=""
                    list-type="picture-card"
                    :on-change="changeAvatar"
                    :on-remove="removeAvatar"
                    :file-list="avatar"
                    :auto-upload="false"
                  >
                  <i class="el-icon-plus" :title="$locale.access_editor.users_item.dowload_avatar"></i>
                  </el-upload>
                </fieldset>
              </el-col>
              <el-col :span="!userDto.is_system ? 15 : 24">
                <el-form-item v-if="!userDto.is_system" prop="surname" :label="$locale.access_editor.users_item.surname">
                    <el-input v-model="userDto.surname" autocomplete="off"></el-input>
                  </el-form-item>  
                  <el-form-item prop="name" :label="!userDto.is_system ? $locale.access_editor.users_item.name : $locale.main.fields.name">
                    <el-input v-model="userDto.name" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item v-if="!userDto.is_system" prop="midname" :label="$locale.access_editor.users_item.midname">
                    <el-input v-model="userDto.midname" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item v-if="!userDto.is_system" prop="email" :label="$locale.access_editor.users_item.email">
                    <el-input v-model="userDto.email" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="role_id" :label="$locale.access_editor.users_item.role">
                    <treeselect
                      v-model="userDto.role_id"
                      :placeholder="$locale.main.fields.select"
                      :normalizer="(node) => {return {id: node.id, label: node.name}}"
                      :options="roles"
                      :clear-value-text="$locale.main.message.clear"
                      :loading-text="$locale.main.message.loading"
                      :disable-immediate-search="true"
                      :async="true"
                      :cache-options="false"
                      :append-to-body="false"
                      :load-options="loadRoles"
                      :clearable="false"
                      :delete-removes="false"
                      :backspace-removes="false"
                      @open="loadRoles"
                    ></treeselect>
                  </el-form-item>
              </el-col> 
            </el-row>
            <el-form-item class="checkbox-group">
              <el-checkbox v-if="!userDto.is_system" v-model="userDto.is_admin" :label="$locale.access_editor.users_item.admin" name="is_admin"></el-checkbox>
              <el-checkbox v-model="userDto.is_system" :label="$locale.access_editor.users_item.system" name="is_system"></el-checkbox>
              <el-checkbox v-model="userDto.is_blocked" :label="$locale.access_editor.users_item.block" name="is_blocked"></el-checkbox>
            </el-form-item>
            <el-row v-if="!userDto.is_system">
              <el-col :span="10">
                <el-form-item prop="login" :label="$locale.access_editor.users_item.login" label-width="60px">
                  <el-input v-model="userDto.login" autocomplete="off" style="width:140px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="password" :label="$locale.access_editor.users_item.password" label-width="80px">
                  <el-input v-model="userDto.password" readonly autocomplete="off"  style="width:142px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.change_password" placement="bottom">
                  <el-button icon="el-icon-unlock" class="generate-button"  size="small" @click="passwordLocked = !passwordLocked"></el-button>
                </el-tooltip>
              </el-col>
              <el-col :span="2">
                <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.generate_password" placement="top">
                  <el-button icon="el-icon-refresh" :disabled="passwordLocked" class="generate-button"  size="small" @click="generatePassword"></el-button>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row v-if="userDto.is_system">
              <el-col :span="22">
                <el-form-item v-if="userDto" prop="api_key" :label="$locale.access_editor.users_item.api_key">
                  <el-input v-model="userDto.api_key" readonly type="textarea" autocomplete="off" class="api-key"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" style="padding-left: 4px;">
                <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.generate_api_key" placement="right">
                  <el-button icon="el-icon-refresh" class="generate-button" size="small" @click="generateApiKey"></el-button>
                </el-tooltip>
              </el-col>
            </el-row>
            <fieldset v-if="userDto.is_system">
              <legend>{{$locale.access_editor.users_item.system_ips}}</legend>
              <el-tag
                class="ip-tag"
                :key="ip"
                v-for="ip in userDto.system_ips"
                closable
                :disable-transitions="false"
                @close="handleRemoveIp(ip)">
                {{ip}}
              </el-tag>
              <el-input
                class="input-new-ip"
                v-if="inputIpVisible"
                v-model="inputIpValue"
                ref="saveIpInput"
                size="mini"
                @keyup.enter.native="handleIpInputConfirm"
                @blur="handleIpInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-ip" size="small" @click="showIpInput">+ IP</el-button>
            </fieldset>
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <span v-loading="true" v-if="isUserLoading"></span>
          <el-button icon="el-icon-close" :disabled="isUserLoading" size="small" @click="closeCreateUserWindow">{{$locale.main.button.cancel}}</el-button>
          <el-button icon="el-icon-success" :disabled="userDto == null || isUserLoading" size="small" @click="saveUser" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="userUploadDto != null"
      :visible.sync="isUploadUsersWindowVisible"
      class="upload-users-window"
      @close="isUploadUsersWindowVisible = !isUploadUsersWindowVisible; userUploadDto = null"
      :close-on-click-modal="false"
      width="320px">
      <el-upload
        ref="upload_users"
        :auto-upload="false"
        :multiple="false"
        action="/"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button style="width: 100%" slot="trigger" size="small" type="primary">{{$locale.main.button.choose_file}}</el-button>
        <el-button style="margin-left: 10px;width:50%" size="small" type="success" @click="submitUsers">{{$locale.main.button.upload}}</el-button>
        <el-row style="margin-top: 10px">
          <treeselect
            v-model="userUploadDto.role_id"
            style="width:100%"
            :placeholder="$locale.access_editor.users_item.role"
            :normalizer="(node) => {return {id: node.id, label: node.name}}"
            :options="roles"
            :clear-value-text="$locale.main.message.clear"
            :loading-text="$locale.main.message.loading"
            :disable-immediate-search="true"
            :async="true"
            :cache-options="false"
            :append-to-body="false"
            :load-options="loadRoles"
            :clearable="false"
            :delete-removes="false"
            :backspace-removes="false"
            @open="loadRoles"
          ></treeselect>
        </el-row>
        <el-row  style="margin-top: 10px">
          <el-checkbox v-model="userUploadDto.is_login_dynamic" :label="$locale.access_editor.users_item.is_login_dynamic" name="is_login_dynamic"></el-checkbox>
        </el-row> 
      </el-upload>
    </el-dialog>
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createUser"></el-button>
      <el-button icon="edit-button" size="mini" circle  @click="updateUser"></el-button>
      <el-button icon="delete-button" size="mini" circle  @click="deleteUser"></el-button>
      <span class="accent-splitter">|</span>
      <el-button icon="el-icon-upload2" size="mini" circle  @click="uploadUsers"></el-button>
    </el-container>
    <el-row class="main-table user-table">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="registry custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="users"
          stripe
          border
          ref="users_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeUser"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="surname"
          :label="$locale.access_editor.users_item.surname"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.access_editor.users_item.name"
          width="180"
          align="left"
        ></el-table-column>         
        <el-table-column
          prop="midname"
          :label="$locale.access_editor.users_item.midname"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="email"
          :label="$locale.access_editor.users_item.email"
          width="180"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="is_admin"
          :label="$locale.access_editor.users_item.admin"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_admin ? 'success' : 'primary'"
            >{{scope.row.is_admin ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_system"
          :label="$locale.access_editor.users_item.system"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_system ? 'success' : 'primary'"
            >{{scope.row.is_system ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_blocked"
          :label="$locale.access_editor.users_item.block"
          width="140"
          align="left"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_blocked ? 'success' : 'primary'"
            >{{scope.row.is_blocked ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="user-pagination"
            :page-size="usersPageSize"
            :layout="'total, prev, pager, next'"
            :total="usersCount"
            @current-change="handleUsersPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Treeselect from '@bingosoftnn/vue-treeselect'
import UserCreateCommand from '@/services/AccessEditor/application/command/UserCreateCommand'
import UserUpdateCommand from '@/services/AccessEditor/application/command/UserUpdateCommand'
import UserDeleteCommand from '@/services/AccessEditor/application/command/UserDeleteCommand'
import UsersUploadFromFileCommand from '@/services/AccessEditor/application/command/UsersUploadFromFileCommand'
import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import UsersCountQuery from '@/services/AccessEditor/application/query/UsersCountQuery'
import UserByGuidQuery from '@/services/AccessEditor/application/query/UserByGuidQuery'
import UserPasswordQuery from '@/services/AccessEditor/application/query/UserPasswordQuery'
import UserApiKeyQuery from '@/services/AccessEditor/application/query/UserApiKeyQuery'
import UserAvatarQuery from '@/services/AccessEditor/application/query/UserAvatarQuery'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'
import User, { UserDTO, UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'

export default {
  name: 'UserPanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      usersPageSize: 0,
      defaultUsersPageSize: 100,
      usersCurrentPage: 0,
      usersPageLimit: 100,
      usersCount: 0,
      allUsersCount: 0,
      users: [],
      roles: [],
      user: null,
      userDtoPrev: null,
      userDto: null,
      userUploadDto: null,
      avatar: [],
      informationPanelHeight: 0,
      isCreateUserWindowVisible: false,
      isUploadUsersWindowVisible: false,
      isSystemUser: null,
      passwordLocked: true,
      inputIpVisible: false,
      inputIpValue: '',
      upload_users_role: null,
      upload_users_dynamic_login: false,
      createUserRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        role_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    userCreated: function (location) {
      const guid = location.replace('/users/', '');
      this.loadUsers(() => {
        this.closeCreateUserWindow();
        for (let i = 0; i < this.users.length; i += 1) {
          let el = this.users[i];
          if (el.guid == guid) {
            this.$refs.users_table.setCurrentRow(el);
            break;
          }
        }
      });
    },
    isUserLoading: (state) => state,
    getSaveUserError: function (err) {
      
    },
    isCreateUserWindowVisible: function(flag) {
      this.renderUploadButton();
    },
    userDto: {
      handler: async function(curDto) {
        this.isSystemUser = curDto !== null ? curDto.is_system : null;
      },
      deep: true
    },
    isSystemUser: function (prev, cur) {
      if (prev == false && cur) {
        this.renderUploadButton();
      }
    },
    passwordLocked: function(value) {
      if (value) {
        this.userDto.password = "";
      }
    }
  },
  computed: {
    isUserLoading() {
      return this.$store.getters['User/isLoading'];     
    },
    userCreated() {
      return this.$store.getters['User/getLocation'];
    },
    getSaveUserError() {
      return this.$store.getters['User/getError']; 
    }
  },
  methods: {
    loadUsers(callback) {
      if (this.allUsersCount == 0) {
        this.countAndLoadUsers(callback);
      } else {
        this.loadAllUsers(callback);
      }
    },
    async loadRoles() {
      await this.getQueryBus().execute(
        new RolesQuery()
      ).then(data => {
        this.roles = data;
      });
    },
    async countAndLoadUsers(callback) {
      await this.getQueryBus().execute(
        new UsersCountQuery()
      ).then(data => {
        this.usersCount = data[0].count;      
        this.loadAllUsers(callback);
      });
    },
    async loadAllUsers(callback) {
      await this.getQueryBus().execute(
        new UsersQuery({
          limit: this.usersPageLimit,
          offset: this.usersCurrentPage
        })
      ).then(data => {
        this.usersPageSize = this.usersPageLimit;
        this.users = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createUser() {
      this.removeAvatar();
      this.passwordLocked = true;
      this.userDtoPrev = this.userDto;
      this.userDto = new UserDTO({});    
      this.isCreateUserWindowVisible = true;
    },
    async updateUser() {
      if (this.user !== null) {
        this.isCreateUserWindowVisible = true;
        let avatarId = this.user.getAvatarId();
        if (avatarId != null) {
          this.avatar = [];
          await this.getQueryBus().execute(
            new UserAvatarQuery(avatarId)
          ).then(data => {
            this.avatar.push({
              name: `${data.name}`,
              url: `${this.$config.api}/files/${data.guid}.${data.extension}`
            });
          });
        }
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveUser() {
      let user = User.create(this.userDto);
      if (user.getId() == null) {
        this.getCommandBus().execute(
          new UserCreateCommand(
            user.getRoleId(),
            user.getName(),
            user.getMidname(),
            user.getSurname(),
            user.getAvatar(),
            user.getLogin(),
            user.getPassword(),
            user.getIsAdmin(),
            user.getIsBlocked(),
            user.getIsSystem(),
            user.getApiKey(),
            user.getSystemIps()
          )
        );
      } else {
        this.getCommandBus().execute(
            new UserUpdateCommand(
              user.getGuid(),
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getIsAvatarDeleted(),
              user.getLogin(),
              user.getPassword(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps()
            )
          ).then(async () => {
            await this.getQueryBus().execute(
              new UserByGuidQuery(user.getGuid())
            ).then(data => {
              this.userDtoPrev = new UserDTO(data);
              this.userDto = this.userDtoPrev;
              for (let i = 0; i < this.users.length; i += 1) {
                if (this.users[i].guid == this.userDto.guid) {
                  this.users[i] = this.userDto;
                  break;
                }
              }           
            });
            this.closeCreateUserWindow();
          });
      }
    },
    closeCreateUserWindow() {
      this.isCreateUserWindowVisible = false;
      this.removeAvatar();
      if (this.userDtoPrev !== null) {
        this.userDto = this.userDtoPrev;
        this.user = User.create(this.userDto);
      }
    },
    changeUser(userDto) {
      this.passwordLocked = true;
      if (userDto !== null) {
        userDto.password = "";
        this.user = User.create(userDto);
        this.userDto = userDto;
        this.roles = [{"id": this.userDto.role_id, "name": this.userDto.role_name}];
      }
    },
    deleteUser() {
      if (this.user == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {          
          this.getCommandBus().execute(
            new UserDeleteCommand(
              this.user.getGuid()
            )
          ).then((response) => {
            this.user = null;
            this.$refs.users_table.setCurrentRow(null);
            this.loadUsers();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    handleUsersPageChange(val) {
      val--;
      this.usersCurrentPage = (val * this.usersPageLimit);
      this.loadUsers();
    },
    changeAvatar (file) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.removeAvatar();
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1);        
      }
      this.userDto.avatar = this.$refs.upload.uploadFiles[0].raw;
      this.userDto.is_avatar_deleted = false;
    },
    removeAvatar () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = [];
      }
      if (this.userDto !== null) {
        this.userDto.avatar = null;
        this.userDto.is_avatar_deleted = true;
        this.userDto.password = "";
      }
      this.avatar = [];
    },
    async generateApiKey () {
      if (this.userDto.id == null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_must_be_saved,
          type: 'warning'
        });
        this.userDto.api_key = '';
        return;
      }
      if (this.userDto.role_id == null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_role_must_be_set,
          type: 'warning'
        });
        this.userDto.api_key = '';
        return;
      }
      if (this.userDto.system_ips.length == 0) {
        this.$message({
          message: this.$locale.access_editor.users_item.system_ips_must_be_set,
          type: 'warning'
        });
        this.userDto.api_key = '';
        return;
      }
      await this.getQueryBus().execute(
        new UserApiKeyQuery(
          this.userDto.id,
          this.userDto.role_id,
          this.userDto.system_ips
        )
      ).then(data => {
        this.userDto.api_key = data.api_key;
      });
    },
    async generatePassword () {
      await this.getQueryBus().execute(
        new UserPasswordQuery()
      ).then(data => {
        this.userDto.password = data.password;
      });
    },
    renderUploadButton () {
      this.$nextTick(async () => {
        let trigger = typeof this.$refs.upload !== 'undefined' ? this.$refs.upload.$el.children[1] : undefined;
        if (typeof trigger !== 'undefined') {
          let triggerParent = this.$refs.avatar_trigger;
          if (!triggerParent.children.length) {
            triggerParent.appendChild(trigger);
          }
        }          
      });      
    },
    handleRemoveIp (ip) {
      this.userDto.system_ips.splice(this.userDto.system_ips.indexOf(ip), 1);
    },
    handleIpInputConfirm() {
      let inputIpValue = this.inputIpValue;
      if (inputIpValue) {
        this.userDto.system_ips.push(inputIpValue);
      }
      this.inputIpVisible = false;
      this.inputIpValue = '';
    },
    showIpInput() {
      this.inputIpVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveIpInput.$refs.input.focus();
      });
    },
    uploadUsers () {
      this.userUploadDto = new UserUploadDTO({});
      this.isUploadUsersWindowVisible = !this.isUploadUsersWindowVisible;
    },
    submitUsers () {
      let me = this;
      if (this.$refs.upload_users.uploadFiles.length) {
        this.$refs.upload_users.uploadFiles.forEach((file) => {
          me.getCommandBus().execute(
            new UsersUploadFromFileCommand(
              me.userUploadDto.role_id,
              me.userUploadDto.is_login_dynamic,
              file.raw
            )
          ).then((response) => {
            me.user = null;
            me.$refs.users_table.setCurrentRow(null);
            me.userUploadDto = null;
            me.isUploadUsersWindowVisible = !me.isUploadUsersWindowVisible;
            me.loadUsers();
          });
        });
      }
    }
  },
  mounted () {
    this.loadUsers();
  }
}
</script>