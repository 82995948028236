import CommandInterface from './CommandInterface'

export default abstract class AbstractEntityDeleteCommand implements CommandInterface
{
    private guid: string;

    constructor(guid: string)
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    abstract getClassName(): string;
}