export default class StiHelper {
  constructor (url, timeout) {
    this.url = url
    this.timeout = timeout
  }

  process (args, callback) {
    if (args) {
      if (args.event === 'BeginProcessData') {
        args.preventDefault = true
        if (args.database === 'XML' || args.database === 'JSON' || args.database === 'Excel') return callback(null)
      }
      var command = {}
      for (var p in args) {
        if (p === 'report' && args.report != null) command.report = JSON.parse(args.report.saveToJsonString())
        else if (p === 'settings' && args.settings != null) command.settings = args.settings
        else if (p === 'data') command.data = window.Stimulsoft.System.Convert.toBase64String(args.data)
        else command[p] = args[p]
      }

      var isNullOrEmpty = function (value) {
        return value === null || value === '' || value === undefined
      }
      var json = JSON.stringify(command)
      if (!callback) {
        callback = function (message) {
          if (window.Stimulsoft.System.StiError.errorMessageForm && !isNullOrEmpty(message)) {
            var obj = JSON.parse(message)
            if (!obj.success || !isNullOrEmpty(obj.notice)) {
              var msg = isNullOrEmpty(obj.notice) ? 'There was some error' : obj.notice
              window.Stimulsoft.System.StiError.errorMessageForm.show(msg, obj.success)
            }
          }
        }
      }
      this.send(json, callback)
    }
  }

  send (json, callback) {
    try {
      var request = new XMLHttpRequest()
      request.open('post', this.url, true)
      //request.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate')
      //request.setRequestHeader('Cache-Control', 'max-age=0')
      //request.setRequestHeader('Pragma', 'no-cache')
      request.timeout = this.timeout * 1000
      request.onload = function () {
        if (request.status === 200) {
          var responseText = request.responseText
          request.abort()
          callback(responseText)
        } else {
          window.Stimulsoft.System.StiError.showError('[' + request.status + '] ' + request.statusText, false)
        }
      }
      request.onerror = function (e) {
        var errorMessage = 'Connect to remote error: [' + request.status + '] ' + request.statusText
        window.Stimulsoft.System.StiError.showError(errorMessage, false)
      }
      request.send(json)
    } catch (e) {
      var errorMessage = 'Connect to remote error: ' + e.message
      window.Stimulsoft.System.StiError.showError(errorMessage, false)
      request.abort()
    }
  }

  getUrlVars (json, callback) {
    var vars = {}
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    })
    return vars
  }
}
