<template>
  <div class="rule_form">
    <el-form ref="ruleForm" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.task_editor.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.task_editor.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.task_editor.labels.registry" prop="object_id">
        <registry-select-tree
          style="width: 100%;"
          :disabled="!!value.id"
          type="registry"
          value-as="number"
          v-model="model.object_id"
          :placeholder="$locale.task_editor.labels.registry"
          @change="objectChange" />
      </el-form-item>

      <el-form-item :label="$locale.task_editor.labels.state_match_type">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="rule_states">
              <el-select v-model="model.rule_states" clearable multiple :placeholder="$locale.task_editor.labels.states">
                <el-option
                  v-for="item in targetStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-select v-model="model.state_match_type"  :placeholder="$locale.task_editor.labels.match_type">
                <el-option
                  v-for="item in matchTypeList"
                  :key="item.id"
                  :label="$locale.task_editor.match_types[item.name]"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-button style="float: right;" @click="addRuleField()" type="success" circle icon="el-icon-plus" size="mini"></el-button>
      <el-form-item class="multi_form" :label="$locale.task_editor.labels.rule_fields">
        <rule-field-form ref="ruleFieldForm" v-for="(item, index) in model.rule_fields" v-model="model.rule_fields[index]" :key="item.id" :object-id="model.object_id">
          <el-button @click="removeRuleField(index)" type="danger" circle icon="el-icon-delete" size="mini"></el-button>
        </rule-field-form>
      </el-form-item>

      <el-form-item :label="$locale.task_editor.labels.related_record_field" prop="related_record_field_id">
        <!-- el-tree-select v-model="model.related_record_field_id" -->
        <registry-select-tree
          style="width: 100%;"
          type="field"
          value-as="number"
          v-model="model.related_record_field_id"
          :parent-id="model.object_id"
          :placeholder="$locale.task_editor.labels.related_record_field" />
      </el-form-item>

      <el-form-item prop="is_divided_by_executors">
        <el-checkbox v-model="model.is_divided_by_executors" >{{ $locale.task_editor.labels.divided_by_executors }}</el-checkbox>
      </el-form-item>

      <el-form-item :label="$locale.task_editor.labels.close_condition">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="rule_target_registry_states">
              <el-select v-model="model.rule_target_registry_states" clearable multiple :placeholder="$locale.task_editor.labels.target_registry_state">
                <el-option
                  v-for="item in targetStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-select v-model="model.target_registry_state_match_type" :placeholder="$locale.task_editor.labels.match_type">
                <el-option
                  v-for="item in matchTypeList"
                  :key="item.id"
                  :label="$locale.task_editor.match_types[item.name]"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="rule_task_registry_states">
              <el-select v-model="model.rule_task_registry_states" clearable multiple :placeholder="$locale.task_editor.labels.task_registry_state">
                <el-option
                  v-for="item in taskStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="12">
           <el-form-item>
             <el-select v-model="model.task_registry_state_match_type" :placeholder="$locale.task_editor.labels.match_type">
               <el-option
                 v-for="item in matchTypeList"
                 :key="item.id"
                 :label="$locale.task_editor.match_types[item.name]"
                 :value="item.id">
               </el-option>
             </el-select>
           </el-form-item>
         </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$locale.task_editor.labels.close_match_type">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="rule_close_commands">
              <command-select-tree v-model="model.rule_close_commands" :multiple="true"></command-select-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-select v-model="model.close_match_type" :placeholder="$locale.task_editor.labels.match_type">
                <el-option
                  v-for="item in matchTypeList"
                  :key="item.id"
                  :label="$locale.task_editor.match_types[item.name]"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RuleFieldForm from '@/services/TaskEditor/infrastructure/components/forms/RuleFieldForm/index'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import CommandSelectTree from '@/components/Common/CommandSelectTree'
export default {
  name: 'RuleForm',

  components: {
    RuleFieldForm,
    RegistrySelectTree,
    CommandSelectTree
  },

  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {
          name: null,
          object_id: null,
          related_record_field_id: null,
          is_divided_by_executors: false,
          state_match_type: 'all',
          rule_states: [],
          close_match_type: 'all',
          rule_close_commands: [],
          target_registry_state_match_type: 'all',
          rule_target_registry_states: [],
          task_registry_state_match_type: 'all',
          rule_task_registry_states: [],
          rule_fields: [],
          parent_tree_element_id: null
        }
      }
    }
  },

  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input field "Name"', trigger: 'blur' }
        ],
        object_id: [
          { required: true, message: 'Please select "Target object"', trigger: 'change' }
        ],
        related_record_field_id: [
          { required: true, message: 'Please select "Related record field"', trigger: 'change' }
        ]
      },

      model: this.value,

      matchTypeList: [
        {
          id: 'all',
          name: 'all'
        },
        {
          id: 'any',
          name: 'any'
        }
      ],

      targetStateList: [],
      taskStateList: []
    }
  },

  computed: {
  },

  watch: {
    value () {
      this.model = this.value
    }
  },

  mounted () {
    if (this.model.object_id) {
      this.targetStateList = this.getStatesByObjectId(this.model.object_id)
    }

    this.$http
      .get(`${this.$config.api}/logiceditor/states/task_registry`)
      .then((response) => {
        this.taskStateList.push(...response.data)
      })
  },

  methods: {
    checkNode (node, checked) {
    },

    addRuleField () {
      this.model.rule_fields.push({
        field_id: null,
        rule_field_type_id: null,
        condition_field_type_id: null,
        users_field_id: null,
        current_table_field_id: null,
        is_parameter: false,
        properties: {
          name: null,
          value: null
        }
      })
    },

    objectChange (newValue, oldValue) {
      this.targetStateList = []
      this.model.rule_states = []
      this.model.rule_target_registry_states = []
      if (newValue !== null) {
        this.targetStateList = this.getStatesByObjectId(newValue)
      }
    },

    getStatesByObjectId (objectId) {
      let states = []
      this.$http
        .get(`${this.$config.api}/logiceditor/states/${objectId}/registry`)
        .then((response) => {
          if (response.data.length) {
            states.push(...response.data)
          }
        })
      return states
    },

    removeRuleField (index) {
      this.model.rule_fields.splice(index, 1)
    },

    fieldValid () {
      if (this.$refs.ruleFieldForm) {
        let cntx = 0

        this.$refs.ruleFieldForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.ruleFieldForm.length
      }

      return true
    },

    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.ruleForm.validate((valid) => {
        if (valid && this.fieldValid()) {
          callback()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style type="text/css">
  .multi_form .rule_form:last-child .el-form-item {
    margin-bottom: 0;
  }
</style>
