<template>
  <div class="command-tree-select" :class="selectClass">
    <el-select
      :style="styles"
      class="el-tree-select-input"
      v-model="checkedValue"
      :disabled="localDisabled"
      popper-class="select-option"
      ref="select"
      :popper-append-to-body="false"
      :filterable="false"
      :collapse-tags="true"
      v-popover:popover
      :multiple="multiple"
      :placeholder="placeholder"
      @remove-tag="removeTag"
      @focus="_popoverShowFun">
      <el-option
        v-for="item in dataList"
        :value="item.guid"
        :label="item.name"
        :key="item.guid">
      </el-option>
    </el-select>
    <el-popover ref="popover" :placement="placement" :popper-class="popperClass" :width="width" :height="400" v-model="visible" trigger="click">
      <el-scrollbar tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list" class="is-empty">
        <el-tree
          v-if="isParsed"
          ref="tree"
          node-key="guid"
          :data="data"
          :props="props"
          check-strictly
          show-checkbox
          :default-checked-keys="defaultCheckedKeys"
          :default-expanded-keys="expanded"
          @check-change="checkNode">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span style="width: 100%;">
              <span style="font-size: 16px;" :class="getTreeIcon(data, node.expanded)"></span> {{ node.label }}
            </span>
          </span>
        </el-tree>
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'CommandSelectTree',

  props: {
    value: {
      type: [Number, Array],
      default: function () {
        return this.multiple ? [] : null
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    popoverStyles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    styles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    selectClass: {
      type: String,
      default () {
        return ''
      }
    },
    popoverClass: {
      type: String,
      default () {
        return ''
      }
    },
    placement: {
      type: String,
      //  bottom
      default () {
        return 'bottom'
      }
    },
    multiple: {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  computed: {
    popperClass () {
      let _c = 'command-tree-select-popper ' + this.popoverClass
      return this.disabled ? _c + ' disabled ' : _c
    }
  },

  data () {
    return {
      expanded: [],
      data: [],
      props: {
        isLeaf: 'is_leaf',
        label: 'name',
        disabled: 'disabled',
        children: 'children'
      },
      width: 150,
      localDisabled: this.disabled,
      visible: false,
      localValue: this.value,
      dataList: [],
      checkedValue: this.multiple ? [] : null,
      defaultCheckedKeys: [],
      isParsed: false
    }
  },

  mounted () {
    this._updateH()
    this.$http.get(`${this.$config.api}/logiceditor/grouped_commands`).then((response) => {
      response.data.forEach(item => {
        item.disabled = true

        item.children.forEach(cmd => {
          this.dataList.push(cmd)
        })

        this.data.push(item)
      })

      this.parseValue()
    })
  },

  watch: {
    disabled () {
      this.localDisabled = this.disabled
    },
    value () {
      this.localValue = this.value
    }
  },

  methods: {
    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width
      })
    },
    _popoverShowFun (val) {
      this._updateH()
    },
    getTreeIcon (data, expanded) {
      if (data.report_type === 'logic' && !expanded) {
        return 'el-icon-folder'
      } else if (data.report_type === 'logic' && expanded) {
        return 'el-icon-folder-opened'
      }

      let accessIcon = {
        command: 'el-icon-tickets'
      }

      return accessIcon[data.element_type]
    },
    checkNode (node, checked) {
      if (checked) {
        if (this.multiple) {
          this.checkedValue.push(node.guid)
          this.$refs.tree.setCheckedKeys(this.checkedValue)
        } else {
          this.checkedValue = node.guid
          this.$refs.tree.setCheckedKeys([this.checkedValue])
        }
      } else {
        if (this.multiple) {
          let index = this.checkedValue.indexOf(node.guid)
          if (index !== -1) {
            this.checkedValue.splice(index, 1)
          }
        } else {
          if (this.checkedValue === node.id) {
            this.checkedValue = null
          }
        }
      }

      this.buildValue()
    },
    buildValue () {
      if (this.multiple) {
        this.localValue = []
        this.checkedValue.forEach(value => {
          let dateItem = this.dataList.find(item => item.guid === value)

          if (dateItem) {
            this.localValue.push(dateItem.id)
          }
        })
      } else {
        let dateItem = this.dataList.find(item => item.guid === this.checkedValue)

        if (dateItem) {
          this.localValue = dateItem.id
        }
      }

      this.$emit('change', this.localValue)
      this.$emit('input', this.localValue)
    },
    parseValue () {
      if (this.multiple) {
        this.localValue.forEach(value => {
          let dateItem = this.dataList.find(item => item.id === value)

          if (dateItem) {
            this.checkedValue.push(dateItem.guid)
          }
        })

        this.defaultCheckedKeys = this.checkedValue
      } else {
        let dateItem = this.dataList.find(item => item.id === this.localValue)

        if (dateItem) {
          this.checkedValue = dateItem.guid
        }

        this.defaultCheckedKeys = [this.checkedValue]
      }

      this.expanded = this.getExpanded()

      this.isParsed = true
    },
    getExpanded () {
      let expanded = []

      let getLogicGuid = (value) => {
        let command = this.dataList.find(cmd => cmd.id === value)
        let logic = this.data.find(lg => lg.id === command.parent_id)

        return logic.guid || null
      }

      if (this.multiple) {
        this.localValue.forEach(value => {
          let guid = getLogicGuid(value)

          if (expanded.indexOf(guid) === -1) {
            expanded.push(guid)
          }
        })
      } else {
        expanded.push(getLogicGuid(value))
      }

      return expanded
    },
    removeTag (value) {
      if (this.multiple) {
        let index = this.checkedValue.indexOf(value)
        if (index !== -1) {
          this.checkedValue.splice(index, 1)
        }

        this.$refs.tree.setCheckedKeys(this.checkedValue)

        this.buildValue()
      }
    }
  }
}
</script>

<style>
  .command-tree-select .select-option {
    display: none !important;
  }
  [aria-disabled='true'] > .el-tree-node__content {
    color: inherit !important;
    background: transparent !important;
    cursor: no-drop !important;
  }
  .command-tree-select-popper.disabled {
    display: none !important;
  }
  .command-tree-select-popper .el-button--small {
    width: 25px !important;
    min-width: 25px !important;
  }
  .command-tree-select-popper[x-placement^='bottom'] {
    margin-top: 5px;
  }
</style>
