import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import LayerType from './LayerType'

export class LayerDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    source_id?: number;
    type?: string;
    projection_type?: string = 'coordinate_system';
    srs_handling_type?: string = 'keep_native';    
    declared_coordinate_system_id?: number;
    native_coordinate_system_id?: Nullable<number>;
    affine_transformation_id?: Nullable<number>;
    constraint_filter?: Nullable<string>;
    is_clustered?: boolean = false;
    native_bbox_xmin?: number;
    native_bbox_ymin?: number;
    native_bbox_xmax?: number;
    native_bbox_ymax?: number;
    latlon_bbox_xmin?: number;
    latlon_bbox_ymin?: number;
    latlon_bbox_xmax?: number;
    latlon_bbox_ymax?: number;
    properties?: object = {};
    project_id?: number;
}

export default class Layer extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private sourceId: number;
    private type: LayerType;
    private projectionType: string;
    private srsHandling: string;
    private declaredCoordinateSystem: number;
    private nativeCoordinateSystem: Nullable<number>;
    private affineTransformation: Nullable<number>;
    private constraintFilter: Nullable<string>;
    private isClustered: boolean;
    private nativeBboxXmin: number;
    private nativeBboxYmin: number;
    private nativeBboxXmax: number;
    private nativeBboxYmax: number;
    private latlonBboxXmin: number;
    private latlonBboxYmin: number;
    private latlonBboxXmax: number;
    private latlonBboxYmax: number;
    private properties: object;
    private projectId: number;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        sourceId: number,
        type: LayerType,
        projectionType: string,
        srsHandling: string,
        declaredCoordinateSystem: number,
        nativeCoordinateSystem: Nullable<number>,
        affineTransformation: Nullable<number>,
        constraintFilter: Nullable<string>,
        isClustered: boolean = false,
        nativeBboxXmin: number,
        nativeBboxYmin: number,
        nativeBboxXmax: number,
        nativeBboxYmax: number,
        latlonBboxXmin: number,
        latlonBboxYmin: number,
        latlonBboxXmax: number,
        latlonBboxYmax: number,
        properties: object = {},
        projectId: number
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.sourceId = sourceId;
        this.type = type;
        this.projectionType = projectionType;
        this.srsHandling = srsHandling;
        this.declaredCoordinateSystem = declaredCoordinateSystem;
        this.nativeCoordinateSystem = nativeCoordinateSystem;
        this.affineTransformation = affineTransformation;
        this.constraintFilter = constraintFilter;
        this.isClustered = isClustered;
        this.nativeBboxXmin = nativeBboxXmin;
        this.nativeBboxYmin = nativeBboxYmin;
        this.nativeBboxXmax = nativeBboxXmax;
        this.nativeBboxYmax = nativeBboxYmax;
        this.latlonBboxXmin = latlonBboxXmin;
        this.latlonBboxYmin = latlonBboxYmin;
        this.latlonBboxXmax = latlonBboxXmax;
        this.latlonBboxYmax = latlonBboxYmax;
        this.properties = properties;
        this.projectId = projectId;
        this.assertInvariants();
    }

    static create(dto: LayerDTO): Layer
    {
        return new Layer(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.source_id,
            LayerType[dto.type.toUpperCase()],
            dto.projection_type,
            dto.srs_handling_type,
            dto.declared_coordinate_system_id,
            dto.native_coordinate_system_id,
            dto.affine_transformation_id,
            dto.constraint_filter,
            dto.is_clustered,
            dto.native_bbox_xmin,
            dto.native_bbox_ymin,
            dto.native_bbox_xmax,
            dto.native_bbox_ymax,
            dto.latlon_bbox_xmin,
            dto.latlon_bbox_ymin,
            dto.latlon_bbox_xmax,
            dto.latlon_bbox_ymax,
            dto.properties,
            dto.project_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setSourceId(sourceId: number): void
    {
        this.sourceId = sourceId;
    }

    getSourceId(): number
    {
        return this.sourceId;
    }

    setType(type: LayerType): void
    {
        this.type = type;
    }

    getType(): LayerType
    {
        return this.type;
    }

    setProjectionType(projectionType: string): void
    {
        this.projectionType = projectionType;
    }

    getProjectionType(): string
    {
        return this.projectionType;
    }

    setSrsHandling(srsHandling: string): void
    {
        this.srsHandling = srsHandling;
    }

    getSrsHandling(): string
    {
        return this.srsHandling;
    }

    setDeclaredCoordinateSystem(declaredCoordinateSystem: number): void
    {
        this.declaredCoordinateSystem = declaredCoordinateSystem;
    }

    getDeclaredCoordinateSystem(): number
    {
        return this.declaredCoordinateSystem;
    }

    setNativeCoordinateSystem(nativeCoordinateSystem: Nullable<number>): void
    {
        this.nativeCoordinateSystem = nativeCoordinateSystem;
    }

    getNativeCoordinateSystem(): Nullable<number>
    {
        return this.nativeCoordinateSystem;
    }

    setAffineTransformation(affineTransformation: Nullable<number>): void
    {
        this.affineTransformation = affineTransformation;
    }

    getAffineTransformation(): Nullable<number>
    {
        return this.affineTransformation;
    }

    setConstraintFilter(constraintFilter: Nullable<string>): void
    {
        this.constraintFilter = constraintFilter;
    }

    getConstraintFilter(): Nullable<string>
    {
        return this.constraintFilter;
    }

    setIsClustered(isClustered: boolean): void
    {
        this.isClustered = isClustered;
    }

    getIsClustered(): boolean
    {
        return this.isClustered;
    }

    setNativeBboxXmin(nativeBboxXmin: number): void
    {
        this.nativeBboxXmin = nativeBboxXmin;
    }

    getNativeBboxXmin(): number
    {
        return this.nativeBboxXmin;
    }

    setNativeBboxYmin(nativeBboxYmin: number): void
    {
        this.nativeBboxYmin = nativeBboxYmin;
    }

    getNativeBboxYmin(): number
    {
        return this.nativeBboxYmin;
    }

    setNativeBboxXmax(nativeBboxXmax: number): void
    {
        this.nativeBboxXmax = nativeBboxXmax;
    }

    getNativeBboxXmax(): number
    {
        return this.nativeBboxXmax;
    }

    setNativeBboxYmax(nativeBboxYmax: number): void
    {
        this.nativeBboxYmax = nativeBboxYmax;
    }

    getNativeBboxYmax(): number
    {
        return this.nativeBboxYmax;
    }

    setLatlonBboxXmin(latlonBboxXmin: number): void
    {
        this.latlonBboxXmin = latlonBboxXmin;
    }

    getLatlonBboxXmin(): number
    {
        return this.latlonBboxXmin;
    }

    setLatlonBboxYmin(latlonBboxYmin: number): void
    {
        this.latlonBboxYmin = latlonBboxYmin;
    }

    getLatlonBboxYmin(): number
    {
        return this.latlonBboxYmin;
    }

    setLatlonBboxXmax(latlonBboxXmax: number): void
    {
        this.latlonBboxXmax = latlonBboxXmax;
    }

    getLatlonBboxXmax(): number
    {
        return this.latlonBboxXmax;
    }

    setLatlonBboxYmax(latlonBboxYmax: number): void
    {
        this.latlonBboxYmax = latlonBboxYmax;
    }

    getLatlonBboxYmax(): number
    {
        return this.latlonBboxYmax;
    }

    setProperties(properties: object): void
    {
        this.properties = properties;
    }

    getProperties(): object
    {
        return this.properties;
    }

    setProjectId(projectId: number): void
    {
        this.projectId = projectId;
    }

    getProjectId(): number
    {
        return this.projectId;
    }
}