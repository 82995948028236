<template>
  <div style="padding-top: 15px" class="bi_editor">
    <component
      v-if="selectType"
      :ref="`${selectType}CreateForm`"
      v-bind:is="forms.create[selectType]"
      v-model="createModel"
      :title="formTitle.create"
      :rules="formRules[selectType]"
      :is-show="selectType !== null"
      :before-close="cancelEntity"
      :submit="saveEntity">
    </component>
    <el-container>
      <el-header height="">{{$locale.bi_editor.header.title}}</el-header>
    </el-container>
    <el-container>
      <el-aside width="100%" :class="editorModel ? 'is-hidden' : ''">
        <div class="tool_box">
          <el-tooltip
            slot="reference"
            effect="dark"
            content="Добавить источник данных"
            placement="bottom">
            <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
              <el-button icon="el-icon-plus" size="mini">
                {{ $locale.main.button.add }}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.id" v-for="(item, index) in treeTypeList" :key="index">
                  {{ $locale.bi_editor.tree.dropdown[item.name] }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
        </div>
        <div class="tree-menu">
          <el-scrollbar class="tree-scroll">
            <el-tree
              ref="tree"
              class="main-tree"
              lazy
              node-key="guid"
              :load="loadNode"
              :props="treeProps"
              draggable
              @node-drag-end="handleDragEnd"
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              :expand-on-click-node="false">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span :class="{ 'selected_node': selectedNode && selectedNode.data.guid === data.guid }" @click="selectEntity(node)" style="width: 100%;">
                  <i style="font-size: 16px;" :class="getTreeIcon(data, node.expanded)"></i>
                  {{ node.label }} <b>{{ `(id: ${data.id}; type: ${$locale.bi_editor.tree.dropdown[treeTypeListMap[data.type]]})` }}</b>
                </span>
                <span v-if="selectedNode && selectedNode.data.guid === data.guid">
                  <el-dropdown
                    v-if="data.type === 'group'"
                    type="text"
                    size="small"
                    trigger="click"
                    @command="addEntity($event, data)">
                    <el-button
                      type="text"
                      size="small"
                      icon="el-icon-plus"
                      class="text_success">
                      {{ $locale.main.button.add }}
                    </el-button>
                    <el-dropdown-menu
                      slot="dropdown"
                      size="small">
                      <el-dropdown-item v-for="(item, index) in treeTypeList" :command="item.id" :index="index" :key="index">
                        {{ $locale.bi_editor.tree.dropdown[item.name] }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-edit"
                    @click="openEditor(data, node)">
                    {{ $locale.main.button.edit }}
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-delete"
                    @click="() => deleteEntity(node, data)"
                    class="text_danger">
                    {{ $locale.bi_editor.tree.remove }}
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </el-aside>
      <el-container v-if="editorModel && editorModel.id" :class="editorModel ? '' : 'is-hidden'">
        <el-header class="panel-header" height="auto">
          <h2>{{ formTitle.edit.main }}: {{ formTitle.edit.sub }}</h2>
          <el-button type="text" icon="el-icon-back" @click="cancelEntity(false)" size="medium">{{ $locale.main.button.back }}</el-button>
        </el-header>
        <el-main>
          <el-dialog
            :title="$locale.main.button.btn_result"
            :visible.sync="dataWarehouse.dialog"
            :before-close="() => { dataWarehouse.dialog = false}"
            :close-on-click-modal="false"
            width="50%">
            <template v-if="dataWarehouse.dialog">
              <el-table
                :data="dataWarehouse.data.data"
                max-height="400px"
                style="width: 100%">
                <el-table-column v-for="(item, index) in dataWarehouse.structure"
                  :prop="item.name"
                  :label="item.name"
                  :key="index">
                </el-table-column>
              </el-table>
              <el-pagination
                @current-change="tablePageChange"
                :page-size="dataWarehouse.pagination.limit"
                layout="total, prev, pager, next"
                :total="dataWarehouse.data.count">
              </el-pagination>
            </template>
            <span slot="footer" class="dialog-footer">
              <el-button type="default" @click="dataWarehouse.dialog = false" size="small">{{ $locale.main.button.close }}</el-button>
            </span>
          </el-dialog>

          <component
            v-if="selectedNode"
            :ref="`${selectedNode.data.type}UpdateForm`"
            v-bind:is="forms.update[selectedNode.data.type]"
            v-model="editorModel"
            :data-warehouse="dataWarehouse"
            :rules="formRules[selectedNode.data.type]">
          </component>

          <el-button v-if="selectedNode" type="primary" @click="saveEntity(false)" size="small">
            {{ $locale.bi_editor.form.btn_save }}
          </el-button>

          <el-button v-if="selectedNode && selectedNode.data.type === 'extended_object'" style="float: right;" type="default" @click="viewResult" size="small">
            {{ $locale.main.button.btn_result }}
          </el-button>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import DataSource from './Models/DataSource'
import Query from './Models/Query'
import Group from './Models/Group'
import ExtendedObject from './Models/ExtendedObject'
import Field from './Models/ExtendedObjectField'
import Node from 'element-ui/packages/tree/src/model/node'
import DataWarehouseObject from './Models/DataWarehouseObject'

import GroupCreateForm from './Forms/CreateGroup'
import QueryCreateForm from './Forms/CreateQuery'
import ExtendedObjectCreateForm from './Forms/CreateExtendedObject'

import GroupUpdateForm from './Forms/UpdateGroup'
import QueryUpdateForm from './Forms/UpdateQuery'
import ExtendedObjectUpdateForm from './Forms/UpdateExtendedObject'

export default {
  name: 'BIEditor',
  data () {
    return {
      formTitle: {
        create: null,
        edit: {
          main: null,
          sub: null
        }
      },
      selectedNode: null,
      selectType: null,
      selectData: null,
      createModel: null,
      editorModel: null,
      treeProps: {
        isLeaf: 'is_leaf',
        children: 'children',
        label: 'name'
      },
      treeTypeList: [
        {
          id: 'group',
          name: 'group'
        }, {
          id: 'query',
          name: 'query'
        }, {
          id: 'extended_object',
          name: 'extended_object'
        }
      ],
      treeTypeListMap: {
        group: 'group',
        query: 'query',
        extended_object: 'extended_object'
      },
      models: {
        group: Group,
        query: Query,
        extended_object: ExtendedObject
      },
      forms: {
        create: {
          group: GroupCreateForm,
          query: QueryCreateForm,
          extended_object: ExtendedObjectCreateForm
        },
        update: {
          group: GroupUpdateForm,
          query: QueryUpdateForm,
          extended_object: ExtendedObjectUpdateForm
        }
      },
      accessIcon: {
        group: 'el-icon-folder',
        query: 'el-icon-s-promotion',
        extended_object: 'el-icon-s-grid'
      },
      defaultModel: {
        group: {
          name: null,
          parent_id: null
        },
        query: {
          name: null,
          group_id: null,
          sql_statement: 'SELECT 1',
          is_materialized: false
        },
        extended_object: {
          name: null,
          group_id: null,
          object_id: null,
          is_materialized: false,
          is_group_by_record: false,
          extended_object_fields: []
        }
      },
      formRules: {
        group: {
          name: [
            { required: true, message: this.$locale.bi_editor.form.message.name.required, trigger: 'change' }
          ]
        },
        query: {
          name: [
            { required: true, message: this.$locale.bi_editor.form.message.name.required, trigger: 'blur' }
          ],
          sql_statement: [
            { required: true, message: this.$locale.bi_editor.form.message.sql_statement.required, trigger: 'blur' }
          ]
        },
        extended_object: {
          name: [
            { required: true, message: this.$locale.bi_editor.form.message.name.required, trigger: 'blur' }
          ],
          object_id: [
            { required: true, message: this.$locale.bi_editor.form.message.object_id.required, trigger: 'blur' }
          ]
        }
      },
      dataWarehouse: {
        structure: [],
        data: [],
        dialog: false,
        pagination: {
          current: 0,
          limit: 30
        }
      }
    }
  },
  async mounted () {
  },
  methods: {
    getTreeIcon (data, expanded) {
      if (data.type === 'group' && !expanded) {
        return 'el-icon-folder'
      } else if (data.type === 'group' && expanded) {
        return 'el-icon-folder-opened'
      }

      return this.accessIcon[data.type]
    },
    async tablePageChange (val) {
      val--
      this.dataWarehouse.pagination.current = (val * this.dataWarehouse.pagination.limit)
      this.dataWarehouse.data.data = await DataWarehouseObject.params({ offset: this.dataWarehouse.pagination.current, limit: this.dataWarehouse.pagination.limit }).find(this.editorModel.id)
    },
    async viewResult () {
      this.dataWarehouse.structure = await Field.params({ extended_object_id: this.editorModel.id }).get()

      let response = await DataWarehouseObject.params({ '*': { func: 'count' } }).find(this.editorModel.id)
      this.dataWarehouse.data.count = response.length ? response[0]['count'] : 0
      response = await DataWarehouseObject.params({ offset: this.dataWarehouse.pagination.current, limit: this.dataWarehouse.pagination.limit }).find(this.editorModel.id)
      this.$set(this.dataWarehouse.data, 'data', [])
      for (const [key, value] of Object.entries(response)) {
        this.dataWarehouse.data.data.push(value)
      }

      this.dataWarehouse.dialog = true
    },
    cancelEntity (isNewEntity = true) {
      if (isNewEntity) {
        this.createModel = null
        this.selectType = null
        this.selectData = null
      } else {
        this.selectedNode = null
        this.editorModel = null
      }
    },
    async addEntity (type, data = null) {
      this.createModel = new this.models[type](this.defaultModel[type])
      this.formTitle.create = this.$locale.bi_editor.form.title.create[type]
      this.selectType = type
      this.selectData = data
    },
    selectEntity (node) {
      this.selectedNode = node
    },
    async openEditor (data, node) {
      this.editorModel = await this.models[data.type].find(data.id)
      this.selectedNode = node
      this.formTitle.edit.main = this.$locale.bi_editor.form.title.edit[data.type]
      this.formTitle.edit.sub = `${data.name} (id: ${data.id})`
    },
    async saveEntity (isNewEntity = true) {
      if (!isNewEntity) {
        let form = `${this.selectedNode.data.type}UpdateForm`
        this.$refs[form].validate(async (valid) => {
          if (valid) {
            let response = await this.editorModel.save()

            this.selectedNode.data = await new DataSource().find(response.guid)
          }
        })
      } else {
        if (this.selectData !== null) {
          let fieldName = { group: 'parent_id', query: 'group_id', extended_object: 'group_id' }
          this.createModel[fieldName[this.selectType]] = this.selectData.id
        }

        let response = await this.createModel.save()
        let dataSource = await new DataSource().find(response.guid)

        if (this.selectData !== null) {
          this.$refs.tree.append(dataSource, this.selectData.guid)
        } else {
          let node = new Node({
            parent: this.$refs.tree.root,
            store: this.$refs.tree.store,
            data: dataSource
          })

          node.level = 1

          this.$refs.tree.root.childNodes.push(node)
        }

        this.cancelEntity()
      }
    },
    deleteEntity (node, data) {
      this.$confirm(
        this.$locale.etl_editor.form.messages.delete,
        this.$locale.etl_editor.form.messages.warningTitle,
        {
          confirmButtonText: this.$locale.etl_editor.form.btn_delete,
          cancelButtonText: this.$locale.etl_editor.form.btn_cancel,
          type: 'warning'
        }
      ).then(async () => {
        await new this.models[data.type]({ id: data.id }).delete()
        this.cancelEntity(false)
        this.$refs.tree.remove(data.guid)
      })
    },
    async loadNode (node, resolve) {
      resolve(await this.loadEntities(node.level === 0 ? null : node.data.guid))
    },
    async loadEntities (parentGuid = null) {
      let data = []
      if (parentGuid === null) {
        data = await new DataSource().params({ spec: 'is_null', parent_guid: parentGuid, order: 'row_order:asc,id:desc' }).$get()
      } else {
        data = await new DataSource().params({ parent_guid: parentGuid, order: 'row_order:asc,id:desc' }).$get()
      }

      return data
    },
    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      this.moveRow(draggingNode, dropNode, dropType)
    },
    allowDrag (draggingNode) {
    },
    allowDrop (draggingNode, dropNode, type) {
    },
    async moveRow (draggingNode, dropNode, type) {
      if (dropNode.data.type === 'group') {
        return false
      } else if (type === 'inner' && dropNode.data.entity_type_id !== 'field_group') {
        return false
      } else {
        return true
      }
    },
    // средние значение row_order
    mediumRowOrder (draggingNode, dropNode) {
      // console.log('бросить сюда parent_id', dropNode.data.parent_id)
      // console.log('взятый id', draggingNode.data.id)
      // console.log('parent_id взятого элемента', draggingNode.data.parent_id)
      if (draggingNode.data.parent_id === dropNode.data.parent_id) {
        // console.log('перенос внутри родителя')
        const a = dropNode.parent.childNodes
        return this.rowOrderMethods(draggingNode, a)
      } else {
        const a = dropNode.parent.childNodes
        // console.log('вынос за родителя', a)
        return this.rowOrderMethods(draggingNode, a)
      }
    },
    rowOrderMethods (draggingNode, a) {
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 3333))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((1001 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }
      return rowOrder
    }
  }
}
</script>

<style type="text/css">
  .bi_editor .el-button.is-hidden {
    display: none;
  }

  .bi_editor .el-aside.is-hidden {
    display: none;
  }

  .bi_editor .el-header {
    border-bottom: 1px solid #efefef;
    font-size: 24px;
    font-weight: 700;
    padding: 0 20px 10px 20px;
  }

  .bi_editor .el-header.panel-header {
    border-bottom: 1px solid #efefef;
    font-size: 18px;
    font-weight: 700;
    color: #303030;
    padding: 10px 20px 10px 20px;
  }

  .bi_editor .el-header.panel-header h2 {
    float: left;
    margin: 0;
  }

  .bi_editor .el-header.panel-header .el-button {
    padding: 2px 0;
    float: right;
  }

  .bi_editor .el-aside {
    border-right: 1px solid #efefef;
  }

  .bi_editor .tree-tools {
    padding: 5px 0;
    border-bottom: 1px solid #efefef;
  }

  .bi_editor .tree-tools button {
    margin-left: 20px;
  }

  .bi_editor .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .bi_editor .tree-scroll {
    height: calc(100vh - 162px);
  }

  .bi_editor .form-scroll {
    height: calc(100vh - 250px);
  }

  .bi_editor .el-dropdown+.el-button {
    margin-left: 10px;
  }

  .bi_editor .el-select,
  .bi_editor .el-input,
  .bi_editor .el-input-number {
    width: 100%;
  }

</style>
