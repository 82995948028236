<template>
  <div :style="css" :class="{'hover_effect': clickTable}">
    <span :class="{'notExtended': !extended}" v-html="parceHtmlTemplate"></span>
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  props: ['value', 'extended', 'css', 'htmlTemplate', 'scopeRow'],
  mixins: [clickTableMixin],
  name: 'html_field',
  computed: {
    parceHtmlTemplate () {
      if (this.htmlTemplate) {
        let label = this.htmlTemplate
        let attributes = this.htmlTemplate.match(/\{{(.*?)\}}/g) || []
        attributes.forEach((attribute) => {
          attribute = attribute.replace('{{', '').replace('}}', '')
          let value = this.scopeRow[attribute]
          try {
            value = JSON.parse(value)
          } catch (e) {}
          if (value instanceof Array) {
            value = value.map(item => item.name).join(',')
          }
          label = label.replace(`{{${attribute}}}`, value || '')
        })

        return label
      } else {
        return ''
      }
    }
  }
}

</script>

<style scoped>
  .notExtended {
    white-space: pre;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
