<template>
  <div>
    <el-form-item v-if="!isTreeSelect" :label="$locale.bi_editor.form.message.object_id.required">
      <editor-registry-select
        :value="value.card.registryId"
        @change="$set(value.card ,'registryId', $event)"
      ></editor-registry-select>
    </el-form-item>
    <el-form-item v-else :label="$locale.bi_editor.form.message.object_id.required">
      <treeselect
        ref="registryFieldSelect"
        :options="dataTree"
        :placeholder="$locale.main.placeholder.select"
        class="custom_scrollbar"
        :value="valueTree"
        :load-options="loadOptions"
        :loading-text="$locale.main.message.loading"
        :normalizer="registryFieldsSettings.normalizer"
        :disable-branch-nodes="true"
        :clear-on-select="true"
        :clearValueText="registryFieldsSettings.clearValueText"
        :noChildrenText="registryFieldsSettings.noChildrenText"
        :noResultsText="registryFieldsSettings.noResultsText"
        :limitText="registryFieldsSettings.limitText"
        :matchKeys="registryFieldsSettings.matchKeys"
        value-format="object"
        :auto-load-root-options="false"
        @select="selectFieldCurrentTable($event)"
        :value-consists-of="registryFieldsSettings.valueConsistsOf">
        <div slot="value-label" slot-scope="{ node }">{{ node.label }} <b> (id: {{ node.raw.id }})</b></div>
        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
          {{ node.label }}(id: {{node.id}}, <b>{{ $locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id }}</b>)
        </label>
        </treeselect>
      </el-form-item>
    <el-form-item :label="$locale.logic_editor.type">
      <el-select
        :value="value.card.type"
        value-key="id"
        filterable
        @change="$set(value.card ,'type', $event )"
      >
        <el-option
          v-for="(item, index) in $locale.interface_editor.component_editor.button_action.open_card_types"
          :key="index"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.button_action.constant_record_id">
      <el-input v-model="value.card.constantRecordId"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.object_editor.entity_types.field">
      <el-select v-model="value.card.fieldId" v-if="isButton" clearable>
        <el-option
          v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
          :key="index"
          :label="(item.name.match(/attr_(.*?)_/g) || []).length ? `${item.name}` : `${item.name} ${item.properties.name}`"
          :value="item.properties.name"
        ></el-option>
      </el-select>
      <selectTree
        v-else
        :clearable="false"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.card.fieldId"/>
      <!-- <el-select v-model="value.card.fieldId" v-else>
        <el-option
          v-for="(item, index) in fields"
          :key="index"
          :label="`${item.text} id: ${item.id}`"
          :value="item.id"
        ></el-option>
      </el-select> -->
    </el-form-item>
    <el-form-item
      :label="$locale.interface_editor.component_editor.table.card"
      v-if="value.card.registryId"
    >
      <el-select v-model="value.card.cardId" clearable>
        <el-option v-for="(item, index) in cards" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <editor-filters :value="value.card.defaults" @change="$set(value.card ,'defaults', $event)" :title="$locale.main.fields.default" :show-xref-option="false"/>
    <el-form-item :label="$locale.interface_editor.component_editor.button_action.frame">
      <el-select v-model="value.card.frameGuid" v-if="!isButton" clearable>
        <el-option
          v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
          :key="index"
          :label="`${item.name}`"
          :value="item.guid"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.button_action.open_in_window">
      <el-checkbox v-model="value.card.isWindow"></el-checkbox>
    </el-form-item>
    <el-form-item
      :label="this.$locale.interface_editor.component_editor.button_action.window_title"
      v-if="value.card.isWindow"
    >
      <el-input v-model="value.card.windowTitle" />
    </el-form-item>
    <el-form-item
      :label="this.$locale.interface_editor.component_editor.button_action.window_width"
      v-if="value.card.isWindow"
    >
      <editor-select
        :value="value.card.windowWidth"
        @change="$set(value.card ,'windowWidth', $event)"
        :options="{
            multiple: false,
            options: [
              { id: '25', name:'25%' },
              { id: '50', name:'50%' },
              { id: '75', name:'75%' },
              { id: '100', name:'100%' },
            ]
          }"
      ></editor-select>
    </el-form-item>
  </div>
</template>

<script>
import EditorRegistrySelect from './editor-registry-select'
import EditorFilters from './editor-filters'
import EditorSelect from './editor-select'
import Card from '@/components/ObjectEditor/Models/Card'
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SelectTree from '@/components/LogicEditor/components/treeSelect'
import Registry from '@/components/LogicEditor/Models/Registry'
export default {
  name: 'editor-open-card',
  components: {
    EditorRegistrySelect,
    EditorSelect,
    Treeselect,
    SelectTree,
    EditorFilters
  },
  watch: {
    'value.card.registryId': {
      async handler (value) {
        if (value) {
          this.loadCards(value)
        }
      }
    }
  },
  props: {
    value: {
      type: Object
    },
    listExtendObject: {
      type: Array
    },
    listField: {
      type: Array,
      default: () => []
    },
    isTreeSelect: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: true
    },
    xrefObjectId: {
      type: Number
    }
  },
  inject: {
    getComponents: {
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      components: this.getComponents(),
      cards: [],
      loading: false,
      dataTree: null,
      valueTree: null,
      fields: [],
      registryFieldsSettings: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        limitText: count => `и еще ${count}`,
        normalizer (node) {
          return {
            id: node.id,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      }
    }
  },
  methods: {
    async loadCards (entityId) {
      this.cards = await new Card().params({ entity_id: entityId, fields: ['id', 'name'] }).$get()
    },
    selectFieldCurrentTable (e) {
      this.$set(this.value.card, 'registryId', e.id)
      // this.value.card.registryId = e.id
    },
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.entity_type_id === 'registry_group') el.children = null
        // if (!el.leaf && multi) el.isDisabled = true
        // if (el.children instanceof Array && el.children.length) this.deepSearch(el.children)
      }
      return arr
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        let children = await this.$http.get(`${this.$config.api}/objecteditor/entities/${parentNode.id}/children`)
        parentNode.children = this.deepSearch(children.data.data)
        callback()
      }
      if (action === LOAD_ROOT_OPTIONS) {
        let response = await this.$http.get(`${this.$config.api}/objecteditor/entities?root=true`)
        this.dataTree = this.deepSearch(response.data.data)
        callback()
      }
    }
  },
  async created () {
    console.log(this)
    if (this.value.card && this.value.card.registryId) {
      this.loadCards(this.value.card.registryId)
      let parent = await this.$http.get(`${this.$config.api}/objecteditor/entities/${this.value.card.registryId}`)
      this.valueTree = parent.data.data
    } else {
      this.$set(this.value, 'card', {
        registryId: null,
        type: null,
        fieldId: null,
        cardId: null,
        isWindow: false,
        windowWidth: 25,
        windowTitle: null,
        frameGuid: null,
        defaults: []
      })
    }
    if (!this.isButton && !this.listExtendObject) {
      let fields = await Registry.params({ parent_id: this.xrefObjectId, show_children: true, properties: { is_visible_in_grid: true } }).$get()
      this.fields = fields
    } else if (this.listExtendObject) {
      this.fields = this.listExtendObject.map((item) => {
        return {
          id: item.name,
          name: item.name
        }
      })
    }
    if (this.listField.length) {
      this.fields = this.listField
    }
  }
}
</script>

<style scoped>
</style>
