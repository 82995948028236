<template>
  <div class="el-tree-select" :class="selectClass">
    <el-select
      ref="select"
      v-model="localValue"
      v-popover:popover
      :style="styles"
      class="el-tree-select-input"
      popper-class="select-option"
      :disabled="disabled"
      :clearable="clearable"
      :multiple="multiple"
      :placeholder="placeholder"
      :collapse-tags="collapseTags"
      :filterable="false"
      :popper-append-to-body="false"
      @clear="_clear"
      @remove-tag="_removeTag"
      @focus="_popoverShowFun">
      <el-option
        v-for="item in options"
        :value="item.id"
        :label="item.label"
        :key="item.id">
      </el-option>
    </el-select>
    <el-popover ref="popover" :placement="placement" :popper-class="popperClass" :width="width" v-model="visible" trigger="click">
      <el-scrollbar tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list" class="is-empty">
        <el-tree
          ref="tree"
          :data="data"
          :props="props"
          :node-key="nodeKey"
          :size="size"
          :render-content="renderContent"
          :default-checked-keys="defaultCheckedKeys"
          :accordion="false"
          show-checkbox
          disabled
          check-on-click-node
          check-strictly
          expand-on-click-node
          default-expand-all
          @check-change="_checkNode">
        </el-tree>
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script>
const defaultProps = {
  children: 'children',
  isLeaf: 'isLeaf',
  label: 'label',
  disabled: 'disabled'
}

export default {
  name: 'TreeSelect',

  props: {
    value: [Number, Array, String],

    nodeKey: {
      type: String,
      default: 'id'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    data: {
      type: Array,
      default () {
        return []
      }
    },

    props: {
      type: Object,
      default () {
        return defaultProps
      }
    },

    renderContent: {
      type: Function,
      default: function (h, { node, data, store }) {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>);
      }
    },

    size: String,

    disabled: {
      type: Boolean,
      default: false
    },

    collapseTags: {
      type: Boolean,
      default: true
    },

    clearable: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: null
    },

    popoverStyles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },

    styles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },

    selectClass: {
      type: String,
      default () {
        return ''
      }
    },

    popoverClass: {
      type: String,
      default () {
        return ''
      }
    },

    placement: {
      type: String,
      //  bottom
      default () {
        return 'bottom-start'
      }
    }
  },

  computed: {
    popperClass () {
      let _c = 'el-tree-select-popper ' + this.popoverClass
      return this.disabled ? _c + ' disabled ' : _c
    },

    options () {
      return this._buildOptions(this.data)
    },

    mergedProps () {
      return Object.assign({}, defaultProps, this.props)
    },

    defaultCheckedKeys () {
      let checkedKeys = []
      if (this.value !== null) {
        checkedKeys = this.multiple ? this.value : [this.value]
      }
      return checkedKeys
    }
  },

  data () {
    return {
      width: 150,
      visible: false,
      localValue: this.value
    }
  },

  mounted () {
    this._updateH()
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  methods: {
    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width + 100
      })
    },

    _popoverShowFun (val) {
      this._updateH()
    },

    _buildOptions (items) {
      let options = []

      items.forEach((item) => {
        if (item[this.mergedProps.children].length > 0) {
          options.push(...this._buildOptions(item[this.mergedProps.children]))
        } else {
          options.push({
            id: item[this.nodeKey],
            label: item[this.mergedProps.label]
          })
        }
      })

      return options
    },

    _checkNode (node, checked) {
      if (checked) {
        if (this.multiple) {
          if (!Array.isArray(this.localValue)) {
            this.localValue = []
          }
          this.localValue.push(node[this.nodeKey])
          this.$refs.tree.setCheckedKeys(this.localValue)
        } else {
          this.localValue = node[this.nodeKey]
          this.$refs.tree.setCheckedKeys([this.localValue])
        }
      } else {
        if (this.multiple) {
          let index = this.localValue.indexOf(node[this.nodeKey])
          if (index !== -1) {
            this.localValue.splice(index, 1)
          }
        } else {
          if (this.localValue === node[this.nodeKey]) {
            this.localValue = null
          }
        }
      }

      this.$emit('change', this.localValue)
      this.$emit('input', this.localValue)
    },

    _clear () {
      this.localValue = null
      this.$refs.tree.setCheckedKeys([])
      this.$emit('clear')
    },

    _removeTag (value) {
      if (this.multiple) {
        let index = this.localValue.indexOf(value)
        if (index !== -1) {
          this.localValue.splice(index, 1)
        }

        this.$refs.tree.setCheckedKeys(this.localValue)
        this.$emit('remove-tag', value)
      }
    }
  }
}
</script>

<style>
  /*.registry-select-tree {
    width: 100%;
  }
  .el-tree-select .el-select {
    display: block;
  }*/
  .el-tree-select .select-option {
    display: none !important;
  }
  [aria-disabled='true'] > .el-tree-node__content {
    color: inherit !important;
    background: transparent !important;
    cursor: no-drop !important;
  }
  .el-tree-select-popper {
    max-height: 400px;
    overflow: auto;
  }
  .el-tree-select-popper.disabled {
    display: none !important;
  }
  .el-tree-select-popper .el-button--small {
    width: 25px !important;
    min-width: 25px !important;
  }
  .el-tree-select-popper[x-placement^='bottom'] {
    margin-top: 5px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .no-data {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #cccccc;
    text-align: center;
  }
</style>
