import { Nullable } from '@/core/domain/type/types'

export default class SmevSettingUpdateCommand
{
    private guid: string;
    private name: string;
    private value: Nullable<string>;

    constructor(
        guid: string,
        name: string,
        value: Nullable<string>
    ) {
        this.guid = guid;
        this.name = name;
        this.value = value;
    }

    getClassName(): string
    {
        return 'SmevSettingUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getValue(): string
    {
        return this.value;
    }
}