import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CoordinateSystemCommandRepositoryInterface from '../../../domain/repository/CoordinateSystemCommandRepositoryInterface'
import CoordinateSystemDeleteCommand from '@/services/MapEditor/application/command/CoordinateSystemDeleteCommand'

export default class CoordinateSystemDeleteHandler implements CommandHandlerInterface
{
    private repo: CoordinateSystemCommandRepositoryInterface;

    constructor(repo: CoordinateSystemCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: CoordinateSystemDeleteCommand): Promise<any>
    {
        return this.repo.delete(query.getSrid());
    }
}