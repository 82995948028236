<template>
  <div>
    <el-select v-model="value.author_type" size="mini" class="extraAccordion__row">
      <el-option v-for="item in listTypes" :key="item.type" :label="item.name" :value="item.type"></el-option>
    </el-select>

    <author-tree v-if="value.author_type === 'field'" :object_id='value.object_id' v-model="value.author_field_id"></author-tree>

    <el-select
      v-show="value.author_type === 'state'"
      class="extraAccordion__row"
      filterable
      v-model="value.author_state_id"
      size="mini"
    >
      <el-option
        v-for="item in listStatesAroundUser"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select
      v-show="value.author_type === 'user'"
      filterable
      class="extraAccordion__row"
      v-model="value.author_id"
      size="mini"
    >
      <el-option
        v-for="item in fullname"
        :key="item.id"
        :label="item.surname + ' ' + item.name + ' ' + item.midname "
        :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import authorTree from '@/components/NotificationEditor/components/notify/authorTree'
export default {
  name: 'author',
  props: ['listStatesAroundUser', 'listUser', 'listField', 'value'],
  components: {
    authorTree
  },
  data () {
    return {
      listTypes: [
        {
          type: 'field',
          name: this.$locale.notify_editor.label.field_registry
        },
        {
          type: 'state',
          name: this.$locale.logic_editor.routes.states
        },
        {
          type: 'user',
          name: this.$locale.logic_editor.routes.list
        }
      ],
      styles: {
        width: '100%'
      },
      treeParams: {
        clickParent: false,
        'check-strictly': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          isLeaf: 'leaf',
          label: 'name',
          children: 'children',
          value: 'id'
        }
      }
    }
  },
  computed: {
    fullname () {
      let arr = this.listUser.map(el =>
        ({
          id: el.id,
          surname: el.surname !== null ? el.surname : '',
          name: el.name !== null ? el.name : '',
          midname: el.midname !== null ? el.midname : ''
        })
      )
      return arr
    }
  },
  watch: {
    'value.author_type': function (newVal, oldval) {
      if (oldval !== null) {
        this.$nextTick(function () {
          this.value.author_field_id = null
          this.value.author_state_id = null
          this.value.author_id = null
        })
      }
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped>
.extraAccordion__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0px;
  margin-top: 0px;
}
</style>
