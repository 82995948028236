<template>
  <div class="el-tree-select" :class="selectClass">
    <el-select
      :style="styles"
      class="el-tree-select-input"
      v-model="selectValue"
      :disabled="localDisabled"
      popper-class="select-option"
      ref="select"
      :popper-append-to-body="false"
      :filterable="false"
      v-popover:popover
      :multiple="valueType === 'array'"
      :placeholder="placeholder"
      @focus="_popoverShowFun">
      <el-option
        v-for="item in dataList"
        :value="item.id"
        :label="item.name"
        :key="item.id">
      </el-option>
    </el-select>
    <el-popover ref="popover" :placement="placement" :popper-class="popperClass" :width="width" v-model="visible" trigger="click">
      <el-scrollbar tag="div" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list" class="is-empty">
        <el-tree
          ref="tree"
          lazy
          :props="props"
          :accordion="false"
          :load="loadNode"
          show-checkbox
          @check-change="checkNode"
          :default-checked-keys="defaultCheckedKeys"
          :default-expanded-keys="expanded"
          check-strictly
          node-key="id"
          :size="size"
          :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span style="width: 100%;">
              <span style="font-size: 16px;" :class="getTreeIcon(data, node.expanded)"></span> {{ node.label }}
            </span>
          </span>
        </el-tree>
        <!--<div v-if="data.length===0" class="no-data">Нет данных!</div>-->
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'ReportSelectTree',

  props: {
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    value: {
      type: [Object, Number, Array, String],
      default: function () {
        return {
          id: null,
          name: null
        }
      }
    },
    type: {
      type: String,
      default: 'any',
      validate: function (value) {
        return [
          'document',
          'report',
          'xml',
          'any'
        ].indexOf(value) !== -1
      }
    },
    size: String,
    disabled: {
      type: Boolean,
      default: false
    },
    valueType: {
      type: String,
      default: 'number',
      validate: function (value) {
        return [
          'object',
          'number',
          'array',
          'string'
        ].indexOf(value) !== -1
      }
    },
    parentId: {
      type: Number,
      default: null
    },

    popoverStyles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    styles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },
    selectClass: {
      type: String,
      default () {
        return ''
      }
    },
    popoverClass: {
      type: String,
      default () {
        return ''
      }
    },
    placement: {
      type: String,
      //  bottom
      default () {
        return 'bottom'
      }
    }
  },

  computed: {
    defaultCheckedKeys () {
      if (this.valueType === 'array') {
        return this.getValue()
      }

      return [this.getValue()]
    },

    popperClass () {
      let _c = 'el-tree-select-popper ' + this.popoverClass
      return this.disabled ? _c + ' disabled ' : _c
    }
  },

  data () {
    return {
      expanded: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: 'disabled'
      },
      isLoaded: false,
      width: 150,
      localDisabled: this.disabled,
      visible: false,
      selectValue: this.getValue(),
      dataList: []
    }
  },

  mounted () {
    this.onMounted()
    this._updateH()
    this.isLoaded = true
  },

  watch: {
    disabled () {
      this.localDisabled = this.disabled
    },
    value () {
      if (this.isLoaded) this.onMounted()

      this.selectValue = this.getValue()
    }
  },

  methods: {
    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width
      })
    },
    _popoverShowFun (val) {
      this._updateH()
    },
    async onMounted () {
      this.$refs.tree.setCheckedKeys([])

      if (this.getValue()) {
        let elements = []
        await this.$http.get(`${this.$config.api}/reporteditor/reports?id=${this.getValue()}`).then((response) => {
          elements.push(...response.data)
        })
        this.expanded = this.getExpanded(elements)
      }
    },
    getTreeIcon (data, expanded) {
      if (data.report_type === 'report_group' && !expanded) {
        return 'el-icon-folder'
      } else if (data.report_type === 'report_group' && expanded) {
        return 'el-icon-folder-opened'
      }

      let accessIcon = {
        report: 'el-icon-document-copy',
        document: 'el-icon-document',
        xml: 'el-icon-tickets'
      }

      return accessIcon[data.report_type]
    },
    checkNode (node, checked) {
      if (checked) {
        this.setValue(node)
        this.$refs.tree.setCheckedKeys([this.getValue()])
      } else {
        if (node.id === this.getValue()) {
          this.setValue(null)
        }
      }
    },
    getExpanded (object) {
      let array = []
      if (object && object.parent) {
        array.push(object.parent.id)
        array.push(...this.getExpanded(object.parent))
      }
      return array
    },
    async loadNode (node, resolve) {
      resolve(await this.loadElements(node.level === 0 ? this.parentId : node.data.id))
    },
    async loadElements (elementId = null) {
      let params = !elementId ? 'is_null=parent_id' : `parent_id=${elementId}`

      let elements = []
      await this.$http
        .get(`${this.$config.api}/reporteditor/reports?${params}&order=report_type:asc,row_order:asc,name:desc`)
        .then((response) => {
          response.data.forEach((item) => {
            if (this.type !== 'any' && item.report_type === this.type) {
              item.leaf = true
              item.disabled = false

              elements.push(item)
            } else if (item.report_type === 'report_group') {
              item.leaf = false
              item.disabled = true

              elements.push(item)
            } else if (this.type === 'any' && item.report_type !== 'report_group') {
              item.leaf = true
              item.disabled = false

              elements.push(item)
            }
          })

          this.dataList.push(...elements)
        })

      return elements
    },
    getValue () {
      if (this.valueType === 'object') {
        return this.value.id || null
      } else if (this.valueType === 'string') {
        return this.value
      } else {
        return this.value !== null ? parseInt(this.value) : null
      }
    },
    setValue (value) {
      let newValue
      if (this.valueAs === 'object') {
        newValue = value ? {
          id: value.id,
          name: value.name
        } : {
          id: null,
          name: null
        }
      } else {
        newValue = value ? value.id : null
      }

      this.$emit('change', newValue)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>
  /*.registry-select-tree {
    width: 100%;
  }
  .el-tree-select .el-select {
    display: block;
  }*/
  .el-tree-select .select-option {
    display: none !important;
  }
  [aria-disabled='true'] > .el-tree-node__content {
    color: inherit !important;
    background: transparent !important;
    cursor: no-drop !important;
  }
  .el-tree-select-popper {
    max-height: 400px;
    overflow: auto;
  }
  .el-tree-select-popper.disabled {
    display: none !important;
  }
  .el-tree-select-popper .el-button--small {
    width: 25px !important;
    min-width: 25px !important;
  }
  .el-tree-select-popper[x-placement^='bottom'] {
    margin-top: 5px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .no-data {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #cccccc;
    text-align: center;
  }
</style>
