<template>
  <el-row>
  <!-- <span>{{$locale.interface_editor.component_editor.plugins.plugin}}</span> -->
    <el-select
      v-model="localModel"
      filterable
      clearable
      @change="onChange">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <el-input v-show="!withoutPostfix" @change="onChange" v-model="postfix"></el-input>
  </el-row>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    withoutPostfix: {
      type: Boolean,
      default: false
    }
  },
  name: 'editor-plugins',
  data () {
    return {
      localModel: (this.value || '').split('/')[0],
      options: [],
      postfix: (this.value || '').split('/')[1]
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/logiceditor/plugins`)
    this.options = response.data
  },
  methods: {
    onChange (value) {
      this.$emit('change', this.localModel + (this.postfix ? '/' + this.postfix : ''))
    }
  }
}
</script>

<style scoped>

</style>
