import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    XML_ROUTE_LOADING_EVENT = 'xml_route_loading_event',
    XML_ROUTE_LOADED_EVENT = 'xml_route_loaded_event',
    XML_ROUTE_CREATED_EVENT = 'xml_route_created_event',
    XML_ROUTE_UPDATED_EVENT = 'xml_route_updated_event',
    XML_ROUTE_DELETED_EVENT = 'xml_route_deleted_event' 
}

export class XmlRouteLoadingEvent implements FluxStandardAction {
    type = MutationTypes.XML_ROUTE_LOADING_EVENT
}

export class XmlRouteLoadedEvent implements FluxStandardAction {
    type = MutationTypes.XML_ROUTE_LOADED_EVENT
}

export class XmlRouteCreatedEvent implements FluxStandardAction {
    type = MutationTypes.XML_ROUTE_CREATED_EVENT
    constructor(public location: string){}
}

export class XmlRouteUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.XML_ROUTE_UPDATED_EVENT
}

export class XmlRouteDeletedEvent implements FluxStandardAction {
    type = MutationTypes.XML_ROUTE_DELETED_EVENT
    constructor(public guid: string){}
}