import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RoleCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleCommandRepositoryInterface'
import RoleUpdateCommand from '@/services/AccessEditor/application/command/RoleUpdateCommand'
import { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'

export default class RoleUpdateHandler implements CommandHandlerInterface
{
    private repo: RoleCommandRepositoryInterface;

    constructor(repo: RoleCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RoleUpdateCommand): Promise<void>
    {
        let item: RoleDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            is_readable_default: command.getIsReadable(),
            is_addable_default: command.getIsAddable(),
            is_editable_default: command.getIsEditable(),
            is_deletable_default: command.getIsDeletable(),
            is_menu_visible_default: command.getIsMenuVisible(),
            root_menu_id: command.getRootMenuId(),
            dashboards: command.getDashboards()
        };
        return this.repo.update(item);
    }
}