import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export namespace ProjectAPI {

    export class UpdateProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectDTO) {
            this.path = `/mapeditor/projects/${params.guid}`;
        }
    }

    export class GetProjectSources implements APIRequest<Array<SourceDTO>> {
        response: Array<SourceDTO>;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/sources`;
        }
    }

    export class GetProjectCoordinateSystems implements APIRequest<Array<CoordinateSystemDTO>> {
        response: Array<CoordinateSystemDTO>;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/coordinate_systems`;
        }
    }

}