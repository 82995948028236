<template>
    <div>
        <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.conditions.style}}</span>
        <el-button style="float: right" @click="addCondition">
            {{$locale.interface_editor.component_editor.filters.add_filter}}
        </el-button>
        <el-row v-if="value.length">
            <el-col :span="10">{{ $locale.interface_editor.component_editor.conditions.condition }}</el-col>
            <el-col :span="14">{{ $locale.interface_editor.component_editor.registry_headers.style }}</el-col>
        </el-row>
        <el-row v-for="(condition, index) in value" :key="index">
            <el-col :span="10">
                <el-input v-model="condition.condition"></el-input>
            </el-col>
            <el-col :span="10">
                <el-input v-model="condition.style"></el-input>
            </el-col>
            <el-col :span="4">
                <el-button @click="deleteCondition(index)"
                                         size="mini"
                                         type="danger"
                                         :title="$locale.main.button.delete">
                    <i class="el-icon-delete"></i>
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'style-conditions-editor',
  props: {
    value: {
      type: Array,
      default () { return [] }
    }
  },
  methods: {
    addCondition () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.value.push({
        condition: null,
        style: null
      })
    },
    deleteCondition (index) {
      this.value.splice(index, 1)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
