import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

// ADDED 2 imports
import {
  toWidget,
  viewToModelPositionOutsideModelElement
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import './style.css'
import PlaceholderCommand from './placeholdercommand'

export default class PlaceholderEditing extends Plugin {
  static get requires () { // ADDED
    return [ Widget ]
  }

  init () {
    this._defineSchema()
    this._defineConverters() // ADDED

    this.editor.commands.add('placeholder', new PlaceholderCommand(this.editor))
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('placeholder'))
    )
  }

  _defineSchema () {
    const schema = this.editor.model.schema

    schema.register('placeholder', {
      // Allow wherever text is allowed:
      allowWhere: '$text',

      // The placeholder will act as an inline node:
      isInline: true,

      // The inline widget is self-contained so it cannot be split by the caret and can be selected:
      isObject: true,

      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: [ 'name', 'guid' ]
    })
    schema.addChildCheck((context, childDefinition) => {
      if (context.endsWith('tableCell') && childDefinition.name === 'placeholder') {
        return true
      }
    })
  }

  _defineConverters () { // ADDED
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: [ 'placeholder']
      },
      model: (viewElement, modelWriter) => {
        // Extract the "name" from "{name}".
        const name = viewElement.getChild(0).data
        const guid = viewElement.getAttribute('guid')
        return modelWriter.createElement('placeholder', { name: name, guid: guid })
      }
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'placeholder',
      view: (modelItem, viewWriter) => {
        const widgetElement = createPlaceholderView(modelItem, viewWriter)
        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter)
      }
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'placeholder',
      view: createPlaceholderView
    })

    // Helper method for both downcast converters.
    function createPlaceholderView (modelItem, viewWriter) {
      const name = modelItem.getAttribute('name')
      const guid = modelItem.getAttribute('guid')

      const placeholderView = viewWriter.createContainerElement('span', {
        class: 'placeholder',
        guid: guid
      })
      // Insert the placeholder name (as a text).
      const innerText = viewWriter.createText(name)
      viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), innerText)

      return placeholderView
    }
  }
}
