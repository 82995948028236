<template>
  <el-input
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    type="textarea"
    :rows="1"
    :placeholder="placeholder"
  ></el-input>
</template>

<script>
export default {
  name: 'TextInput',

  props: ['value', 'placeholder']
}
</script>

<style type="text/css"></style>
