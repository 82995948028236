import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'

export enum ActionTypes {
    ADD_SOURCE = 'add_source',
    UPDATE_SOURCE = 'update_source',
    DELETE_SOURCE = 'delete_source',
    GET_SOURCES = 'get_sources',
    COUNT_SOURCES = 'count_sources',
    GET_SOURCE_BY_GUID = 'get_source_by_guid',
    GET_SOURCE_PROJECTS = 'get_source_projects',
    GET_TYPES = 'get_types',
    GET_TYPE_PROPERTIES = 'get_type_properties'
}

export class AddSource implements FluxStandardAction {
    type = 'Source/' + ActionTypes.ADD_SOURCE
    constructor(public payload: SourceDTO){}
}

export class UpdateSource implements FluxStandardAction {
    type = 'Source/' + ActionTypes.UPDATE_SOURCE
    constructor(public payload: SourceDTO){}
}

export class DeleteSource implements FluxStandardAction {
    type = 'Source/' + ActionTypes.DELETE_SOURCE
    constructor(public guid: string){}
}

export class GetSources implements FluxStandardAction {
    type = 'Source/' + ActionTypes.GET_SOURCES
    constructor(public payload: string[][]){}
}

export class CountSources implements FluxStandardAction {
    type = 'Source/' + ActionTypes.COUNT_SOURCES
    constructor(public payload: string[][]){}
}

export class GetSourceByGuid implements FluxStandardAction {
    type = 'Source/' + ActionTypes.GET_SOURCE_BY_GUID
    constructor(public guid: string){}
}

export class GetSourceProjects implements FluxStandardAction {
    type = 'Source/' + ActionTypes.GET_SOURCE_PROJECTS
    constructor(public guid: string){}
}

export class GetTypes implements FluxStandardAction {
    type = 'Source/' + ActionTypes.GET_TYPES
    constructor(){}
}

export class GetTypeProperties implements FluxStandardAction {
    type = 'Source/' + ActionTypes.GET_TYPE_PROPERTIES
    constructor(public val: string){}
}
