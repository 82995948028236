import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { SnapshotDTO } from '@/services/SyncService/domain/model/Snapshot/Snapshot'

export namespace SnapshotAPI {

    export class CountSnapshots implements APIRequest<SnapshotDTO> {
        response: SnapshotDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/syncservice/snapshots?func=count'
        }
    }

    export class GetSnapshots implements APIRequest<SnapshotDTO> {
        response: SnapshotDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/syncservice/snapshots?' +  (new URLSearchParams(payload).toString())
        }
    }

}