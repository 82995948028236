import Vue from 'vue'
import Vuex from 'vuex'
import Tabs from './modules/Tabs'
import Authorization from './modules/Authorization'
import Socket from './modules/Socket'
import Notify from './modules/Notify'
import ProjectTreeElement from '../services/MapEditor/infrastructure/store/modules/ProjectTreeElement'
import Project from '../services/MapEditor/infrastructure/store/modules/Project'
import ProjectGroup from '../services/MapEditor/infrastructure/store/modules/ProjectGroup'
import Layer from '../services/MapEditor/infrastructure/store/modules/Layer'
import Source from '../services/MapEditor/infrastructure/store/modules/Source'
import Entity from '../services/MapEditor/infrastructure/store/modules/Entity'
import CoordinateSystem from '../services/MapEditor/infrastructure/store/modules/CoordinateSystem'
import AffineTransformation from '../services/MapEditor/infrastructure/store/modules/AffineTransformation'
import Topic from '../services/Databus/infrastructure/store/modules/Topic'
import Package from '../services/Databus/infrastructure/store/modules/Package'
import DatabusEvent from '../services/Databus/infrastructure/store/modules/DatabusEvent'
import Route from '../services/Databus/infrastructure/store/modules/Route'
import Dag from '../services/Databus/infrastructure/store/modules/Dag'
import DatabusTask from '../services/Databus/infrastructure/store/modules/DatabusTask'
import XmlRoute from '../services/Databus/infrastructure/store/modules/XmlRoute'
import User from '../services/AccessEditor/infrastructure/store/modules/User'
import Role from '../services/AccessEditor/infrastructure/store/modules/Role'
import SmevSetting from '../services/AccessEditor/infrastructure/store/modules/SmevSetting'
import History from '../services/History/infrastructure/store/modules/History'
import Snapshot from '../services/SyncService/infrastructure/store/modules/Snapshot'
import Address from '../services/AddressService/infrastructure/store/modules/Address'
import Region from '../services/AddressService/infrastructure/store/modules/Region'
import Activity from '../services/ActivityService/infrastructure/store/modules/Activity'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Tabs,
    Authorization,
    Socket,
    Notify,
    ProjectTreeElement,
    Project,
    ProjectGroup,
    Layer,
    Source,
    Entity,
    CoordinateSystem,
    AffineTransformation,
    Topic,
    Package,
    DatabusEvent,
    History,
    Route,
    DatabusTask,
    XmlRoute,
    User,
    Role,
    Dag,
    Snapshot,
    Address,
    Region,
    Activity,
    SmevSetting
  }
})
