<template>
  <el-popover v-model="isVisible" placement="bottom" style="width: 100%" trigger="click">
    <el-input
      placeholder="Поиск по дереву"
      v-model="filterText">
    </el-input>
    <el-tree
      v-if="this.model !== null"
      style="height: 200px; overflow: auto"
      class="custom_scrollbar"
      ref="tree"
      :data="data"
      :props="props"
      node-key="id"
      show-checkbox
      @check-change="selectedAlias"
      :default-expanded-keys="expanded"
      :default-checked-keys="[this.model.id]"
      check-strictly
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span style="width: 100%;">
          {{ node.label }} (id: {{data.id}},
          <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)
        </span>
      </span>
    </el-tree>
    <el-tree v-else
      style="height: 200px; overflow: auto"
      class="custom_scrollbar"
      ref="tree"
      :data="data"
      :props="props"
      node-key="id"
      show-checkbox
      @check-change="selectedAlias"
      check-strictly
      :expand-on-click-node="false"
      :filter-node-method="filterNode">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span style="width: 100%;">
          {{ node.label }} (id: {{data.id}},
          <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)
        </span>
      </span></el-tree>
    <el-footer style="height: 40px;text-align:right; margin-top: 30px">
      <el-button @click="isVisible = !isVisible">{{ $locale.main.button.cancel }}</el-button>
      <el-button @click="clear" type="danger" plain>{{ $locale.main.button.clear }}</el-button>
    </el-footer>
    <el-input v-if="this.model !== null" readonly v-model="model.name" slot="reference" class="extraLine_select" size="mini"></el-input>
    <el-input v-else readonly v-model="model" slot="reference" class="extraLine_select" size="mini"></el-input>
  </el-popover>
</template>

<script>
import Entity from '../../ObjectEditor/Models/Entity'
export default {
  name: 'custom-popover-tree',
  props: ['model', 'data'],
  watch: {
    model (val) {
      this.localValue = val
      this.onMounted()
    },
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    return {
      expanded: [],
      localValue: this.model ? this.model.id : '',
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      },
      isVisible: false,
      filterText: ''
    }
  },
  methods: {
    async onMounted () {
      // this.$refs.tree.setCheckedKeys([])
      // два if по-другому не работает
      if (this.localValue) {
        if (this.localValue.id) {
          let expanded = await new Entity({ id: this.localValue.id }).expanded().$first()
          this.expanded = this.getExpanded(expanded)
        }
      }
    },
    getExpanded (object) {
      let array = []
      if (object && object.parent) {
        array.push(object.parent.id)
        array.push(...this.getExpanded(object.parent))
      }
      return array
    },
    selectedAlias (node, checked) {
      if (checked) {
        this.$refs.tree.setCheckedKeys([node.id])
        this.$emit('update:model', node)
        // this.model = data
        this.isVisible = false
      }
    },
    clear () {
      this.$emit('update:model', {})
      // this.model = {}
      this.isVisible = false
    },
    async mounted () {
      this.onMounted()
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.toLowerCase().search(value.trim().toLowerCase()) !== -1
    }
  }
}
</script>

<style scoped  src='./style/row_formulas.css'>
</style>
