<template>
  <div class="editor_box notify_editor">
    <el-dialog :title="formTitle" :visible="selectType !== null" :before-close="cancelEntity">
      <component
        v-if="selectType"
        :is="forms[selectType]"
        :ref="selectType"
        v-model="createModel"
        :rules='formRules[selectType]'>
      </component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEntity" size="small">
          {{ $locale.main.button.cancel }}
        </el-button>
        <el-button type="primary" @click="saveEntity()" icon="el-icon-success" size="small">
          {{ $locale.main.button.save }}
        </el-button>
      </span>
    </el-dialog>

    <el-container>
      <el-header height="">
        {{ $locale.notify_editor.main.title }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.main.button.add"
                placement="top">
                <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
                  <el-button icon="el-icon-plus" size="small" plain>
                    {{ $locale.main.button.add }}
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="group">{{ $locale.notify_editor.tree.dropdown.group }}</el-dropdown-item>
                    <el-dropdown-item command="notification">{{ $locale.notify_editor.tree.dropdown.notification }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
            </div>
            <el-scrollbar :style="{height:'calc(100vh - 153px)'}" >
              <el-tree
                ref="tree"
                class="tree custom_scrollbar"
                lazy
                node-key="guid"
                :load="loadNode"
                :props="treeProps"
                :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span :class="{ 'selected-node': selectNode && selectNode.data.guid === data.guid }" @click="selectEntity(node, data)" style="width: 100%;">
                    <span style="font-size: 16px;" :class="getTreeIcon(data, node.expanded)"></span> {{ node.label }} <b>(id: {{data.id}})</b>
                  </span>
                  <span v-if="selectNode && selectNode.data.guid === data.guid">
                    <el-dropdown @command="addEntity($event, data)" v-show="data.type === 'group'" trigger="click">
                      <el-button type="text" size="small"><i class="el-icon-plus el-icon-left"></i>{{ $locale.main.button.add }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="group">{{ $locale.notify_editor.tree.dropdown.group }}</el-dropdown-item>
                        <el-dropdown-item command="notification">{{ $locale.notify_editor.tree.dropdown.notification }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-popover
                      placement="top"
                      width="160">
                        <p>{{ $locale.main.message.confirm }}</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="small" type="text">{{ $locale.main.button.cancel }}</el-button>
                          <el-button type="primary" size="small" @click="deleteEntity(data)">{{ $locale.main.button.delete }}</el-button>
                        </div>
                        <el-button type="text" size="small" style="color:red" slot="reference"><i class="el-icon-delete el-icon-left"></i> {{ $locale.main.button.delete }}</el-button>
                    </el-popover>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <div v-if="editorModel" class="tool_box">
              <div class="tool_header">
                {{ formEditTitle }}
              </div>
            </div>
            <template v-if="editorModel">
              <el-main>
                <component v-model="editorModel" @dslBtn='dslBtn' :save='save' ref="notifyComponent" :key="editorModel.id" :is="currentComponent"></component>

                <div style="text-align: right;">
                  <el-button :disabled="disabledBtn" type="primary" @click="save" icon="el-icon-success" size="small">
                    {{ $locale.main.button.save }}
                  </el-button>
                </div>
              </el-main>
            </template>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Node from 'element-ui/packages/tree/src/model/node'
import NotifyModel from './Models/NotifyModel'
import NotifyTree from './Models/NotifyTree'
import GroupModel from './Models/GroupModel'
import Notify from './components/notify/notify'
import Group from './components/group'

import GroupForm from './Forms/groupForm'
import NotifyForm from './Forms/notifyForm'

export default {
  name: 'NotificationEditor',
  components: {
    notification: Notify,
    group: Group
  },
  data () {
    return {
      treeProps: {
        isLeaf: 'is_leaf',
        children: 'children',
        label: 'name'
      },
      loading: true,
      models: {
        group: GroupModel,
        notification: NotifyModel
      },
      selectNode: null,
      editorModel: null,
      createModel: null,
      types: {
        notification: {
          Model: NotifyModel,
          parent_field: 'group_id'
        },
        group: {
          Model: GroupModel,
          parent_field: 'parent_id'
        }
      },
      currentComponent: null,
      forms: {
        group: GroupForm,
        notification: NotifyForm
      },
      formRules: {
        group: {
          name: [
            { required: true, message: this.$locale.notify_editor.main.not_empty, trigger: 'blur' }
          ]
        },
        notification: {
          name: [
            { required: true, message: this.$locale.notify_editor.main.not_empty, trigger: 'blur' }
          ],
          object_id: [
            { required: true, message: this.$locale.notify_editor.main.not_empty, trigger: 'blur' }
          ],
          condition_type: [
            { required: true, message: this.$locale.notify_editor.main.not_empty, trigger: 'blur' }
          ]
        }
      },
      selectType: null,
      defaultModel: {
        group: {
          name: null,
          parent_id: null
        },
        notification: {
          name: null,
          group_id: null,
          object_id: null,
          condition_type: null,
          author_type: 'state',
          recipient_type: 'state',
          condition_match_type: 'any',
          period_type_id: 'once'
        }
      },
      disabledBtn: false
    }
  },
  computed: {
    formTitle () {
      if (this.selectType === 'group') {
        return this.$locale.notify_editor.main.group
      } else {
        return this.$locale.notify_editor.main.notification
      }
    },
    formEditTitle () {
      if (this.selectNode.data.type === 'group') {
        return this.$locale.notify_editor.main.edit_group
      } else {
        return this.$locale.notify_editor.main.edit_notification
      }
    }
  },
  methods: {
    getTreeIcon (data, expanded) {
      if (data.type === 'group' && !expanded) {
        return 'el-icon-folder'
      } else if (data.type === 'group' && expanded) {
        return 'el-icon-folder-opened'
      }

      return 'el-icon-document'
    },
    dslBtn (val) {
      this.disabledBtn = val
    },
    async loadNode (node, resolve) {
      this.loading = true
      if (node.level === 0) {
        resolve(await this.loadTree())
      } else {
        resolve(await this.loadTree(node.data.guid))
      }
      this.loading = false
    },
    async loadTree (parentGuid = null) {
      let data = []
      if (parentGuid === null) {
        data = await new NotifyTree().params({ spec: 'is_null', parent_guid: parentGuid, order: 'row_order:asc,id:desc' }).$get()
      } else {
        data = await new NotifyTree().params({ parent_guid: parentGuid, order: 'row_order:asc,id:desc' }).$get()
      }

      return data
    },
    async selectEntity (node, data) {
      if (!data) {
        return false
      }
      this.selectNode = node
      this.editorModel = await this.models[data.type].find(data.id)
      this.currentComponent = data.type
    },
    async deleteEntity (node) {
      let entity = new this.types[node.type].Model({ id: node.id })
      await entity.delete()
      this.$refs.tree.remove(node.guid)
      this.selectNode = null
      this.editorModel = null
    },
    cancelEntity () {
      this.createModel = null
      this.selectType = null
      this.selectData = null
    },
    async addEntity (type, data = null) {
      this.createModel = new this.models[type](this.defaultModel[type])
      this.selectType = type
      this.selectData = data
    },
    async save () {
      if (!this.editorModel) {
        return false
      }
      await this.editorModel.save()
      this.selectNode.data.name = this.editorModel.name
    },
    async saveEntity () {
      this.$refs[this.selectType].validate(async valid => {
        if (valid) {
          if (this.selectData !== null) {
            this.selectType === 'group'
              ? this.createModel.parent_id = this.selectData.id
              : this.createModel.group_id = this.selectData.id
          }
          let response = await this.createModel.save()
          let dataSource = await new NotifyTree().find(response.guid)

          if (this.selectData !== null) {
            this.$refs.tree.append(dataSource, this.selectData.guid)
          } else {
            let node = new Node({
              parent: this.$refs.tree.root,
              store: this.$refs.tree.store,
              data: dataSource
            })
            node.level = 1
            this.$refs.tree.root.childNodes.push(node)
          }
          this.cancelEntity()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.notify_editor .tree {
  height: calc(100% - 84px);
  overflow: hidden;
  overflow-y: scroll;
}
.notify_editor .title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 14px;
  margin-bottom: 14px;
  height: 28px;
  color: #2C2D35;
}
</style>
