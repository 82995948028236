import ProjectCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectCommandRepositoryInterface'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import store from '@/store'
import { UpdateProject } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectCommandRepository implements ProjectCommandRepositoryInterface
{
    update(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new UpdateProject(item));
    }
}
