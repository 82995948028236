<template>
  <el-checkbox
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :label="`Да / Нет`"
  ></el-checkbox>
</template>

<script>
export default {
  name: 'FlagInput',

  props: ['value', 'placeholder']
}
</script>

<style type="text/css"></style>
