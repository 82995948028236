import QueryInterface from './QueryInterface'

export default abstract class AbstractEntitiesQuery implements QueryInterface
{
    private payload: string[][] | object;

    constructor(payload?: string[][] | object)
    {
        this.payload = payload;
    }

    getPayload(): string[][]|object
    {
        return this.payload;
    }

    abstract getClassName(): string;
}