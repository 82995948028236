<template>
  <div class="rule_group_form">
    <el-form ref="ruleGroupForm" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.task_editor.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.task_editor.labels.name"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RuleGroupForm',

  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {
          parent_tree_element_id: null,
          name: null
        }
      }
    }
  },

  watch: {
    value () {
      this.model = this.value
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: 'Please input field "Name"', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.ruleGroupForm.validate((valid) => {
        if (valid) {
          callback()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style type="text/css"></style>
