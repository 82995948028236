














































































































import Treeselect from '@bingosoftnn/vue-treeselect'
import TopicCreateCommand from '@/services/Databus/application/command/TopicCreateCommand'
import TopicUpdateCommand from '@/services/Databus/application/command/TopicUpdateCommand'
import TopicDeleteCommand from '@/services/Databus/application/command/TopicDeleteCommand'
import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
import TopicsCountQuery from '@/services/Databus/application/query/TopicsCountQuery'
import Topic, { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export default {
  name: 'TopicPanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      topicsPageSize: 0,
      defaultTopicsPageSize: 100,
      topicsCurrentPage: 0,
      topicsPageLimit: 100,
      topicsCount: 0,
      allTopicsCount: 0,
      topics: [],
      topic: null,
      topicDto: null,
      topicDtoInit: null,
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateTopicWindowVisible: false,
      topicTypes: [{
        id: "package",
        name: this.$locale.databus.xml_route_panel.package
      },{
        id: "smev_request",
        name: this.$locale.databus.xml_route_panel.smev_request
      }],
      createTopicRules: {
        code: {
          required: true,
          trigger: 'change',
          validator: (rules, value, callback) => {
            if (value) {
              if (!/^[a-zA-Z_0-9]+[a-zA-Z_0-9]+$/.test(value)) {
                callback(new Error(this.$locale.databus.topic_panel.invalid_topic_code_format))
              } 
            } else {
              callback(new Error(this.$locale.main.message.required_field))
            }
          }
        },
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    topicCreated: function (location) {
      this.loadTopics(() => {
        this.closeCreateTopicWindow();
      });
    },
    isTopicLoading: (state) => state,
    getSaveTopicError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.databus.topic_panel.duplicate_topic_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isTopicLoading() {
      return this.$store.getters['Topic/isLoading'];     
    },
    topicCreated() {
      return this.$store.getters['Topic/getLocation'];
    },
    getSaveTopicError() {
      return this.$store.getters['Topic/getError']; 
    }
  },
  methods: {
    loadTopics(callback) {
      if (this.allTopicsCount == 0) {
        this.countAndLoadTopics(callback);
      } else {
        this.loadAllTopics(callback);
      }
    },
    async countAndLoadTopics(callback) {
      await this.getQueryBus().execute(
        new TopicsCountQuery()
      ).then(data => {
        this.topicsCount = data[0].count;      
        this.loadAllTopics(callback);
      });
    },
    async loadAllTopics(callback) {
      await this.getQueryBus().execute(
        new TopicsQuery({
          limit: this.topicsPageLimit,
          offset: this.topicsCurrentPage
        })
      ).then(data => {
        this.topicsPageSize = this.topicsPageLimit;
        this.topics = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createTopic() {
      this.topicDto = new TopicDTO({});
      this.isCreateTopicWindowVisible = true;
    },
    updateTopic() {
      if (this.topic !== null) {
        this.isCreateTopicWindowVisible = true;
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveTopic() {
      let topic = Topic.create(this.topicDto);
      if (topic.getId() == null) {
        this.getCommandBus().execute(
          new TopicCreateCommand(
            topic.getCode(),
            topic.getName(),
            topic.getDescription(),
            topic.getType()
          )
        );
      } else {
        this.getCommandBus().execute(
            new TopicUpdateCommand(
              topic.getGuid(),
              topic.getCode(),
              topic.getName(),
              topic.getDescription(),
              topic.getType()
            )
          ).then(() => {
            if (this.isDuplicate) {
              this.isDuplicate = false;
            } else {
              this.closeCreateTopicWindow();
            }
          });
      }
    },
    closeCreateTopicWindow() {
      this.topicDto = null;
      this.isCreateTopicWindowVisible = false;
    },
    changeTopic(topicDto) {
      if (topicDto !== null) {
        this.topic = Topic.create(topicDto);
        this.topicDto = topicDto;
      } else {
        this.topic = null;
        this.topicDto = null;
      }
    },
    deleteTopic() {
      if (this.topic == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new TopicDeleteCommand(
              this.topic.getGuid()
            )
          ).then((response) => {
            this.topic = null;
            this.$refs.topics_table.setCurrentRow(null);
            this.loadTopics();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    handleTopicsPageChange(val) {
      val--;
      this.topicsCurrentPage = (val * this.topicsPageLimit);
      this.loadTopics();
    }
  },
  mounted() {
    this.loadTopics();
  }
}
