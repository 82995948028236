<template>
  <el-select
    v-model="local_value" @change="$emit('change', local_value)">
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: ['value'],
  name: 'editor-fit-image',
  data () {
    return {
      local_value: this.value,
      options: [
        'fill', 'contain', 'cover', 'none', 'scale-down'
      ]
    }
  }
}
</script>

<style scoped>

</style>
