<template>
  <div style="height: 100%">
    <grid-layout
      :layout="layoutData"
      :col-num="12"
      :row-height="30"
      :is-draggable="false"
      :is-resizable="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
      v-loading="loading"
      @layout-ready="onLayoutReady"
    >
      <grid-item
        v-for="(container, index) in containers"
        :style="(loading ? 'opacity: 0;' : '') +
        (container.extended ? 'display: inline-table;' : '') +
        (container.extendedToBottom ? 'height:' + getExtendedHeight(container.guid) + 'px' : '')"
        :is-draggable="false"
        :is-resizable="false"
        :ref="container.guid"
        :key="index"
        :x="container.x"
        :y="container.y"
        :w="container.w"
        :h="container.h"
        :i="container.i"
      >
        <!--  -->
        <div class="grid-item custom_scrollbar"
            :style="container.extended ? `display: contents; ${container.style}` : `${container.style}; background-image: url(${container.urlImage}); background-size: ${container.fitImage};`">
          <default-container
            v-if="!container.isHtml"
            :ref="'container_' + container.guid"
            :components="componentsByContainers[container.guid]"
            :model="model"
          ></default-container>
          <html-container
            v-else
            :ref="'container_' + container.guid"
            :components="componentsByHtmlContainers[container.guid]"
            :html="container.html"
            :model="model"
          ></html-container>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import VisibilityMixin from '@/mixins/visibility'

import DefaultContainer from './default-container'
import HtmlContainer from './html-container'

export default {
  components: {
    HtmlContainer,
    DefaultContainer,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  mixins: [VisibilityMixin],
  props: ['layout-data', 'containers', 'components', 'model', 'is-active'],
  name: 'layout',
  data () {
    return {
      notLazyLoadingComponents: [
        'registry/boolean_field',
        'registry/date_field',
        'registry/datetime_field',
        'registry/float_field',
        'registry/integer_field',
        'registry/string_field',
        'registry/text_field',
        'registry/time_field',
        'registry/xref_field',
        'registry/xref_multi_field',
        'basic/a-file'
      ],
      loading: true
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.loading = true
        this.onLayoutReady()
      }
    }
  },
  beforeDestroy () {
    this.containers.forEach((item) => {
      this.$refs[item.guid][0].$destroy()
    })
  },
  computed: {
    componentsByContainers () {
      let result = {}
      this.components.forEach((component) => {
        if (typeof result[component.containerGuid] === 'undefined') {
          result[component.containerGuid] = []
        }
        if (this.notLazyLoadingComponents.includes(component.initialType) || this.isActive) {
          result[component.containerGuid].push(component)
        }
      })
      for (let key in result) {
        if (result.hasOwnProperty(key)) {
          result[key] = result[key].sort((a, b) => {
            return a.orderId - b.orderId
          })
        }
      }

      return result
    },
    componentsByHtmlContainers () {
      let result = {}
      this.components.forEach((component) => {
        if (typeof result[component.containerGuid] === 'undefined') {
          result[component.containerGuid] = []
        }
        result[component.containerGuid].push(component)
      })
      for (let key in result) {
        if (result.hasOwnProperty(key)) {
          result[key] = result[key].sort((a, b) => {
            return a.orderId - b.orderId
          })
        }
      }

      return result
    }
  },
  methods: {
    getExtendedHeight (guid) {
      if (!this.$refs[guid]) {
        return 0
      }
      let container = this.$refs[guid][0]
      let offsetHeight = 0
      let uniqueHeight = []
      let components = [...container.$el.parentNode.children]
      components.forEach((item) => {
        if (item !== container.$el && !item.classList.contains('el-loading-mask')) {
          // offsetHeight += item.offsetHeight
          uniqueHeight.push({ height: item.offsetHeight, top: item.getBoundingClientRect().top })
        }
      })
      // return (container.$el.parentNode.parentNode.offsetHeight - offsetHeight - 25)
      // уникальный объект
      uniqueHeight = uniqueHeight.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.top === item.top
        ))
      )
      uniqueHeight.forEach(item => {
        offsetHeight += item.height
      })
      return (container.$el.parentNode.parentNode.offsetHeight - offsetHeight - 30)
    },
    onLayoutReady () {
      this.onVisibilityChange(() => {
        if (this.$children[0].width === 0) {
          this.$children[0].onWindowResize()
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    /* возвращает все дочерние компоненты слоя */
    getLayoutComponents () {
      let result = {}
      this.containers.forEach((container) => {
        result = { ...result, ...this.$refs[`container_${container.guid}`][0].getRefs() }
      })

      return result
    }
  }

}
</script>

<style scoped>
  .grid-item {
    overflow-y: auto;
    height: 100%;
  }
</style>
<style src="../../InterfaceEditor/InterfaceViewer.css"></style>
