<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-scrollbar style="height: calc(100vh - 205px);">
      <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
        <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor.form.fields.file" prop="file_id">
        <el-input :placeholder="$locale.etl_editor.form.fields.file" size="mini" :value="file">
          <template slot="prepend">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.form.download_file" placement="top">
              <el-button size="mini"  icon="el-icon-download" type="primary" @click="downloadFile()"></el-button>
            </el-tooltip>
          </template>
          <template slot="append">
            <el-upload
              :headers="getHeaders()"
              class="upload-demo"
              ref="upload"
              :action="`${this.$config.api}/registryservice/files`"
              :file-list="fileList"
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
              :multiple="false">
              <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.form.upload_file" placement="top">
                <el-button size="mini"  icon="el-icon-upload" type="primary"></el-button>
              </el-tooltip>
            </el-upload>
          </template>
        </el-input>
      </el-form-item>
    </el-scrollbar>

    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: 'TemplateUpdate',

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  computed: {
    file () {
      return this.fileData ? `${this.fileData.name}.${this.fileData.extension}` : null
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ]
      },
      fileList: [],
      fileData: null
    }
  },

  async mounted () {
    await this.$http
      .get(`${this.$config.api}/registryservice/files/${this.value.file_id}`)
      .then((response) => {
        this.fileData = response.data
      })
  },

  methods: {
    submit (save) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$refs.upload.uploadFiles.length) {
            const fd = new FormData()
            fd.append('file', this.$refs.upload.uploadFiles[0].raw)
            this.$http
              .post(`${this.$config.api}/registryservice/files`, fd)
              .then((response) => {
                this.localValue.file_id = response.data.id
                this.$emit('input', this.localValue)
              })
              .then(async () => {
                await save(false)
                await this.$http
                  .get(`${this.$config.api}/registryservice/files/${this.value.file_id}`)
                  .then((response) => {
                    this.fileData = response.data
                  })
              })
          }
        }
      })
    },

    downloadFile () {
      this.$http({
        method: 'get',
        url: `${this.$config.api}/files/${this.fileData.guid}.${this.fileData.extension}`,
        responseType: 'blob'
      }).then((response) => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] })
        this.downloadBlob(blob, this.fileData.name)
      })
    },

    downloadBlob (blob, filename) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  }
}
</script>

<style type="text/css">

</style>
