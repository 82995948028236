import { MutationTree } from 'vuex'
import { XmlRouteState, initialState } from './state'
import { MutationTypes, XmlRouteCreatedEvent, XmlRouteUpdatedEvent, XmlRouteDeletedEvent } from './mutation-types'

export const mutations: MutationTree<XmlRouteState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.XML_ROUTE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.XML_ROUTE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.XML_ROUTE_CREATED_EVENT]: (state, action: XmlRouteCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.XML_ROUTE_UPDATED_EVENT]: (state, action: XmlRouteUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.XML_ROUTE_DELETED_EVENT]: (state, action: XmlRouteDeletedEvent) => {
    state.isLoading = false;
  }
}