import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import DashboardQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/DashboardQueryRepositoryInterface'
import store from '@/store'
import { GetDashboards } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class DashboardQueryRepository extends AbstractQueryRepository implements DashboardQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetDashboards(payload));
    }
}