






































import ElCollapsibleTabs from '@/core/infrastructure/components/CollapsibleTabs.vue'
import MapPanel from '@/services/MapEditor/infrastructure/components/MapPanel/index.vue'
import FormSet from '@/services/MapEditor/infrastructure/components/FormSet/index.vue'

export default {
  name: 'LayerPanel',
  components: {
    ElCollapsibleTabs,
    MapPanel,
    FormSet
  },
  data () {
    return {
      layerFormSetItems: [{
        type: "layer",
        title: this.$locale.map_editor.layer_form.add_layer_op 
      }],
      layerPanelCollapsed: false,
      activeProject: null,
      activeProjectLayersTab: null,
      projects: [],
      layerPanelDeafultWidth: null,
      layerSelectedForEdit: null,
      showLayerForm: false
    }
  },
  inject: ['getEventBus', 'getProjectTabsContainer'],
  provide() {
    return {
      getLayerSelectedForEdit: this.getLayerSelectedForEdit
    }
  },
  methods: {
    collapsePanel() {
      if (this.layerPanelDeafultWidth == null) {
        this.layerPanelDeafultWidth = this.$refs.layerPanel.$el.offsetWidth;
      }
      this.layerPanelCollapsed = !this.layerPanelCollapsed;
      this.$refs.layerPanel.$el.style.width = this.layerPanelCollapsed ? "0px" : (this.layerPanelDeafultWidth + "px");
      this.getEventBus().$emit('mapUpdateSize');
    },
    showProjectLayerPanel (project) {
      let addTab = true;
      this.projects.forEach(curProject => {
        if (curProject.getId() == project.getId()) {
          addTab = false;
        }
      });
      if (addTab) {
        this.projects.push(project);        
      }
      if (this.projects.length == 1 && this.layerPanelCollapsed) {
        this.collapsePanel();
      }
      this.activeProject = project;
      this.activeProjectLayersTab = String(project.getId());
    },
    removeProjectLayersTab (targetId) {
      for (let i = 0; i < this.projects.length; i += 1) {
        if (this.projects[i].getId() == targetId) {
          this.projects.splice(i, 1);
          if (i != 0) {
            this.activeProjectLayersTab = String(this.projects[i-1].getId());
          }
        }
      }
      if (this.projects.length == 0 && !this.layerPanelCollapsed) {
        this.collapsePanel();
      }
    },
    removeProjectLayerPanel (project) {
      this.removeProjectLayersTab(project.getId());
    },
    createLayerTreeElementWindow () {
      console.log("open");
    },
    fixProjectTabsPosition() {
      let tabsDiv = this.$refs.projectTabs.$el.childNodes[0];
      let container = this.getProjectTabsContainer();
      container.$el.appendChild(tabsDiv);
    },
    addLayerTreeElement(type) {
      if (type == 'add_new_layer') {
        this.showLayerForm = false; 
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "layer",
            title: this.$locale.map_editor.layer_form.add_layer_op,
            action: this.$locale.map_editor.layer_form.save_layer_action
          }];
          this.showLayerForm = true; 
          this.getEventBus().$emit('layerFormVisible');
        });           
      }
    },
    getLayerSelectedForEdit() {
      return this.layerSelectedForEdit;
    },
    selectSourcesFromContextMenu() {
      this.showLayerForm = false;
      this.$nextTick(() => {
        this.layerFormSetItems = [{
          type: "listof_sources",
          title: this.$locale.map_editor.entities.sources,
          hide_submit: true,
          hide_all_sources: true
        }];
        this.showLayerForm = true;
      });      
    },
    selectCoordinateSystemsFromContextMenu() {
      this.showLayerForm = false;
      this.$nextTick(() => {
        this.layerFormSetItems = [{
          type: "listof_coordinate_systems",
          title: this.$locale.map_editor.entities.coordinate_systems,
          hide_submit: true,
          hide_all_coordinate_systems: true
        }];
        this.showLayerForm = false;
      });      
    }
  },
  mounted () {
    this.fixProjectTabsPosition();
    this.collapsePanel();
    this.getEventBus().$on('showProjectLayerPanel', this.showProjectLayerPanel);
    this.getEventBus().$on('removeProjectLayerPanel', this.removeProjectLayerPanel);
    this.getEventBus().$on('hideLayerForm', () => { this.showLayerForm = false });
    this.getEventBus().$on('selectSourcesFromContextMenu', this.selectSourcesFromContextMenu);
  }
}
